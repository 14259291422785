/* global Blockly */
import React from 'react';
import { hamburgerIcon } from "./index";


function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput('WIDGET')
                .appendField("get")
                .appendField(new Blockly.FieldImage(hamburgerIcon, 30, 30, "hamburger icon", null), 'hamburgerIcon')
                .appendField("hamburger menu image");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the image URL of a hamburger menu.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function () {            
            var code = "hamburgerModule.getHeaderImage()";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetHamburgerImage = function (props) {
    const name = 'snap_get_hamburger_image';

    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.widgets.length ? <block type={name}></block> : null;
}

export default GetHamburgerImage
