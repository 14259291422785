/* global Blockly */
import React from 'react';
import MobiControlCore from './MobiControlCore';

function defineBlock(context) {
    let prop = {...context};
    prop.blockText = "get device IP address";
    prop.tooltipText = "Get IP address from MobiControl.";
    prop.responseText = "IP address";
    prop.responseBlockType = "text_response";
    let blockInstance = MobiControlCore(prop);
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let coreResponseCode = block.getJavascriptCore();
            return `Snap.mcDeviceDetails.getIPAddress(${coreResponseCode});\n`;
        }
    }
}

const MobiControlGetIpAddress = function (props) {
    const name = 'snap_mobicontrol_getipaddress';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}></block>
}

export default MobiControlGetIpAddress