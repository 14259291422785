/* global Blockly */
import React from 'react';

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("reload current page")
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Reload current page, triggering OnLoad events.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let code = `navigationModule.reloadPage();\n`;
            return code;
        }
    }
}

const ReloadCurrentPage = function (props) {
    let name = "snap_action_reloadcurrentpage";

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return <block type={name}></block>;
};

export default ReloadCurrentPage
