/* global Blockly */
import React from 'react';
import {ratingIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput('VALUE')
                .appendField("get")
                .appendField(new Blockly.FieldImage(ratingIcon, 50, 30, "rating icon", null), 'ratingIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,'rating', undefined, '- select -'), "ID");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, ["Number"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the number value of a rating widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('ratingIcon') && this.getField('ratingIcon').imageElement_)
                this.getField('ratingIcon').setTooltip('Rating');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block) {
            let id = block.getFieldValue('ID');
            let code = 'JSON.parse($(\'[id="' + id + '"]\').attr("data-selected-value")).value';
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetRating = function(props){

    const name = 'snap_get_rating_value';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }


    if(props.widget){
        return (
            <block type={name}>
                <field name='ID'>{props.widget.id}</field>
            </block>
        )
    } else{
        return <block type={name}/>
    }

}

export default GetRating