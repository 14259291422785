/* global Blockly */
/* global Util */

/*
NOTE: Empty block for Boolean input for customized blocks
*/
const BooleanInput = function () {
    var name = 'boolean';

    Blockly.Blocks[name] = {
        init: function(){

            this.appendDummyInput().appendField('','LABEL');
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            var col = {};
            Object.assign(Blockly.Msg.CONNECTIONS_HUE,col);
            col.primary = '#c85961';
            this.setColour(col);
            this.setOutput(true,["Boolean", "Argument_Boolean"]);

        },
        mutationToDom: function(){
            let path = this.inputList[0].fieldRow.map(function(x){return x.getValue()}.bind(this));
            path = path.slice(1); // get rid of 'value of ' field
            let mutation = Util.dom('mutation',{label:this.getFieldValue('LABEL'),path:path,parent_name:this.parent_name});
            return mutation;
        },
        domToMutation: function(xml){
            var label = (xml.getAttribute('label') || this.getFieldValue('LABEL'));
            this.setLabel(label);
            this.path = xml.getAttribute('path');
            this.path = this.path?this.path.split(','):[];
            this.parent_name = xml.getAttribute('parent_name');
        },
        setLabel: function(label){
            this.getField('LABEL').setValue(label);
        },
        setParentName: function(name){
            this.parent_name = name;
        },
        getLabel: function () {
            return this.getField('LABEL').getValue();
        }
    };

    Blockly.JavaScript[name] = function() {
        var code = this.parent_name;
        return [code,Blockly.JavaScript.ORDER_NONE]
    };

};

export default BooleanInput;
