import React from 'react'
import _ from "underscore";

const Text  = function(props){
        return (
            <category name='Text' id='text' iconclass='category category_text'>
                <label text="Text"></label>
                { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
            </category>
        )
}

export default Text;