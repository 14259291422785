/* global Blockly */
import React from 'react';
import util from '../../../../utils/es5Utils'
import {radioButtonListIcon} from "./index";

function defineGenerators(context, widgets) {

    return {
        /**
         * @return {string}
         */
        "JavaScript": function (block) {
            var id = block.getFieldValue("ID");
            let value = util.parseString(Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE));
            let by = block.getFieldValue('BY');

            let widget = widgets.find(function(x){return x.id===id});
            if(!widget){
                console.warn("radio button ' + id + ' not found");
                return 'console.warn("radio button ' + id + ' not found");\n'
            }

            
            let rbText = value;

            if (block.getInput("VALUE").connection.targetConnection) {
                if (block.getInput("VALUE").connection.targetConnection.sourceBlock_.type === "text" ||
                    block.getInput("VALUE").connection.targetConnection.sourceBlock_.type === "dropdown_options") {
                    rbText = '"' + rbText + '"';
                }
            } else {
                rbText = '""'
            }

            // By text
            if (by==='text') {

                if (value === "__CLEAR__") {
                    return '$(\'[id="' + id + '"] input\').prop(\'checked\', false).checkboxradio().checkboxradio("refresh");\n'
                }

                return (
                    '$(\'[id="' + id + '"] input\').prop(\'checked\', false).checkboxradio().checkboxradio("refresh");\n' +
                    '$("[id=\'' + id + '\'] label").each(function(i,obj){\n' +
                    'if($(obj).text().trim() == ' + rbText + ')\n' +
                    '{ $(obj).siblings("input").prop(\'checked\',true).checkboxradio("refresh"); }\n' +
                    '});\n'
                )

            } else { // By Value
                let rbValue = value;
                let code = `$('[id="${id}"] input').prop('checked', false).checkboxradio().checkboxradio("refresh");
                            $('[id="${id}"] input').each(function(i, obj) {
                                if ($(obj).attr('value').trim() === ${rbValue}) {
                                    $(obj).prop('checked', ${value}).checkboxradio("refresh");
                                }
                            });
                            `;
                return code;
            }
        }
    }
}


function defineBlock(context, radiobuttonLists) {
    var radiobuttonOpts = {};
    radiobuttonLists.forEach(function (widget) {
        radiobuttonOpts[widget.id] = {
            text: [["< no selection >", "__CLEAR__"]].concat(widget.properties.options.map(function (cb) {return [cb.name, cb.name]})),
            value: widget.properties.options.map(function(rb){
                return [rb.properties.value,rb.properties.value]
            })
        }
    });

    return {
        init: function () {
            var widgetDD = new Blockly.FieldWidgetsDropdown(context, "radiobuttonlist",undefined, '- select -');
            this.appendValueInput("VALUE")
                .appendField("set")
                .appendField(new Blockly.FieldImage(radioButtonListIcon, 30, 30, "radio button list icon", null), 'radioButtonListIcon')
                .appendField(widgetDD, "ID")
                .appendField("by")
                .appendField(new Blockly.FieldDropdown([['text', 'text'],['value', 'value']], undefined, undefined, this.updateByType.bind(this)), "BY")
                .appendField("to")
                .setCheck("String");
            widgetDD.setOnChange(this.updateByWidget.bind(this));
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the value of a radio button widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this.update(this.getFieldValue("ID"), this.getFieldValue("BY"));
        },
        onchange: function(){
            if (this.getField('radioButtonListIcon') && this.getField('radioButtonListIcon').imageElement_)
                this.getField('radioButtonListIcon').setTooltip('Radio Button');
        },
        onLoad: function(){
            var id= this.getFieldValue('ID');
            var by= this.getFieldValue('BY');
            var widget = radiobuttonLists.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']];
            } else {
                opts = radiobuttonOpts[id][by] || [['', '']];
            }
            // Run time block
            var value = '';
            try {
                value = Blockly.JavaScript.valueToCode(this, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            } catch (e) { }

            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                var input = this.getInput('VALUE');
                var valuesDDBlock = input.connection.targetBlock();
                if (valuesDDBlock.type !== "dropdown_options")
                    return;
                // var dd = valuesDDBlock.getFieldDropdown();
                var dd = valuesDDBlock.setFieldDropdown(opts);
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function(){
                    dd.setWarning(false);
                    if(closure){
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        updateByType: function(val){ this.update(this.getFieldValue('ID'),val)},
        updateByWidget: function(val){ this.update(val,this.getFieldValue('BY'))},

        // update the last input
        update: function (val, by) {
            var input = this.getInput('VALUE');
            var existingBlock = input.connection.targetBlock();
            var block = this.workspace.newBlock('dropdown_options');
            block.setShadow(true);

            if(this.getField('ID').hasBeenSet){
                var widget = radiobuttonLists.find(function (x) {return x.id === val});
                var opts;
                if (!widget) {
                    console.warn('widget ' + val + " not found");
                    opts = [['','']]
                } else {
                    opts = radiobuttonOpts[val][by]
                }
                block.setFieldDropdown(opts, undefined, '- select -');
            } else{
                var dd = block.setFieldDropdown([['','']], undefined, '- select -');
                dd.setEnabled(false);
            }
            input.connection.connect(block.outputConnection);

            // If there was already a block connected to this input, re-connect it to the input.
            // Still needed to connect the shadow block prior so if user then removes the added block,
            // the underlying shadow block still shows the correct drop-down options for that widget and text/ value option
            if(existingBlock && !existingBlock.isShadow()){
                input.connection.connect(existingBlock.outputConnection);
                if (this.rendered) {
                    existingBlock.initSvg();
                    existingBlock.render();
                }
            } else{
                if(this.rendered){
                    block.initSvg();
                    block.render();
                }
            }
        }
    }
}


const SetRadiobuttonList = function (props) {
    const name = "snap_set_radiobuttonlist";

    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }


    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default SetRadiobuttonList
