/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import { mapIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectMapDropdown = new Blockly.FieldWidgetsDropdown(context, 'map', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField('set')
                .appendField(new Blockly.FieldImage(mapIcon, 30, 30, 'map icon', null), 'mapIcon')
                .appendField(selectMapDropdown, 'ID');

            this.appendValueInput('ZOOM')
                .appendField('zoom to')
                .setCheck("Number");

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the zoom of a map.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let mapId = block.getFieldValue('ID');
            let zoom = Blockly.JavaScript.valueToCode(block, 'ZOOM', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            var code = "Snap.widgets.map.setMapZoom('" + mapId + "'," + zoom + ");\n";
            return code;
        }
    }
}

const SetMapZoom = function (props) {
    const name = 'snap_map_set_zoom';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context, "map").length ?
    <block type={name}>
        <value name="ZOOM">
            <shadow type="math_number"></shadow>
        </value>
    </block> :
    null;
}

export default SetMapZoom
