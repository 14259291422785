/* global Blockly */
import React from 'react';
import util from '../../../utils/es5Utils'

function defineBlock(context, listviews) {
    var ddOpts = {};
    listviews.forEach(function (lv) {
        if(lv != undefined){
            ddOpts[lv.id] = lv.columns
        }
    });


    return {
        ColName : [
            ['- select -', 'ColName']
        ],
        init: function () {
            this.appendDummyInput("VALUE")
                .appendField("get")
                .appendField(new Blockly.FieldWidgetsDropdown(context, "tableview", undefined, "- select -"), "ID")
                .appendField("col")
                .appendField(new Blockly.FieldDropdown([['','']], undefined, '- select -'), "COLUMN")
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip("Return the specified column in a Table widget.");
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(e){
            if(e.name != "ID" || e.newValue == e.oldValue || e.blockId != this.id) return;
            var widgetId = this.getFieldValue('ID');
            this.ID = widgetId;
            this.updateText(widgetId);
            this.updateColumn();
        },
        updateText: function(widgetId){
            var found = false;
            this.oldColumns = this.columns;
            for(var i = 0; i < context.pages.length; i++){
                var page = context.pages[i];
                for(var j = 0; j < page.widgets.length; j++){
                    var widget = page.widgets[j];
                    if(widget.id == widgetId){
                        this.columns = widget.columns;
                        if(this.columns.length > 0){
                            this.ColName = [];
                        }else{
                            this.ColName =
                                [
                                    ['- select -', 'ColName']
                                ];
                        }

                        for(var i = 0; i < this.columns.length; i++){
                            this.ColName[i] = [this.columns[i].name, this.columns[i].id];
                        }
                        found = true;
                        break;
                    }
                }
                if(found) break;
            }
        },
        updateColumn: function() {
            let input = this.getInput("VALUE");
            input.removeField("COLUMN");
            input.appendField(new Blockly.FieldDropdown(this.ColName), "COLUMN");
        },

        /**
         * Create XML to represent list inputs.
         * @return {!Element} XML storage element.
         * @this {Blockly.Block}
         */
        mutationToDom: function() {
            var container = Blockly.utils.xml.createElement('mutation');
            container.setAttribute('ID', this.getFieldValue('ID'));
            container.setAttribute('COLUMN', this.getFieldValue('COLUMN'));
            return container;
        },

        /**
         * Parse XML to restore the list inputs.
         * @param {!Element} xmlElement XML storage element.
         * @this {Blockly.Block}
         */
        domToMutation: function(xml){
            let ID = xml.getAttribute("ID");
            this.updateText(ID);
            this.updateColumn();
        },
    }
}

function defineGenerators(context, widgets) {
    return {
        /*
         * @return {string}
         */
        "JavaScript": function (block) {
            var id = block.getFieldValue("ID");
            // var value = util.parseString(Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE));
            var columnId = block.getFieldValue('COLUMN');
            // var widget = widgets.find(function (x) {return x.id===id});
            // var columnIndex = -1;
            // for(var i = 0; i < widget.columns.length; i++){
            //     if(columnId == widget.columns[i].id){
            //         columnIndex = i;
            //         break;
            //     }
            // }
            // if(columnIndex == -1){
            //     console.warn("column not found");
            //     return 'console.warn("column not found");\n'
            // }
            //getColumn: function (id, columnId)
            var code = "Snap.widgets.tableview.getColumn(`" + id + "`,`" + columnId + "`)";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetColumn = function (props) {
    const name = "snap_get_column_record";

    let widgets = (props.widgets && props.widgets.length > 0) ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetColumn;
