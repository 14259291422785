/* global Blockly */
import React from 'react';
import {textBoxIcon} from "./index";


function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput('VALUE')
                .appendField("get")
                .appendField(new Blockly.FieldImage(textBoxIcon, 30, 30, "test box icon", null), 'textBoxIcon')
                // .appendField(new Blockly.FieldImage(textBoxIcon, 30, 30, "Tsetset", null), 'ID2')
                // .appendField(new Blockly.FieldDropdownSearchCategories(textboxNames), "ID")
                .appendField(new Blockly.FieldWidgetsDropdown(context, "textbox", undefined, '- select -'), "ID")

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, ["String", "Number"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the text inside a text box.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
           if (this.getField('textBoxIcon') && this.getField('textBoxIcon').imageElement_)
               this.getField('textBoxIcon').setTooltip('Textbox');
        }
    }
}

function defineGenerators(){
    return {
        'JavaScript': function(block){
            let id = block.getFieldValue('ID');
            let code  = "Snap.widgets.textbox.getValue(\"" + id + "\")";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetTextboxValue = function(props){
    const name = 'snap_get_textbox_value';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id!==undefined)?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetTextboxValue
