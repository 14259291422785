import React from 'react';
import util from "../../../utils/es5Utils";
import _ from "underscore";

const TableView  = function(props){
    let widgets = util.widgetsOfType(props.context, "tableview");
    let newProps = Object.assign({},props,{widgets});

    return (
        <category name='Table' id='tableview' iconclass='category category_tableview'>
            <label text={"Table"}/>
            {_.values(props.blocks).map((Component, id) => <Component.component { ...newProps } setRestBlockError = {props.setRestBlockError} key={id}/>)}
        </category>
    )
}

export default TableView;
