/* global Blockly */
import React from 'react';
import {radioButtonListIcon} from "./index";

// everything here should be ES5
function defineBlock(context)
{
    return {
        init: function () {
            let valueOrText = new Blockly.FieldDropdown([["value","VALUE"],['text','TEXT'],['value and text','BOTH']]);
            this.appendDummyInput()
                .appendField("get")
                .appendField(valueOrText,"VORT")
                .appendField("of selected")
                .appendField(new Blockly.FieldImage(radioButtonListIcon, 30, 30, "radio button list icon", null), 'radioButtonListIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"radiobuttonlist", undefined, '- select -'), "RB");
            valueOrText.setOnChange(this.ddChange.bind(this));
            // Provide values for all of these
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function(){
                let valOrText = this.getFieldValue('VORT');
                if(valOrText === 'BOTH'){
                    return 'Returns the text and value of the selected radiobutton option as a JSON object: {"text":<...>, "value":<...>}'
                }
                return 'Returns the ' + (valOrText==="VALUE"?"value":'text') + ' of the selected radiobutton option';
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('radioButtonListIcon') && this.getField('radioButtonListIcon').imageElement_)
                this.getField('radioButtonListIcon').setTooltip('Radio Button');
        },
        ddChange:function(val){
            if(val==='BOTH'){
                this.setOutput(true,"Object");
            } else{
                this.setOutput(true,"String")
            }
        }
    }
}

// everything here should be ES5
function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('RB');
            let vort = block.getFieldValue("VORT")
            let code ='(function(){' +
                'var result = {text:"",value:""};' +
                '$(\'[id="' + id + '"] input:checked\').each(function(i,obj)' +
                '{ result.text = $("label[for=\'"+$(obj).attr("id")+"\']").text().trim(); result.value = $(obj).val(); }' +
                ');' +
                'return result' +
                '}())'

            if(vort==="VALUE"){
                code = code + ".value"
            } else if (vort === "TEXT"){
                code = code +".text"
            }

            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const GetValueOfSelectedRadiobutton = function(props) {
    const name = "snap_get_value_of_selected_radiobutton";
    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }


    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)?
                    <field name="RB">{props.widget.id}</field>
                    : null
            }
        </block>
    )
}


export default GetValueOfSelectedRadiobutton