/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {imageIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("set")
                .appendField(new Blockly.FieldImage(imageIcon, 30, 30, "text box icon", null), 'imageIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "image", undefined, '- select -'), "ID")
                .appendField("source to");
            this.appendValueInput('VALUE');
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the source (URL or base64 string) for image widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
            if (this.getField('imageIcon') && this.getField('imageIcon').imageElement_)
                this.getField('imageIcon').setTooltip('Image control');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let code = `Snap.widgets.image.setSource("${id}", ${value});\n`;
            return code;
        }
    }
}

const SetImageSource = function (props) {
    let name = 'snap_set_image_source';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null
    }
    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="VALUE">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetImageSource
