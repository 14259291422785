/* global Blockly */
import React from 'react'

function defineBlock() {
    return {
        init: function () {
            var MODE =
                [
                    [Blockly.Msg['LISTS_SET_INDEX_SET'], 'SET'],
                    [Blockly.Msg['LISTS_SET_INDEX_INSERT'], 'INSERT']
                ];
            this.WHERE_OPTIONS =
                [
                    [Blockly.Msg['LISTS_GET_INDEX_FROM_START'], 'FROM_START'],
                    [Blockly.Msg['LISTS_GET_INDEX_FROM_END'], 'FROM_END'],
                    [Blockly.Msg['LISTS_GET_INDEX_FIRST'], 'FIRST'],
                    [Blockly.Msg['LISTS_GET_INDEX_LAST'], 'LAST'],
                    [Blockly.Msg['LISTS_GET_INDEX_RANDOM'], 'RANDOM']
                ];
            this.setHelpUrl(Blockly.Msg['LISTS_SET_INDEX_HELPURL']);
            this.setStyle('list_blocks');
            this.appendDummyInput()
                .appendField(Blockly.Msg['LISTS_SET_INDEX_INPUT_IN_LIST'])
                .appendField(new Blockly.FieldVariable(), "LIST");
            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown(MODE), 'MODE')
                .appendField('', 'SPACE');
            this.appendDummyInput('AT');
            this.appendValueInput('TO')
                .appendField(Blockly.Msg['LISTS_SET_INDEX_INPUT_TO']);
            this.setInputsInline(true);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip(Blockly.Msg['LISTS_SET_INDEX_TOOLTIP']);
            this.updateAt_(true);
            // Assign 'this' to a variable for use in the tooltip closure below.
            var thisBlock = this;
            this.setTooltip(function () {
                var mode = thisBlock.getFieldValue('MODE');
                var where = thisBlock.getFieldValue('WHERE');
                var tooltip = '';
                switch (mode + ' ' + where) {
                    case 'SET FROM_START':
                    case 'SET FROM_END':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FROM'];
                        break;
                    case 'SET FIRST':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FIRST'];
                        break;
                    case 'SET LAST':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_LAST'];
                        break;
                    case 'SET RANDOM':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_RANDOM'];
                        break;
                    case 'INSERT FROM_START':
                    case 'INSERT FROM_END':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FROM'];
                        break;
                    case 'INSERT FIRST':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FIRST'];
                        break;
                    case 'INSERT LAST':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_LAST'];
                        break;
                    case 'INSERT RANDOM':
                        tooltip = Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_RANDOM'];
                        break;
                }
                if (where === 'FROM_START' || where === 'FROM_END') {
                    tooltip += '  ' + Blockly.Msg['LISTS_INDEX_FROM_START_TOOLTIP']
                        .replace('%1',
                            thisBlock.workspace.options.oneBasedIndex ? '#1' : '#0');
                }
                return tooltip;
            });
        },
        /**
         * Create XML to represent whether there is an 'AT' input.
         * @return {Element} XML storage element.
         * @this Blockly.Block
         */
        mutationToDom: function () {
            let container = Blockly.utils.xml.createElement('mutation');
            let isAt = this.getInput('AT').type === Blockly.INPUT_VALUE;
            container.setAttribute('at', isAt);
            return null;
        },
        /**
         * Parse XML to restore the 'AT' input.
         * @param {!Element} xmlElement XML storage element.
         * @this Blockly.Block
         */
        domToMutation: function (xmlElement) {
            // Note: Until January 2013 this block did not have mutations,
            // so 'at' defaults to true.
            let isAt = (xmlElement.getAttribute('at') !== 'false');
            this.updateAt_(isAt);
        },
        /**
         * Create or delete an input for the numeric index.
         * @param {boolean} isAt True if the input should exist.
         * @private
         * @this Blockly.Block
         */
        updateAt_: function (isAt) {
            // Destroy old 'AT' and 'ORDINAL' input.
            this.removeInput('AT');
            this.removeInput('ORDINAL', true);
            // Create either a value 'AT' input or a dummy input.
            if (isAt) {
                let input = this.appendValueInput('AT').setCheck('Number');
                let block = this.workspace.newBlock("math_whole_number");
                block.setShadow(true);
                block.getField("NUM").setValue(1);
                input.connection.connect(block.outputConnection);
                if(this.rendered){
                    block.initSvg();
                    block.render();
                }

                if (Blockly.Msg['ORDINAL_NUMBER_SUFFIX']) {
                    this.appendDummyInput('ORDINAL')
                        .appendField(Blockly.Msg['ORDINAL_NUMBER_SUFFIX']);
                }
            } else {
                this.appendDummyInput('AT');
            }
            let menu = new Blockly.FieldDropdown(this.WHERE_OPTIONS, function (value) {
                let newAt = (value === 'FROM_START') || (value === 'FROM_END');
                // The 'isAt' variable is available due to this function being a closure.
                if (newAt !== isAt) {
                    let block = this.getSourceBlock();
                    block.updateAt_(newAt);
                    // This menu has been destroyed and replaced.  Update the replacement.
                    block.setFieldValue(value, 'WHERE');
                    return null;
                }
                return undefined;
            });
            this.moveInputBefore('AT', 'TO');
            if (this.getInput('ORDINAL')) {
                this.moveInputBefore('ORDINAL', 'TO');
            }

            this.getInput('AT').appendField(menu, 'WHERE');
            if (this.rendered) {
                this.initSvg();
                this.render();
            }
        }
    };

}

function defineGenerators() {
    return {
        'JavaScript': function (block) {// Set element at index.
            // Note: Until February 2013 this block did not have MODE or WHERE inputs.
            // var list = Blockly.JavaScript.valueToCode(block, 'LIST',
            //     Blockly.JavaScript.ORDER_MEMBER) || '[]';
            var list = Blockly.JavaScript.variableDB_ ?
                Blockly.JavaScript.variableDB_.getName(block.getField('LIST').getText(), Blockly.Variables.NAME_TYPE) : [];

            let mode = block.getFieldValue('MODE') || 'GET';
            let where = block.getFieldValue('WHERE') || 'FROM_START';
            let value = Blockly.JavaScript.valueToCode(block, 'TO',
                Blockly.JavaScript.ORDER_ASSIGNMENT) || 'null';
            // Cache non-trivial values to variables to prevent repeated look-ups.
            // Closure, which accesses and modifies 'list'.
            function cacheList() {
                if (list.match(/^\w+$/)) {
                    return '';
                }
                let listVar = Blockly.JavaScript.variableDB_.getDistinctName(
                    'tmpList', Blockly.Variables.NAME_TYPE);
                let code = 'var ' + listVar + ' = ' + list + ';\n';
                list = listVar;
                return code;
            }

            switch (where) {
                case ('FIRST'): {
                    if (mode === 'SET') {
                        return list + '[0] = ' + value + ';\n';
                    } else if (mode === 'INSERT') {
                        return list + '.unshift(' + value + ');\n';
                    }
                    break;}
                case ('LAST'): {
                    if (mode === 'SET') {
                        let code = cacheList();
                        code += list + '[' + list + '.length - 1] = ' + value + ';\n';
                        return code;
                    } else if (mode === 'INSERT') {
                        return list + '.push(' + value + ');\n';
                    }
                    break;}
                case ('FROM_START'): {
                    let at = Blockly.JavaScript.getAdjusted(block, 'AT');
                    if (mode === 'SET') {
                        return list + '[' + at + '] = ' + value + ';\n';
                    } else if (mode === 'INSERT') {
                        return list + '.splice(' + at + ', 0, ' + value + ');\n';
                    }
                    break;}
                case ('FROM_END'): {
                    let at = Blockly.JavaScript.getAdjusted(block, 'AT', 1, false,
                        Blockly.JavaScript.ORDER_SUBTRACTION);
                    let code = cacheList();
                    if (mode === 'SET') {
                        code += list + '[' + list + '.length - ' + at + '] = ' + value + ';\n';
                        return code;
                    } else if (mode === 'INSERT') {
                        code += list + '.splice(' + list + '.length - ' + at + ', 0, ' + value +
                            ');\n';
                        return code;
                    }
                    break;}
                case ('RANDOM'): {
                    let code = cacheList();
                    let xVar = Blockly.JavaScript.variableDB_.getDistinctName(
                        'tmpX', Blockly.Variables.NAME_TYPE);
                    code += 'var ' + xVar + ' = Math.floor(Math.random() * ' + list +
                        '.length);\n';
                    if (mode === 'SET') {
                        code += list + '[' + xVar + '] = ' + value + ';\n';
                        return code;
                    } else if (mode === 'INSERT') {
                        code += list + '.splice(' + xVar + ', 0, ' + value + ');\n';
                        return code;
                    }
                    break;}
            }
            throw Error('Unhandled combination (lists_setIndex).');
        }
    }
}

const ListsSetIndex2 = function (props) {
    let name = "lists_setIndex_2";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="AT">
                <shadow type="math_whole_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
    )
}
export default ListsSetIndex2
