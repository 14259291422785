import { call, put } from 'redux-saga/effects';
import { ActionTypes, Actions } from '../actions';
import {saveMyBlock, saveJsBlock, loadJsBlocks, saveLibrary, loadLibraries, deleteLibrary, deleteBlock} from './network';

// All data sagas to add to middleware.
export default [
    [ActionTypes.DO_SAVE_MY_BLOCK, doSaveMyBlock],
    [ActionTypes.DO_SAVE_JS_BLOCK, doSaveJsBlock],
    [ActionTypes.GET_JS_BLOCKS, getJsBlocks],
    [ActionTypes.DO_SAVE_LIBRARY, doSaveLibrary],
    [ActionTypes.GET_LIBRARIES, getLibraries],
    [ActionTypes.DO_DELETE_LIBRARY, doDeleteLibrary],
    [ActionTypes.DO_DELETE_BLOCK, doDeleteBlock]
];

function * doSaveMyBlock ({ payload }) {
    console.log("saving my block");
    yield put(Actions.doingSaveMyBlock());
    const response = yield call(saveMyBlock, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
        // handle error here
        // yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSaveMyBlock(response.data));
    }
}


function * doSaveJsBlock ({ payload }) {
    yield put(Actions.doingSaveJsBlock());
    const response = yield call(saveJsBlock, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.doneSaveJsBlock(response.data));
    }
}

function * getJsBlocks ({ payload }) {
    yield put(Actions.loadingJsBlocks());
    const response = yield call(loadJsBlocks, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.loadedJsBlocks(response.data));
    }
}

function * doSaveLibrary ({ payload }) {
    yield put(Actions.doingSaveLibrary());
    const response = yield call(saveLibrary, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.doneSaveLibrary(response.data));
    }
}

function * getLibraries ({ payload }) {
    yield put(Actions.doingDeleteLibrary());
    const response = yield call(loadLibraries, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.loadedLibraries(response.data));
    }
}

function * doDeleteLibrary ({ payload }) {
    yield put(Actions.doingDeleteLibrary());
    const response = yield call(deleteLibrary, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.doneDeleteLibrary(payload));
    }
}

function * doDeleteBlock ({ payload }) {
    yield put(Actions.doingDeleteBlock());
    const response = yield call(deleteBlock, payload);
    if (!response.successful) {
        console.error("error => ", response.error);
    } else {
        yield put(Actions.doneDeleteBlock(payload));
    }
}