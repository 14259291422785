import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const sectionIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M3,3V27H27V3Zm22.4,7.63h-7v-6h7ZM11.53,12.24H25.4V20H11.53ZM16.76,4.6v6H4.6v-6ZM4.6,12.24H9.93V25.4H4.6ZM11.53,25.4V21.6H25.4v3.8Z'/%3E%3C/svg%3E";

export default function Section(props){
    let widgets = util.widgetsOfType(props.context, 'section');
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Sections"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components;
}

