import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './index.scss';
import blocks from './assets/blocks.png';

class SaveBlockTypeErrorDialog extends Component {

    render() {

        return (
            <div id={"save-block-type-error-dialog-container"}>
                <div id={"save-block-type-error-dialog-wrapper"}>
                    <div className={"save-block-type-error-dialog-header"}>
                        <h4>Save Block Error</h4>
                    </div>
                    <div className={"save-block-type-error-dialog-body"}>
                        <p>
                            Only statement blocks can be saved. If you would like to save this block,
                            please insert it into a statement block before saving.
                        </p>
                        <p>
                            Statement blocks have previous and next connections to other statement blocks.
                            For example:
                        </p>
                        <img src={blocks} alt={"Blocks"} />
                        <p id={"block-type-description"}>
                            These blocks can be saved.
                            <span id={"block-type-description-space"}></span>
                            These blocks cannot be saved.
                        </p>
                    </div>
                    <div className="save-block-type-error-dialog-footer">
                        <Link to={`/${window.location.search}`}>
                            <button className="btn-OK">OK</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default SaveBlockTypeErrorDialog