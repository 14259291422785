/* global Blockly */
import React from 'react';
import {textBoxIcon} from "./index";


function defineBlock(context) {
    const thickAdd = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-add-SNAP%3C/title%3E%3Cg id='icon-add-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' stroke='%23E4E7E9' cx='14' cy='14' r='11.5'%3E%3C/circle%3E%3Cpath d='M14,3 C20.0751322,3 25,7.92486775 25,14 C25,20.0751322 20.0751322,25 14,25 C7.92486775,25 3,20.0751322 3,14 C3,7.92486775 7.92486775,3 14,3 Z M14.25,8 L13.75,8 C13.3357864,8 13,8.33578644 13,8.75 L13,8.75 L13,13 L8.75,13 C8.37030423,13 8.05650904,13.2821539 8.00684662,13.6482294 L8,13.75 L8,14.25 C8,14.6642136 8.33578644,15 8.75,15 L8.75,15 L13,15 L13,19.25 C13,19.6296958 13.2821539,19.943491 13.6482294,19.9931534 L13.75,20 L14.25,20 C14.6642136,20 15,19.6642136 15,19.25 L15,19.25 L15,15 L19.25,15 C19.6296958,15 19.943491,14.7178461 19.9931534,14.3517706 L20,14.25 L20,13.75 C20,13.3357864 19.6642136,13 19.25,13 L19.25,13 L15,13 L15,8.75 C15,8.37030423 14.7178461,8.05650904 14.3517706,8.00684662 L14.25,8 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const thickRemove = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-remove-SNAP%3C/title%3E%3Cg id='icon-remove-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' stroke='%23E4E7E9' cx='14' cy='14' r='11.5'%3E%3C/circle%3E%3Cpath d='M14,3 C20.0751322,3 25,7.92486775 25,14 C25,20.0751322 20.0751322,25 14,25 C7.92486775,25 3,20.0751322 3,14 C3,7.92486775 7.92486775,3 14,3 Z M18.75,12.5 L9.25,12.5 C8.55964406,12.5 8,13.0596441 8,13.75 L8,13.75 L8,14.25 C8,14.9403559 8.55964406,15.5 9.25,15.5 L9.25,15.5 L18.75,15.5 C19.4403559,15.5 20,14.9403559 20,14.25 L20,14.25 L20,13.75 C20,13.0596441 19.4403559,12.5 18.75,12.5 L18.75,12.5 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const responseBlockType = "text_response";
    return {
        showOnError: false, // is "onError" block expanded
        init: function () {
            this.appendDummyInput('APP')
                .appendField("open app")
                .appendField(new Blockly.FieldDropdown([["URI - iOS & Android", "uri"], ["Package - Android", "package"]]), "INPUTTYPE");
            this.appendValueInput('VALUE')
                .setOnNewRow(false)
                .setCheck("String");

            this.appendValueInput('RESPONSE')
                .appendField('on success ')
                .setOnNewRow(true);
            let responseParam = this.getInput("RESPONSE");
            responseParam.connection.readOnly = responseBlockType;

            // on success block
            this.appendStatementInput("SUCCESS")
            this.appendDummyInput("ADD")
                .appendField(new Blockly.FieldImage(thickAdd, 24, 24, "*", function() {
                    this.updateErrorVisibility(true);
                }.bind(this)));

            // on error block
            this.appendDummyInput('ERROR_PARAM')
                .appendField('on error ')
                .setVisible(false);
            this.appendDummyInput("MINUS")
                .appendField(new Blockly.FieldImage(thickRemove, 24, 24, "*", function() {
                    this.updateErrorVisibility(false);
                }.bind(this)))
                .setAlign(Blockly.ALIGN_RIGHT)
                .setVisible(false);
            this.appendStatementInput('ERROR')
                .setVisible(false);

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Open an app using URI or package');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
            this.setOnChange(this.changeHandler);
        },
        // show/hide "onError" block
        updateErrorVisibility: function(showOnError) {
            if (this.showOnError === showOnError) {
                return;
            }
            this.showOnError = showOnError;
            let plusButton = this.getInput('ADD');
            if (!!plusButton) {
                // mark all blocks in "ERROR" statement visible/invisible
                plusButton.setVisible(!this.showOnError);
                let startHiding = false;
                for (let i in this.inputList) {
                    if (startHiding) {
                        let innerInput = this.inputList[i];
                        innerInput.setVisible(this.showOnError);
                        if (!!innerInput.connection && !!innerInput.connection.targetBlock()) {
                            innerInput.connection.targetBlock().initSvg();
                            innerInput.connection.targetBlock().render();
                        }
                    } else if (this.inputList[i].name === 'ADD'){
                        startHiding = true;
                    }
                }
                this.initSvg();
                this.render();
            }
        },
        // base change handler
        changeHandler: function(event) {
            if (!this.firstInit) {
                // for avoid blocks inside "error" block not rendered properly
                this.updateErrorVisibility(true);
                this.updateErrorVisibility(false);
                this.firstInit = true;
            }

            // auto-recreate response block when you drag one out
            let response = this.getInput('RESPONSE');
            if (!response.connection.isConnected()) {
                // make sure there is always onsuccess response block
                let block = this.createSuccessResponseBlock(this.workspace);
                response.connection.connect(block.outputConnection)
            } else {
                if (response.connection.targetBlock().type === responseBlockType) {
                    response.connection.targetBlock().setParentId(this.id);
                }
            }
        },
        // creates "response" block for onSuccess block
        createSuccessResponseBlock: function(ws) {
            ws = ws || this.workspace;
            let jsonBlock = ws.newBlock(responseBlockType);
            jsonBlock.setLabel("Response Message");
            jsonBlock.setParentId(this.id);
            if (this.rendered) {
                jsonBlock.initSvg();
                jsonBlock.render();
            }
            return jsonBlock;
        },
    }
}

function defineGenerators(){
    return {
        'JavaScript': function(block){
            let inputType = block.getFieldValue('INPUTTYPE');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let successCode = Blockly.JavaScript.statementToCode(this, 'SUCCESS');
            let errorCode = Blockly.JavaScript.statementToCode(this, 'ERROR');
            let callback =`function(message) {
                var ResponseScope = ResponseScope || {};
                ResponseScope['${this.id}'] = message;
                ${successCode} 
            }, function(message) {
                var ResponseScope = ResponseScope || {};
                ResponseScope['${this.id}'] = message;
                ${errorCode}
            }`;
            let code = `Snap.app.openAppUsingURI(${value}, ${callback});`;
            if (inputType === "package")  {
                code = `Snap.app.openAppUsingPackage(${value}, ${callback});`;
            }
            return code;
        }
    }
}

const GetTextboxValue = function(props){
    const name = 'snap_app_openapp';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context);

    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="VALUE">
                <shadow type="fixed_width_text">
                    <mutation length="29"></mutation>
                </shadow>
            </value>
        </block>
    )
}

export default GetTextboxValue
