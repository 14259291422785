import React from 'react';
import { Route } from 'react-router-dom';
import Workspace from './containers/Workspace';
import Documentation from './components/Documentation';

export default (
    <div className="content-container">
        <Route exact path='/docs' component={ Documentation }/>
        <Route path='/' component={ Workspace }/>
    </div>
)
