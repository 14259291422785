/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import { mapIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectMapDropdown = new Blockly.FieldWidgetsDropdown(context, 'map', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField('add to')
                .appendField(new Blockly.FieldImage(mapIcon, 30, 30, 'map icon', null), 'mapIcon')
                .appendField(selectMapDropdown, 'ID');

            this.appendValueInput('LATITUDE')
                .appendField('marker with coordinates')
                .setCheck("Number");

            this.appendValueInput('LONGITUDE')
                .setCheck("Number");

            this.appendValueInput('WIDGET_COLOR')
                .appendField(', color')
                .setCheck("Colour");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Add marker to a map.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let mapId = block.getFieldValue('ID');
            let latitude = Blockly.JavaScript.valueToCode(block, 'LATITUDE', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            let longitude = Blockly.JavaScript.valueToCode(block, 'LONGITUDE', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            let color = Blockly.JavaScript.valueToCode(block, 'WIDGET_COLOR', Blockly.JavaScript.ORDER_NONE);
            var code = `Snap.widgets.map.addMarker('${mapId}', ${latitude}, ${longitude}, ${color})`;
            return [code, Blockly.JavaScript.ORDER_NONE];;
        }
    }
}

const AddMarker = function (props) {
    const name = 'snap_map_add_marker';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context, "map").length ?
    <block type={name}>
        <value name="LATITUDE">
            <shadow type="math_number"></shadow>
        </value>
        <value name="LONGITUDE">
            <shadow type="math_number"></shadow>
        </value>
        <value name="WIDGET_COLOR">
            <shadow type="colour_picker">
                <field name="COLOUR">#FF0000</field>
            </shadow>
        </value>
    </block> :
    null;
}

export default AddMarker
