import React from 'react'

export function ListsCreateWith() {
    return (
        <block type="lists_create_with"></block>
    )
}

export function ListsRepeat() {
    return (
        <block type="lists_repeat">
            <value name="NUM">
                <shadow type="math_whole_number">
                    <field name="NUM">5</field>
                </shadow>
            </value>
        </block>
    )
}

export function ListsLength() {
    return (
        <block type="lists_length"></block>
    )
}

export function ListsIsEmpty() {
    return (
        <block type="lists_isEmpty"></block>
    )
}

export function ListsIndexOf() {
    return (
        <block type="lists_indexOf">
            <value name="VALUE"></value>
        </block>
    )
}

export function ListsGetIndex() {
    return (
        <block type="lists_getIndex">
            <value name="AT">
                <shadow type="math_whole_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
    )
}

{/*<ListsSetIndex2 context={props.context}/>*/}

export function ListsGetSublist() {
    return (
        <block type="lists_getSublist">
            <value name="AT1">
                <shadow type="math_whole_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>

            <value name="AT2">
                <shadow type="math_whole_number">
                    <field name="NUM">3</field>
                </shadow>
            </value>

        </block>
    )
}

export function ListsSplit() {
    return (
        <block type="lists_split">
            <value name="DELIM">
                <shadow type="text">
                    <field name="TEXT">,</field>
                </shadow>
            </value>
        </block>
    )
}

export function ListsSort() {
    return (
        <block type="lists_sort"></block>
    )
}