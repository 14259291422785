/* global Blockly */
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import NameBlock from "./Steps/NameBlock";
import DefineArguments from "./Steps/DefineArguments";
import AssignArguments from "./Steps/AssignArguments";
import './index.scss';
import {blockToXmlSanitized} from "../../utils";

class SaveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            blockToSaveXml: null,
            refresh: false
        };
        this.initialState = this.state;
    }

    componentDidMount() {
        this.props.sendValidatedToParent(false);
    }

    componentWillUnmount() {
        this.props.sendValidatedToParent(true);
        Blockly.Workspace.getAll()[0].markFocused();
    }

    _incrementStep () {
        this.setState({ step: this.state.step + 1 });
    }

    _decrementStep () {
        this.setState({ step: this.state.step - 1 });
    }

    _handleNameBlockNext (blockInfo) {
        this.setState({ blockInfo });

        let createNewLibrary = true;
        for (let i = 0; i < this.props.libraries.length; i++) {
            if (blockInfo.library === this.props.libraries[i].name) {
                createNewLibrary = false;
            }
        }

        if (blockInfo.library && blockInfo.library != "" && createNewLibrary) {
            this._createNewLibrary(blockInfo.library);
        }
        this._incrementStep();
    }

    _handleDefineArgumentsNext (blockArgs, blockDom) {
        this.setState({ blockArgs });
        let blockText = Blockly.Xml.domToText(blockDom);
        this.setState({ blockToSaveXml: blockText });
        this._incrementStep();
    }

    _createNewLibrary (name) {
        this.props.saveLibrary({
            'userId': this.props.userId,
            'tenantId': this.props.tenantId,
            'name': name,
            'version': '1.0',
            'dependentLibrary': []
        })
    }

    _handleAssignBlocksNext (block) {
        const { blockInfo } = this.state;

        let xml_text = Blockly.Xml.domToText(blockToXmlSanitized(block));

        let libraryId = "";
        for (let i = 0; i < this.props.libraries.length; i++) {
            if (blockInfo.library === this.props.libraries[i].name) {
                libraryId = this.props.libraries[i].id;
            }
        }

        /* Proceed to save my block */
        this.props.saveMyBlock({
            userId: this.props.userId,
            tenantId: this.props.tenantId,
            name: blockInfo.name,
            xml: xml_text,
            code: 'currently not available',
            language: 'javascript',
            version: '1.0.0',
            type: 'myBlock',
            keywords: blockInfo.keywords ? blockInfo.keywords.toString() : " ",
            libraryId: libraryId,
            description: blockInfo.description ?  blockInfo.description.toString() : " "
        })

        this.props.history.push(`/${window.location.search}`);
    }

    render() {
        if (!this.props.block) { return null; }
        let step = this.state.step;
        const { blockInfo, blockArgs, blockToSaveXml} = this.state;

        return (
            <div id={"save-modal-container"}>
                {
                    step === 1 && <NameBlock
                                    handleCancel={() => {}}
                                    values={blockInfo}
                                    handleNext={this._handleNameBlockNext.bind(this)}
                                    libraries={this.props.libraries}
                                    JSFlag={"Inputs"}
                                    title={"Save Block to Library"}/>
                }
                {
                    step === 2 && <DefineArguments
                                    values={blockArgs}
                                    blockName={blockInfo.name}
                                    block={this.props.block}
                                    step={this.state.step}
                                    toolbox={this.props.toolbox}
                                    type={"blank_block"}
                                    handleBack={this._decrementStep.bind(this)}
                                    handleCancel={() => {}}
                                    handleNext={this._handleDefineArgumentsNext.bind(this)}
                                    description={this.state.blockInfo.description ? this.state.blockInfo.description : ""}
                                    JSFlag={"Inputs"}
                                    title={"Save Block to Library"}/>
                }
                {
                    step === 3 && <AssignArguments
                                    values={blockInfo}
                                    blockName={blockInfo.name}
                                    xmlText = {blockToSaveXml}
                                    args={blockArgs}
                                    handleBack={this._decrementStep.bind(this)}
                                    handleCancel={() => {}}
                                    handleNext={this._handleAssignBlocksNext.bind(this)} />
                }
            </div>
        );
    }
}

export default withRouter(SaveModal);
