/* global Blockly */
import React, { Component } from 'react';

// replace RountToDecimal 'math_round_to_decimal', 'math'

class RoundToDecimal extends Component {
    constructor(props) {
        super(props);

        // Often want some shared variables for defining the block and its generator(s) -> eg. a list of
        // all checkboxes for a block pertaining only to checkboxes. Define them as attributes of this.vars
        // then will be easier to translate these components to the backend.
        this.vars = {};

        Blockly.Blocks['math_round_to_decimal'] = this.block(this.props.context);

        let generators = this.generators(this.props.context);
        for(let i in generators){
            Blockly[i]['math_round_to_decimal'] = generators[i]
        }
    }


    // Everything should be in ES5
    block() {
        return {
            init:function(){
                this.appendValueInput("VALUE")
                    .appendField("round")
                    .setCheck("Number");
                this.appendValueInput("DECIMALS")
                    .appendField("to")
                    .setCheck("Number");
                this.appendDummyInput()
                    .appendField("decimals");
                // Provide values for all of these
                this.setPreviousStatement(false);
                this.setNextStatement(false);
                this.setOutput(true,"Number"); // add appropriate checks
                this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
                this.setColour(Blockly.Msg.MATH_HUE);
                this.setTooltip('round a value to the specified number of decimal places');
                this.setHelpUrl(Blockly.BASE_HELP_URL+'#math');
            }
        }
    }

    // Everything should be in ES5
    generators() {
        return {
            'JavaScript': function (block) {
                let val = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
                let decimals = Blockly.JavaScript.valueToCode(block, 'DECIMALS', Blockly.JavaScript.ORDER_NONE);
                let multiplier = 'Math.pow(10,'+decimals+')';
                let code = '(Math.round(toNumber('+val+')*'+multiplier+')/'+multiplier+')';
                return [code, Blockly.JavaScript.ORDER_ATOMIC]
            }
        }
    }

    defaultXml() {
        return (<block type="math_round_to_decimal"></block>)
    }

    // Should return null if block shouldn't appear in toolbox
    render(){
        return (
            <block type='math_round_to_decimal'>
                <value name="VALUE"><shadow type="math_number"><field name="NUM">0.123</field></shadow></value>
                <value name="DECIMALS"><shadow type="math_number"><field name="NUM">2</field></shadow></value>
            </block>
        )
    }
}

RoundToDecimal.category = 'Math';

export default RoundToDecimal