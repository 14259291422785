/* global Blockly */

// replace Checkbox 'checkbox', null'

function defineBlock(){
    // everything in init should be ES5
    return {
        init:function(){
            this.appendDummyInput()
                .appendField(" ")
                .appendField(new Blockly.FieldCheckbox(true),"VALUE")
                .appendField(" ");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true,"Boolean"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('If checked, returns \'true\', otherwise \'false\'');
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#logic');
        }
    }
}

function defineGenerators() {
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            var code = block.getFieldValue("VALUE");
            return [String(code==="TRUE"), Blockly.JavaScript.ORDER_ATOMIC];
        }
    }
}

const Checkbox = function(props){
    Blockly.Blocks['checkbox'] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for(let i in generators){
        Blockly[i]['checkbox'] = generators[i]
    }

    return null
}

export default Checkbox