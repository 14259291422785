var util = {};

util.flatten = function(array){
    let l = [];
    for (let i = 0; i < array.length; i++) {
        if(array[i] instanceof Array){
            l = l.concat(util.flatten(array[i]))
        } else{
            l.push(array[i])
        }
    }
    return l
};

util.contextHasWidget = function(context,type){
    return util.widgetsOfType(context,type).length>0;
}

util.widgetsOfType = function(context, type){
    let allWidgets = context.pages.map(function(page){
        return page.widgets.map(function(widget){
            widget.page = {name:page.name,id: page.id, formName:page.formName};
            return widget
        });
    });
    let flattened = util.flatten(allWidgets);
    let filtered = flattened.filter(function(x){return !type || x.type === type});
    return filtered;
};

util.parseString = function(str){
    if(typeof str !== 'string'){
        return ''+str
    }

    if((str[0]==="'" && str[str.length-1]==="'") || (str[0] === '"' && str[str.length-1] === '"') ){
        str = str.substring(1);
        str = str.substring(0,str.length-1);
    }
    return str
}


util.displayPageName = function (page){
    if(page.formName){
        return page.formName + " > " + page.name
    }
    else {
        return page.name
    }
}

util.trimmedPageName = function (page, length){
    let halfLen = Math.floor(length/2);
    let pageName;

    if(page.name.length>halfLen){
        pageName = page.name.substring(0,halfLen-2) + "..." + page.name.substring(page.name.length-(halfLen+1),page.name.length);
    } else{
        pageName = page.name;
    }

    if(page.formName){
        let formName;
        if(page.formName.length>halfLen){
            formName = page.formName.substring(0,halfLen-2) + "..." + page.formName.substring(page.formName.length-(halfLen+1),page.formName.length);
        } else{
            formName = page.formName;
        }
        return formName + " > " + pageName;
    }

    return pageName;
}

export default util