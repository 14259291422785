/* global Blockly */
import React from 'react';
import { getPagesFromContext } from "../helpers";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("reload page")
                .appendField(new Blockly.FieldDropdown(context.formPages), "PAGE");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Reload target page, triggering OnLoad events.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let pageDataGenyId = block.getFieldValue('PAGE').split('<<<>>>').slice(-1);
            let code = `navigationModule.reloadPage('${pageDataGenyId}');\n`;
            return code;
        }
    }
}

const ReloadPage = function (props) {
    let name = "snap_action_reloadpage";

    let context = {
        pages: props.context.pages.concat(props.context.webPages),
        workflowItems: props.context.workflowItems
    };
    context.formPages = getPagesFromContext(context);

    Blockly.Blocks[name] = defineBlock(context);
    let generators = defineGenerators(context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return context.formPages.length > 0 ?
        <block type={name}></block>
        : null;
};

export default ReloadPage
