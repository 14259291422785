/* global Blockly */
/* global Util */
import React from 'react';

function defineBlock(props) {
  let editImage = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 3 25 25'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23141e25;%7D%3C/style%3E%3C/defs%3E%3Cpath d='M19.68,2,3.4,18.28,2,26l7.72-1.4L26,8.32Zm4,6.32-13.95,14-4-4,14-13.95ZM4.82,19.7,8.3,23.18,4.05,24Z'/%3E%3C/svg%3E";

  return {
    code: '',

    init: function () {
      this.appendValueInput('UNIQUEID')
        .appendField('Run function name')
        .setCheck("String");

      this.appendValueInput('PARAMS')
        .appendField('parameter')
        .setCheck("Object");

      this.appendDummyInput()
        .appendField(new Blockly.FieldImage(editImage, 20, 20, "edit", this.workspace.isCreateBlockWorkspace ? null : function () { this.openJSEditor(); }.bind(this)), "edit");

      this.setTooltip('Run a custom JavaScript function.');
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour(Blockly.Msg.MYBLOCKS_HUE);
    },

    mutationToDom: function () {
      let mutation = Blockly.utils.xml.createElement('mutation');
      mutation.setAttribute('blockcode', this.code);
      return mutation;
    },

    domToMutation: function (xml) {
      let code = xml.getAttribute("blockcode");
      this.setBlockCode(code);
    },

    openJSEditor: function () {
      props.openJSEditor(this.id, this.setBlockCode.bind(this), this.code);
    },

    setBlockCode(code) {
      this.code = code;
    }
  }
}

function defineGenerators() {
  return {
    'JavaScript': function (block) {
      let uniqueId = Blockly.JavaScript.valueToCode(block, 'UNIQUEID', Blockly.JavaScript.ORDER_NONE);
      let params = Blockly.JavaScript.valueToCode(block, "PARAMS",Blockly.JavaScript.ORDER_NONE);;
      return '/*' + uniqueId + '*/\n(function (params) {\n' + this.code + '\n})(' + params + ');\n';
    }
  }
}

const CustomJS = function (props) {
  let name = 'snap_jsblocks_custom_js';

  Blockly.Blocks[name] = defineBlock(props);
  let generators = defineGenerators();
  for (let i in generators) {
    Blockly[i][name] = generators[i];
  }

  return (
    <block type={name}>
      <value name="UNIQUEID">
        <block type="text"/>
      </value>
      <value name="PARAMS">
        <block type="make_json_with"/>
      </value>
    </block>
  )
}

export default CustomJS