import React from 'react'
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const radioButtonListIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M15,4.88A10.12,10.12,0,1,1,4.88,15,10.13,10.13,0,0,1,15,4.88M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3Z'/%3E%3Ccircle cx='15' cy='15' r='6.17'/%3E%3C/svg%3E";

export default function RadioButtonList(props) {
    let widgets = util.widgetsOfType(props.context, 'radiobuttonlist');
    let defaultWidget = {
        "id": "",
        "name": "",
        "type": "< no radio buttons >",
        "properties": {
            "options": [{
                "name": "",
                "id": "",
                "properties": {"value": ""}
            }]
        }
    };
    let newProps = Object.assign({}, props, {widgets, defaultWidget});

    let components = [
        newProps.widgets.length > 0 ? <label text={"Radio Buttons"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];
    return components;
}

