/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {ratingIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("VALUE")
                .appendField("set")
                .appendField(new Blockly.FieldImage(ratingIcon, 50, 30, "rating icon", null), 'ratingIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"rating", undefined, '- select -'), "ID")
                .appendField("to")
                .setCheck(["Number"]);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setOutput(false);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the rating value of the provided rating widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('ratingIcon') && this.getField('ratingIcon').imageElement_)
                this.getField('ratingIcon').setTooltip('Rating');
        }
    }
}

function defineGenerators() {
    // var widgets = util.widgetsOfType(context,"rating");
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue("ID");
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            // var widget = widgets.find(function(x){return x.id===id});
            // var max = widget.properties.max;
            // var min = widget.properties.min;
            let code = '' +
                'var BlocklyTmps = BlocklyTmps || {};\n' +
                'BlocklyTmps["'+block.id+'"] = JSON.parse($(\'[id="' + id + '"]\').attr("data-selected-value"));\n' +
                'BlocklyTmps["'+block.id+'"].value = Math.max(0,Math.min(10,toNumber('+value+',0)));\n' +
                'RatingModule.initRatingControl(BlocklyTmps["'+block.id+'"]);\n';

            return code;
        }
    }
}

const SetRating = function (props) {
    let name = "snap_set_rating";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    let widgets = util.widgetsOfType(props.context, 'rating');

    if (widgets.length < 1) {return null;}

    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined )
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="VALUE">
                <shadow type="math_whole_number"/>
            </value>
        </block>
    )
};

export default SetRating