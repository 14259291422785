/* global Blockly */
import React from 'react'
import { WORKFLOW_APP_TYPE_ID } from "../../../../utils";
import { userIcon } from './index';

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(userIcon, 30, 30, "user icon", null), 'userIcon')
                .appendField(new Blockly.FieldVariable(),'VARIABLE')
                .appendField('first name');

            this.appendStatementInput('CALLBACK');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the first name of a logged in user.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#variables");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let variableName = Blockly.JavaScript.variableDB_.getName(block.getField('VARIABLE').getText(), Blockly.Variables.NAME_TYPE);
            let callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
            return 'Snap.user.getFirstName(function(' + variableName + ') {' + callback + '});\n';
        }
    }
}

const GetUserFirstName = function (props) {
    const name = 'snap_user_get_first_name';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.context.appTypeId === WORKFLOW_APP_TYPE_ID ? <block type={name}></block> : null;
}

export default GetUserFirstName
