import React from 'react';

export function Text() {
    return (
        <block type="text"></block>
    )
}

export function TextJoin() {
    return (
        <block type="text_join"></block>
    )
}

export function TextAppend() {
    return (
        <block type="text_append">
            <value name="TEXT">
                <shadow type="text"></shadow>
            </value>
        </block>
    )
}

export function TextLength() {
    return (
        <block type="text_length">
            <value name="VALUE">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}

export function TextIsEmpty() {
    return (
        <block type="text_isEmpty">
            <value name="VALUE">
                <shadow type="text">
                    <field name="TEXT"></field>
                </shadow>
            </value>
        </block>
    )
}

export function TextIndexOf() {
    return (
        <block type="text_indexOf">
            <value name="VALUE">
            </value>
            <value name="FIND">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}

export function TextCharAt() {
    return (
        <block type="text_charAt">
            <value name="VALUE">
            </value>
        </block>
    )
}

export function TextGetSubstring() {
    return (
        <block type="text_getSubstring">
            <value name="STRING">
            </value>
        </block>
    )
}

export function TextChangeCase() {
    return (
        <block type="text_changeCase">
            <value name="TEXT">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}

export function TextTrim() {
    return (
        <block type="text_trim">
            <value name="TEXT">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}

export function TextPrint() {
    return (
        <block type="text_print">
            <value name="TEXT">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}

export function TextPromptExt() {
    return (
        <block type="text_prompt_ext">
            <value name="TEXT">
                <shadow type="text">
                    <field name="TEXT">abc</field>
                </shadow>
            </value>
        </block>
    )
}