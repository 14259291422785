import { ActionTypes } from '../actions';

const defaultState = {
    inTraffic: false,
    isInitialized: false,
    isMockData: true,
    context: null
};

export default (state = defaultState, action) => {
    try {


        switch(action.type) {

            case ActionTypes.INITIALIZE_APP:
                return {
                    ...state,
                    inTraffic: true
                };

            case ActionTypes.LOADED_INIT_INFO:
                return {
                    ...state,
                    context: action.payload,
                    isInitialized: true,
                    isMockData: false
                };

            case ActionTypes.FAILED_INIT_INFO:
                return {
                    ...state,
                    context: action.payload,
                    isInitialized: true,
                    isMockData: true
                };

            case ActionTypes.LOADED_NEW_VARIABLE: {
                let environmentVariables = action.payload;
                let context = Object.assign({}, state.context, {environmentVariables});
                return {
                    ...state,
                    context,
                };}

            case ActionTypes.ON_SEARCH:
                return {
                    ...state,
                    searchQuery: action.payload
                };

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
