/* global Blockly */

// replace DropdownOptions 'dropdown_options', undefined'

function defineBlock() {

    // var dds = util.widgetsOfType(context,"dropdown");
    // var ddNames = dds.map(function(x){return [x.name,x.id]});

    // everything in init should be ES5
    return {
        customColor: null,

        init: function () {
            this.menuGen = [['', '']];
            this.appendDummyInput("MAIN")
                .appendField(new Blockly.FieldDropdown([['', '']]), "VALUE");
            // .appendField(new Blockly.FieldDropdown(function(){return this.menuGen}.bind(this)), "VALUE");
            // this.menuGen = [["test","test"]]
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(!!this.customColor ? this.customColor : Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Dropdown widget option');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');

        },
        getFieldDropdown: function(){
            return this.getField('VALUE');
        },
        setFieldDropdown: function(opts, validator, select, onchange){
            this.menuGen = opts;
            this.ddSelectOption = select;
            let input = this.getInput('MAIN');
            input.removeField('VALUE');
            let dd = new Blockly.FieldDropdown(opts, validator, select, onchange);
            input.appendField(dd ,'VALUE');
            this.setShadow(true);
            return dd;
        },
        domToMutation(xml) { 
            let list = xml.getAttribute("list").split(",");
            this.menuGen = [];
            for (var i = 0; i < list.length; i = i + 2) {
                this.menuGen.push([list[i], list[i + 1]]);
            }
            this.ddSelectOption = xml.getAttribute('ddSelectOption');
            this.setFieldDropdown(this.menuGen,undefined,this.ddSelectOption);
            this.customColor = xml.getAttribute('customColor');
            if (!!this.customColor && this.customColor !== null) {
                this.setColour(this.customColor);
            }
        },
        mutationToDom: function () {
            let dom = document.createElement('mutation');
            dom.setAttribute('list', this.menuGen);
            dom.setAttribute('ddSelectOption', this.ddSelectOption);
            dom.setAttribute('customColor', !!this.customColor ? this.customColor : '');
            return dom;
        }, setDDList:function(l){
            this.setFieldDropdown(l)
        }
        // setDDList: function (l) {
        //     this.menuGen = l;
        //     return;
            // let input = this.getInput("MAIN");
            // input.removeField("VALUE");
            // var field = new Blockly.FieldDropdown(l);
            // input.appendField(field, "VALUE");
            // @uhg - for some reason needs this or the dd field will render a rectangle and larger...
            // this.setShadow(true);
        // }
    }
}

function defineGenerators()
{
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            let code = block.getFieldValue("VALUE");
            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}


const DropdownOptions = function (props) {
    Blockly.Blocks['dropdown_options'] = defineBlock(props.context);

    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i]['dropdown_options'] = generators[i]
    }

    return null;
}

export default DropdownOptions