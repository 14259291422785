/* global Blockly */
import React from 'react';

const BLOCK_NAME = "snap_debug_log_info";


const LogInfo = () => {
    Blockly.Blocks[BLOCK_NAME] = {
        init: function () {
            this.appendDummyInput()
                .appendField("log info to device");
            this.appendValueInput("DATA")
                .setCheck(null);
            this.setColour(Blockly.Msg.DEBUG_HUE);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Logs specified block value to the information logs');
        }
    };

    Blockly.JavaScript[BLOCK_NAME] = function(block) {
        let value = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE);
        value = 'JSON.stringify('+value+')'
        return 'Snap.eventLogs.writeLog(1, ' + value + ');\n';
    };

    return (
        <block type={BLOCK_NAME}>
            <value name={"DATA"}><shadow type={"text"}></shadow></value>
        </block>
    )
};

export default LogInfo;