/* global Blockly */

import React from 'react';
import MobiControlCore from './MobiControlCore';
import util from '../../../utils/es5Utils'

function defineBlock(context) {
    let prop = {...context};
    prop.tooltipText = "Get custom attribute value by specified key from MobiControl.";
    prop.responseText = "attribute value";
    prop.responseBlockType = "text_response";
    let blockInstance = MobiControlCore(prop);

    // override default fields
    blockInstance.addFields = function() {
        this.appendValueInput("VALUE")
            .appendField("get custom value by key")
            .setCheck("String");
    }
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let coreResponseCode = block.getJavascriptCore();
            let value = util.parseString(Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE));
            return `Snap.mcDeviceDetails.getCustomAttributeValue('${value}', ${coreResponseCode});\n`;
        }
    }
}

const MobiControlGetCustomAttribute = function (props) {
    const name = 'snap_mobicontrol_getcustomattribute';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return  <block type={name}>
                <value name="VALUE">
                    <shadow type="text"></shadow>
                </value>
            </block>
}

export default MobiControlGetCustomAttribute