import React from 'react';
import CustomJS from './CustomJS';

export default function JSBlocks(props){
    return (
        <category name='Code Blocks' id='JSVariables' iconclass='category category_env-variables'>
            <button text="Create Custom JS Code Block" callbackKey="createCustomJSBlock"></button>
            <CustomJS context={props.context} openJSEditor={props.openJSEditor}/>
        </category>
    );
}