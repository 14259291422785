import { BRIDGE, API } from '../utils';

// Pre blockly render data fetch
export const loadInitInfo = () => BRIDGE.send('initInfo', {}, 2000);
export const sendCodeToParent = payload => BRIDGE.send('output', payload);
export const closeBlockly = () => BRIDGE.send('close');
export const getNewVariable = payload => BRIDGE.send('getNewVariable', payload);
export const sendValidatedToParent = (isValid) => BRIDGE.send('validated', isValid);

// API requests for my blocks
export const saveMyBlock = payload => API.POST('/block/create', payload);
export const saveJsBlock = payload => API.POST('/block/create', payload);
export const loadJsBlocks = ({ userId, appId }) => API.GET(`/myblock/user/${userId}/app/${appId}`);

//API requests for library
export const saveLibrary = payload => API.POST('/library/create', payload);
export const loadLibraries = ({ userId, tenantId}) => API.GET(`/user/${userId}/tenant/${tenantId}/library`);
export const deleteLibrary = payload => API.DELETE('/library/delete', payload);
export const deleteBlock = payload => API.DELETE('/block/delete', payload);
