/* global Blockly */
import React from 'react';
import { getPagesFromContext } from "../helpers";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("navigate to ")
                .appendField(new Blockly.FieldDropdown(context.formPages), "TOPAGE");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Navigate to a new page in the application.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let toPage = block.getFieldValue('TOPAGE').split('<<<>>>').slice(-1);

            let code = (
                'if (appWidgetMetaData[\'' + toPage + '\']) {\n' +
                'if (appWidgetMetaData[\'' + toPage + '\'].widgetType == "webpage") {\n' +
                'appContextModule.pushIntoNavigationStack(\'' + toPage + '\');\n' +
                'webPageModule.openWebPage(\'' + toPage + '\');\n' +
                '} else {\n' +
                'var navEvent = new navigateEventService();\n' +
                'navEvent.executeNavigate(\'' + toPage + '\');\n' +
                '} }\n'
            )

            return code;
        }
    }
}

const SnapActionNavigate = function (props) {

    let name = "snap_action_navigate";

    let context = {
        pages: props.context.pages.concat(props.context.webPages),
        workflowItems: props.context.workflowItems
    };
    context.formPages = getPagesFromContext(context);

    Blockly.Blocks[name] = defineBlock(context);

    let generators = defineGenerators(context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (context.formPages.length === 0) {
        return null;
    }

    return (
        <block type={name}></block>
    )
};

export default SnapActionNavigate
