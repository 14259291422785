import React from 'react';
import _ from "underscore";

const MobiControl  = function(props){

    return (
        <category name='MobiControl' id='mobicontrol' iconclass='category category_mobicontrol'>
            <label text={"MobiControl"}/>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id} />)}
        </category>
    )
}

export default MobiControl