/* global Blockly */
import util from "../../../utils/es5Utils";
import SimpleRestService from "../Connections/SimpleRestService";
import RestService from "../Connections/RestService";

import _ from 'underscore'

const Legacy = function(props){
    var widgets = util.widgetsOfType(props.context,'textbox');
    var widgetNames = _.map(widgets,x=>{
        let occurrences = _.filter(widgets,y=>{return x.name===y.name}).length;
        let name = occurrences>1?x.name+` (${x.pageName})`:x.name;
        return [name,x.id]
    });

    widgetNames = widgetNames.length>0?widgetNames:[['< no textboxes >','none']];

    Blockly.Blocks['setter'] = {
        init(){
            this.appendValueInput('VALUE')
                .appendField("Set")
                .appendField(new Blockly.FieldDropdown(widgetNames),"ID")
                .appendField("to");
            this.setPreviousStatement(true,null);
            this.setNextStatement(true,null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the text inside a text box.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#widget-values');
        }
    };

    Blockly.JavaScript['setter'] = function(block){
        let id = block.getFieldValue('ID');
        let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
        let code = `document.getElementById('${id}').value = ${value};`;
        return code;
    };

    Blockly.Blocks['getter'] = {
        init(){
            this.appendDummyInput('VALUE')
                .appendField("get")
                .appendField(new Blockly.FieldDropdown(widgetNames),"ID");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the text inside a text box.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#widget-values');
        }
    };

    Blockly.JavaScript['getter'] = function(block){
        let id = block.getFieldValue('ID');
        return [`document.getElementById('${id}').value`, Blockly.JavaScript.ORDER_NONE];
    }

    SimpleRestService(props.context);
    RestService(props.context);

    return null;
}
export default Legacy