/* global Blockly */

import React from 'react';
import MobiControlCore from './MobiControlCore';
import util from '../../../utils/es5Utils'

function defineBlock(context) {
    let prop = {...context};
    prop.tooltipText = "Get Android device details value by specified key from MobiControl.";
    prop.responseText = "device details value";
    prop.responseBlockType = "text_response";
    let blockInstance = MobiControlCore(prop);

    Object.assign(blockInstance, {
        initialized: false,
        currentCategory: null,
        displayedKeysPerCategory: null,
        isMoving: false,

        // predefined categories
        categories: [
            [' device_info  ', 'device_info'], 
            [' memory_info  ', 'memory_info'],
            [' hardware_info  ', 'hardware_info'],
            [' support_information  ', 'support_information'],
            [' telephony_info  ', 'telephony_info'],
            [' network_info  ', 'network_info']
        ],

        // predefined keys
        keys: {
            'device_info': [
                [' deviceId  ', 'deviceId'],
                [' deviceName  ', 'deviceName'],
                [' isAdminActive  ', 'isAdminActive'],
                [' isEnrolled  ', 'isEnrolled']
            ],
            'memory_info': [
                [' systemStorageTotal', 'systemStorageTotal'],
                [' systemStorageFree', 'systemStorageFree'],
                [' internalStorageTotal', 'isAdminternalStorageTotalinActive'],
                [' internalStorageFree', 'internalStorageFree'],
                [' externalStorageTotal', 'externalStorageTotal'],
                [' externalStorageFree', 'externalStorageFree'],
                [' sdStorageTotal', 'sdStorageTotal'],
                [' sdStorageFree', 'sdStorageFree'],
                [' ramTotal', 'ramTotal'],
                [' ramFree', 'ramFree']
            ],
            'hardware_info': [
                [' serialNumber', 'serialNumber'],
                [' processorName', 'processorName'],
                [' processorType', 'processorType'],
                [' phoneId', 'phoneId'],
                [' imei', 'imei'],
                [' esn', 'externalSesnorageFree'],
                [' androidId', 'androidId'],
                [' androidDeviceId', 'androidDeviceId'],
                [' isEmulator', 'isEmulator']
            ],
            'support_information': [
                [' supportCompanyName', 'supportCompanyName'],
                [' supportCompanyTelephoneNumber', 'supportCompanyTelephoneNumber'],
                [' supportCompanyEmail', 'supportCompanyEmail'],
                [' supportCompanyIconPath', 'supportCompanyIconPath']
            ],
            'telephony_info': [
                [' mobileTxBytes', 'mobileTxBytes'],
                [' mobileRxBytes', 'mobileRxBytes'],
                [' isSimReady', 'isSimReady'],
                [' isInRoaming', 'isInRoaming'],
                [' isAvailable', 'isAvailable'],
                [' isApnReady', 'isApnReady'],
                [' subscriberId', 'subscriberId'],
                [' simSerialNumber', 'simSerialNumber'],
                [' simCarrier', 'simCarrier'],
                [' signalStrength', 'signalStrength'],
                [' phoneType', 'phoneType'],
                [' phoneNumber', 'phoneNumber'],
                [' currentCarrier', 'currentCarrier'],
                [' asuLevel', 'asuLevel'],
                [' networkType', 'networkType']
            ],
            'network_info': [
                [' activeWifiMacAddress', 'activeWifiMacAddress'],
                [' allLocalActiveIpAddresses', 'allLocalActiveIpAddresses'],
                [' bluetoothMacAddress', 'bluetoothMacAddress'],
                [' connectionType', 'connectionType'],
                [' hostName', 'hostName'],
                [' ipAddressType', 'ipAddressType'],
                [' networkMacAddress', 'networkMacAddress'],
                [' macAddress', 'macAddress'],
                [' operatorName', 'operatorName'],
                [' rssi', 'rssi'],
                [' ssid', 'ssid'],
                [' bssid', 'bssid'],
                [' isNetworkAvailable', 'isNetworkAvailable'],
                [' isWifiEnabled', 'isWifiEnabled'],
            ],
        },

        // override default fields
        addFields: function() {
            this.appendValueInput("CATEGORY")
                .appendField("get Android device details by category ")
                .setCheck(['String', 'dropdown_options']);
            this.appendValueInput("KEY")
                .appendField(" key ")
                .setCheck(['String', 'dropdown_options']);
        },

        // change handler
        customChangeHandler: function(event) {
            // make sure category block is always connected
            let categoryInput = this.getInput('CATEGORY');
            if (!categoryInput.connection.isConnected()) {
                // auto-create drop-down block with preselected categories
                let ddCategoryBlock = this.workspace.newBlock('dropdown_options');
                ddCategoryBlock.setFieldDropdown(this.categories, undefined, undefined);
                ddCategoryBlock.setColour(Blockly.Msg.MOBICONTROL_HUE);
                ddCategoryBlock.customColor = Blockly.Msg.MOBICONTROL_HUE;
                ddCategoryBlock.setShadow(true);
                if (this.rendered) {
                    ddCategoryBlock.initSvg();
                    ddCategoryBlock.render();
                }
                categoryInput.connection.connect(ddCategoryBlock.outputConnection);
            } else {
                let categoryTargetBlock = categoryInput.connection.targetBlock();
                if (categoryTargetBlock.type === 'dropdown_options' && categoryTargetBlock.customColor !== Blockly.Msg.MOBICONTROL_HUE) {
                    // when rendered for the first time, the color is reset for existing block - refresh it
                    categoryTargetBlock.setColour(Blockly.Msg.MOBICONTROL_HUE);
                    categoryTargetBlock.customColor = Blockly.Msg.MOBICONTROL_HUE;
                }
            }
            
            // check if category is changed
            let category = Blockly.JavaScript.valueToCode(this, "CATEGORY", Blockly.JavaScript.ORDER_NONE);
            if (category.length > 0 && category[0] === '\'' && category[category.length - 1] === '\'') {
                category = category.substring(1, category.length - 1);
            }
            this.onCategoryChanged(category);
            this.initialized = true;

            // when starting to drag block from toolbox to canvas, its inner blocks are off,
            // when released - only "value" block is off;
            // this is caused when "value"/"key" block is connected, though the origin of issue is unknown
            // this is a workaround, to fix dragging issue (it still might glitch a littble bit)
            if (event.type === Blockly.Events.MOVE/* there is no OnDragStarted event */ && !this.isMoving) {
                this.childBlocks_.forEach((childBlock) => {
                    childBlock.render();
                });
                this.isMoving = true;
                this.unselect(); // block appears to be selected while dragging - fixed
            }
            if (event.type === Blockly.Events.END_DRAG && this.isMoving) {
                this.childBlocks_.forEach((childBlock) => {
                    childBlock.render();
                });
                this.isMoving = false;
            }
        },

        // update category and get new keys to display
        updateCategory: function(category) {
            this.currentCategory = category;
            let keys = [['- no category found -', '']];
            if (!!this.currentCategory && !!this.keys[this.currentCategory]) {
                // valid category, use predefined keys
                keys = this.keys[this.currentCategory];
            }
            return keys;
        },

        // handle category changed - auto-populate with predefined keys
        onCategoryChanged: function(category) {
            let keyInput = this.getInput("KEY");
            if (!keyInput) {
                // corrupted block
                return;
            }
            if (this.currentCategory === category && this.initialized) {
                // same category
                return;
            }
            
            // update connected inner block
            let keyTargetBlock = null;
            if (keyInput.connection.isConnected()) {
                keyTargetBlock = keyInput.connection.targetBlock();
                if (keyTargetBlock.type !== 'dropdown_options') {
                    // non-default dropdown blocks will remain
                    return;
                }
                
                if (this.initialized) {
                    // update existing dropdown with new keys
                    keyTargetBlock.setFieldDropdown(this.updateCategory(category), undefined, undefined);
                    if (keyTargetBlock.customColor !== Blockly.Msg.MOBICONTROL_HUE) {
                        // "key" rendered for the first time
                        keyTargetBlock.setColour(Blockly.Msg.MOBICONTROL_HUE);
                        keyTargetBlock.customColor = Blockly.Msg.MOBICONTROL_HUE;
                    }
                    return;
                }
                keyTargetBlock.dispose();
            }

            // recreate the dropdown with new keys
            let ddKeyBlock = this.workspace.newBlock('dropdown_options');
            ddKeyBlock.setFieldDropdown(this.updateCategory(category), undefined, undefined);
            ddKeyBlock.setColour(Blockly.Msg.MOBICONTROL_HUE);
            ddKeyBlock.customColor = Blockly.Msg.MOBICONTROL_HUE;
            ddKeyBlock.setShadow(true);
            if (this.rendered) {
                ddKeyBlock.initSvg();
                ddKeyBlock.render();
            }
            keyInput.connection.connect(ddKeyBlock.outputConnection);
        }
    });
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let coreResponseCode = block.getJavascriptCore();
            let category = Blockly.JavaScript.valueToCode(block, "CATEGORY", Blockly.JavaScript.ORDER_NONE);
            let categoryBlock = block.getInput("CATEGORY");
            if (categoryBlock.connection.isConnected() && categoryBlock.connection.targetConnection.sourceBlock_.type === "dropdown_options") {
                category = "'" + category + "'";
            }
            let value = Blockly.JavaScript.valueToCode(block, "KEY", Blockly.JavaScript.ORDER_NONE);
            let valueBlock = block.getInput("KEY");
            if (valueBlock.connection.isConnected() && valueBlock.connection.targetConnection.sourceBlock_.type === "dropdown_options") {
                value = "'" + value + "'";
            }
            return `Snap.mcDeviceDetails.getAndroidDeviceDetails(${category}, ${value}, ${coreResponseCode});\n`;
        }
    }
}

const MobiControlGetAndroidDeviceDetails = function (props) {
    const name = 'snap_mobicontrol_getandroiddevicedetails';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return  <block type={name}>
            </block>
}

export default MobiControlGetAndroidDeviceDetails
