import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './index.css';


export default class ErrorMessage extends Component{

    _dismiss() {
        if (this.props.dismissErr && typeof this.props.dismissErr === 'function'){
            this.props.dismissErr();
        }
    }

    render(){
        let buttons = null;
        if(this.props.buttons){
            buttons = this.props.buttons.map((x, id) => {
                return <button onClick={x.callback} key={id}>{x.text}</button>
            });
        }

        return (
            <CSSTransition in={this.props.show} timeout={0} className='ErrorMessage'>
                <div>
                    <div>{this.props.errMsg}</div>
                    {buttons}
                </div>
            </CSSTransition>
        )
    }
}