/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {layoutIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("COL")
                .appendField("set")
                .appendField(new Blockly.FieldImage(layoutIcon, 30, 30, "layout icon", null), 'layoutIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "layout", undefined, '- select -'), "ID")
                .appendField("colour to")
                .setCheck(["String", "Colour"]);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setOutput(false); // add appropriate checks
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the text color of the specified widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('layoutIcon') && this.getField('layoutIcon').imageElement_)
                this.getField('layoutIcon').setTooltip('Layout');
        }
    }
}

function defineGenerators() {
    // TODO - this isn't a great method, must use setAttribute bc. dom.style.backgroundColor doesn't work for !important (and there are other !importants on Snap side
    //        that require use of !important).

    // generators dictionary in ES5
    return {
        /**
         * @return {string}
         */
        'JavaScript': function (block) {
            let id = block.getFieldValue("ID");
            let colour = Blockly.JavaScript.valueToCode(block, 'COL', Blockly.JavaScript.ORDER_NONE);
            let code = "document.getElementById('" + id + "').style.setProperty('background-color', " + colour + ", 'important');\n" +
                "Array.prototype.slice.call(document.getElementById('" + id + "').children).forEach(function (element) {" +
                "element.nodeName === 'LI' && element.style.setProperty('background-color', " + colour + ", 'important')});\n";
            return code;
        }
    }
}


const SetLayoutBackgroundColour = function (props) {
    let name = "snap_set_layout_background_colour";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    let widgets = util.widgetsOfType(props.context, 'layout');

    if (widgets.length < 1) {return null;}

    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined )
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="COL">
                <shadow type="colour_picker"/>
            </value>
        </block>
    )
};

export default SetLayoutBackgroundColour