import React from 'react'
import _ from 'underscore';
import util from "../../../../utils/es5Utils";
export const buttonIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 7.5 30 13'%3E%3Cpath d='M28,21H2V9H28ZM3.65,19.35h22.7v-8.7H3.65Z'/%3E%3Cpath d='M7.94,13.46H9.3a1.13,1.13,0,0,1,.79.27.69.69,0,0,1,.2.5h0a.72.72,0,0,1-.43.67.75.75,0,0,1,.6.74h0c0,.54-.45.83-1.12.83H7.94Zm1.82.86c0-.24-.19-.39-.53-.39H8.46v.81h.73c.34,0,.57-.14.57-.41Zm-.46.87H8.46V16h.88c.37,0,.59-.15.59-.42h0C9.93,15.34,9.72,15.19,9.3,15.19Z'/%3E%3Cpath d='M11,15.66V14.2h.53v1.3c0,.36.17.55.49.55a.5.5,0,0,0,.51-.56V14.2H13v2.29H12.5v-.36a.82.82,0,0,1-.71.4A.78.78,0,0,1,11,15.66Z'/%3E%3Cpath d='M13.79,15.86V14.65H13.5V14.2h.29v-.62h.52v.62h.62v.45h-.62v1.12a.25.25,0,0,0,.28.29.76.76,0,0,0,.33-.08v.43a.93.93,0,0,1-.47.12A.59.59,0,0,1,13.79,15.86Z'/%3E%3Cpath d='M15.57,15.86V14.65h-.29V14.2h.29v-.62h.52v.62h.61v.45h-.61v1.12c0,.21.1.29.28.29A.76.76,0,0,0,16.7,16v.43a1,1,0,0,1-.48.12A.58.58,0,0,1,15.57,15.86Z'/%3E%3Cpath d='M17.05,15.36h0a1.19,1.19,0,0,1,1.22-1.19,1.18,1.18,0,0,1,1.21,1.18h0a1.19,1.19,0,0,1-1.22,1.19A1.18,1.18,0,0,1,17.05,15.36Zm1.91,0h0a.7.7,0,0,0-.7-.73.68.68,0,0,0-.69.72h0a.7.7,0,0,0,.7.73A.69.69,0,0,0,19,15.36Z'/%3E%3Cpath d='M20,14.2h.53v.35a.8.8,0,0,1,.71-.39.78.78,0,0,1,.81.87v1.46h-.52v-1.3c0-.35-.18-.56-.49-.56a.51.51,0,0,0-.51.57v1.29H20Z'/%3E%3C/svg%3E"

export default function Button(props){
    let widgets = util.widgetsOfType(props.context, 'button');
    let newProps = Object.assign({},props,{widgets});
    let components = [
        newProps.widgets.length>0?<label text={"Buttons"}/>:null,
        ..._.values(props.childComponents).map((Component, i) => <Component.component key={i} { ...newProps }/>)
    ];

    return components;
}