import React from 'react';

const ControlsRepeat = function () {
    return (
        <block type="controls_repeat_ext">
            <value name="TIMES">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
    )
};

export default ControlsRepeat;