/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {datesIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("DATE")
                .appendField("set")
                .appendField(new Blockly.FieldImage(datesIcon, 30, 30, "dates icon", null), 'datesIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,'date', undefined, '- select -'), "ID")
                .appendField("to")
                .setCheck(["Date"]);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setOutput(false); // add appropriate checks
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the date of the specified date picker widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function() {
            if (this.getField('datesIcon') && this.getField('datesIcon').imageElement_)
                this.getField('datesIcon').setTooltip('Date');
        }
    }
}

function defineGenerators() {
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            var id = block.getFieldValue("ID");
            var date = Blockly.JavaScript.valueToCode(block, 'DATE', Blockly.JavaScript.ORDER_NONE);
            var code = "$('[id=\"" + id + "\"]').val(UtilityModule.formatDateToString(createDateAsUTC(" + date + ")));\n";
            return code;
        }
    }
}

const SetDateValue = function (props) {
    let name = "snap_set_datepicker_value";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    let widgets = util.widgetsOfType(props.context, 'date');
    if (widgets.length < 1) {return null;}

    return (
        <block type={name}>
            {
                (props.widget && (props.widget.id !== undefined ))
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="DATE">
                <shadow type="date"/>
            </value>
        </block>
    )
};

export default SetDateValue