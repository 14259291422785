/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import { flipSwitchIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectFlipSwitchDropdown = new Blockly.FieldWidgetsDropdown(context, 'flipswitch', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("get")
                .appendField(new Blockly.FieldImage(flipSwitchIcon, 30, 30, "flipSwitch icon", null), 'flipSwitchIcon')
                .appendField(selectFlipSwitchDropdown, "ID");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the value of a switch.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let flipSwitchId = block.getFieldValue('ID');
            var code = "(Snap.widgets.switch.getValue('" + flipSwitchId + "') === 'ON')";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetFlipSwitchValue = function (props) {
    const name = 'snap_get_flipswitch_value';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context, "flipswitch").length ? <block type={name}></block> : null;
}

export default GetFlipSwitchValue
