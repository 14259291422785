/* global Blockly */
import React from 'react';
import {dropdownIcon} from "./index";

// everything here should be ES5
function defineBlock(context) {
    return {
        init: function () {
            var valueOrText = new Blockly.FieldDropdown([['text','TEXT'],["unique ID","VALUE"],['text and unique ID','BOTH']]);
            this.appendDummyInput()
                .appendField("get")
                .appendField(valueOrText,"VORT")
                .appendField("of selected option in")
                .appendField(new Blockly.FieldImage(dropdownIcon, 30, 30, "dropdown icon", null), 'dropdownIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"dropdown", undefined, '- select -'), "DD");
            valueOrText.setOnChange(this.ddChange.bind(this));
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "String"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function(){
                var valOrText = this.getFieldValue('VORT');
                if (valOrText === 'BOTH') {
                    return 'Returns the text and unique ID of the selected dropdown option as a JSON object: {"text":<...>, "value":<...>}'
                }
                return 'Returns the ' + (valOrText == "VALUE" ? "unique ID" : 'text') + ' of the selected dropdown option';
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function() {
            if (this.getField('dropdownIcon') && this.getField('dropdownIcon').imageElement_)
                this.getField('dropdownIcon').setTooltip('DropDown');
        },
        ddChange: function(val) {
            if (val==='BOTH') {
                this.setOutput(true,"Object");
            } else {
                this.setOutput(true,"String")
            }
        }
    }
}

// everything here should be ES5
function defineGenerators() {
    return {
        'JavaScript': function (block) {
            var id = block.getFieldValue('DD');
            var vort = block.getFieldValue("VORT");
            var code = "Snap.widgets.dropdown.getValue(`" + id + "`)";

            if (vort === "VALUE") { // get value of selected option
                code += ".value";
            } else if (vort === "TEXT") { // get text of selected option
                code += ".text";
            }

            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const GetSelectedValueOfDropdown = function(props) {
    const name = "snap_get_selected_value_of_dropdown";
    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }


    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)?
                    <field name="DD">{props.widget.id}</field>
                    : null
            }
        </block>
    )
}

export default GetSelectedValueOfDropdown