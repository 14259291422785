/* global Blockly */
import React from 'react';
import { externalDataIcon } from './index';


function defineBlock(context) {
    return {
        columnNames: [['- select -', 'ColName']],

        init: function () {
            let selectExternalDataDropdown = new Blockly.FieldWidgetsDropdown(context, 'dynamicdropdown', undefined, '- select -', this.updateColumns.bind(this));

            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(externalDataIcon, 30, 30, "externalData icon", null), 'externalDataIcon')
                .appendField(selectExternalDataDropdown, 'ID')
                .appendField('column')
                .appendField(new Blockly.FieldDropdown(this.columnNames), 'COLUMN');

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Array");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Return the specified column in a External Data widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        },

        updateColumns: function (selectedExternalDataDropdownId) {
            if (selectedExternalDataDropdownId === '- select -') return;
            
            context.pages.forEach(page => {
                let externalDataDropdown = page.widgets.find(widget => widget.id == selectedExternalDataDropdownId);

                if (externalDataDropdown) {
                    let widget = this.getInput("WIDGET");
                    this.columnNames = externalDataDropdown.columns.some(column => column) ? externalDataDropdown.columns.map(column => [column.name, column.id]) : [['- select -', 'ColName']];
                    
                    widget.removeField("COLUMN");
                    widget.appendField(new Blockly.FieldDropdown(this.columnNames), 'COLUMN');
                    return;
                }
            });
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let externalDataDropdownId = block.getFieldValue('ID');
            var columnId = block.getFieldValue('COLUMN');
            var code = "Snap.widgets.dynamicdropdown.getColumn('" + externalDataDropdownId + "','" + columnId + "')";
            return [code, Blockly.JavaScript.ORDER_NONE];;
        }
    }
}

const GetExternalDataColumn = function (props) {
    const name = 'snap_externaldata_get_column';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.widgets.length ? <block type={name}></block> : null;
}

export default GetExternalDataColumn