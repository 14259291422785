/* global Blockly */
import React from 'react'

function defineBlock() {
    return {
        init: function(){
            this.appendValueInput("KEY")
                .appendField("get ")
                .setCheck("String");
            this.appendValueInput("OBJ")
                .appendField("from JSON object")
                .setCheck(["Object","Array"]);
            this.setColour(Blockly.Msg.LISTS_HUE);
            this.setOutput(true);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setTooltip("Get the value with the specified key in the provided JSON object. eg. ({\"a\":1})[\"a\"]");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {// Set element at index.
            let key = Blockly.JavaScript.valueToCode(block, "KEY",Blockly.JavaScript.ORDER_NONE);
            let obj = Blockly.JavaScript.valueToCode(block, "OBJ",Blockly.JavaScript.ORDER_NONE);
            let code = "("+obj+")"+"["+key+"]";
            return [code,Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetFromJSON = function (props) {
    let name = "get_from_json";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
    return (
        <block type={name}>
            <value name="KEY"><shadow type="text"/></value>
        </block>
    )
}
export default GetFromJSON