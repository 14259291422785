/* global Blockly */
import React from 'react';

function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("VALUE")
                .setCheck("String")
                .appendField("test value")
            this.appendValueInput("REGEX")
                .setCheck("String")
                .appendField("against regular expression");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.TEXTS_HUE);
            this.setTooltip('Search for a match between a regular expression and a specified string');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#text');
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block) {
            let regex = Blockly.JavaScript.valueToCode(block, 'REGEX', Blockly.JavaScript.ORDER_NONE);
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let code = `(() => {
                var regexString = ${regex};
                if (!regexString || regexString.length == 0)
                    return true;
                var regex = new RegExp(regexString);
                return regex.test(${value});
            })()`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const TextRegex = function(props){
    const name = 'text_regex';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="VALUE">
                <shadow type="text"></shadow>
            </value>
            <value name="REGEX">
                <shadow type="text"></shadow>
            </value>
        </block>
    )
}

export default TextRegex
