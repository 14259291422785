/* global Util, Blockly */

/*
NOTE: Empty block for number/text input for customized blocks
*/
const BlankBlock = function () {
    var name = 'blank_block';

    Blockly.Blocks[name] = {
        init: function () {
            this.visible = false;
            this.appendDummyInput("NAME_INPUT")
                .appendField(" ", "NAME");
            this.appendDummyInput("TOGGLE_BTN")
                .appendField(new Blockly.FieldButton(" ▼ ", undefined, function(){
                    this.switchVisibility(!this.visible);
                }.bind(this)))
                .appendField(" ", "NAME");

            this.statementInput = this.appendStatementInput("DEFINITION");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.statementInput.setVisible(this.visible);
            this._hideInternalBlock();
            this.setColour("#ff677e");
            // Small hack to set the dirty state of the block to true for custom disabling
            setTimeout(function () {
                this.dirty = true
            }.bind(this), 1000);
        },

        // create a new input block that can be inserted into a parent block
        createInputBlock: function (name, shape) {
            let argumentType = "boolean";
            if (shape === Blockly.OUTPUT_SHAPE_ROUND) {
                argumentType = "number_text"
            }
            if (shape === Blockly.OUTPUT_SHAPE_SQUARE) {
                argumentType = "label"
            }
            let inputBlock = this.workspace.newBlock(argumentType);
            inputBlock.setLabel(name);
            inputBlock.setShadow(true);
            inputBlock.initSvg();
            inputBlock.render();
            return inputBlock;
        },

        checkInputNames: function(name) {
            return (name !== "TOGGLE_BTN" && name !== "DEFINITION");
        },

        sortArgs: function(a, b) {
            if (Number(a.slice(10)) < Number(b.slice(10))) return -1;
            if (Number(a.slice(10)) < Number(b.slice(10))) return 1;
            return 0;
        },

        updateShapeMyBlock: function(props) {
            /* Create new input blocks */
            for (let i = this.inputList.length - 1; i > 0; i--) {
                if (this.checkInputNames(this.inputList[i].name)) {
                    this.removeInput(this.inputList[i].name);
                }
            }

            this.setLabel(props.name);
            Object.keys(props.args).sort(this.sortArgs).map(argName => {
                switch (props.args[argName].type) {
                    case 'boolean': {
                        let inputBlock = this.createInputBlock(props.args[argName].value, Blockly.OUTPUT_SHAPE_HEXAGONAL, `${argName}_INPUT-boolean`);
                        let inputSlot = this.appendValueInput(`${argName}`).setCheck("Boolean");
                        inputSlot.connection.connect(inputBlock.outputConnection);
                        break;}
                    case 'number_text': {
                        let inputBlock = this.createInputBlock(props.args[argName].value, Blockly.OUTPUT_SHAPE_ROUND, `${argName}_INPUT-text`);
                        let inputSlot = this.appendValueInput(`${argName}`).setCheck(["String", "Number"]);
                        inputSlot.connection.connect(inputBlock.outputConnection);
                        break;}
                    case 'label':
                        // Condition to check if the block is saved and currently being used
                        // if yes, set the label as readonly text within a block
                        if (this.code) {
                            this
                                .appendDummyInput(argName.indexOf('readonly') > -1 ? argName : `${argName}-readonly`)
                                .appendField(props.args[argName].value, `${argName}_INPUT-label`);
                        } else {
                            let inputBlock = this.createInputBlock(props.args[argName].value, Blockly.OUTPUT_SHAPE_SQUARE, `${argName}_INPUT-label`);
                            let inputSlot = this.appendValueInput(`${argName}`);
                            inputSlot.connection.connect(inputBlock.outputConnection);
                        }
                        break;
                    default:
                        this
                            .appendDummyInput(`${argName}`)
                            .appendField(props.args[argName].value, `${argName}_INPUT-label`);
                        break;
                }
            });

            this.moveInputBefore("DEFINITION", null);
            this.initSvg();
            this.render();
        },

        setFieldValue: function (value, field) {
            this.getField(field);
        },

        /* Save mutation to xml for reconstruction the block when retrieve from the backend */
        mutationToDom: function () {
            let block = this;

            let args = block.inputList.reduce((all, input)  => {
                let inputName = input.name;
                if (inputName === "NAME_INPUT") { return all; }
                if (inputName === "TOGGLE_BTN") { return all; }
                if (inputName === "DEFINITION") { return all; }

                const targetBlock = input.connection ? input.connection.targetBlock() : input.fieldRow[0];
                let value = '';
                if (targetBlock && targetBlock.getLabel){
                    value = targetBlock.getLabel()
                } else if (targetBlock.getValue) {
                    value = targetBlock.getValue();
                }
                let type = "label";
                if (input.connection && !['number_text', 'boolean', 'label'].includes(input.connection.targetBlock().type)) {
                    type = targetBlock.outputConnection.targetConnection.getShadowDom().getAttribute('type');
                    value = targetBlock.outputConnection.targetConnection.getShadowDom().firstChild.getAttribute('label');
                } else {
                    type = targetBlock ? targetBlock.type : type;
                }
                all[inputName] = { value, type };
                return all;
            }, {});

            let mutation = Util.dom("mutation", {name: this.getLabel(), args: JSON.stringify(args), code: this.getCode(), disabled: this.disabled, tooltip: this.tooltip});
            this._hideInternalBlock();

            return mutation;
        },

        /* Reconstruction the from the backend based on xml mutation*/
        domToMutation: function (xml) {
            let name = xml.getAttribute('name');
            let args = xml.getAttribute("args");
            let code = xml.getAttribute("code");
            let tooltip = xml.getAttribute("tooltip");

            if (args) {
                this.updateShapeMyBlock({
                    name: name,
                    args: JSON.parse(args),
                    code: code
                });  // Helper function for adding/removing 2nd input.
            }

            setTimeout(() => {
                var disabled = xml.getAttribute("disabled");
                if (disabled === "true") {
                    // block has to be enabled, then disabled in order to appear disabled
                    this.setEnabled(true);
                    this.setEnabled(false);
                }
            }, 1);

            this.setTooltip(tooltip);
        },

        _hideInternalBlock: function () {
            let renderList = [];

            if (!this.statementInput.visible && this.statementInput.outlinePath) {
                this.outlinePath.setAttribute('style', 'visibility: hidden');
            }

            let display = this.visible ? 'block' : 'none';

            if (this.statementInput.connection) {
                // Has a connection.
                if (this.visible) {
                    renderList = this.statementInput.connection.unhideAll();
                } else {
                    this.statementInput.connection.hideAll();
                }
                let child = this.statementInput.connection.targetBlock();
                if (child) {
                    child.getSvgRoot().style.display = display;
                    if (!this.visible) {
                        child.rendered = false;
                    }
                }
            }

            return renderList;
        },

        switchVisibility: function (visible) {
            this.setVisible(visible);
            this.statementInput.setVisible(visible);
            this.initSvg();
            this.render();
            this.workspace.render();
        },

        setLabel: function (label) {
            this.getField('NAME').setValue(label);
        },

        getLabel: function () {
            return this.getField('NAME') && this.getField('NAME').getValue();
        },

        setVisible: function (visible) {
            this.visible = visible;
        },

        getCode: function () {
            let definitionInput = this.getInput("DEFINITION");
            let innerLogic = definitionInput.connection.targetBlock();
            try {
                return Blockly.JavaScript.blockToCode(innerLogic);
            } catch (e) {
                return e;
            }
        },

        setEnabled: function (enabled) {
            if (this.isEnabled() !== enabled) {
                this.disabled = !enabled;
                if (this.dirty) {
                    this.updateDisabled();
                } else {
                    this.dirty = true;
                }
            }
        },

        onchange: function () {
            this._hideInternalBlock();
        }
    };

    Blockly.JavaScript[name] = function(block){
        let args = block.inputList.reduce((all, input)  => {
            if (["NAME_INPUT", "TOGGLE_BTN", "DEFINITION"].includes(input.name) || !input.connection) { return all; }
            let inputName = input.connection.getShadowDom().firstChild.getAttribute("label");
            const targetBlock = input.connection.targetBlock();
            if (targetBlock.type === "label") { return all; }
            all[inputName] = Blockly.JavaScript.blockToCode(targetBlock)[0];
            return all;
        }, {});

        let code = Object.keys(args).reduce((vars, arg) => {
            vars += `var ${arg} = ${args[arg]};\n`;
            return vars;
        }, "");

        code += block.getCode() + "\n";
        return code;
    };

};

export default BlankBlock;
