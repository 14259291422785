export const dummyState = {
    "pages": [
        {
            "id": "page2",
            "name": "a page",
            "formId": "form1",
            "formName": "Form 1",
            "widgets": [
                {"id": "textbox24", "name": "bbb", "type": "textbox", "properties": null},
                {"id": "textbox25", "name": "aaaa", "type": "textbox", "properties": null},
                {"id": "barcode3", "name": "ccc", "type": "barcode", "properties": null},
                {
                    "id": "listview122",
                    "name": "listview12",
                    "type": "tableview",
                    "properties": null,
                    "columns": [
                        {
                            "id": "col1",
                            "name": "name",
                        },
                        {
                            "id": "col2",
                            "name": "data",
                        },
                        {
                            "id": "col3",
                            "name": "email",
                        }
                    ],
                    "size": null,
                    "style": null
                },
                {
                    "id": "switch98",
                    "name": "switch98",
                    "type": "flipswitch",
                    "properties": null
                },
                {
                    "id": "map6",
                    "name": "map6",
                    "type": "map",
                    "properties": null
                },
                {
                    "id": "externaldata1",
                    "name": "externaldata1",
                    "type": "dynamicdropdown",
                    "properties": null,
                    "columns": [{
                        "id": "First name",
                        "name": "First name",
                    }, {
                        "id": "Last name",
                        "name": "Last name",
                    }]
                },
                {
                    "id": "userlist14",
                    "name": "userlist14",
                    "type": "userlist",
                    "properties": null
                }
            ]
        },
        {
            "id": "page1",
            "name": "page2",
            "widgets": [
                {"id": "button1", "name": "button1", "type": "button", "properties": null},
                {
                    "id": "label58",
                    "name": "label58",
                    "type": "label",
                    "properties": null,
                },
                {
                    "id": "fileupload2",
                    "name": "fileupload123",
                    "type": "fileupload",
                    "properties": null
                },
                {
                    "id": "image2",
                    "name": "image22",
                    "type": "image",
                    "properties": null
                },
                {
                    "id": "listview222",
                    "name": "listview22",
                    "type": "tableview",
                    "properties": null,
                    "columns": [
                        {
                            "id": "col1",
                            "name": "number",
                        },
                        {
                            "id": "col2",
                            "name": "balance",
                        },
                        {
                            "id": "col3",
                            "name": "status",
                        },
                        {
                            "id": "col4",
                            "name": "other",
                        }
                    ],
                    "size": null,
                    "style": null
                },
                {
                    "id": "dropdown2",
                    "name": "dropdown2",
                    "type": "dropdown",
                    "properties": {
                        "options": [{"name": "Select a value", "id": "key-select-a-value"}, {
                            "name": "Choice 1",
                            "id": "Choice_y8Dq"
                        }, {"name": "Choice 2", "id": "spyk"}, {"name": "Choice 3", "id": "Byf9"}]
                    }
                }, {
                    "id": "rating3",
                    "name": "rating3",
                    "type": "rating",
                    "properties": {"size": "large", "style": "heart"}
                }, {
                    "id": "checkboxlist4",
                    "name": "my checkboxlist",
                    "type": "checkboxlist",
                    "properties": {
                        "checkboxes": [
                            {
                                "id": "checkbox17",
                                "name": "Choice 1",
                                "type": "checkbox",
                                "properties": {"value": "Mkmd"}
                            },
                            {
                                "id": "checkbox18",
                                "name": "Choice 2",
                                "type": "checkbox",
                                "properties": {"value": "W18s"}
                            }]
                    }
                }, {
                    "id": "checkbox17",
                    "name": "Choice 1",
                    "type": "checkbox",
                    "properties": {"value": "Mkmd"}
                }, {
                    "id": "checkbox18",
                    "name": "Choice 2",
                    "type": "checkbox",
                    "properties": {"value": "W18s"}
                }, {
                    "id": "checkboxlist19",
                    "name": "checkboxlist19",
                    "type": "checkboxlist",
                    "properties": {
                        "checkboxes": [
                            {"id": "checkbox46", "name": "cb1", "type": "checkbox", "properties": {"value": "val1"}},
                            {"id": "checkbox47", "name": "cb2", "type": "checkbox", "properties": {"value": "val2"}}]
                    }
                }, {
                    "id": "checkbox46",
                    "name": "hmm",
                    "type": "checkbox",
                    "properties": {"value": "Mkmd"}
                }, {
                    "id": "checkbox47",
                    "name": "testplease",
                    "type": "checkbox",
                    "properties": {"value": "W18s"}
                }, {"id": "layout8", "name": "layout8", "type": "layout", "properties": null}, {
                    "id": "radiobuttonlist49",
                    "name": "my rb",
                    "type": "radiobuttonlist",
                    "properties": {
                        "options": [{
                            "name": "test option 1",
                            "id": "radiobutton94",
                            "properties": {"value": "2JfW"}
                        }, {"name": "test option 2", "id": "radiobutton95", "properties": {"value": "UvsG"}}]
                    }
                }, {
                    "id": "textbox48",
                    "name": "mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2mytb2",
                    "type": "textbox",
                    "properties": null
                }, {
                    "id": "textbox11",
                    "name": "my tb",
                    "type": "textbox",
                    "properties": null
                }, {
                    "id": "barcode4",
                    "name": "barcode scanner",
                    "type": "barcode",
                    "properties": null
                }, {"id": "section12", "name": "section12", "type": "section", "properties": null}, {
                    "id": "radiobuttonlist13",
                    "name": "radiobuttonlist13",
                    "type": "radiobuttonlist",
                    "properties": {
                        "options": [{
                            "name": "Choice 1",
                            "id": "radiobutton14",
                            "properties": {"value": "wERL"}
                        }, {"name": "Choice 2", "id": "radiobutton15", "properties": {"value": "w6tr"}}]
                    }
                }, {
                    "id": "dropdown6",
                    "name": "dropdown6",
                    "type": "dropdown",
                    "properties": {
                        "options": [{
                            "name": "apples",
                            "id": "dropdownoption25"
                        }, {
                            "name": "oranges",
                            "id": "dropdownoption26"
                        }]
                    }
                }, {"id": "date16", "name": "date16", "type": "date", "properties": null}]
        }
        ],
    webPages: [
        {
            id: "webpage3",
            formId: "",
            formName: "",
            name: "Web Page 1",
            widgets: []
        },
        {
            id: "webpage5",
            formId: "",
            formName: "",
            name: "Youtube",
            widgets: []
        },
        {
            id: "webpage6",
            formId: "",
            formName: "",
            name: "Soti sync page",
            widgets: []
        }
    ],
    hamburgers: [
        {
            id: "hamburger2",
            name: "Menu",
            type: "hamburger",
            properties: null,
            columns: [],
            size: null,
            style: null
        }
    ],
    environmentVariables: [
        {
            "id": "variable10",
            "name": "myvar",
            "description": "my new variable",
            "defaultValue": "1",
            "type": "variable",
            "persistValue": true,
            "isNameNotUnique": false,
            "$$hashKey": "object:385"
        },
        {
            "id": "variable11",
            "name": "another var",
            "description": "my another variable",
            "defaultValue": "asd",
            "type": "variable",
            "persistValue": true,
            "isNameNotUnique": false,
            "$$hashKey": "object:388"
        }
    ],
    xSightTopics: [
        {
            dataType: 3,
            defaultValue: "1",
            description: "",
            expectsRunningApp: false,
            name: "Topic one",
            topicCalculationCode: null,
            topicInputArgumentValues: null,
            type: 0
        },
        {
            dataType: 3,
            defaultValue: "123",
            description: "",
            expectsRunningApp: false,
            name: "Topic two",
            topicCalculationCode: null,
            topicInputArgumentValues: null,
            type: 0
        }
    ],
    "code": '',
    tenantId: "-1",
    userId: "-1",
    accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lciI6eyJjcmVhdGVkQXQiOiIyMDIxLTEwLTIwVDE3OjAzOjI4LjUzNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTEwLTIwVDE3OjAzOjI4LjUzNFoiLCJpZCI6NSwibmFtZSI6ImFzc2lzdCIsImxhbmd1YWdlIjoiSmF2YVNjcmlwdCJ9LCJjcmVhdGVkQXQiOiIyMDIxLTEwLTIwVDE3OjA0OjA3LjA3NVoiLCJpYXQiOjE2MzQ3NDk0NDd9.iVuQ4KmrJqrGETiF9tc4Gefwjb4zvExIAzWWfD7jnRg',
    appTypeId: 2
};

// export const dummyState = {
//     code: '....',
//     pages: [
//         {
//             name: 'Splash Screen',
//             id: 'page0',
//             widgets: []
//         },
//         {
//             name: 'Order Form',
//             id: 'page1',
//             widgets: [
//                 {
//                     name: 'button1',
//                     id: 'button1',
//                     type: 'button',
//                     properties: {}
//                 },
//                 {
//                     name: 'checkbox1',
//                     id: 'checkbox1',
//                     type: 'checkbox',
//                     properties: {value:'cbvalue1'}
//                 },
//                 {
//                     name: 'checkbox2',
//                     id: 'checkbox2',
//                     type: 'checkbox',
//                     properties: {value:'cbvalue2'}
//                 },
//                 {
//                     name: 'checkboxlist1',
//                     id: 'checkboxlist1',
//                     type: 'checkboxlist',
//                     properties: {checkboxes:[{name:'checkbox1',id:'checkbox1',type:'checkbox',properties:{value:'cbvalue1'}}, {name:'checkbox1',id:'checkbox1',type:'checkbox',properties:{value:'cbvalue2'}}]}
//                 },
//                 {
//                     name: 'datepicker1',
//                     id: 'datepicker1',
//                     type: 'date',
//                     properties: {}  // child.Properties.importedObject.DisplayColumn.Data or child.Properties.dataSourceManual
//                 },
//                 {
//                     name: 'dropdown1',
//                     id: 'dropdown1',
//                     type: 'dropdown',
//                     properties: {options:[{name:'opt1',id:'id2'},{name:'opt12',id:'id4'}]}  // child.Properties.importedObject.DisplayColumn.Data or child.Properties.dataSourceManual
//                 },
//                 {
//                     name: 'dropdown2',
//                     id: 'dropdown2',
//                     type: 'dropdown',
//                     properties: {options:[{name:'DD2OPT1',id:'id2'},{name:'dd2opt2',id:'id4'}]}  // child.Properties.importedObject.DisplayColumn.Data or child.Properties.dataSourceManual
//                 },
//                 {
//                     name: 'externalData1',
//                     id: 'externalData1',
//                     type: 'externaldata',
//                     properties: {}
//                 },
//                 {
//                     name: 'layout1',
//                     id: 'layout1',
//                     type: 'layout',
//                     properties: {}
//                 },
//                 {
//                     name: 'section1',
//                     id: 'section1',
//                     type: 'section',
//                     properties: {}
//                 },
//                 {
//                     name: 'radiobutton1',
//                     id: 'radiobutton1',
//                     type: 'radiobuttonlist',
//                     properties: {options:[{name:'rb1',id:'rb1'},{name:'rb2',id:'rb2'}]}
//                 },
//                 {
//                     name: 'rating1',
//                     id: 'rating1',
//                     type: 'rating',
//                     properties: {style:'heart',size:'large'}
//                 },
//                 {
//                     name: 'textbox1',
//                     id: 'textbox1',
//                     type: 'textbox',
//                     properties: {}
//                 },
//                 {
//                     name: 'datePicker',
//                     id: 'datePicker',
//                     type: 'datepicker',
//                     properties: {options:[{name:'name',id:'id1'},{name:'name',id:'id3'}]}
//                 }
//             ]
//         }
//     ]
// }
//

// export const dummyState =
//     {
//         pages: [
//             {
//                 name: 'Splash Screen',
//                 id: 'page0',
//                 widgets: []
//             },
//             {
//                 name: 'Order Form',
//                 id: 'page1',
//                 widgets: [
//                     {
//                         name: 'Hamburger Quantity',
//                         id: 'textbox4',
//                         restrictions: [],
//                         type: 'textbox',
//                     },
//                     {
//                         name: 'Pizza Quantity',
//                         id: 'textbox5',
//                         restrictions: [],
//                         type: 'textbox'
//                     },
//                     {
//                         name: 'Fries Quantity',
//                         id: 'textbox6',
//                         restrictions: [],
//                         type: 'textbox'
//                     },
//                     {
//                         name: 'Cart Quantity',
//                         id: 'textbox7',
//                         restrictions: [],
//                         type: 'textbox'
//                     }
//                 ]
//             },
//             {
//                 name: 'Checkout Page',
//                 id: 'page2',
//                 widgets: [
//                     {
//                         name: 'checkbox test',
//                         id: 'textbox94',
//                         restrictions: [],
//                         type: 'checkbox'
//                     },
//                     {
//                         name: 'total price',
//                         id: 'textbox9',
//                         restrictions: [],
//                         type: 'textbox'
//                     }
//                 ]
//             }
//         ],
//         code: ''
//     }

// temp hard-coaded default state suited to Snap
// once backend is built, initializeBlocks and initializeToolbox
// will be provided by the server to suit the product and user
let dummyDefaultState = {
    app: {
        context: dummyState // this is set to something intelligent on getInitInfo
    }
}

export default dummyDefaultState
