/* global Blockly */
import React from 'react';
import { hamburgerIcon } from "./index";


function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput('WIDGET')
                .appendField('set')
                .appendField(new Blockly.FieldImage(hamburgerIcon, 30, 30, 'hamburger icon', null), 'hamburgerIcon');

            this.appendValueInput('VALUE')
                .appendField('hamburger menu image to')
                .setCheck("String");

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the image URL of a hamburger menu.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            var code = `hamburgerModule.setHeaderImage(${value});\n`;
            return code;
        }
    }
}

const SetHamburgerImage = function (props) {
    const name = 'snap_set_hamburger_image';

    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.widgets.length ? 
    <block type={name}>
        <value name="VALUE">
            <shadow type="text"></shadow>
        </value>
    </block> : 
    null;
}

export default SetHamburgerImage
