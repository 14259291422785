import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const textBoxIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M13.94,17.14v1.14H11.71v-.8a2.92,2.92,0,0,1-2.08.9,2.24,2.24,0,0,1-1.54-.58,1.87,1.87,0,0,1-.66-1.48,1.9,1.9,0,0,1,.7-1.51,2.51,2.51,0,0,1,1.68-.59,3.2,3.2,0,0,1,1.77.55v-.65a2.57,2.57,0,0,0-.09-.77,1,1,0,0,0-.44-.48,1.68,1.68,0,0,0-.91-.22,1.47,1.47,0,0,0-1.39.77L7.52,13.1a2.91,2.91,0,0,1,2.81-1.52,3.69,3.69,0,0,1,1.27.19,1.88,1.88,0,0,1,.8.52,1.69,1.69,0,0,1,.36.67,5.38,5.38,0,0,1,.09,1.16v3Zm-2.36-1.29a2.7,2.7,0,0,0-1.68-.64,1.31,1.31,0,0,0-.89.31,1.09,1.09,0,0,0,0,1.56,1.14,1.14,0,0,0,.85.31,2.54,2.54,0,0,0,1.76-.78Z'/%3E%3Cpath d='M5.83,21.63A.87.87,0,0,1,5,20.76V8.2a.87.87,0,0,1,.87-.87H26.51l.49-2H4.83A1.83,1.83,0,0,0,3,7.2V21.76a1.83,1.83,0,0,0,1.83,1.83H18.3l.49-2Z'/%3E%3Crect x='16.1' y='10.23' width='0.88' height='9.5'/%3E%3C/svg%3E";


export default function Textbox (props){

    let widgets = util.widgetsOfType(props.context, 'textbox');
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Textboxes"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];
    return components;
}

