import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './index.css';

class DeleteBlockDialog extends Component {

    _deleteBlock() {
        this.props.deleteBlock({
            userId: this.props.userId ? this.props.userId : -1,
            tenantId: this.props.tenantId ? this.props.tenantId : -1,
            libraryId: this.props.libraryId,
            blockId: this.props.blockId
        });
    }

    render() {
        return (
            <div id={"delete-block-dialog-container"}>
                <div id={"delete-block-dialog-wrapper"}>
                    <div className={"delete-block-dialog-header"}>
                        <h4>Confirm Delete Block</h4>
                    </div>
                    <div className={"delete-block-dialog-body"}>
                        <p>
                            Are you sure you want to delete the block &quot;{this.props.blockName}&quot; from
                            the library &quot;{this.props.libraryName}&quot;?
                        </p>
                        <p>
                            This block will be permanently deleted from the library. This action cannot be undone.
                        </p>
                    </div>
                    <div className="delete-block-dialog-footer">
                        <Link to={`/${window.location.search}`}>
                            <button className="btn-continue"
                                    onClick={this._deleteBlock.bind(this)}>CONFIRM DELETE</button>
                        </Link>
                        <Link to={`/${window.location.search}`}>
                            <button className="btn-cancel">CANCEL</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteBlockDialog
