/* global Blockly */
import React, { Component } from 'react';

// replace Today 'date_today', ''

class Today extends Component {
    constructor(props){
        super(props);

        // Often want some shared variables for defining the block and its generator(s) -> eg. a list of
        // all checkboxes for a block pertaining only to checkboxes. Define them as attributes of this.vars
        // then will be easier to translate these components to the backend.
        this.vars = {};

        Blockly.Blocks['date_today'] = this.block(this.props.context);

        let generators = this.generators(this.props.context);
        for(let i in generators){
            Blockly[i]['date_today'] = generators[i]
        }
    }


    // Everything should be in ES5
    block() {
        return {
            init:function(){
                // this.appendValueInput('HOUR')
                // this.appendValueInput('MINUTE')
                //     .appendField(":");
                this.appendDummyInput()
                //     .appendField(new Blockly.FieldDropdown([['am','AM'],['pm','PM']]))
                    .appendField("today");
                // Provide values for all of these
                this.setPreviousStatement(false);
                this.setNextStatement(false);
                this.setOutput(true,"Date"); // add appropriate checks
                this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
                this.setColour(Blockly.Msg.DATES_HUE);
                this.setTooltip('Get today\'s date');
                this.setHelpUrl(Blockly.BASE_HELP_URL+'#dates');
            }
        }
    }

    // Everything should be in ES5
    generators() {
        return {
            'JavaScript': function () {
                let code = '(new Date())';
                return [code, Blockly.JavaScript.ORDER_NONE]
            }
        }
    }

    defaultXml() {
        return (<block type="date_today"></block>)
    }

    // Should return null if block shouldn't appear in toolbox
    render(){
        return (
            <block type='date_today'>
                <value name="HOUR"><shadow type="math_whole_number"><field name="NUM">12</field></shadow></value>
                <value name="MINUTE"><shadow type="math_whole_number"><field name="NUM">00</field></shadow></value>
            </block>
        )
    }
}

export default Today