/* global Blockly */
import React from 'react';
import {labelIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("TEXT")
                .appendField("set")
                .appendField(new Blockly.FieldImage(labelIcon, 30, 30, "label icon", null), 'labelIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"label", undefined, '- select -'), "ID")
                .appendField("to");
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setOutput(false); // add appropriate checks
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the display text of the provided label widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('labelIcon') && this.getField('labelIcon').imageElement_)
                this.getField('labelIcon').setTooltip('Label');
        }
    }
}

function defineGenerators() {
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue("ID");
            let text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_NONE);
            let textlimit = 100000; // there is no practical reason to display more than 100k of data in a label
            return `
                var labelText = ${text};
                if (labelText.length > ${textlimit})
                    labelText = labelText.substring(0, ${textlimit}) + "...";
                $('[id="${id}"]').text(labelText);
                `;
        }
    }
}

const SetLabelText = function (props) {
    let name = "snap_set_label_text";
    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name="ID">{props.widget.id}</field>
                : null
            }
            <value name="TEXT">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetLabelText