/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        init: function () {
            let selectRepeaterDropdown = new Blockly.FieldWidgetsDropdown(context, 'repeater', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("save current entry for")
                .appendField(selectRepeaterDropdown, "ID");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Validate and save current entry for repeater widget. Will return true if validation passed and entry was successfully saved.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let repeaterId = block.getFieldValue('ID');
            let code = `Snap.widgets.repeater.saveEntry("${repeaterId}")`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const RepeaterNewSave = function (props) {
    const name = 'snap_repeater_entry_save';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}></block>;
}

export default RepeaterNewSave