import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const ratingIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 8 30 15'%3E%3Cpolygon points='6.23 11.93 7.23 13.95 9.46 14.28 7.84 15.85 8.22 18.07 6.23 17.02 4.23 18.07 4.61 15.85 3 14.28 5.23 13.95 6.23 11.93'/%3E%3Cpolygon points='15 11.93 16 13.95 18.23 14.28 16.61 15.85 17 18.07 15 17.02 13.01 18.07 13.39 15.85 11.77 14.28 14 13.95 15 11.93'/%3E%3Cpath d='M23.77,13.54l.36.73.17.33.37.06.8.11-.58.57-.27.26.06.37.14.8-.72-.38-.33-.17-.33.17-.72.38.14-.8.06-.37-.27-.26-.58-.57.81-.11.37-.06.16-.33.36-.73m0-1.61-1,2-2.23.33,1.62,1.57-.38,2.22,2-1.05,2,1.05-.38-2.22L27,14.28,24.77,14l-1-2Z'/%3E%3C/svg%3E";
export default function Rating(props) {
    let widgets = util.widgetsOfType(props.context, 'rating');
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Ratings"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components;
}

