/* global Blockly */
import React from 'react';
import {dropdownIcon} from "./index";


function defineBlock(context, dropdowns) {
    var dropdownOpts = {};
    dropdowns.forEach(function (widget) {
        dropdownOpts[widget.id] = {
            text: [["< no selection >", "__CLEAR__"]].concat(widget.properties.options.map(function (cb) {return [cb.name, cb.name]})),
            value: widget.properties.options.map(function (opt) {
                return [opt.id, opt.id]
            })
        }
    });

    return {
        init: function () {
            var widgetDD = new Blockly.FieldWidgetsDropdown(context, "dropdown",undefined, '- select -');
            this.appendValueInput("VALUE")
                .appendField("set")
                .appendField(new Blockly.FieldImage(dropdownIcon, 30, 30, "dropdown icon", null), 'dropdownIcon')
                .appendField(widgetDD, "ID")
                .appendField("by")
                .appendField(new Blockly.FieldDropdown([['text', 'text'], ['unique ID', 'value']], undefined, undefined, this.updateByType.bind(this)), "BY")
                .appendField("to")
                .setCheck("String");
            widgetDD.setOnChange(this.updateByWidget.bind(this));
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function() {
                var valOrText = this.getFieldValue('BY');
                return 'Set the selected option of a dropdown by ' + (valOrText == "value" ? "unique ID" : 'text');
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this.update(this.getFieldValue("ID"), this.getFieldValue("BY"));
        },
        onchange: function() {
            if (this.getField('dropdownIcon') && this.getField('dropdownIcon').imageElement_)
                this.getField('dropdownIcon').setTooltip('DropDown');
        },
        onLoad: function(){
            var id = this.getFieldValue('ID');
            var by = this.getFieldValue('BY');
            var widget = dropdowns.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']];
            } else {
                opts = dropdownOpts[id][by] || [['', '']];
            }

            var value = '';
            try {
                // there could be some runtime blocks, we should skip those
                value = Blockly.JavaScript.valueToCode(this, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            }
            catch(e) { }

            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                var input = this.getInput('VALUE');
                var valuesDDBlock = input.connection.targetBlock();
                if (valuesDDBlock.type !== "dropdown_options")
                    return;
                var dd = valuesDDBlock.setFieldDropdown(opts);
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function(){
                    dd.setWarning(false);
                    if(closure){
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        updateByType: function(val){ this.update(this.getFieldValue('ID'),val)},
        updateByWidget: function(val){ this.update(val,this.getFieldValue('BY'))},

        // update the last input
        update: function (val, by) {
            var input = this.getInput('VALUE');
            var existingBlock = input.connection.targetBlock();
            var block = this.workspace.newBlock('dropdown_options');
            block.setShadow(true);

            if (this.getField('ID').hasBeenSet) {
                var widget = dropdowns.find(function (x) {return x.id === val});
                var opts;
                if (!widget) {
                    console.warn('widget ' + val + " not found");
                    opts = [['','']]
                } else {
                    opts = dropdownOpts[val][by]
                }
                block.setFieldDropdown(opts, undefined, '- select -');
            } else{
                var dd = block.setFieldDropdown([['','']], undefined, '- select -');
                dd.setEnabled(false);
            }
            input.connection.connect(block.outputConnection);

            // If there was already a block connected to this input, re-connect it to the input.
            // Still needed to connect the shadow block prior so if user then removes the added block,
            // the underlying shadow block still shows the correct drop-down options for that widget and text/ value option
            if(existingBlock && !existingBlock.isShadow()){
                input.connection.connect(existingBlock.outputConnection);
                if (this.rendered) {
                    existingBlock.initSvg();
                    existingBlock.render();
                }
            } else{
                if(this.rendered){
                    block.initSvg();
                    block.render();
                }
            }
        }
    }
}

function defineGenerators(context, widgets) {
    return {
        /**
         * @return {string}
         */
        "JavaScript": function (block) {
            var id = block.getFieldValue("ID");
            var value = Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE);
            var by = block.getFieldValue('BY');

            var widget = widgets.find(function (x) {return x.id===id});
            if(!widget){
                console.warn("dropdown ' + id + ' not found");
                return 'console.warn("dropdown ' + id + ' not found");\n'
            }

            // by text
            if (by === 'text') {
                var ddText = value;

                // get the value that the dropdown will be set to
                if (block.getInput("VALUE").connection.targetConnection) {
                    if (//block.getInput("VALUE").connection.targetConnection.sourceBlock_.type === "text" ||
                        block.getInput("VALUE").connection.targetConnection.sourceBlock_.type === "dropdown_options") {
                        ddText = '`' + ddText + '`';
                    }
                } else {
                    ddText = '``'
                }

                // clear dropdown so that the placeholder is the selected option
                if (value === "__CLEAR__") {
                    return 'Snap.widgets.dropdown.setValue("' + id + '", "value", "key-select-a-value");\n';
                }

                return 'Snap.widgets.dropdown.setValue("' + id + '", "text", ' + ddText + ');\n';
            } else { // by Value
                return 'Snap.widgets.dropdown.setValue("' + id + '", "value", ' + value + ');\n';
            }
        }
    }
}

const SetDropdown = function (props) {
    const name = "snap_set_dropdown";

    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default SetDropdown;
