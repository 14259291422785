/* global Blockly */
import React  from 'react';
import {checkBoxListIcon} from "./index";

function defineGenerators() {
    return {
        "JavaScript": function(block){
            // var id = block.getFieldValue("ID");
            let cb = block.getFieldValue("CB");
            let code = '($(\'[id="' + cb + '"]\').prop("checked"))';
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

function defineBlock(context, checkboxlists){
    var ddOpts = {};
    checkboxlists.forEach(function(cbl){
        ddOpts[cbl.id] = cbl.properties.checkboxes.map(function(cb){
            return [cb.name,cb.id];
        });
    });

    return {
        update:function(val){
            let input = this.getInput("VALUE");
            let opts, newField;
            input.removeField("CB");

            if(this.getField('ID').hasBeenSet){
                opts = ddOpts[val] || [['', '']];
                newField = new Blockly.FieldDropdown(opts,undefined,'- select -');
            } else {
                newField = new Blockly.FieldDropdown([['','']],undefined,'- select -');
                newField.setEnabled(false);
            }
            input.insertFieldAt(3,newField,"CB");
        },
        onLoad: function(){
            var id= this.getFieldValue('ID');
            var widget = checkboxlists.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']]
            } else {
                opts = ddOpts[id];
            }

            var value = this.getFieldValue('CB');

            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                var dd = this.getField('CB');
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function(){
                    dd.setWarning(false);
                    if(closure){
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        init:function(){
            let widgetDD = new Blockly.FieldWidgetsDropdown(context,"checkboxlist",undefined,'- select -');
            this.appendDummyInput('VALUE')
                .appendField("in")
                .appendField(new Blockly.FieldImage(checkBoxListIcon, 30, 30, "check box list icon", null), 'checkBoxListIcon')
                .appendField(widgetDD,"ID")
                .appendField("checkbox","TXT")
                .appendField(new Blockly.FieldDropdown([['','']],undefined, '- select -'), "CB")
                .appendField("is checked");
            widgetDD.setOnChange(this.update.bind(this));
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true,"Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('\'true\' if checkbox is checked, otherwise \'false\'');
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#widget-values');
            this.update(this.getFieldValue("ID"));
        },
        onchange: function(){
            if (this.getField('checkBoxListIcon') && this.getField('checkBoxListIcon').imageElement_)
                this.getField('checkBoxListIcon').setTooltip('Checkbox List');
        }
    }
}


const InCheckboxlistCheckboxIsChecked = function (props){
    const name = 'snap_in_checkboxlist_checkbox_is_checked';
    let widgets = props.widgets.length>0?props.widgets:[props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if(props.widgets.length<1){
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default InCheckboxlistCheckboxIsChecked