/* global goog */
/* global Blockly */

const fieldWidgetsDropdown = function () {
    Blockly.FieldWidgetsDropdown = function (context, opt_widgetTypes, opt_validator, opt_show_select, opt_on_change, parentFormId) {
        if(typeof menuGenerator === "function"){
            throw "FieldWidgetsDropdown cannot have function for menu generator (for now)"
        }

        var filter = function(w){
            if (!!parentFormId && w.formId !== parentFormId) {
                // doesn't belong to specified form
                return false;
            }
            if(opt_widgetTypes instanceof Array){
                return opt_widgetTypes.includes(w.type);
            } else if ((typeof opt_widgetTypes) === 'string'){
                return opt_widgetTypes === w.type
            } else {
                return true
            }
        }

        // get widgets according to widget types
        var widgets = context.pages.map(function(page){
            return page.widgets.filter(filter);
        });
        widgets = widgets.reduce(function(acc,x){return acc.concat(x)},[]);


        // Sort according first to what page they appear on, then by the widget name
        widgets = widgets.sort(function(a,b){
            var pageA = Blockly.FieldWidgetsDropdown.displayPageName(a.page).toLowerCase();
            var pageB = Blockly.FieldWidgetsDropdown.displayPageName(b.page).toLowerCase();
            if(pageA > pageB){
                return 1
            } else if (pageA < pageB){
                return -1
            } else{
                return a.name.toLowerCase() < b.name.toLowerCase();
            }
        });

        // trim page names
        widgets = widgets.map(function(x){
            var page = {
                display:Blockly.FieldWidgetsDropdown.trimmedPageName(x.page,30),
                name:Blockly.FieldWidgetsDropdown.displayPageName(x.page)
            }
            return [x.name,x.id,page];
        });

        if (widgets.length<1){
            widgets = [['< no widgets >', '', {display:'',name:''}]]
            this.warnNoWidgets = true;
        }

        Blockly.FieldWidgetsDropdown.superClass_.constructor.call(this, widgets, opt_validator, opt_show_select, opt_on_change);

        // Insert separators for new pages
        var finalOpts = [];
        for(var i=0; i<widgets.length; i++){
            var opt = widgets[i];
            if(finalOpts.find(function(x){return Blockly.FieldWidgetsDropdown.displayPageName(x[2])=== opt[2].name}) === undefined){
                finalOpts.push([opt[2],"_SEPARATOR_", opt[2]])
            }
            finalOpts.push(opt);
        }
        this.menuGenerator_ = finalOpts;

        var closure = this.init
        this.init = function(){
            if (this.warnNoWidgets){
                this.sourceBlock_.setWarningText("This block refers to a Snap widget that does not exist, please modify or remove");
            }
            closure.bind(this)()
        }.bind(this);
    };

    goog.inherits(Blockly.FieldWidgetsDropdown, Blockly.FieldDropdownSearch);


    Blockly.FieldWidgetsDropdown.displayPageName = function (page){
        if(page.formName){
            return page.formName + " > " + page.name
        }
        return page.name
    }

    Blockly.FieldWidgetsDropdown.trim = function(str, len){
        if(str.length<=len) return str;
        var halfLen = Math.floor(len/2);
        return str.substring(0,halfLen-2)+"..."+str.substring(str.length-halfLen-1,str.length);
    }

    Blockly.FieldWidgetsDropdown.trimmedPageName = function (page, length){
        var halfLen = Math.floor(length/2);
        if(page.formName){
            var formName = Blockly.FieldWidgetsDropdown.trim(page.formName, halfLen-2);
            var pageName = Blockly.FieldWidgetsDropdown.trim(page.name, halfLen-1)

            return formName + " > " + pageName;
        }
        return Blockly.FieldWidgetsDropdown.trim(page.name, halfLen);
    }





    /**
     * Resize the editor to fit the text.
     * @protected
     */
    Blockly.FieldWidgetsDropdown.prototype.resizeEditor_ = function () {
        var scale = this.sourceBlock_.workspace.scale;
        var div = Blockly.WidgetDiv.DIV;

        var initialWidth;
        if (this.sourceBlock_.isShadow() || this.getTotalFields_() == 1) {
            initialWidth = this.sourceBlock_.getHeightWidth().width * scale;
        } else {
            initialWidth = this.size_.width * scale;
        }

        var width;
        if (Blockly.BlockSvg.FIELD_TEXTINPUT_EXPAND_PAST_TRUNCATION) {
            // Resize the box based on the measured width of the text, pre-truncation
            var textWidth = Blockly.pxtBlocklyUtils.measureText(
                Blockly.FieldTextInput.htmlInput_.style.fontSize,
                Blockly.FieldTextInput.htmlInput_.style.fontFamily,
                Blockly.FieldTextInput.htmlInput_.style.fontWeight,
                Blockly.FieldTextInput.htmlInput_.value
            );
            // Size drawn in the canvas needs padding and scaling
            textWidth += Blockly.FieldTextInput.TEXT_MEASURE_PADDING_MAGIC;
            textWidth *= scale;
            width = textWidth;
        } else {
            // Set width to (truncated) block size.
            width = initialWidth;
        }
        // The width must be at least FIELD_WIDTH and at most FIELD_WIDTH_MAX_EDIT
        width = Math.max(width, Blockly.BlockSvg.FIELD_WIDTH_MIN_EDIT * scale);
        width = Math.min(width, Blockly.BlockSvg.FIELD_WIDTH_MAX_EDIT * scale);

        var inputHeight = this.getTotalFields_() == 1 ?
            this.sourceBlock_.getHeightWidth().height : Blockly.BlockSvg.FIELD_HEIGHT_MAX_EDIT;

        // Add 1px to width and height to account for border (pre-scale)
        div.style.width = (width / scale + 1) + 'px';
        div.style.height = (inputHeight + 1) + 'px';
        div.style.transform = 'scale(' + scale + ')';

        // Use margin-left to animate repositioning of the box (value is unscaled).
        // This is the difference between the default position and the positioning
        // after growing the box.
        div.style.marginLeft = -0.5 * (width - initialWidth) + 'px';

        // Add 0.5px to account for slight difference between SVG and CSS border
        var borderRadius = this.getBorderRadius() + 0.5;
        div.style.borderRadius = borderRadius + 'px';
        //Blockly.FieldTextInput.htmlInput_.style.borderRadius = borderRadius + 'px';
        // Pull stroke colour from the existing shadow block
        var strokeColour = this.sourceBlock_.getColourTertiary();
        div.style.borderColor = strokeColour;

        var xy = this.getAbsoluteXY_();
        // Account for border width, post-scale
        xy.x -= scale / 2;
        xy.y -= scale / 2;
        // In RTL mode block fields and LTR input fields the left edge moves,
        // whereas the right edge is fixed.  Reposition the editor.
        if (this.sourceBlock_.RTL) {
            xy.x += width;
            xy.x -= div.offsetWidth * scale;
            xy.x += 1 * scale;
        }
        // Shift by a few pixels to line up exactly.
        xy.y += 1 * scale;
        if (Blockly.utils.userAgent.GECKO && Blockly.WidgetDiv.DIV.style.top) {
            // Firefox mis-reports the location of the border by a pixel
            // once the WidgetDiv is moved into position.
            xy.x += 2 * scale;
            xy.y += 1 * scale;
        }
        if (Blockly.utils.userAgent.WEBKIT) {
            xy.y -= 1 * scale;
        }
        // Finally, set the actual style
        div.style.left = xy.x + 'px';
        div.style.top = xy.y + 'px';
    };


    Blockly.FieldWidgetsDropdown.prototype.getBorderRadius = function () {
        if (this.sourceBlock_.getOutputShape() == Blockly.OUTPUT_SHAPE_ROUND) {
            return Blockly.BlockSvg.NUMBER_FIELD_CORNER_RADIUS;
        }
        return Blockly.BlockSvg.TEXT_FIELD_CORNER_RADIUS;
    };

    Blockly.FieldWidgetsDropdown.prototype.createTextInput_ = function () {

        var htmlInput = document.createElement('input');
        htmlInput.className = 'blocklyHtmlInput';
        var txt = this.menuGenerator_.find(function(x){
            return x[1] == this.value_
        }.bind(this));

        if(!this.hasBeenSet) {
            txt = this.selectOption_;
        } else if(txt !==undefined && txt[0] !== undefined){
            txt = txt[0];
        } else {
            txt = '< no widgets >'
        }
        htmlInput.setAttribute('placeholder', txt);

        htmlInput.style.fontSize = Blockly.BlockSvg.FIELD_TEXTINPUT_FONTSIZE_FINAL + 'pt';

        var closure = this;


        htmlInput.addEventListener('keydown', function (e) {
            if (e.key.toLowerCase() === 'enter') {
                var filtered = closure.menu_.children_.filter(function (item) {
                    return !(item instanceof goog.ui.MenuHeader) && item.fullContent_.toLowerCase().startsWith(e.target.value.toLowerCase())
                });
                if (filtered.length === 1) {
                    closure.onItemSelected(closure.menu_, filtered[0]);
                    Blockly.FieldWidgetsDropdown.cleanUpEditor();
                }
            } else if (e.key.toLowerCase() === "escape") {
                Blockly.Events.setGroup(false);
            }
        });

        htmlInput.addEventListener('input', function (e) {
            if (closure.menu_) {
                var menu = closure.menu_;
                var filteredItems = [];
                var headers = [];
                menu.children_.forEach(function (item) {
                    if(item instanceof goog.ui.MenuHeader){
                        headers.push(item);
                        return;
                    }

                    if (!item.fullContent_.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                        item.addClassName('displayNone');
                    } else {
                        filteredItems.push(item);
                        item.removeClassName('displayNone');
                    }
                });

                var remainingCategories = filteredItems.map(function(x){return x.category});
                headers.forEach(function(header){
                    if(!remainingCategories.includes(header.getContent())){
                        header.addClassName('displayNone');
                    } else {
                        header.removeClassName('displayNone');
                    }
                })

                var notFoundItem = menu.children_[menu.children_.length - 1];
                if (filteredItems.length === 0) {
                    notFoundItem.removeClassName('displayNone')
                } else {
                    notFoundItem.addClassName('displayNone');
                }
            }
        });

        return htmlInput;
    }

    Blockly.FieldWidgetsDropdown.cleanUpEditor = function(){
        Blockly.DropDownDiv.hide();
        Blockly.WidgetDiv.hide();
        Blockly.Events.setGroup(false);
    }

    Blockly.FieldWidgetsDropdown.prototype.showEditor_ = function () {
        Blockly.WidgetDiv.show(this, this.sourceBlock_.RTL);

        this.textInput_ = this.createTextInput_();

        var div = Blockly.WidgetDiv.DIV;
        div.classList.add('fieldTextInput');
        div.appendChild(this.textInput_);
        this.resizeEditor_();

        var options = this.getOptions();
        if (options.length == 0) return;

        this.dropDownOpen_ = true;
        // If there is an existing drop-down someone else owns, hide it immediately and clear it.
        Blockly.DropDownDiv.hideWithoutAnimation();
        Blockly.DropDownDiv.clearContent();

        var contentDiv = Blockly.DropDownDiv.getContentDiv();

        var thisField = this;

        var selected = false;


        function callback(e) {
            if (selected) return;
            var menu = this;
            var menuItem = e.target;
            if (menuItem) {
                thisField.onItemSelected(menu, menuItem);
                selected = true;
            }
            Blockly.FieldWidgetsDropdown.cleanUpEditor();
        }

        this.menu_ = new goog.ui.Menu();
        this.menu_.setRightToLeft(this.sourceBlock_.RTL);
        for (var i = 0; i < options.length; i++) {
            var content = options[i][0]; // Human-readable text or image.
            var value = options[i][1];   // Language-neutral value.
            var page = options[i][2];
            var separator = value === '_SEPARATOR_';
            if (separator) {
                let menuItem = new goog.ui.MenuHeader(content.display);

                menuItem.setRightToLeft(this.sourceBlock_.RTL);
                this.menu_.addChild(menuItem, true);
                menuItem.getElement().style.borderColor = this.sourceBlock_.getColourTertiary();
                if(content.name!=content.display){
                    let tooltip = new goog.ui.Tooltip(undefined, content.name);
                    tooltip.attach(menuItem.element_)
                }
                continue;
            }
            if (typeof content == 'object') {
                // An image, not text.
                var image = new Image(content['width'], content['height']);
                image.src = content['src'];
                image.alt = content['alt'] || '';
                content = image;
            }
            var trimmedContent = Blockly.FieldWidgetsDropdown.trim(content,30);

            var menuItem = new goog.ui.MenuItem(trimmedContent);
            menuItem.setRightToLeft(this.sourceBlock_.RTL);
            menuItem.setValue(value);
            menuItem.fullContent_ = content;
            menuItem.setCheckable(true);
            menuItem.category = page.name;
            this.menu_.addChild(menuItem, true);
            var checked = (value == this.value_);
            menuItem.setChecked(checked);
            if (checked) {
                this.selectedItem = menuItem;
            }

            if(trimmedContent != content){
                let tooltip = new goog.ui.Tooltip(undefined, content);
                tooltip.attach(menuItem.element_)
            }


        }
        var notFoundItem = new goog.ui.MenuItem("not found");
        notFoundItem.fullContent_ = "not found";
        notFoundItem.setRightToLeft(this.sourceBlock_.RTL);
        notFoundItem.setValue(null);
        notFoundItem.setCheckable(false);
        notFoundItem.setEnabled(false);
        notFoundItem.addClassName('displayNone');

        this.menu_.addChild(notFoundItem, true);

        // Listen for mouse/keyboard events.
        goog.events.listen(this.menu_, goog.ui.Component.EventType.ACTION, callback);

        // Record windowSize and scrollOffset before adding menu.
        this.menu_.render(contentDiv);
        var menuDom = this.menu_.getElement();
        Blockly.utils.dom.addClass(menuDom, 'blocklyDropdownMenu');
        // Record menuSize after adding menu.
        var menuSize = Blockly.utils.uiMenu.getSize(this.menu_);
        // Recalculate height for the total content, not only box height.
        menuSize.height = menuDom.scrollHeight;

        var primaryColour = (this.sourceBlock_.isShadow()) ?
            this.sourceBlock_.parentBlock_.getColour() : this.sourceBlock_.getColour();

        Blockly.DropDownDiv.setColour(primaryColour, this.sourceBlock_.getColourTertiary());

        var category = (this.sourceBlock_.isShadow()) ?
            this.sourceBlock_.parentBlock_.getCategory() : this.sourceBlock_.getCategory();
        Blockly.DropDownDiv.setCategory(category);

        Blockly.DropDownDiv.showPositionedByField(this, this.onHide.bind(this));

        this.menu_.setAllowAutoFocus(false);
        // menuDom.focus();

        // Update colour to look selected.
        if (!this.disableColourChange_) {
            if (this.sourceBlock_.isShadow()) {
                this.sourceBlock_.setShadowColour(this.sourceBlock_.getColourTertiary());
            } else if (this.box_) {
                this.box_.setAttribute('fill', this.sourceBlock_.getColourTertiary());
            }
        }
        this.textInput_.focus();
    };

}

export default fieldWidgetsDropdown
