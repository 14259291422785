import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './index.scss';

class SaveRESTBlockErrorDialog extends Component {

    render() {
        return (
            <div id={"save-rest-block-error-dialog-container"}>
                <div id={"save-rest-block-error-dialog-wrapper"}>
                    <div className={"save-rest-block-error-dialog-header"}>
                        <h4>Save Block Error</h4>
                    </div>
                    <div className={"save-rest-block-error-dialog-body"}>
                        <p>
                            REST API blocks cannot be saved. Please remove any REST API blocks from
                            the group of blocks you wish to save before continuing.
                        </p>
                    </div>
                    <div className="save-block-type-error-dialog-footer">
                        <Link to={`/${window.location.search}`}>
                            <button className="btn-OK">OK</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default SaveRESTBlockErrorDialog