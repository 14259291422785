import React, {Component} from 'react';
import "./index.css";
import SearchIcon from "../../../assets/toolbox/searchbar.svg";
import ExpandIcon from "../../../assets/toolbox/expand.svg";
import CollapseIcon from "../../../assets/toolbox/collapse.svg";

class SearchBar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        let searchBar = document.querySelector(".search_bar-container");
        searchBar.style.width = this.props.collapsed ? "39px" : "199px";
        searchBar.style.height = "40px";
    }

    render() {
        return (
            <div className={"search_bar-container"}>
                { this.props.collapsed ? null :
                    <><div className={"search-icon-container"}>
                        <img className={"search-icon"} src={SearchIcon} alt={"search"} height={"28"} width={"28"}/>
                    </div>
                    <input type="text" id={"search-bar"} placeholder={"Search..."} value={this.props.searchQuery}
                        onChange={e => this.props.onSearch(e.target.value)}
                        onClick={e => e.target.value}
                        onFocus={() => {
                            this.props.searchQuery && this.props.onSearch(this.props.searchQuery);
                            this.props.onSearchFocus();
                            }}
                        onBlur={() => this.props.onSearchBlur()}
                    /></>
                }
                { this.props.collapsed ?
                    <div className={"expand-collapse-container"} onClick={this.props.expandButtonHandler}>
                        <img className={"expand-collapse-icon"} src={ExpandIcon} alt={">"} height={"20"} width={"20"}/>
                    </div> :
                    <div className={"expand-collapse-container"} onClick={this.props.collapseButtonHandler}>
                        <img className={"expand-collapse-icon"} src={CollapseIcon} alt={"<"} height={"20"} width={"20"}/>
                    </div>
                }
            </div>
        );
    }
}

export default SearchBar;
