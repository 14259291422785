import React, {Component} from 'react';
import './index.css';

export default class Documentation extends Component {
    render() {
        return (
            <div id={"documentation"}>

                <h2 id={'title'}>SOTI Blockly Documentation</h2>
                <div id={'content'}>
                    <div id={'contents'}>

                        <ul>
                            <h3>Contents</h3>
                            <li><a href="#overview">Overview</a></li>
                            <li><a href="#widget-values">Widget Values</a></li>
                            <li><a href="#app-actions">App Actions</a></li>
                            <li><a href="#connections">Connections</a></li>
                            <li><a href="#logic">Logic</a></li>
                            <li><a href="#math">Math</a></li>
                            <li><a href="#loops">Loops</a></li>
                            <li><a href="#text">Text</a></li>
                            <li><a href="#lists">Lists</a></li>
                            <li><a href="#colours">Colours</a></li>
                            <li><a href="#dates">Dates</a></li>
                            <li><a href="#variables">Variables</a></li>
                            <li><a href="#XSight Topics">XSight Topics</a></li>
                            <li><a href="#Repeater">Repeater</a></li>
                            <li><a href="#Table">Table</a></li>
                            <li><a href="#MobiControl">MobiControl</a></li>
                            <li><a href="#debug-tools">Debug Tools</a></li>

                        </ul>
                    </div>
                    <div id={'material'}>
                        <div className={"documentation-container"}>
                            <div id={"overview"}>
                                <h3>Overview</h3>
                                <p>
                                    SOTI Blockly is a block-based visual programming environment that aims to
                                    provide SOTI customers with a beginner-friendly interface for writing custom
                                    scripting logic. Instead of writing error-prone text-based code, with SOTI
                                    Blockly
                                    you can create logic flows by dragging and dropping &#39;blocks&#39; onto a canvas.
                                    <br/>
                                    <br/>
                                    Each block performs a different function such as navigating to a new page or
                                    displaying a popup message in a SOTI Snap app.
                                    <div className={'imageContainer'}>
                                        <img src="/images/navigate.png"/>
                                        <img src="/images/display-message.png"/>
                                    </div>
                                    You can connect Blocks to other blocks to create more complicated logical
                                    statements. The shape of a block indicates where it can be connected.
                                    <br/>
                                    <div className={'imageContainer'}>
                                        <img src="/images/statementConnect.gif" alt=""/>
                                        <img src="/images/roundConnect.gif" alt=""/>
                                        <img src="/images/hexagonalConnect.gif" alt=""/>
                                    </div>
                                    <br/>

                                    You can find all available blocks in the &#39;Toolbox&#39; on the left side of the
                                    workspace. Click on a block category to open a menu where you can select blocks
                                    within that category.

                                    <br/>
                                    <div className={'imageContainer'}>
                                        <img src="/images/toolboxDemo.gif" alt="Toolbox"/>
                                    </div>
                                    <br/>

                                    Hover your mouse cursor on a block or toolbox category to reveal a tooltip with a
                                    brief description of its use. Find detailed documentation on each of the available
                                    blocks here:

                                </p>
                                <ul>
                                    <li><a href="#widget-values">Widget Values</a></li>
                                    <li><a href="#app-actions">App Actions</a></li>
                                    <li><a href="#connections">Connections</a></li>
                                    <li><a href="#logic">Logic</a></li>
                                    <li><a href="#math">Math</a></li>
                                    <li><a href="#loops">Loops</a></li>
                                    <li><a href="#text">Text</a></li>
                                    <li><a href="#lists">Lists</a></li>
                                    <li><a href="#colours">Colours</a></li>
                                    <li><a href="#dates">Dates</a></li>
                                    <li><a href="#variables">Variables</a></li>
                                    <li><a href="#XSight Topics">XSight Topics</a></li>
                                    <li><a href="#MobiControl">MobiControl</a></li>
                                    <li><a href="#Repeater">Repeater</a></li>
                                    <li><a href="#Table">Table</a></li>
                                    <li><a href="#debug-tools">Debug Tools</a></li>
                                </ul>
                            </div>
                            <div id={"widget-values"}>
                                <h3>Widget Values</h3>
                                <p>
                                    The Widget Values toolbox category contains all blocks for interacting with SOTI
                                    Snap widgets. Open the Widget Values toolbox category to reveal a list of blocks for
                                    all the widgets that appear in your Snap app. These blocks are categorized by
                                    widget type.

                                    <div className={'imageContainer'}>
                                        <img src="/images/widgetValuesIcon.png" alt="Widgets"/>
                                    </div>

                                    Each Widget Value block has a dropdown list where you can select which widget in
                                    your SOTI Snap app the block applies to. The dropdown list contains options
                                    corresponding to the names you have given to the widgets in your Snap app.
                                    <br/>
                                    <br/>
                                    For instance, the following block can set the text of a Button widget. Click on the
                                    Widget dropdown to show all the buttons in your SOTI Snap app, organized by the
                                    pages they appear on.
                                    <br/>
                                    <br/>
                                    In the example below, the button called &#39;button1&#39; appears on
                                    &#39;Page2&#39; of the SOTI Snap app.
                                    <div className={'imageContainer'}>
                                        <img src="/images/widgetValuesIconDD.png" alt="Widgets"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"app-actions"}>
                                <h3>App Actions</h3>
                                <p>
                                    The App Actions toolbox category contains blocks that can perform actions specific
                                    to a SOTI Snap app. You can use the ‘Navigate’ block to send app users to a
                                    different page in a SOTI Snap app.
                                    <div className={'imageContainer'}>
                                        <img src="/images/navigate.png"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"connections"}>
                                <h3>Connections</h3>
                                <p>

                                    The Connections toolbox category contains blocks for sending RESTful requests to
                                    third party services (see the <a
                                    href={'https://developer.mozilla.org/en-US/docs/Glossary/REST'}>MDN
                                    documentation</a> if you are not familiar with RESTful services). All RESTful method
                                    types (GET, POST, PUT, DELETE, HEAD, CONNECT, OPTIONS, and TRACE) are supported by
                                    the &#39;REST Service&#39; block.
                                    <br/>
                                    <br/>
                                    Connection blocks contain hooks for when a request succeeds or fails so you can
                                    display the appropriate feedback on your app.
                                    <div className={'imageContainer'}>
                                        <img src="/images/restCallbacks.png"/>
                                    </div>
                                    You can check that your service is working correctly by clicking &#39;Test&#39;. When
                                    you test your service, and it responds successfully with data, the success data
                                    block is populated with the response.
                                    <br/>
                                    <br/>
                                    You can use the dropdown menus on this block
                                    to access different parts of the response.
                                    <div className={'imageContainer'}>
                                        <img src="/images/rest-test.gif" alt="Rest Test"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"logic"}>
                                <h3>Logic</h3>
                                <p>

                                    Use Logic blocks to execute blocks based on whether a particular condition is met.
                                    <br/>
                                    <br/>

                                    The &#39;if&#39; block only runs its nested statement if the condition in its hexagonal
                                    block input is met. For instance, the coin-toss Blockly script below prints a
                                    message saying &#39;heads&#39; if a randomly generated fraction is greater than 0.5.
                                    <br/>
                                    <b>Note</b>: All blocks that depend upon whether a condition is met have a hexagonal
                                    shape. These are called &#39;Boolean&#39; values and can be either &#39;true&#39; or
                                    &#39;false&#39;.
                                    <div className={'imageContainer'}>
                                        <img src="/images/basicIfStatement.png"/>
                                    </div>
                                    <br/>

                                    To complete the heads/tails script so that the program prints &#39;tails&#39; the other 50%
                                    of the time, extend the &#39;if&#39; block by clicking the plus button. The new statement
                                    hook will only execute when the condition is not satisfied (when the randomly
                                    generated fraction is less than or equal to 0.5).
                                    <div className={'imageContainer'}>
                                        <img src="/images/ifShowMessage.png"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"loops"}>
                                <h3>Loops</h3>
                                <p>
                                    Use Loop blocks to execute code blocks repeatedly.
                                    <br/>
                                    <br/>
                                    For instance, you can set a code block to repeat some specified number of times, or
                                    while a condition is met.

                                    <div className={'imageContainer'}>
                                        <img src="images/loops.png"/>
                                    </div>
                                    <br/>


                                    It is often useful to maintain a &#39;counter&#39; to keep track of the current iteration of
                                    the loop.
                                    <br/>
                                    <br/>
                                    For instance, the &#39;count with&#39; block below will repeat the print statement 10 times,
                                    and after each iteration of the loop, the &#39;i&#39; variable (see <a
                                    href="#variables">Variables</a> section) will be incremented by one. The first
                                    iteration of the loop will print the first element of the list called &#39;myList&#39;, the
                                    second iteration will print the second element of &#39;myList&#39;, and so on.

                                    <div className={'imageContainer'}>
                                        <img src="images/countWithLoopStatement.png"/>
                                    </div>
                                    <br/>

                                    It’s also common to need to loop through each item of a list (similar to how the
                                    block above loops through the first 10 items).
                                    <br/>
                                    <br/>

                                    The &#39;for each item in list&#39; block will repeat its inner statement as many times as
                                    there are items in the provided list. At each new iteration of the loop, the
                                    specified variable will have the value of the next item in the list.
                                    <br/>
                                    <br/>

                                    So the block below will loop through &#39;myList&#39; and print each item of the list.

                                    <div className={'imageContainer'}>
                                        <img src="images/forEachItemInList.png"/>
                                    </div>
                                </p>
                            </div>

                            <div id={"math"}>
                                <h3>Math</h3>
                                <p>
                                    Use Math blocks to compute values and work with numbers. The Math toolbox category
                                    includes blocks to perform math operations (addition, subtraction, multiplication,
                                    division, square root, etc...), generate random numbers, and add mathematical
                                    constants such as pi.

                                    <div className={'imageContainer'}>
                                        <img src="images/math.png"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"text"}>
                                <h3>Text</h3>
                                <p>
                                    Use Text blocks to construct and manipulate lines of text (which are sometimes
                                    called &#39;strings&#39;). There are text blocks for joining multiple pieces of text
                                    together, searching for a quote in a larger piece of text, changing the case of some
                                    text, and displaying messages.
                                    <div className={'imageContainer'}>
                                        <img src="/images/text.png"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"lists"}>
                                <h3>Lists</h3>
                                <p>

                                    Use Lists blocks to combine multiple values into one block, which you can then
                                    perform operations on.
                                    <br/>
                                    <br/>

                                    For instance, you can aggregate any number of names into a list block, and then use
                                    the &#39;sort&#39; block to alphabetize them.
                                    <div className={'imageContainer'}>
                                        <img src="/images/sort.png"/>
                                    </div>
                                    <br/>
                                    You can also use Lists blocks to perform mathematical operations on sequences of
                                    numbers.
                                    <div className={'imageContainer'}>
                                        <img src="/images/sumOfList.png"/>
                                    </div>
                                    <br/>
                                    You can also get a specific item in a list by referencing its position in the list.
                                    <br/>
                                    <br/>

                                    For instance, if you wanted to get the second item in a list, you could create the
                                    following block.

                                    <div className={'imageContainer'}>
                                        <img src="/images/getFromList.png"/>
                                    </div>
                                </p>
                            </div>

                            <div id={"colours"}>
                                <h3>Colours</h3>
                                <p>
                                    Use Colour blocks to style different aspects of your Snap app. Colours can be chosen
                                    from a colour palette or specified as an RGB value. For more information on RGB
                                    colour values see <a href="https://htmlcolorcodes.com/color-picker/">this link</a>.
                                    <div className={'imageContainer'}>
                                        <img src="/images/colours.png"/>
                                    </div>
                                    Color blocks convert the RGB value to hex format. For instance, the RGB value of
                                    R:255, G:0, B:100, produces &quot;#ff0064&quot; (a <span style={{color:'#ff0064'}}>pink</span> color).
                                </p>
                            </div>

                            <div id={"dates"}>
                                <h3>Dates</h3>
                                <p>
                                    Use Date blocks to work with Snap date widgets and to compare dates.
                                    <br/>
                                    <br/>

                                    For instance, you can display an error if the date entered into a Snap Date widget
                                    is earlier than the current date.

                                    <div className={'imageContainer'}>
                                        <img src="/images/dateCompareShowMessage.png"/>
                                    </div>

                                    Date blocks use the following format: &#39;month day year&#39;, for instance &#39;Jan 1 1970&#39;.
                                </p>
                            </div>


                            <div id={"variables"}>
                                <h3>Variables</h3>
                                <p>

                                    It’s often useful to store the value of a calculation or another computed result
                                    somewhere to use it later (perhaps in another calculation). In the Variables toolbox
                                    category, you can create a new variable to store intermediate values to re-use
                                    elsewhere in your script.

                                    <div className="imageContainer">
                                        <img src="images/createVariables.gif"/>
                                    </div>


                                    <b>Note</b>: When creating a variable three new blocks are added to the Variables
                                    toolbox category. 1. For setting the value of a variable, 2. For getting the value
                                    of a variable, and 3. For incrementing the value of a variable by 1.
                                    <br/>
                                    <br/>
                                    Here&#39;s an example of where you might want to use a variable. If you have a
                                    complicated calculation that requires many math blocks, chances are your block is
                                    long and difficult to read. The first block below calculates a total price of a food
                                    order by adding the prices of each individual menu item together.

                                    <div className={'imageContainer'}>
                                        <img src='images/mathLong.png'/>
                                    </div>
                                    With variables you can split the calculation into a few steps where you update the
                                    value of the variable you use to store intermediate values.
                                    <div className={'imageContainer'}>
                                        <img src='images/mathVariables.png'/>
                                    </div>

                                    With variables, the script is now easier to read and understand.
                                </p>
                            </div>
                            <div id={"XSight Topics"}>
                                <h3>XSight Topics</h3>
                                <p>
                                    Users now have the ability to create an Xsight topic within Snap and showcase the data from the Snap app on the Xsight Live View dashboard.
                                </p>
                                <p>
                                    To create a new topic, follow these steps:
                                </p>
                                <ol>
                                    <li>Navigate to the Data Connection Rule section.</li>
                                    <li>Proceed to the Xsight Live View Topic area.</li>
                                    <li>Click on the New Topic button.</li>
                                    <li>Provide a name for the topic and click Create.</li>
                                    <li>This action will result in the creation of a new Xsight topic.</li>
                                </ol>
                                <p>
                                    If the Snap application incorporates an Xsight topic, there will be an Xsight Topic menu available within Blockly. This menu facilitates the retrieval and adjustment of values associated with the Xsight topic.
                                    <div className={'imageContainer'}>
                                        <img src="/images/XSightTopics.PNG"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"Repeater"}>
                                <h3>Repeater</h3>
                                <p>
                                    The repeater blocks listed below can be employed to exhibit and manipulate data in table widgets.
                                </p>
                            </div>
                            <div id={"Table"}>
                                <h3>Table</h3>
                                <p>
                                    The table blocks listed below can be employed to exhibit and manipulate data in table widgets.
                                </p>
                                <p>
                                    This block yields the value in the specified column in the chosen row of the designated table.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_select_row.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block adds a record or row to the specified table. Once the table is specified, the block will expand to include fields where values can be entered for each column.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_add_to.png"/>
                                    </div>
                                </p>
                                <p>
                                    Utilize this block to modify the name of a particular column within the table.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_set_name.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block retrieves the values under the specified column as a list.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_get_column.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block deletes all rows in the specified table that meet the given conditions.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_deleteRow.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block synchronizes the data in the table with the data from the linked API or database.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_refresh.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block saves changes made to a table row that is under edit. Its functionality corresponds to the 'Run Edit Records' action found in Events > Actions.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_run_edit.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block cancels changes made to a table row that is under edit. Its functionality aligns with the 'Cancel Edit Records' action present in Events > Actions.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_cancel_edit.png"/>
                                    </div>
                                </p>
                                <p>
                                    These blocks can be employed to exhibit or conceal records in the designated table that fulfill the specified conditions.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_show_record.png"/>
                                    </div>
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_hide_record.png"/>
                                    </div>
                                </p>
                                <p>
                                    This block retrieves data from a RESTful API endpoint (specified by the URL) and loads the data into the indicated table. Once the table is selected and the necessary connection details for the API are provided, click the Test button to verify the connection and then Apply in the test window. Additional dropdown fields will appear, enabling mapping of the API response data to the columns in the table.
                                    <div className={'imageContainer'}>
                                        <img src="/images/table_load.png"/>
                                    </div>
                                </p>
                            </div>
                            <div id={"MobiControl"}>
                                <h3>MobiControl</h3>
                                <p>
                                    The MobiControl blocks provide the access to get the device details from the MC agent.
                                </p>
                            </div>
                            <div id={"debug-tools"}>
                                <h3>Debug Tools</h3>
                                <p>

                                    Use debugging blocks to help troubleshoot your Blockly script.
                                    <br/>
                                    <br/>
                                    <div className={'imageContainer'}>
                                        <img src='images/DebugToolBlocks.png'/>
                                    </div>
                                    Debug tools have 3 blocks that can be used individually to produce the value of the
                                    provided block.
                                    <br/>
                                    <br/>
                                    <b>log to console -</b>
                                        <br/>
                                        <div className={'imageContainer'}>
                                            <img src='images/DebugToolConsole.png'/>
                                        </div>
                                        Use ‘log to console’ block to display debugging information on the browser when
                                        app
                                        is previewed. To see the debugging information, place the app in Preview mode
                                        and
                                        right-click anywhere on the page. Select ‘Inspect’ or ‘Inspect Element’
                                        (depending
                                        on your browser) and then choose the ‘Console’ tab in the developer settings
                                        section
                                        that appears.
                                        <br/>
                                        <br/>
                                    <b>log info to device -</b>
                                        <br/>
                                        <div className={'imageContainer'}>
                                            <img src='images/DebugToolDevice.png'/>
                                        </div>
                                        <br/>
                                        <br/>
                                        Use ‘log info to device’ block to display information in the app once it’s
                                        published. When testing a Snap app on a device, the log info to device block
                                        sends
                                        messages to the app log. To see the information in the app click on the 3-dot
                                        menu
                                        at the top right corner and click on ‘View Logs’.
                                        <br/>
                                        <br/>
                                    <b>Log error to device -</b>
                                        <br/>
                                        <div className={'imageContainer'}>
                                            <img src='images/DebugToolError.png'/>
                                        </div>
                                        <br/>
                                        Use ‘log error to device’ block to display debugging information in the app once
                                        it’s published. When testing a Snap app on a device, the log error to device
                                        block
                                        sends messages to the app error log. To see the debugging information in the app
                                        click on the 3-dot menu at the top right corner and click on ‘View Logs’.
                                        <br/>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

