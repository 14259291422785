/* global Blockly */
import React from 'react';
import {getFormsFromContext} from "../helpers";
import { WORKFLOW_APP_TYPE_ID } from "../../../utils";

function defineBlock(context) {
    const forms = getFormsFromContext(context);

    return {
        init: function () {
            this.appendDummyInput()
                .appendField("sync form")
                .appendField(new Blockly.FieldDropdown(forms), "FORMSLUG");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Reset a form or a screen in the application.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let formSlug = block.getFieldValue('FORMSLUG');
            let formFields = formSlug.split("<<<>>>");
            let formId = formFields[0];
            let pageId = formFields[1];

            let code = `
                BlocklyPromises.push(actionsModule.getExecuteActionPromise({
                    action: {
                        actionType: "sync"
                    },
                    formDataGenyId: "${formId || pageId}",
                }))
            `;

            return code;
        }
    }
}

const Sync = function (props) {

    let name = "snap_action_sync";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (!getFormsFromContext(props.context).length || props.context.appTypeId === WORKFLOW_APP_TYPE_ID) {
        return null;
    }

    return (
        <block type={name}></block>
    )
};

export default Sync;
