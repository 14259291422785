/* global Blockly */
import React from 'react';

const snackbarFunc = [
    'function displayMessage(id, text, duration, textColor, backgroundColor, animation){',
    '    var snackbar = document.createElement("div");',
    '    var snackbarId = id +"_snackbar";',
    '    snackbar.innerHTML = text;',
    '    snackbar.style.position = "absolute";',
    '    snackbar.style.textAlign = "center";',
    '    snackbar.style.padding = "5px";',
    '    snackbar.style.borderRadius = "5px";',
    '    snackbar.style.transition = "all 0.5s ease";',
    '    snackbar.style.zIndex = "9999999";',
    '    snackbar.style.width = "100%";',
    '    snackbar.style.wordWrap = "break-word";',
    '    snackbar.id = snackbarId;',
    '',
    '    var initialStyle = {',
    '        left: "50%",',
    '        top: "50%",',
    '        backgroundColor: backgroundColor,',
    '        color: textColor,',
    '       transform: "translate(-50%,0%)"',
    '    };',
    '    var finalStyle = {top: "50%"};',
    '    var transition',
    '    switch (animation) {',
    '        case "fade in":',
    '            initialStyle.backgroundColor = "rgba(0,0,0,0)";',
    '            initialStyle.color = "rgba(0,0,0,0)";',
    '            initialStyle.top = "75%";',
    '            transition = "background-color 2s ease, color 2s ease";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide up":',
    '            initialStyle.top = "100%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide down":',
    '            initialStyle.top = "0%";',
    '            initialStyle.transform = "translate(-50%,-100%)";',
    '            finalStyle.top = "25%";',
    '',
    '            break;',
    '        case "slide right-to-left":',
    '            initialStyle.left = "100%";',
    '            initialStyle.transform = "translate(100%,0%)";',
    '            initialStyle.top = "75%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide left-to-right":',
    '            initialStyle.left = "0%";',
    '            initialStyle.transform = "translate(-100%,0%)";',
    '            initialStyle.top = "75%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        default:',
    '            console.log("WARNING: unrecognized transition");',
    '            break;',
    '    }',
    '',
    '    snackbar.style.left = initialStyle.left;',
    '    snackbar.style.top = initialStyle.top;',
    '    snackbar.style.backgroundColor = initialStyle.backgroundColor;',
    '    snackbar.style.color = initialStyle.color;',
    '    snackbar.style.transform = initialStyle.transform;',
    '',
    '    document.body.appendChild(snackbar);',
    '    setTimeout(function () {',
    '        snackbar.style.backgroundColor = backgroundColor;',
    '        snackbar.style.color = textColor;',
    '        snackbar.style.left = "50%";',
    '        snackbar.style.transform = "translateX(-50%)";',
    '        snackbar.style.top = finalStyle.top;',
    '        setTimeout(function () {',
    '            snackbar.style.backgroundColor = "rgba(0,0,0,0)";',
    '            snackbar.style.color = "rgba(0,0,0,0)";',
    '            setTimeout(function () {',
    '                var el = document.getElementById(snackbarId);',
    '                el.parentNode.removeChild(el);',
    '            }, 700);',
    '        }, duration * 1000)',
    '    }, 100);',
    '}'
]

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput().appendField('        - deprecated -');
            this.setWarningText("This block was deprecated, please use the \"Show message\" block instead.");

            this.appendValueInput('MESSAGE')
                .setCheck(null)
                .appendField('display message')
                .setOnNewRow(true)
            this.appendDummyInput()
                .appendField('duration (s)')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldNumber(2, 0), 'DURATION');
            this.appendValueInput('TEXT_COLOR')
                .appendField('text color')
                .setCheck("Colour")
                .setOnNewRow(true);

            this.appendValueInput('BACKGROUND_COLOR')
                .appendField('background color')
                .setCheck("Colour")
                .setOnNewRow(true)
            this.appendDummyInput()
                .appendField('with animation')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldDropdown([['fade in', 'fade in'], ['slide up', 'slide up'], ['slide down', 'slide down'], ['slide right-to-left', 'slide right-to-left'], ['slide left-to-right', 'slide left-to-right']]), 'ANIMATION');
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Show a message that disappears after some time.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#text");
            this.setColour(Blockly.Msg.TEXTS_HUE)
            this.setOpacity(0.6);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block){
            let displayMessage = Blockly.JavaScript.provideFunction_('displayMessage', snackbarFunc);
            let text = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_NONE) || '<enter message>';
            let duration = block.getFieldValue('DURATION');
            let color = Blockly.JavaScript.valueToCode(block, 'TEXT_COLOR', Blockly.JavaScript.ORDER_NONE);
            let backgroundColor = Blockly.JavaScript.valueToCode(block, 'BACKGROUND_COLOR', Blockly.JavaScript.ORDER_NONE);
            let animation = '"'+block.getFieldValue('ANIMATION')+'"';
            return displayMessage + '("' + block.id + '",' + ([text,duration,color,backgroundColor,animation].toString())+');\n'
        }
    }
}

const SotiSnackbar = () => {
    const name = 'soti_snackbar';
    Blockly.Blocks[name] = defineBlock();

    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="MESSAGE">
                <shadow type="text"></shadow>
            </value>
            <value name="TEXT_COLOR">
                <shadow type="colour_picker">
                    <field name="COLOUR">#000000</field>
                </shadow>
            </value>
            <value name="BACKGROUND_COLOR">
                <shadow type="colour_picker">
                    <field name="COLOUR">#FFFFFF</field>
                </shadow>
            </value>
        </block>
    )
};

export default SotiSnackbar;
