/* global Blockly */
import React from 'react';
import MobiControlCore from './MobiControlCore';

function defineBlock(context) {
    let prop = {...context};
    prop.blockText = "get device serial number";
    prop.tooltipText = "Get serial number from MobiControl.";
    prop.responseText = "serial number";
    prop.responseBlockType = "text_response";
    let blockInstance = MobiControlCore(prop);
    return blockInstance;
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let coreResponseCode = block.getJavascriptCore();
            return `Snap.mcDeviceDetails.getSerialNumber(${coreResponseCode});\n`;
        }
    }
}

const MobiControlGetSerialNumber = function (props) {
    const name = 'snap_mobicontrol_getserialnumber';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}></block>
}

export default MobiControlGetSerialNumber;