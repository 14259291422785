const handlers = {
    // Maps to F key
    'KeyF': (e) => {
        e.preventDefault();
        document.querySelector("#search-bar").focus()
    }
};

const KeyBindings = () => {
    document.addEventListener('keydown', (e) => {
        let metaKey = e.ctrlKey || e.metaKey;
        if (!metaKey) { return; }
        handlers[e.code] && handlers[e.code](e);
    });
    return null;
};

export default KeyBindings;