/* global Blockly */
import React from 'react';
import {dropdownIcon} from "./index";

// everything here should be ES5
function defineBlock(context, dropdowns) {

    var dropdownOpts = {};
    dropdowns.forEach(function (widget) {
        dropdownOpts[widget.id] = widget.properties.options.map(function (cb) {
            return [cb.name, cb.id];
        });
    });

    return {
        init: function () {
            var widgetDD = new Blockly.FieldWidgetsDropdown(context,"dropdown", undefined, '- select -');
            this.appendDummyInput("A")
                // .appendField("dropdown")
                .appendField(new Blockly.FieldImage(dropdownIcon, 30, 30, "dropdown icon", null), 'dropdownIcon')
                .appendField(widgetDD, "DD")
                .appendField("is set to")
                .appendField(new Blockly.FieldDropdown([['', '']], undefined, '- select -'), "VALUE");
            widgetDD.setOnChange(this._ddChanged.bind(this));
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('\'true\' or \'false\' depending on whether the chosen dropdown widget is set to the specified option.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this._ddChanged(this.getFieldValue("DD"));
        },
        onchange: function() {
            if (this.getField('dropdownIcon') && this.getField('dropdownIcon').imageElement_)
                this.getField('dropdownIcon').setTooltip('DropDown');
        },
        onLoad: function(){
            var id= this.getFieldValue('DD');
            var widget = dropdowns.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']];
            } else {
                opts = dropdownOpts[id] || [['', '']];
            }

            var dd = this.getField("VALUE");
            var value = dd.getValue();

            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function(){
                    dd.setWarning(false);
                    if(closure){
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        _ddChanged: function (val) {
            var fieldDropDown;
            var input = this.getInput("A");
            input.removeField("VALUE");

            if(this.getField('DD').hasBeenSet){
                fieldDropDown = new Blockly.FieldDropdown(dropdownOpts[val] || [['', '']], undefined, '- select -');
            } else {
                fieldDropDown = new Blockly.FieldDropdown([['', '']], undefined, '- select -');
                fieldDropDown.setEnabled(false);
            }
            input.appendField(fieldDropDown,'VALUE');
            if (this.rendered) {
                this.initSvg();
                this.render();
            }
        }
    }
}

// everything here should be ES5
function defineGenerators(context, dds) {
    return {
        'JavaScript': function (block) {
            var id = block.getFieldValue('DD');
            var val = block.getFieldValue('VALUE');
            var dd = dds.find(function (x) {
                return x.id === id
            });
            if (!dd) {
                console.warn('unable to find dropdown with id: ' + id);
                return ['undefined', Blockly.JavaScript.ORDER_NONE];
            }

            var code = "Snap.widgets.dropdown.getValue('" + id + "').value === '" + val + "'";
            code = "(" + code + ")";
            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const DropdownIsSetTo = function (props) {
    const name = 'snap_dropdown_is_set_to';
    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='DD'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default DropdownIsSetTo;