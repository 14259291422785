/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        init: function () {
            let selectTableDropdown = new Blockly.FieldWidgetsDropdown(context, 'tableview');

            this.appendDummyInput('WIDGET')
                .appendField("refresh table")
                .appendField(selectTableDropdown, "ID");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Refresh TableView widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let tableViewId = block.getFieldValue('ID');
            let code = `Snap.widgets.tableview.refreshTable("${tableViewId}");\n`;
            return code;
        }
    }
}


const RefreshTable = function (props) {
    const name = 'snap_tableview_refresh_table';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context, "tableview").length  ? <block type={name}></block> : null;
}

export default RefreshTable