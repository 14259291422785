/* global Blockly */
import React from 'react'
import _ from 'underscore';
export const globalIcon = 'data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' width=\'50\' height=\'50\' viewBox=\'0 0 50 50\'%3e%3cdefs%3e%3cpath id=\'path-1\' fill-rule=\'evenodd\' d=\'M22 9h7c6.6229803 0 12 5.3770197 12 12v7c0 6.6229803-5.3770197 12-12 12h-7c-6.6229803 0-12-5.3770197-12-12v-7c0-6.6229803 5.3770197-12 12-12z\'/%3e%3cmask id=\'mask-2\' maskContentUnits=\'userSpaceOnUse\' maskUnits=\'userSpaceOnUse\'%3e%3crect width=\'50\' height=\'50\' x=\'0\' y=\'0\' fill=\'black\'/%3e%3cuse fill=\'white\' xlink:href=\'%23path-1\'/%3e%3c/mask%3e%3c/defs%3e%3cg%3e%3cg%3e%3cuse fill=\'rgb(224,224,224)\' xlink:href=\'%23path-1\'/%3e%3cg mask=\'url(%23mask-2)\'%3e%3cpath fill=\'rgb(242,242,242)\' fill-rule=\'evenodd\' d=\'M20.76 7.76h21.88c6.6229803 0 12 5.3770197 12 12V27c0 6.6229803-5.3770197 12-12 12H20.76c-6.6229803 0-12-5.3770197-12-12v-7.24c0-6.6229803 5.3770197-12 12-12z\'/%3e%3cpath fill=\'rgb(224,224,224)\' fill-rule=\'evenodd\' d=\'M19 27.43501854l35.08412933 35.08413124 8.55265046-8.55265045L28.5 19.82971763V20L25 19l-3.43310738 1.5258255-1.32091522 1.32091522L19 26v1.43501854z\'/%3e%3c/g%3e%3c/g%3e%3ctext font-size=\'0\' style=\'white-space:pre\'%3e%3ctspan x=\'17\' y=\'32\' fill=\'rgb(11,142,105)\' font-family=\'Arial\' font-size=\'20\' font-weight=\'700\' style=\'white-space:pre;text-transform:none\' letter-spacing=\'1.105\'%3eG%3c/tspan%3e%3c/text%3e%3c/g%3e%3c/svg%3e ';

function defineBlock(context){

    let ddOpts;
    if(context.environmentVariables && (context.environmentVariables.length>0)){
        ddOpts = context.environmentVariables.map(x=>{return [x.name, x.name]});
    } else{
        ddOpts = [['- no environment variables -','- no environment variables -']]
    }

    return {
        init: function() {
            // this.appendDummyInput("GLOBAL_ICON")
            //     .appendField(new Blockly.FieldImage(globalIcon, 30, 30, "global icon", null))
            this.appendValueInput('VALUE')
                .appendField('set')
                .appendField(new Blockly.FieldImage(globalIcon, 30, 30, "global icon", null), 'globalIcon')
                .appendField(new Blockly.FieldDropdownSearch(ddOpts, undefined, '- select -'), 'VAR')
                .appendField('to');
            this.setColour(Blockly.Msg.ENV_VARIABLES_HUE);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Set the value of a Snap global variable');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#variables");
        },
        onchange: function() {
            if (this.getField('globalIcon') && this.getField('globalIcon').imageElement_)
                this.getField('globalIcon').setTooltip('Global Variable');
        }
    }
}

function defineGenerators() {
    return {
        /**
         * @return {string}
         */
        'JavaScript': function(block){
            let value = Blockly.JavaScript.valueToCode(block,'VALUE', Blockly.JavaScript.ORDER_NONE);
            let varName = block.getFieldValue('VAR');
            return 'environmentVariableModule.setVariableValueByName("' + varName + '",' + value + ');\n'
        }
    }
}

const SetEnvironmentVariable = function (props){
    const name = 'snap_set_environment_variable';

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (_.isEmpty(props.context.environmentVariables)) {
        return null
    }

    return <block type={name}>
        {
            ((!!props.environmentVariable) && props.environmentVariable.name) ?
                <field name={'VAR'}>
                    {props.environmentVariable.name}
                </field>
                :
                null
        }
        <value name="VALUE">
            <shadow type="text"/>
        </value>
    </block>
}

/* Onchange function for local variables */
var checkExisted = function (block) {
    return (block.inputList && block.inputList[0] && block.inputList[0].fieldRow && block.inputList[0].fieldRow[1] && block.inputList[0].fieldRow[1].imageElement_);
}

Blockly.Blocks['variables_set'].onchange = function () {
    if (checkExisted(this))
        this.inputList[0].fieldRow[1].setTooltip('Local Variable');
}

Blockly.Blocks['math_change'].onchange = function () {
    if (checkExisted(this))
        this.inputList[0].fieldRow[1].setTooltip('Local Variable');
}
/* End */

export default SetEnvironmentVariable
