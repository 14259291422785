/* global Blockly */
import React from 'react'

function defineBlock() {
    return {
        init: function() {
            this.appendValueInput("OBJ")
                .appendField("update JSON object")
                .setCheck(["Object", "Array"]);
            this.appendValueInput("KEY")
                .appendField("set ")
                .setCheck("String");
            this.appendValueInput("VALUE")
                .appendField("equal to ")
                .setCheck(["Object", "String", "Number", "Boolean", "Date"]);
            this.setColour(Blockly.Msg.LISTS_HUE);
            this.setOutput(false);
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip("Update the value with the specified key in the provided JSON object.");
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let key = Blockly.JavaScript.valueToCode(block, "KEY",Blockly.JavaScript.ORDER_NONE);
            let value = Blockly.JavaScript.valueToCode(block, "VALUE",Blockly.JavaScript.ORDER_NONE);
            let obj = Blockly.JavaScript.valueToCode(block, "OBJ",Blockly.JavaScript.ORDER_NONE);
            let code = `(${obj})[${key}] = ${value};
                    `;
            return code;
        }
    }
}

const UpdateSON = function (props) {
    let name = "update_json";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
    return (
        <block type={name}>
            <value name="KEY"><shadow type="text"/></value>
            <value name="VALUE"><shadow type="text"/></value>
        </block>
    )
}
export default UpdateSON