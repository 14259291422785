import React, { useState, useEffect } from 'react';
import Editor  from '@monaco-editor/react';
import ModalButtons from "../../ModalButtons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons';

const AssignScript = ({ handleBack, handleNext, args, values: blockScript }) => {
    var argString = args && Object.values(args).filter(arg => arg.type !== "label")
        .map((arg, i) => arg.value.toLowerCase() || `unnamedArg_${i}`).join(', ');
    let init = `
/* Blockly Custom Script
 * Implemented in Javascript
 * To learn more about Javascript, 
 * please visit https://developer.mozilla.org/en-US/docs/Web/JavaScript/
 *
 *
 * To access the arguments defined in 
 * the previous step you can use from
 * the arguments in the declared function directly. 
 * Block Arguments: ${argString}
 * 
 * Any return values from this code can be stored in Blockly Local Variable
 * using \`SnapReturns\` keyword as follows:
 * SnapReturns.result = "Hello World!"
 * variable result containing the assigned value can be found in
 * the local variables blocks.
 * 
 */
 
// Add your custom script below
`;
    function _reComputeArgs(script) {
        return script.replace(/\* Block Arguments:.*/gm, `* Block Arguments: ${argString}`);
    }
    let initVal = blockScript ? _reComputeArgs(blockScript) : init;
    const [value, setValue] = useState(initVal);
    const [editor, setEditor] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [completionProvider, setCompletionProvider] = useState(null);

    const _sanitizeCode = code => {
        return code.replace(/^\s*\b(const|let)\b/gm, 'var');
    };

    const _handleEditorBeforeMount = monaco => {
        function createProposals(args) {
            return [
                // ...reservedKeywords.keywords.filter(grammer => !grammer.ignore).map(grammer => {
                //     return {
                //         label: grammer.key,
                //         kind: monaco.languages.CompletionItemKind.Keyword,
                //         insertText: grammer.key
                //     }
                // }),
                ...args.map(arg => ({
                    label: arg,
                    kind: monaco.languages.CompletionItemKind.Variable,
                    insertText: arg
                })),
                {
                    label: "SnapReturns",
                    kind: monaco.languages.CompletionItemKind.Method,
                    insertText: "SnapReturns."
                }
            ];
        }

        const completionProvider = monaco.languages.registerCompletionItemProvider('javascript', {
            provideCompletionItems: function() {
                var suggestions = createProposals(argString.split(', '));
                return {
                    suggestions: suggestions
                };
            }
        });
        setCompletionProvider(completionProvider);
        setEditor(monaco);
    };

    const _expandModal = () => {
        if (expanded) {
            setExpanded(false);
            let modalWrapper = document.getElementById("save-modal-wrapper");
            modalWrapper.className = "modal-wrapper";
        } else {
            setExpanded(true);
            let modalWrapper = document.getElementById("save-modal-wrapper");
            modalWrapper.className = "modal-wrapper-expanded";
        }
    }

    useEffect(() => {
        return function cleanup() {
            editor && editor.editor.getModels().map(model => model.dispose());
        };
    }, [editor]);

    useEffect(() => {
        return function cleanup() {
            completionProvider && completionProvider.dispose()
        }
    }, [completionProvider]);

    return (
        <div className={"modal-wrapper"} id={"save-modal-wrapper"}>
            <div className={"modal-header"}>
                <h4>Create Custom JS Code Block</h4>
                <br></br>
                <div className={"icon"}>1</div>
                <div className={"text-description"}>Block Details</div>
                <div className={"straight-line"}>———</div>
                <div className={"icon"}>2</div>
                <div className={"text-description"}>Add Inputs</div>
                <div className={"straight-line"}>———</div>
                <div className={"icon-active"}>&emsp;</div>
                <div className={"text-description-active"}>Assign Custom Script</div>
                <div className={"expand-icon-container"} onClick={_expandModal} >
                    { expanded ?
                        <FontAwesomeIcon icon={faCompressAlt} /> :
                        <FontAwesomeIcon icon={faExpandAlt} />
                    }
                </div>
            </div>
            <div id={"scriptArea"} className={"modal-body"}>
                <Editor
                    language="javascript"
                    value={value}
                    options={{filteredTypes: { interface: false }}}
                    beforeMount={_handleEditorBeforeMount}
                    onChange={val => {setValue(val)}}
                    theme={"vs-dark"}/>
            </div>
            <div className="modal-footer">
                <ModalButtons step={3} back={() => handleBack(value)}
                              next={() => handleNext(_sanitizeCode(value))} />
            </div>
        </div>
    );
};

export default AssignScript;