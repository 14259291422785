/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendValueInput('HEADER')
                .setCheck(null)
                .appendField('show prompt');
            this.appendValueInput('MESSAGE')
                .setCheck(null)
                .appendField('with message');
            
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Show a prompt that will block the page until user interacts with it');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#text");
            this.setColour(Blockly.Msg.TEXTS_HUE);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let header = Blockly.JavaScript.valueToCode(block, 'HEADER', Blockly.JavaScript.ORDER_NONE);
            let message = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_NONE);
            return `UtilityModule.genericDialog(Constants.dialog.alertDialog, ${header}, ${message}, Constants.dialog.buttonTexts, null);
            `;
        }
    }
}

const ShowPrompt = () => {
    const name = 'show_prompt';
    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return (
        <block type={name}>
            <value name="HEADER">
                <shadow type="text"></shadow>
            </value>
            <value name="MESSAGE">
                <shadow type="text"></shadow>
            </value>
        </block>
    )
};

export default ShowPrompt;
