/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {textBoxIcon} from "./index";

function defineBlock(context) {

    return {
        init: function () {
            this.appendDummyInput()
                .appendField("set")
                .appendField(new Blockly.FieldImage(textBoxIcon, 30, 30, "text box icon", null), 'textBoxIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "textbox", undefined, '- select -'), "ID")
                .appendField("to");
            this.appendValueInput('VALUE');
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the value of a widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
            if (this.getField('textBoxIcon') && this.getField('textBoxIcon').imageElement_)
                this.getField('textBoxIcon').setTooltip('Textbox');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let code = `Snap.widgets.textbox.setValue('${id}', ${value});\n`;
            // this doesn't trigger validation
            //"$('[id=\"" + id + "\"]').val(" + value + ");\n";
            return code;
        }
    }
}


const SetTextboxValue = function (props) {
    let name = 'snap_set_textbox_value';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    let widgets = util.widgetsOfType(props.context, "textbox");

    if (widgets.length < 1) {
        return null
    }
    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="VALUE">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetTextboxValue
