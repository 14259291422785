/* global Blockly */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { doDomNodesIntersect } from "../../utils";
import './index.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedoAlt, faUndoAlt} from "@fortawesome/free-solid-svg-icons";

const ActionButtons = ({ saveMyBlock }) => {
    const saveRef = useRef(null);
    const [isSaveHovered, setIsSaveHovered] = useState(0);

    useEffect(() => {
        // when a block is dragged, check if the block overlaps with the garbage can
        var currentlyDragging = Blockly.BlockDragSurfaceSvg.prototype.translateSurface;

        Blockly.BlockDragSurfaceSvg.prototype.translateSurface = function () {
            // if the boxes intersect, change the colour of the garbage can to red
            if (Blockly.selected && doDomNodesIntersect(Blockly.selected.getSvgRoot(), saveRef.current)) {
                setIsSaveHovered(1);
            } else { // if the boxes no longer intersect, change the garbage can back to gray
                setIsSaveHovered(0);
            }
            currentlyDragging.call(this, ...arguments);
        };
    }, [saveRef]);

    useEffect(() => {
        setTimeout(() => {
            Blockly.mainWorkspace.addChangeListener(blocklyEvent => {
                switch (blocklyEvent.type) {
                    case Blockly.Events.BLOCK_MOVE:
                        // get the current block we are moving (in both Blockly and HTML) and the garbage can

                        // the BLOCK_MOVE event is fired:
                        // - once when a top-level block is placed back on the workspace
                        // - once when a block connected to a previous block is selected (or picked up)
                        // - once when a block nested inside another block is picked up
                        // - twice when a block is connected to a previous block
                        // - twice when a block is placed (or nested) inside another block
                        // - twice when a block nested inside a block that has a previous block is picked up
                        // - three times when a block is placed inside another block that is connected to a
                        // previous block


                        // when the mouse is released after dragging a block, check if the selected block overlaps
                        // the garbage can or the save icon

                        // if the selected block overlaps the save icon, save it
                        if (Blockly.selected && doDomNodesIntersect(Blockly.selected.getSvgRoot(), saveRef.current)) {
                            saveMyBlock(Blockly.selected);
                            setIsSaveHovered(0);
                            Blockly.selected.moveBy(-600, -100);
                        }
                        break;
                }
            });
        }, 0)
    }, [Blockly.mainWorkspace])

    useEffect(() => {
        let selected = document.querySelector(".blocklySelected");
        if (!selected) { return; }

        if (isSaveHovered) {
            selected.style.opacity = 0.5;
        } else {
            selected.style.opacity = 1;
        }
    }, [isSaveHovered]);

    return (
        <div className={"action-buttons-container"}>
            <FontAwesomeIcon className={"undoIcon"} style={{top: "48px"}} icon={faUndoAlt} onClick={()=>{Blockly.mainWorkspace.undo(false)}}/>
            <FontAwesomeIcon className={"redoIcon"} style={{top: "78px"}} icon={faRedoAlt} onClick={()=>{Blockly.mainWorkspace.undo(true)}}/>
            <div className={classNames("icon icon-save", { "is-hovered": isSaveHovered })} style={{top: "100px"}} ref={saveRef} />
        </div>
    );
};

export default ActionButtons;
