/* global Blockly */
import React from 'react'

function defineBlock(){
    return {
        init:function() {
            this.appendValueInput('LIST')
                .appendField("for each item")
                .appendField(new Blockly.FieldVariable(),'VAR')
                .appendField('in list');
            this.appendStatementInput("DO")
                .appendField("do");
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.LOOPS_HUE);
        }
    }
}

function defineGenerators(){
    return {
        /**
         * @return {string}
         */
        'JavaScript':function(block){
            let varName = Blockly.JavaScript.variableDB_.getName(block.getField('VAR').getText(), Blockly.Variables.NAME_TYPE);
            let list = Blockly.JavaScript.valueToCode(block,'LIST', Blockly.JavaScript.ORDER_NONE);
            let statement = Blockly.JavaScript.statementToCode(block,'DO');

            return  (
                'for (' + varName + ' in '+list+'){\n' +
                '' + varName + ' = ' + list + '['+varName+'];\n' +
                statement +"\n" +
                "};\n"
            );
        }
    }
}


const ForEachItemInList = function() {
    const name = 'for_each_item_in_list';

    Blockly.Blocks[name] = defineBlock();

    const generators = defineGenerators();
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}/>
    )
};

export default ForEachItemInList