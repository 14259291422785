/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput("Action")
                .appendField(new Blockly.FieldDropdown([['add', 'add'], ['subtract', 'subtract']]), "ACTION_FIELD");

            this.appendValueInput("VALUE")
                .setCheck("Number");

            this.appendDummyInput("CATEGORY")
                .appendField(new Blockly.FieldDropdown([['day(s)', 'days'], ['month(s)', 'months'], ['year(s)', 'years']]), "DATE_FIELD");

            this.appendDummyInput("TO")
                .appendField("to");

            let valueInput = this.appendValueInput('DATE')
                .setCheck("Date");

            let valueBlock = this.workspace.newBlock("date");
            valueBlock.setShadow(true);
            valueInput.connection.connect(valueBlock.outputConnection);

            this.setOutput(true, 'Date');
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('Add or subtract days/months/years from the provided date');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let isAdd = block.getFieldValue("ACTION_FIELD") === "add";
            let field = block.getFieldValue("DATE_FIELD");
            let value = 'toNumber(' + Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_FUNCTION_CALL) + ', 0)';
            let code = Blockly.JavaScript.valueToCode(block, 'DATE', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            code = 'new Date(' + code + ')';
            switch (field) {
                case 'days':
                    code = 'new Date(' + code + '.setDate(' + code + '.getDate() ' + (isAdd ? ' + ' : ' - ') + value + '))';
                    break;
                case 'months':
                    code = 'new Date(' + code + '.setMonth(' + code + '.getMonth() ' + (isAdd ? ' + ' : ' - ') + value + '))';
                    break;
                case 'years':
                    code = 'new Date(' + code + '.setFullYear(' + code + '.getFullYear() ' + (isAdd ? ' + ' : ' - ') + value + '))';
                    break;
            }
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const AddToDate = function (props) {

    const name = "date_adder";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="VALUE">
                <shadow type="math_number"/>
            </value>
        </block>
    )
}
export default AddToDate;
