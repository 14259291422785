/* global Blockly */
import React from 'react';
import {getFormsFromContext} from "../helpers";

function defineBlock(context) {
    const forms = getFormsFromContext(context);

    return {
        init: function () {
            this.appendDummyInput()
                .appendField("is form valid")
                .appendField(new Blockly.FieldDropdown(forms), "FORMSLUG");
            this.setPreviousStatement(false, null);
            this.setNextStatement(false, null);
            this.setOutput(true, "Boolean"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Reset a form or a screen in the application.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let formSlug = block.getFieldValue('FORMSLUG');
            let formFields = formSlug.split("<<<>>>");
            let formId = formFields[0];

            let code = 'validationModule.validate("'+ formId +'").isValid';
            code = "(" + code + ")";

            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const Validate = function (props) {

    let name = "snap_action_validate_page";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if(!getFormsFromContext(props.context).length){
        return null;
    }

    return (
        <block type={name}></block>
    )
};

export default Validate;
