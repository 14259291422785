import _ from 'underscore';

const _getPageItem = page => {
    return [page.id, page.name];
};

const _getWidgetItem = widget => {
    return [widget.id, widget.name, widget.type, widget.properties];
};

const _getVariable = variable => {
    return [variable.name, variable.id, variable.description, variable.defaultValue];
}

const _getXSightTopic = topic => {
    return [topic.name, topic.defaultValue];
}

export const getAllPagesFromContext = context => {
    return context.pages.map(page => _getPageItem(page))
};

export const getAllWidgetsFromContext = context => {
    return context.pages.map(page => {
        return getAllWidgetsInPageObject(page);
    });
};

export const getAllTypeWidgetsFromContext = (context, type) => {
    return context.pages.map(page => {
        return getTypeWidgetsInPageObject(page, type);
    });
};

export const getAllVariablesFromContext = (context) => {
    return context.environmentVariables.map(variable => _getVariable(variable));
}

export const getAllXSightTopicsFromContext = (context) => {
    return context.xSightTopics.map(topic => _getXSightTopic(topic));
}

export const getPageFromContext = (context, query) => {
    return _.findWhere(context.pages, query);
};

export const getPageWidgetsFromContext = (context, query) => {
    const page = getPageFromContext(context, query);
    return getAllWidgetsInPageObject(page);
};

export const getPageTypeWidgetsFromContext = (context, query, type) => {
    const page = getPageFromContext(context, query);
    return getTypeWidgetsInPageObject(page, type);
};

export const getAllWidgetsInPageObject = page => {
    return page.widgets.map(widget => _getWidgetItem(widget));
};

export const getTypeWidgetsInPageObject = (page, type) => {
    return page.widgets.reduce((allWidgets, widget) => {
        if (widget.type === type) {
            allWidgets.push(_getWidgetItem(widget));
        }
        return allWidgets;
    }, []);
};


function trim(str, len) {
    if (str.length <= len) return str;
    let halfLen = Math.floor(len / 2);
    return str.substring(0, halfLen - 2) + "..." + str.substring(str.length - halfLen - 1, str.length);
}

export const getPagesFromContext = context => {
    var pages = context.pages.filter(page => page && (!page.formId || !page.formId.includes('carouselgroup')));

    pages = pages.sort(function (a,b) {
        let aName = a.name;
        let bName = b.name;
        if (a.formName) {
            aName = a.formName + aName;
        }
        if (b.formName) {
            bName = b.formName + bName;
        }
        return (aName.toLowerCase() > bName.toLowerCase() ? 1 : -1);
    });

    if (!!context.workflowItems) {
        pages = [...pages, ...context.workflowItems];
    }

    return pages.map(function (x) {
        let displayName = x.name;
        let id = x.id;
        if (x.formName) {
            displayName = trim(x.formName, 13) + " > " + trim(x.name, 14);
            id = `${x.formId}<<<>>>${x.id}`;
        } else {
            displayName = trim(displayName, 30);
        }
        return [displayName, id];
    });
};

export const getFormsFromContext = context => {
    let pages = context.pages.sort(function (a,b) {
        let aName = a.name;
        let bName = b.name;
        if(a.formName){
            aName = a.formName + aName;
        }
        if(b.formName){
            bName = b.formName + bName;
        }
        return (aName.toLowerCase()>bName.toLowerCase()?1:(-1));
    });

    return pages.filter(p => p.formId).map(function (x) {
        let displayName = trim(x.formName, 13) + " > " + trim(x.name, 14);
        return  [displayName, `${x.formId}<<<>>>${x.id}`]
    });
}