/* global Blockly */
import React from 'react'
import _ from 'underscore';
import parse from 'html-react-parser';
import {blockToXmlSanitized} from "../../../utils";

const MyBlocks = function ({ libraries }) {
    if (_.isEmpty(libraries)) {
        return (
            <category name='My Libraries'
                      id='text'
                      iconclass='category category_my-blocks'>
                <label text="No saved blocks yet"></label>
            </category>
        );
    }

    for (let i = 0; i < libraries.length; i++) {
        libraries[i].parsedBlocks = _.values(libraries[i].blocks).map(block => {
            if (block.xml) {
                const parsedBlock = Blockly.Xml.textToDom(block.xml);
                const sanitizedBlock = blockToXmlSanitized(parsedBlock);
                let xmlText = "";
                if (sanitizedBlock.getElementsByTagName("variables").length > 0) {
                    let blockXml = sanitizedBlock.getElementsByTagName("variables")[0];
                    xmlText += Blockly.Xml.domToText(blockXml);
                }
                if (sanitizedBlock.getElementsByTagName("block").length > 0) {
                    let blockXml = sanitizedBlock.getElementsByTagName("block")[0];
                    blockXml.firstChild.setAttribute('code', block.code);
                    xmlText += Blockly.Xml.domToText(blockXml);
                }
                return xmlText;
            }
            return null;
        });
    }

    function removeSelfClosingTags(xml) {
        let split = xml.split("/>");
        let newXml = "";
        for (let i = 0; i < split.length - 1; i++) {
            let edsplit = split[i].split("<");
            newXml += split[i] + "></" + edsplit[edsplit.length - 1].split(" ")[0] + ">";
        }
        return newXml + split[split.length-1];
    }

    return (
        <category name='My Libraries'
                  id='text'
                  iconclass='category category_my-blocks'
                  expandedclass='category category_my-blocks'>
            {
                libraries.map((library, id) =>
                    <category name={library.name} iconclass={"inner-category " + library.name} key={id}>
                        <button text="Delete Library"
                                id={library.id}
                                callbackKey="showDeleteLibraryDialog" />
                        <label text={library.name} />
                        {
                            library.parsedBlocks.map(block => parse(removeSelfClosingTags(block)))
                        }
                    </category>
                )
            }
        </category>
    )
};

export default MyBlocks;
