/* global Blockly */
import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        columnOptions: [],
        widget: null,
        init: function () {
            this.appendDummyInput('Widget')
                .appendField("selected row")
                .appendField(new Blockly.FieldWidgetsDropdown(context, "tableview", undefined, '- select -', this.updateShape_.bind(this)), "ID");
            this.appendDummyInput('WidgetColumn')
                .appendField("column")
                .appendField(new Blockly.FieldDropdownSearch([], undefined, '- select -'), "COLUMN");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get a value from the selected row of a Table widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        // save values
        domToMutation: function() {
        },

        // render saved values
        mutationToDom: function () {
            // Removed due to MADP-48161
            // When this block is saved and used in a different app that does not contain table block
            // It will crash the saved block
        },

        // render column dropdown for selected widget (if any)
        updateColumns: function () {
            if (this.widget == null)
                return;
            if (this.widget.columns.length) {
                // load columns as options
                this.columnOptions = this.widget.columns.map(column => [column.name, column.id]);
            }
            else {
                // no options
                this.columnOptions = [];
            }
           
            // render column dropdown field
            let input = this.getInput("WidgetColumn");
            input.removeField("COLUMN");
            input.appendField(new Blockly.FieldDropdown(this.columnOptions), "COLUMN");
        },

        // update selected widget
        updateShape_: function() {
            let widgets = util.widgetsOfType(context, 'tableview');
            let id = this.getFieldValue('ID');
            for (let i = 0; i < widgets.length; i++) {
                if (widgets[i].id == id && !(this.widget != null && this.widget.id == id)) {
                    this.widget = widgets[i];
                    this.updateColumns();
                }
            }
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block) {
            let id = block.getFieldValue("ID");
            let columnId = block.getFieldValue('COLUMN');
            let code = `(Snap.widgets.tableview.getSelectedRow("${id}") && Snap.widgets.tableview.getSelectedRow("${id}")["${columnId}"] ? 
                Snap.widgets.tableview.getSelectedRow("${id}")["${columnId}"] : "")`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const SelectedRow = function(props){
    const name = 'tableview_selected_row';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined) ?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default SelectedRow
