/* global Blockly */
import React from 'react';
import {labelIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput("TEXT")
                .appendField("get")
                .appendField(new Blockly.FieldImage(labelIcon, 30, 30, "label icon", null), 'labelIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"label", undefined, '- select -'), "ID")
                .appendField("text");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, 'String'); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND); // add appropriate checks
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the display text of the provided label widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('labelIcon') && this.getField('labelIcon').imageElement_)
                this.getField('labelIcon').setTooltip('Label');
        }
    }
}

function defineGenerators() {
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue("ID");
            return ['($(\'[id="' + id + '"]\').text())', Blockly.JavaScript.ORDER_NONE];
        }
    }
}


const GetLabelText = function (props) {
    let name = "snap_get_label_text";
    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name="ID">{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetLabelText