/* global Blockly */
/* global Util */

/*
NOTE: This block should not be used outside the context of an try-catch block.
The JS it returns depends on the namespace established for the 
error/exception callback of a catch call call.
Thus this function always returns null (should never appear in the toolbox)
*/
const TryCatchException = function () {
    var name = 'try_catch_exception';

    Blockly.Blocks[name] = {
        init: function(){
            this.appendDummyInput()
                .appendField('error')
                .appendField(new Blockly.FieldDropdown([["name", "name"], ["message", "message"]], undefined, undefined), "PROPERTY");
            var col = {};
            Object.assign(Blockly.Msg.CONNECTIONS_HUE, col);
            col.primary = '#b10069';
            this.setColour(col);
            this.setOutput(true, "String");
            this.setTooltip('Get the value of a try-catch exception');
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
        },
        
        mutationToDom: function() {
            var mutation = Util.dom('mutation', {
                parent_id: this.parent_id
            });
            return mutation;
        },

        domToMutation: function(xml) {
            this.parent_id = xml.getAttribute('parent_id');
        },

        setParentId: function(id){
            this.parent_id = id;
        },
    };

    Blockly.JavaScript[name] = function(block) {
        var property = block.getFieldValue('PROPERTY');
        var code = `ExceptionScope['${this.parent_id}'].${property}`;
        return [code, Blockly.JavaScript.ORDER_NONE]
    };

    // this should never show in the toolbox
    return null;
};

export default TryCatchException;