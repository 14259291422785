/* global Blockly */
import React, {Component} from 'react';
import Joyride from 'react-joyride';
import queryString from "query-string";

class AppTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overrideOnboardingCheck: queryString.parse(window.location.search)['onboarding'] === "true",
            isOnboarded: true,
            startTime: 1,
            ifBlock:undefined,
            printBlock:undefined,
            conditionalBlock:undefined,
            steps: [
                {
                    title: 'Welcome to SOTI Blockly!',
                    target:'#root',
                    content:'A beginner-friendly visual programming interface that will help you build your custom scripting logic without needing prior experience in computer programming.' +
                        ' The following short tutorial will help show you the basics, or if you\'re already an expert feel free to skip ahead!',
                    placement:'center',
                    disableBeacon:true
                },
                {
                    target: '.blocklyMainBackground',
                    title:'Blockly Workspace',
                    content: `To write scripts we can drag-and-drop code 'blocks' onto the workspace.`,
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.blocklyToolboxDiv',
                    title:'Toolbox',
                    content: `We can find our blocks in the toolbox area. Blocks are categorized in the toolbox according to their use.`,
                    placement: 'auto'
                },
                {
                    target: '.blocklyTreeRoot div[role="treeitem"]',
                    content: 'For instance, this toolbox category contains blocks that interact with the various widgets in your Snap application.',
                    placement: 'auto'
                },
                {
                    target: '#flyout',
                    content: 'Clicking on a toolbox category opens a flyout menu containing all the blocks in that category.',
                    placement: 'auto'
                },
                {
                    target: '.blocklyFlyout .blocklyWorkspace .blocklyDraggable',
                    content: 'This is one of the blocks. We can click and drag it from the flyout menu onto the workspace.',
                    placement: 'auto',
                    spotlightPadding: 5,
                },
                {
                    target: '.blocklyBlockCanvas',
                    content: 'And we have our first Blockly script!',
                    placement: 'auto',
                    spotlightPadding: 5,
                    stage:'connect'
                },
                {
                    target: '.blocklyBlockCanvas',
                    content: 'Blocks can be connected together with other blocks to form larger statements.',
                    placement: 'auto',
                    spotlightPadding: 5,
                    stage:'connect'
                },
                {
                    target: '.icon-zoom',
                    content: 'The zoom controls allow you to zoom in and out, and recenter your blocks in the workspace.',
                    placement: 'auto'
                },
                {
                    target: '.blocklyToolboxDiv',
                    content: 'To delete a block, simply drag it to the left, over the toolbox.',
                    placement: 'auto'
                },
                {
                    target:'.icon-help',
                    content: 'For further help, click the (?) icon to open the full SOTI Blockly documentation in a new tab.',
                    placement:'auto'
                },
                {
                    target:'#root',
                    title:`Now you're ready to build your own custom logic with SOTI Blockly!`,
                    placement:'center',
                    disableBeacon:true
                }
            ]
        };
    }

    UNSAFE_componentWillMount () {
        let isOnboarded = true;
        try {
            isOnboarded = localStorage.getItem("isOnboarded") === "true";
        } catch (e) {
            console.warn(e);
        }
        this.setState({
            isOnboarded
        });
    }

    componentDidMount () {
        var self = this;
        var timer = setInterval(() => {
            self.setState({ startTime: self.state.startTime - 1 });
            if (self.state.startTime === 0) {
                clearInterval(timer);
            }
        }, 1000)
    }

    _handleCallback (e) {
        if (e.step.target === "#flyout") {
            const selectedToolboxCategory = Blockly.mainWorkspace.getToolbox().tree_.children_.filter(child => child.element_.innerText === "Logic")[0];
            if (!this.state.flyoutOpened) {
                selectedToolboxCategory.element_.click();
                this.setState({ flyoutOpened: true });
            }
        }

        if (e.index===6 && e.lifecycle==="ready") {
            if(this.state.ifBlock){
                this.state.ifBlock.dispose();
            }
            let ifBlock = Blockly.mainWorkspace.newBlock("controls_if");
            ifBlock.initSvg();
            ifBlock.render();
            ifBlock.moveBy(400,200);
            this.setState({ifBlock})
        } else if (e.index<6){
            if(this.state.ifBlock){
                this.state.ifBlock.dispose();
            }
        }


        if (e.index===7 && e.lifecycle==='ready') {
            if(this.state.printBlock){
                this.state.printBlock.dispose();
            }
            let printBlock = Blockly.mainWorkspace.newBlock("text_print");
            let text = Blockly.mainWorkspace.newBlock("text");
            text.setFieldValue("hello!", "TEXT");
            text.initSvg();
            text.render();
            printBlock.getInput("TEXT").connection.connect(text.outputConnection);
            printBlock.initSvg();
            printBlock.render();
            printBlock.previousConnection.connect(this.state.ifBlock.getInput('DO0').connection);
            this.setState({printBlock})
        } else if (e.index<7){
            if(this.state.printBlock){
                this.state.printBlock.dispose();
            }
        }

        if (e.action === "reset") {
            try{
                this.state.ifBlock && this.state.ifBlock.dispose();
                this.setState({ifBlock:undefined})
            } catch (err){
                console.log(err)
            }
            Blockly.mainWorkspace.getFlyout().hide();
            try {
                localStorage.setItem("isOnboarded", true);
            } catch (e) {
                console.warn(e);
            }
        }
    }

    render() {
        const { startTime, isOnboarded, overrideOnboardingCheck } = this.state;
        return startTime === 0 && (overrideOnboardingCheck || !isOnboarded)
            ? <div className={"app-tour"}>
                <Joyride
                    styles={{
                        buttonClose: {
                            display: 'none'
                        }
                    }}
                    disableOverlayClose
                    disableCloseOnEsc
                    locale={{back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
                    run
                    callback={e => {this._handleCallback.bind(this)(e)}}
                    spotlightPadding={0}
                    continuous
                    showSkipButton
                    spotlightClicks
                    steps={this.state.steps} />
            </div>
            : null
    }
}

export default AppTour;