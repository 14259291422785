/* global Blockly */
import React from 'react';
const snackbarFunc = [
    'function displayMessage(id, text, duration, textColor, backgroundColor, animation){',
    '    var snackbar = document.createElement("div");',
    '    var snackbarId = id +"_snackbar";',
    '    snackbar.innerHTML = text;',
    '    snackbar.style.position = "absolute";',
    '    snackbar.style.textAlign = "center";',
    '    snackbar.style.padding = "5px";',
    '    snackbar.style.borderRadius = "5px";',
    '    snackbar.style.transition = "all 0.5s ease";',
    '    snackbar.style.zIndex = "9999999";',
    '    snackbar.style.width = "100%";',
    '    snackbar.style.wordWrap = "break-word";',
    '    snackbar.id = snackbarId;',
    '',
    '    var initialStyle = {',
    '        left: "50%",',
    '        top: "50%",',
    '        backgroundColor: backgroundColor,',
    '        color: textColor,',
    '       transform: "translate(-50%,0%)"',
    '    };',
    '    var finalStyle = {top: "50%"};',
    '    var transition',
    '    switch (animation) {',
    '        case "fade in":',
    '            initialStyle.backgroundColor = "rgba(0,0,0,0)";',
    '            initialStyle.color = "rgba(0,0,0,0)";',
    '            initialStyle.top = "75%";',
    '            transition = "background-color 2s ease, color 2s ease";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide up":',
    '            initialStyle.top = "100%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide down":',
    '            initialStyle.top = "0%";',
    '            initialStyle.transform = "translate(-50%,-100%)";',
    '            finalStyle.top = "25%";',
    '',
    '            break;',
    '        case "slide right-to-left":',
    '            initialStyle.left = "100%";',
    '            initialStyle.transform = "translate(100%,0%)";',
    '            initialStyle.top = "75%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        case "slide left-to-right":',
    '            initialStyle.left = "0%";',
    '            initialStyle.transform = "translate(-100%,0%)";',
    '            initialStyle.top = "75%";',
    '            finalStyle.top = "75%";',
    '            break;',
    '        default:',
    '            console.log("WARNING: unrecognized transition");',
    '            break;',
    '    }',
    '',
    '    snackbar.style.left = initialStyle.left;',
    '    snackbar.style.top = initialStyle.top;',
    '    snackbar.style.backgroundColor = initialStyle.backgroundColor;',
    '    snackbar.style.color = initialStyle.color;',
    '    snackbar.style.transform = initialStyle.transform;',
    '',
    '    document.body.appendChild(snackbar);',
    '    setTimeout(function () {',
    '        snackbar.style.backgroundColor = backgroundColor;',
    '        snackbar.style.color = textColor;',
    '        snackbar.style.left = "50%";',
    '        snackbar.style.transform = "translateX(-50%)";',
    '        snackbar.style.top = finalStyle.top;',
    '        setTimeout(function () {',
    '            snackbar.style.backgroundColor = "rgba(0,0,0,0)";',
    '            snackbar.style.color = "rgba(0,0,0,0)";',
    '            setTimeout(function () {',
    '                var el = document.getElementById(snackbarId);',
    '                el.parentNode.removeChild(el);',
    '            }, 700);',
    '        }, duration * 1000)',
    '    }, 100);',
    '}'
];
var collapseImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=";
var expandImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQLTAyPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAtMDIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQuMDAwMDAwLCAxNC4wMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTE0LjAwMDAwMCwgLTE0LjAwMDAwMCkgIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==";

function defineBlock() {

    return {

        init: function () {
            this.visible = false;
            this.firstCollapse = true;
            this.appendDummyInput("TOGGLE_BTN")
                .appendField(new Blockly.FieldImage(expandImage, 30, 30, "settings", function(){
                    this.switchVisibility();
                }.bind(this)));
            this.appendValueInput('MESSAGE')
                .setCheck(null)
                .appendField('show message');
            /*.setOnNewRow(true)*/
            this.appendDummyInput()
                .appendField('duration (s)')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldNumber(2, 0), 'DURATION');
            this.appendValueInput('TEXT_COLOR')
                .appendField('text color')
                .setCheck("Colour")
                .setOnNewRow(true);

            this.appendValueInput('BACKGROUND_COLOR')
                .appendField('background color')
                .setCheck("Colour")
                .setOnNewRow(true);

            this.appendDummyInput()
                .appendField('with animation')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldDropdown([['fade in', 'fade in'], ['slide up', 'slide up'], ['slide down', 'slide down'], ['slide right-to-left', 'slide right-to-left'], ['slide left-to-right', 'slide left-to-right']]), 'ANIMATION');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Show a message that disappears after some time.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#text");

            this.setColour(Blockly.Msg.TEXTS_HUE);
            //eliminate blank in toolbox by collapsing during init rendering
            this.switchVisibility();


        },

        onchange:function(){
            //hide color picker by expanding and collapsing in onchange
            // if(this.firstCollapse){
                this.switchVisibility();
                this.switchVisibility();
            // }
            // this.firstCollapse = false;
        },


        switchVisibility: function(){
            this.setVisibleBetween(this.visible, 'MESSAGE', null);
            this.removeInput("TOGGLE_BTN")
            if (this.visible) {
                this.appendDummyInput("TOGGLE_BTN")
                    .appendField(new Blockly.FieldImage(collapseImage, 30, 30, "settings", function(){
                        this.switchVisibility();
                    }.bind(this)));
            } else {
                this.appendDummyInput("TOGGLE_BTN")
                    .appendField(new Blockly.FieldImage(expandImage, 30, 30, "settings", function(){
                        this.switchVisibility();
                    }.bind(this)));
            }
            this.moveInputBefore("TOGGLE_BTN","MESSAGE")
            this.removeSelect();

            this.render();
            this.visible = !this.visible;
        },

        setVisibleBetween:function(visible, first, last){
            let toHide = [];
            let startHiding = false;
            for(let i in this.inputList){
                if(this.inputList[i].name===last) break;
                if(startHiding){
                    toHide.push(this.inputList[i])
                } else if (this.inputList[i].name===first){
                    startHiding = true;
                }
            }
            toHide.forEach(function(x){
                x.setVisible(visible);
                if(x.connection && x.connection.targetBlock()){
                    x.connection.targetBlock().initSvg();
                    x.connection.targetBlock().render();
                }
            }.bind(this));
            this.render();
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block){
            let displayMessage = Blockly.JavaScript.provideFunction_('displayMessage', snackbarFunc);
            let text = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_NONE) || '<enter message>';
            let duration = block.getFieldValue('DURATION');
            let color = Blockly.JavaScript.valueToCode(block, 'TEXT_COLOR', Blockly.JavaScript.ORDER_NONE);
            let backgroundColor = Blockly.JavaScript.valueToCode(block, 'BACKGROUND_COLOR', Blockly.JavaScript.ORDER_NONE);
            let animation = '"'+block.getFieldValue('ANIMATION')+'"';
            return displayMessage + '("' + block.id + '",' + ([text,duration,color,backgroundColor,animation].toString())+');\n'
        }
    }
}

const ShowMessage = () => {
    const name = 'show_message';
    Blockly.Blocks[name] = defineBlock();

    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="MESSAGE">
                <shadow type="text"></shadow>
            </value>
            <value name="TEXT_COLOR">
                <shadow type="colour_picker">
                    <field name="COLOUR">#000000</field>
                </shadow>
            </value>
            <value name="BACKGROUND_COLOR">
                <shadow type="colour_picker">
                    <field name="COLOUR">#FFFFFF</field>
                </shadow>
            </value>
        </block>
    )
};

export default ShowMessage;
