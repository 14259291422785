import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";

export const flipSwitchIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' %3E%3Cpath transform='scale(0.035) translate(-70,-40)' d='M682.667 686.933h-341.333c-133.12 0-238.933-105.813-238.933-238.933s105.813-238.933 238.933-238.933h341.333c133.12 0 238.933 105.813 238.933 238.933s-105.813 238.933-238.933 238.933zM682.667 277.333h-341.333c-95.573 0-170.667 75.093-170.667 170.667s75.093 170.667 170.667 170.667h341.333c95.573 0 170.667-75.093 170.667-170.667s-75.093-170.667-170.667-170.667zM802.133 448c0-65.98-53.487-119.467-119.467-119.467s-119.467 53.487-119.467 119.467c0 65.98 53.487 119.467 119.467 119.467s119.467-53.487 119.467-119.467z'/%3E%3C/svg%3E";


export default function FlipSwitch(props) {

    let widgets = util.widgetsOfType(props.context, 'flipswitch');
    let newProps = Object.assign({}, props, { widgets });

    let components = [
        newProps.widgets.length ? <label text={"Switches"} /> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component {...newProps} key={id} />)
    ];

    return components;
}

