import React from 'react'
import util from "../../../../utils/es5Utils";
import _ from "underscore";

export const checkBoxListIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M25.2,4.74v20.4H4.8V4.74H25.2M27,2.94H3v24H27v-24Z'/%3E%3Cpath d='M13.22,19.17a.75.75,0,0,1-.46-.17L9.31,16.18a.73.73,0,1,1,.91-1.13l2.95,2.42,6.55-6.55a.72.72,0,0,1,1,1l-7,7A.7.7,0,0,1,13.22,19.17Z'/%3E%3C/svg%3E";

export default function CheckboxList(props) {
    let widgets = util.widgetsOfType(props.context, 'checkboxlist');
    let defaultWidget = {
        "id": "",
        "name": "< no checkboxes >",
        "type": "checkboxlist",
        "properties": {
            "checkboxes": [{
                "id": "",
                "name": "< no checkboxes >",
                "type": "checkbox",
                "properties": {"value": ""}
            }]
        }
    };
    let newProps = Object.assign({}, props, {widgets, defaultWidget});

    let components = [
        newProps.widgets.length > 0 ? <label text={"Checkbox Lists"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    // var components = [
    //     newProps.widgets.length > 0 ? <label text={"Checkbox Lists"}/> : null,
    //     <SetCheckboxlistOption {...newProps}/>,
    //     <InCheckboxlistCheckboxIsChecked {...newProps}/>,
    //     <GetCheckboxlistSelectedValues {...newProps}/>,
    //     <GetSelectedValuesInFormat {...newProps}/>
    // ];

    // var components = [
    //     newProps.widgets.length > 0 ? <label text={"Checkbox Lists"}/> : null,
    //     <SetCheckboxlistOptionByText {...newProps}/>,
    //     <SetCheckboxlistOptionByValue {...newProps}/>,
    //     <InCheckboxlistCheckboxIsChecked {...newProps}/>,
    //     <GetCheckboxlistSelectedValues {...newProps}/>,
    //     <GetSelectedValuesInFormat {...newProps}/>
    // ];

    return components;
}

