/* global Blockly */
/* global $ */
/* global Util */
import React from 'react';
import RestCore from "../AssistingBlocks/RestCore";
import util from "../../../utils/es5Utils";

// define block appearance and behaviour
function defineBlock(context,widgets,props) {
    let blockInstance = RestCore(props);
    Object.assign(blockInstance, {
        widget: null,
        jsonOptionsList: null, // the list of options for column fields as json paths
        jsonRelativePaths: null, // last used json structure for json paths options generation
        structureLoaded: false, // indicates if we have already loaded json structure once

        init: function() {
            this.coreColor = Blockly.Msg.WIDGET_VALUES_HUE;
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.toggleOn = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-toggle on-SNAP%3C/title%3E%3Cg id='icon-toggle-on-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill='%233b0078' x='2' y='8' width='24' height='12' rx='6'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='20' cy='14' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E"
            this.toggleOff = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-toggle off-SNAP%3C/title%3E%3Cg id='icon-toggle-off-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill-opacity='0.7' fill='%23FFFFFF' x='2' y='8' width='24' height='12' rx='6'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23644585' cx='8' cy='14' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E"
            this.tabCol = { primary: Blockly.Msg.WIDGET_VALUES_HUE, secondary: '#000000', tertiary: '#FFFFFF', text: '#FFFFFF' };
            this.testButton = { primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:Blockly.Msg.WIDGET_VALUES_HUE };
            this.disabledCol = { primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:Blockly.Msg.WIDGET_VALUES_HUE };

            // expand/collapse button
            this.initToggleButton();

            // block header: tableview widget selector
            this.appendDummyInput('Widget')
                .appendField("load data to table")
                .appendField(new Blockly.FieldWidgetsDropdown(context, "tableview", undefined, '- select -', this.updateShape_.bind(this)), "ID");

            // block header: rest method selector and url
            this.initRestUrl(true /* use only GET and POST verbs */, true /* put on new row */);

            // init header tabs and rest parameters
            this.initHeaderTabs();

            // init "test" button
            this.appendDummyInput('TEST')
                .appendField(new Blockly.FieldButton( 'Set Schema', this.testButton, function() {
                    this.openTestPage();
                }.bind(this)),'TEST')
                .setOnNewRow(true);

            // init success/error blocks
            this.initRestResults();

            // finalize block
            this.setPreviousStatement(true, null);
            this.setNextStatement(true,null);
            this.setTooltip('Invoke a RESTful API request and fill table view with response data.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#connections");
            this.setOnChange(this.changeHandler);
        },

        // generates preview string for inner JSON structure that will be displayed to user
        generatePreviewStringForPath: function(parents, property) {
            var updatedParents = [...parents];
            var parentsDisplayPath = "";
            if (updatedParents.length) {
                var updatedParentsPreview = [...parents];
                if (property.index != null) { // append index display to preview
                    updatedParentsPreview[updatedParents.length - 1] += ` [${property.index/* + 1*/}]`; // machine index to preview
                }
                updatedParentsPreview.push("");
                parentsDisplayPath = updatedParentsPreview.join(" . ");
            } 

            // will return string like this: 
            // A . B [0] . C
            return parentsDisplayPath + property.value;
        },

        // generates js code for inner JSON structure
        generateJsCodeForPath: function(parents, property) {
            var updatedParents = [...parents];
            var parentsPath = "";
            var innerChecks = "";
            if (updatedParents.length) {
                if (property.index != null) { // append array index to last parent
                    updatedParents[updatedParents.length - 1] += `[${property.index}]`; // machine index to path
                }

                // faster way with ?. syntax, should be removed
                //parentsPath = updatedParents.map(s => `.${s}?`).join(""); 
                // old way without experimental syntax
                updatedParents.forEach(function(parent) {
                    parentsPath += `['${parent}']`;
                    innerChecks += `if (!responseData[iterator]${parentsPath}) 
                        return "";
                `;
                });
            } 
            var jsCode = `(() => {
                ${innerChecks}
                return responseData[iterator]${parentsPath}['${property.value}'];
            })()`; 

            /* instead of using this: responseData[iterator].A?.B[0]?.C
                this will produce this code:
                (() => {
                    if (!responseData[iterator].A) 
                        return "";
                    if (!responseData[iterator].A.B[0]) 
                        return "";
                    return responseData[iterator].A.B[0].C;
                })()
            */
            return jsCode;
        },

        // generate menu options from json result
        generateMenuOptions: function() {
            if (!this.jsonOptionsList)
                this.jsonOptionsList = [];
            if (!this.jsonRelativePaths) // return default structure
                return this.jsonOptionsList;

            // build structure
            var result = []; // list of final options added to drop down
            var allFullPaths = []; // list of unique json paths of all options
            var deepArrayLimit = 9; // maximum array items we check for inner arrays

            // check current property path already exists and if not, add it to options
            var processProperty = (parents, property) => {
                var fullPath = parents.map(s => `.${s}`).join("") + `.${property.value}`;
                if (allFullPaths.includes(fullPath)) 
                    return;

                var previewString = this.generatePreviewStringForPath(parents, property);
                var jsCode = this.generateJsCodeForPath(parents, property);
                result.push([previewString /* human-readable */, fullPath /* path string value */, jsCode /* result code */]);
                allFullPaths.push(fullPath);
            }

            // top array - means array selected in onResponse block, which will be iterated
            //      we iterate through all items of this array and each item corresponds to its own table row
            // inner array - subarrays that may or may not present in each iteration
            //      each value of those subarrays can be added as a separate path, even if it exists only for one item
            //      however, we do not collect all items in subarrays due to possible productivity issues
            var processStructureNode = (parents, node) => {
                // recursive structure processing
                if (Array.isArray(node)) {
                    // can process all elements at the top array 
                    // or limited amount of elements in inner arrays
                    if (node.length == 0)
                        return;
                    var isInnerArray = parents.length > 0;
                    var fullPaths = []; // unique properties path (relative to current array) to make sure we don't add duplicates
                    // parse out all properties from all elements
                    for (var innerArrayIterator = 0; innerArrayIterator < node.length; innerArrayIterator++) {
                        if (isInnerArray && innerArrayIterator >= deepArrayLimit) {
                            node.length = deepArrayLimit; // remove all unprocessed nodes
                            break; // process only limited amount of nodes in inner array
                        }
                        var element = node[innerArrayIterator];
                        Object.getOwnPropertyNames(element).forEach(function(property) {
                            var propertyValue = element[property];
                            var propertyPath = property;
                            if (isInnerArray) // add array index for inner arrays
                                propertyPath = `[${innerArrayIterator}]` + propertyPath;
                            if (Array.isArray(propertyValue)) {
                                // we ignore inner arrays of arrays and don't process them
                                return;
                            }
                            else if (typeof(propertyValue) == "object" && !!propertyValue) {
                                // process its own properties for complex objects
                                var innerParents = [...parents];
                                innerParents.push(propertyPath);
                                processStructureNode(innerParents, propertyValue);
                                return;
                            }
                            else {
                                element[property] = ''; // clean up the property value to free up the space
                                if (!fullPaths.includes(propertyPath)) {
                                    // add property to unique paths for this array
                                    fullPaths.push(propertyPath);
                                    var propertyObj = { 
                                        index: isInnerArray ? innerArrayIterator : null,
                                        value: property
                                    };
                                    // check/add property to options list
                                    processProperty(parents, propertyObj);
                                }
                            }
                        });
                    }
                    return;
                }

                // process owned properties and check inner objects
                if (parents.length == 0 || !node)
                    return; // for root we can only process arrays
                Object.getOwnPropertyNames(node).forEach(function(property) {
                    var propertyValue = node[property];
                    if (Array.isArray(propertyValue)) {
                        // process inner array
                        var innerParents = [...parents];
                        innerParents.push(property);
                        processStructureNode(innerParents, propertyValue);
                        return;
                    }
                    else if (typeof(propertyValue) == "object") {
                        // process its own properties for complex objects
                        var innerParents = [...parents];
                        innerParents.push(property);
                        processStructureNode(innerParents, propertyValue);
                        return;
                    }
                    else { // add property to result
                        var propertyObj = { 
                            index: null,
                            value: property
                        };
                        // check/add property to options list
                        processProperty(parents, propertyObj);
                    }
                });
            }
            processStructureNode([], this.jsonRelativePaths);

            // overwrite response JSON structure with simplified array (all values removed, all arrays are limited)
            this.responseJsonStructure = this.jsonRelativePaths;

            // sort values by path and return
            result = result.sort(function(a, b) {
                return a[1] > b[1] ? 1 : -1;
            });
            this.jsonOptionsList = result;
            return result;
        },

        // render columns from selected widget
        updateShape_: function() {
            let widgets = util.widgetsOfType(context, 'tableview');
            let id = this.getFieldValue('ID');
            for (let i = 0; i < widgets.length; i++) {
                if (widgets[i].id == id) {
                    if (!(this.widget != null && this.widget.id == id)) {
                        this.widget = widgets[i];
                        this.updateColumns();
                    }
                }
            }
            if (id != null) // bypass initial render 
                this.initialColumnsRendered = true;
        },

        // render tableview column
        updateColumns: function () {
            // remove previous inputs from another widget
            if (this.widget != null) {
                for (let i = this.inputList.length - 1; i > 0; i--) {
                    if (this.inputList[i].name.indexOf("COLUMN_") == 0) {
                        var columnBlock = this.inputList[i];
                        this.removeInput(columnBlock.name);
                    }
                }
            }

            // add new columns for new widget
            if (!this.widget)
                return;
            this.generateMenuOptions();
            var _this = this;
            Object.keys(this.widget.columns).map(argName => {
                var column = this.widget.columns[argName];

                // render column
                var fieldSelector = new Blockly.FieldDropdownSearchCategories(_this.jsonOptionsList, undefined, '- select -', undefined);
                _this.appendDummyInput("COLUMN_" + column.id)
                    .appendField(column.name)
                    .appendField(fieldSelector, "COLUMNVALUE_" + column.id)
                    .setAlign(Blockly.ALIGN_LEFT)
                    .setOnNewRow(true);
                _this.moveInputBefore("COLUMN_" + column.id, "SUCCESS");
                return null;
            });
        },

        // loading state data
        domToMutation: function(xml) {
            this.domToMutationCore(xml);

            // read options list
            let optionsListRaw = xml.getAttribute("optionslist");
            if (optionsListRaw && optionsListRaw.length >= 2) 
                this.jsonOptionsList = JSON.parse(optionsListRaw);
            // read last valid json structure (it might work bad with huge datasets)
            let responseJsonStructureRaw = xml.getAttribute("responsejsonstructure");
            if (responseJsonStructureRaw && responseJsonStructureRaw.length >= 2)
                this.responseJsonStructure = JSON.parse(responseJsonStructureRaw);
        },

        // saving state data
        mutationToDom: function() {
            var mutationObj = this.mutationToDomCore();

            let optionsList = this.jsonOptionsList ? JSON.stringify(this.jsonOptionsList) : "";
            // saving raw response data might be a bad option for huge datasets,
            // but it allows to travel through "response data" block without retesting 
            let responseJsonStructureRaw = JSON.stringify(this.responseJsonStructure);
            Object.assign(mutationObj, {
                optionslist: optionsList,
                responsejsonstructure: responseJsonStructureRaw,
            });
            let mutation = Util.dom("mutation", mutationObj);
            return mutation;
        },

        // update reponse data json or relative path
        updateJsonStructure: function(responseDataStructure) {
            let successParamData = Blockly.JavaScript.valueToCode(this, 'SUCCESS_PARAM', Blockly.JavaScript.ORDER_NONE);
            let successParamRelativePath = successParamData.split(`ResponseScope['${this.id}']`).join("");
            if (successParamRelativePath.indexOf("['") == 0)
                successParamRelativePath = successParamRelativePath.substring(2);
            if (successParamRelativePath.lastIndexOf("']") == successParamRelativePath.length - 2)
                successParamRelativePath = successParamRelativePath.substring(0, successParamRelativePath.length - 2);
            let successParamRelativePaths = successParamRelativePath.split("']['");
            if (!successParamRelativePaths[0].length)
                successParamRelativePaths.splice(0, 1);
            // drill into structure
            let failed = false;
            successParamRelativePaths.forEach(function(parameterPath) {
                if (!responseDataStructure)
                    failed = true;
                else
                    responseDataStructure = responseDataStructure[parameterPath];
            });
            if (failed) {
                // no structure or incorrect structure
                this.jsonRelativePaths = {};
                return;
            }
            if (!this.jsonRelativePaths || this.jsonRelativePaths != responseDataStructure) {
                // update the structure (only if it has been changed)
                this.jsonRelativePaths = responseDataStructure;
                if (!this.structureLoaded) { // do not clean/update selector for the first time
                    this.structureLoaded = true;
                    // set json structure to response block
                    var targetJsonBlock = this.getInput('SUCCESS_PARAM').connection.targetBlock();
                    if (targetJsonBlock) 
                        targetJsonBlock.setJsonStructure(this.responseJsonStructure || {});
                }
                else
                    this.updateColumns();
            }
        },

        // base change handler
        changeHandler: function(event) {
            this.changeHandlerCore(event);
            
            // refresh json path selector fields for columns, if needed
            let successParam = this.getInput('SUCCESS_PARAM');
            if (successParam.connection.isConnected() && successParam.connection.targetBlock().type === 'request_response_parameter') {
                let responseDataStructure = this.responseJsonStructure || successParam.connection.targetBlock().jsonStructure;
                if (responseDataStructure) {
                    // refresh path fields
                    this.updateJsonStructure(responseDataStructure);
                }
            }
        },

        // handling visibility change after expand/collapse triggered
        switchVisibilityOuter: function (visible) {
            // show/hide rest output as well
            if (this.visible) {
                $('.custom-rest-ouput').show();
            } 
            else {
                $('.custom-rest-ouput').hide();
            }
            // show/hide columns as well
            if (this.widget != null) {
                this.widget.columns.forEach(column => this.setInputVisibility(this.getInput("COLUMN_" + column.id), visible));
            }
        },

        // open test page holder, runs request and display response data
        openTestPage: function() {
            var _this = this;
            this.openTestPageCore(context, function(responseData) {
                if (responseData) {
                    // force render columns again with new json structure to select from
                    _this.updateColumns();
                }
            });
        },

        // show help page
        openHelpPage: function() {
            Blockly.WidgetDiv.hide();
            Blockly.WidgetDiv.show(this);
            let dom;
            dom = Util.dom('iframe', {src:"http://localhost:3000/docs#connections", position: 'absolute',width: '800px', height: '900px'})
            Blockly.WidgetDiv.DIV.style.right = 0;
            Blockly.WidgetDiv.DIV.style.width = 'auto';
            Blockly.WidgetDiv.DIV.style.height = 'auto';
            Blockly.WidgetDiv.DIV.appendChild(dom);
        }
    });
    return blockInstance;
}

function defineGenerators(context) {
    return {
        'JavaScript': function(block) {
            let success = Blockly.JavaScript.statementToCode(block, 'SUCCESS');
            let successParam = Blockly.JavaScript.valueToCode(block, 'SUCCESS_PARAM', Blockly.JavaScript.ORDER_NONE);

            // table add code
            let tableRowFillCode = "";
            this.widget.columns.forEach((column) => {
                // load path string
                var columnValueNode = this.getFieldValue("COLUMNVALUE_" + column.id);
                // load full js code by path
                var columnValueOption = this.jsonOptionsList.find((s) => s[1] == columnValueNode);
                var columnValue = columnValueOption ? columnValueOption[2] /* js code */ : "''";
                tableRowFillCode += `rowObject['${column.id}'] = ${columnValue};\n`;
            });
            let tableAddCode = `
                let responseData = ${successParam};
                if (responseData) {
                    for (let iterator = 0; iterator < responseData.length; iterator++) {
                        let rowObject = {};
                        ${tableRowFillCode}
                        Snap.widgets.tableview.addRecord('${this.widget.id}', rowObject);
                    }
                };
                `;

            // combine javascript code
            let fullSuccessCode = `if (BlocklyModule && BlocklyModule.onRestAjaxFinished && BlocklyModule.onRestAjaxFinished(true, '${this.widget.id}') !== false) {`;
            fullSuccessCode += tableAddCode + success + `\n}\n`;
            
            let error = `// indicate that we have finished loading records with error
                if (BlocklyModule && BlocklyModule.onRestAjaxFinished && BlocklyModule.onRestAjaxFinished(false, '${this.widget.id}') !== false) {
                    ${Blockly.JavaScript.statementToCode(block, 'ERROR')}
                }`;
                
            return block.getJavascriptCore(context, fullSuccessCode, error, true);
        }
    }
}

// main block describer
const LoadDataTableRest = function (props) {
    const name = 'load_data_table_rest';

    Blockly.Blocks[name] = defineBlock(props.context, props.widgets,props);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="USERNAME">
                <shadow type="fixed_width_text">
                    <mutation length="29"></mutation>
                </shadow>
            </value>
            <value name="PASSWORD">
                <shadow type="password_text">
                    <mutation length="29"></mutation>
                </shadow>
            </value>
            <value name="HEADERS">
                <block type="rest_params">
                    <mutation background={Blockly.Msg.WIDGET_VALUES_HUE}></mutation>
                </block>
            </value>
            <value name="QUERY_STRINGS">
                <block type="rest_params">
                    <mutation background={Blockly.Msg.WIDGET_VALUES_HUE}></mutation>
                </block>
            </value>
            <value name="DATA">
                <block type="rest_params">
                    <mutation background={Blockly.Msg.WIDGET_VALUES_HUE}></mutation>
                </block>
            </value>
        </block>
    )
};

export default LoadDataTableRest;
