/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("navigate back");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Navigate to the previous page in the application.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function () {
            var code = ('appContextModule.popFromNavigationStack();\n');
            return code;
        }
    }
}

const SnapActionNavigateBack = function (props) {

    var name = "snap_action_navigate_back";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if(props.context.pages.length<2){
        return null;
    }

    return (
        <block type={name}></block>
    )
};

export default SnapActionNavigateBack;