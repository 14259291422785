import React from 'react'
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const barcodeScannerIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 5 30 20'%3E%3Crect x='3' y='7.89' width='1.75' height='14.2'/%3E%3Crect x='6.44' y='7.88' width='1.23' height='9.48'/%3E%3Crect x='9.62' y='7.88' width='1.23' height='9.48'/%3E%3Crect x='12.8' y='7.88' width='1.23' height='9.48'/%3E%3Crect x='15.98' y='7.88' width='1.23' height='9.48'/%3E%3Crect x='19.15' y='7.85' width='1.23' height='9.48'/%3E%3Crect x='22.33' y='7.88' width='1.23' height='9.48'/%3E%3Crect x='25.25' y='7.89' width='1.75' height='14.2'/%3E%3Cpath d='M8.61,20.25c0,1.19-.48,1.84-1.33,1.84S6,21.46,6,20.3s.55-1.81,1.33-1.81S8.61,19.14,8.61,20.25Zm-2.08.06c0,.9.31,1.42.78,1.42s.78-.56.78-1.45-.24-1.43-.78-1.43S6.53,19.36,6.53,20.31Z'/%3E%3Cpath d='M9.09,22v-.29l.41-.35c1-.85,1.42-1.3,1.43-1.83,0-.35-.19-.68-.77-.68a1.36,1.36,0,0,0-.81.3l-.16-.34a1.79,1.79,0,0,1,1.08-.35,1,1,0,0,1,1.17,1c0,.65-.51,1.17-1.32,1.88l-.3.25h1.72V22Z'/%3E%3Cpath d='M13.25,19h0l-.66.33-.1-.36.83-.41h.45V22h-.5Z'/%3E%3Cpath d='M17.44,18.94H16l-.14.9.31,0A1.71,1.71,0,0,1,17,20a1,1,0,0,1,.55.89,1.28,1.28,0,0,1-1.43,1.19,2,2,0,0,1-.95-.21l.13-.36a1.8,1.8,0,0,0,.82.19A.82.82,0,0,0,17,21c0-.45-.34-.78-1.11-.78-.21,0-.39,0-.53,0l.25-1.67h1.84Z'/%3E%3Cpath d='M20.46,18.94H19l-.15.9.32,0A1.71,1.71,0,0,1,20,20a1,1,0,0,1,.55.89,1.29,1.29,0,0,1-1.43,1.19,2,2,0,0,1-.95-.21l.13-.36a1.8,1.8,0,0,0,.82.19A.81.81,0,0,0,20,21c0-.45-.33-.78-1.1-.78-.22,0-.39,0-.53,0l.25-1.67h1.83Z'/%3E%3Cpath d='M22.81,22v-.94H21v-.32l1.71-2.22h.56v2.16h.53v.38H23.3V22Zm0-1.32V19.55a5.15,5.15,0,0,1,0-.55h0c-.12.21-.22.36-.32.52l-.94,1.18h1.26Z'/%3E%3C/svg%3E";

export default function Barcode(props) {
    let widgets = util.widgetsOfType(props.context, 'barcode');
    let defaultWidget = {
        "id": "",
        "name": "",
        "type": "< no barcode widgets >",
        "properties": null
    };
    let newProps = Object.assign({}, props, {widgets, defaultWidget});

    let components = [
        newProps.widgets.length > 0 ? <label text={"Barcode Scanners"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];
    return components;
}
