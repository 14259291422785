/* global Blockly */
import React from 'react';
import _ from "underscore";
import parse from "html-react-parser";

const MyBlockSearch = ({ libraries: libraryObj }) => {
    if (_.isEmpty(libraryObj)) { return null; }
    let libraries = [];

    function _getParsedBlocksFromLibrary (library) {
        return _.values(library.childComponents).map(block => {
            const {xml} = block.component;
            if (xml) {
                const parsedBlock = Blockly.Xml.textToDom(xml);
                let xmlText = "";

                if (parsedBlock.getElementsByTagName("variables").length > 0) {
                    let blockXml = parsedBlock.getElementsByTagName("variables")[0];
                    xmlText += Blockly.Xml.domToText(blockXml);
                }

                if (parsedBlock.getElementsByTagName("block").length > 0) {
                    let blockXml = parsedBlock.getElementsByTagName("block")[0];
                    blockXml.firstChild.setAttribute('code', block.code);
                    xmlText += Blockly.Xml.domToText(blockXml);
                }

                return xmlText
            }

            return null;
        });
    }

    _.keys(libraryObj).map((libraryKey) => {
        libraries.push({
            name: libraryKey,
            parsedBlocks: _getParsedBlocksFromLibrary(libraryObj[libraryKey])
        })
    });

    function removeSelfClosingTags(xml) {
        let split = xml.split("/>");
        let newXml = "";
        for (let i = 0; i < split.length - 1;i++) {
            let edsplit = split[i].split("<");
            newXml += split[i] + "></" + edsplit[edsplit.length - 1].split(" ")[0] + ">";
        }
        return newXml + split[split.length-1];
    }

    return libraries.map(library => {
        return (
            <>
                <label text={library.name} />
                {
                    library.parsedBlocks.map(block => parse(removeSelfClosingTags(block)))
                }
            </>
        )
    })
};

export default MyBlockSearch;