import React from 'react'
import _ from 'underscore';
import util from "../../../../utils/es5Utils";
export const paragraphIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Crect x='13.75' y='5.69' width='13.25' height='1.17'/%3E%3Crect x='13.75' y='11.6' width='13.25' height='1.17'/%3E%3Crect x='3' y='17.51' width='24' height='1.17'/%3E%3Crect x='3' y='23.42' width='24' height='1.17'/%3E%3Cpath d='M10.24,11.55v1.27H7.75v-.89a3.23,3.23,0,0,1-2.3,1,2.52,2.52,0,0,1-1.72-.65A2.07,2.07,0,0,1,3,10.65,2.13,2.13,0,0,1,3.77,9,2.8,2.8,0,0,1,5.64,8.3a3.57,3.57,0,0,1,2,.62V8.2a2.69,2.69,0,0,0-.1-.85A1.13,1.13,0,0,0,7,6.81a1.91,1.91,0,0,0-1-.24,1.63,1.63,0,0,0-1.55.85L3.1,7.06A3.24,3.24,0,0,1,6.23,5.37a4,4,0,0,1,1.41.22,2.1,2.1,0,0,1,.88.57,1.83,1.83,0,0,1,.4.75A6.4,6.4,0,0,1,9,8.2v3.35ZM7.61,10.12A3,3,0,0,0,5.75,9.4a1.44,1.44,0,0,0-1,.35,1.12,1.12,0,0,0-.4.89,1.15,1.15,0,0,0,.35.85,1.29,1.29,0,0,0,1,.35,2.8,2.8,0,0,0,2-.87Z'/%3E%3C/svg%3E";
export default function Paragraph(props){
    let widgets = util.widgetsOfType(props.context, 'paragraph');
    let newProps = Object.assign({},props,{widgets});
    var components = [
        newProps.widgets.length>0?<label text={"Paragraphs"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components;
}