import React from 'react';
import { WORKFLOW_APP_TYPE_ID } from "../../../../utils";
import _ from "underscore";

export const userIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F0F2F3;%7D .st1%7Bfill:%23F0F2F3;%7D%0A%3C/style%3E%3Cpath class='st0' d='M8,4c1.65,0,3,1.35,3,3s-1.35,3-3,3S5,8.65,5,7S6.35,4,8,4 M8,2C5.24,2,3,4.24,3,7s2.24,5,5,5s5-2.24,5-5 S10.76,2,8,2L8,2z'/%3E%3Cg%3E%3Cpath class='st0' d='M15,22c-0.55,0-1-0.45-1-1v-2c0-1.65-1.35-3-3-3H5c-1.65,0-3,1.35-3,3v2c0,0.55-0.45,1-1,1s-1-0.45-1-1v-2 c0-2.76,2.24-5,5-5h6c2.76,0,5,2.24,5,5v2C16,21.55,15.55,22,15,22z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st1' d='M23,9h-7c-0.55,0-1-0.45-1-1s0.45-1,1-1h7c0.55,0,1,0.45,1,1S23.55,9,23,9z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st1' d='M23,13h-7c-0.55,0-1-0.45-1-1s0.45-1,1-1h7c0.55,0,1,0.45,1,1S23.55,13,23,13z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st1' d='M23,17h-5c-0.55,0-1-0.45-1-1s0.45-1,1-1h5c0.55,0,1,0.45,1,1S23.55,17,23,17z'/%3E%3C/g%3E%3C/svg%3E%0A";

export default function User(props) {

    let components = [
        props.context.appTypeId === WORKFLOW_APP_TYPE_ID ? <label text={"User"} /> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component {...props} key={id} />)
    ];

    return components;
}

