import React from 'react';
import './index.scss';
import {Link} from "react-router-dom";

const ModalButtons = ({step, back, next}) => {
    return (
        <div className={"modal-buttons-container"}>
            <div className={"container-left"}>
                {
                    step > 1 ?
                        <button className={"text-button"} onClick={() => back()}>
                            BACK
                        </button>
                        : null
                }
            </div>
            <div className={"container-right"}>
                <Link to={`/${window.location.search}`}>
                    <button className={"text-button text-button-cancel"}>
                        CANCEL
                    </button>
                </Link>
                {
                    step < 3 ?
                        <button className={"text-button"} onClick={() => next()} >
                            NEXT
                        </button>
                        :
                        <button className={"text-button"} onClick={() => next()}>
                            DONE
                        </button>
                }
            </div>
        </div>
    );
}

export default ModalButtons;