import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";

export const hamburgerIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 1200' transform='translate(2,2)' %3E%3Cpath d='M125.806 182.857h772.389c29.074 0 52.663 23.479 52.663 52.48s-23.589 52.48-52.663 52.48h-772.389c-29.074 0-52.663-23.479-52.663-52.48s23.589-52.48 52.663-52.48z'/%3E%3Cpath d='M126.171 465.554h771.657c29.294 0 53.029 23.735 53.029 53.029v0.366c0 29.294-23.735 53.029-53.029 53.029h-771.657c-29.294 0-53.029-23.735-53.029-53.029v-0.366c0-29.294 23.735-53.029 53.029-53.029z'/%3E%3Cpath d='M125.806 749.349h772.389c29.074 0 52.663 23.479 52.663 52.48v0c0 29.001-23.589 52.48-52.663 52.48h-772.389c-29.074 0-52.663-23.479-52.663-52.48v0c0-29.001 23.589-52.48 52.663-52.48z'/%3E%3C/svg%3E";

export default function Hamburger(props) {

    let widgets = props.context.hamburgers;
    let newProps = Object.assign({}, props, { widgets });

    let components = [
        newProps.widgets.length ? <label text={"Hamburgers"} /> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component {...newProps} key={id} />)
    ];

    return components;
}

