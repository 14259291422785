/* global Blockly */
/* global Util */
import React from 'react'

const collapseImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=";
const expandImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi1kcm9wZG93bi1TTkFQLTAyPC90aXRsZT4KICAgIDxnIGlkPSJpY29uLWRyb3Bkb3duLVNOQVAtMDIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIHN0cm9rZT0iI0U0RTdFOSIgY3g9IjE0IiBjeT0iMTQiIHI9IjExLjUiPjwvY2lyY2xlPgogICAgICAgIDxwYXRoIGQ9Ik0xNCwzIEMyMC4wNzUxMzIyLDMgMjUsNy45MjQ4Njc3NSAyNSwxNCBDMjUsMjAuMDc1MTMyMiAyMC4wNzUxMzIyLDI1IDE0LDI1IEM3LjkyNDg2Nzc1LDI1IDMsMjAuMDc1MTMyMiAzLDE0IEMzLDcuOTI0ODY3NzUgNy45MjQ4Njc3NSwzIDE0LDMgWiBNOS42MzYzNjM2NCwxMS4yODg3NzAxIEM5LjI1MTMzNjksMTAuOTAzNzQzMyA4LjYwOTYyNTY3LDEwLjkwMzc0MzMgOC4yODg3NzAwNSwxMS4yODg3NzAxIEw4LjI4ODc3MDA1LDExLjI4ODc3MDEgTDguMjA2NzUyNTIsMTEuMzgwODY0NSBDNy45MDYwMjE1OCwxMS43NjE1MSA3LjkzMzM2MDc2LDEyLjI4MDk1NDMgOC4yODg3NzAwNSwxMi42MzYzNjM2IEw4LjI4ODc3MDA1LDEyLjYzNjM2MzYgTDEzLjEwMTYwNDMsMTcuNDQ5MTk3OSBDMTMuNjE0OTczMywxNy45NjI1NjY4IDE0LjM4NTAyNjcsMTcuOTYyNTY2OCAxNC44OTgzOTU3LDE3LjQ0OTE5NzkgTDE0Ljg5ODM5NTcsMTcuNDQ5MTk3OSBMMTkuNzExMjI5OSwxMi42MzYzNjM2IEMyMC4wOTYyNTY3LDEyLjI1MTMzNjkgMjAuMDk2MjU2NywxMS42NzM3OTY4IDE5LjcxMTIyOTksMTEuMjg4NzcwMSBMMTkuNzExMjI5OSwxMS4yODg3NzAxIEwxOS42MTkxMzU1LDExLjIwNjc1MjUgQzE5LjIzODQ5LDEwLjkwNjAyMTYgMTguNzE5MDQ1NywxMC45MzMzNjA4IDE4LjM2MzYzNjQsMTEuMjg4NzcwMSBMMTguMzYzNjM2NCwxMS4yODg3NzAxIEwxNC40NDkxOTc5LDE1LjIwMzIwODYgQzE0LjE5MjUxMzQsMTUuNDU5ODkzIDEzLjgwNzQ4NjYsMTUuNDU5ODkzIDEzLjU1MDgwMjEsMTUuMjAzMjA4NiBMMTMuNTUwODAyMSwxNS4yMDMyMDg2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iI0ZGRkZGRiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQuMDAwMDAwLCAxNC4wMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTE0LjAwMDAwMCwgLTE0LjAwMDAwMCkgIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==";
const plusImage = "data:image/svg+xml,%3Csvg width=\'28px\' height=\'28px\' viewBox=\'0 0 28 28\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Ctitle%3Eicon-add 02-SNAP%3C/title%3E%3Cg id=\'icon-add-02-SNAP\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z\' id=\'Combined-Shape\' fill=\'%23FFFFFF\'%3E%3C/path%3E%3Crect id=\'Rectangle\' fill=\'%23FFFFFF\' x=\'8\' y=\'13\' width=\'12\' height=\'2\' rx=\'0.75\'%3E%3C/rect%3E%3Crect id=\'Rectangle-Copy\' fill=\'%23FFFFFF\' transform=\'translate(14.000000, 14.000000) rotate(90.000000) translate(-14.000000, -14.000000) \' x=\'8\' y=\'13\' width=\'12\' height=\'2\' rx=\'0.75\'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";
const minusImage = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-remove 02-SNAP 2%3C/title%3E%3Cg id='icon-remove-02-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='8' y='12.5' width='12' height='3' rx='1.25'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";

function defineBlock() {
    return {
        length:0,
        init: function(){
            this.visible = false;
            this.setColour(Blockly.Msg.LISTS_HUE);
            this.appendDummyInput("TOGGLE_BTN")
                .appendField(new Blockly.FieldImage(expandImage, 30, 30, "expand", function(){
                    this.switchVisibility();
                }.bind(this)), "expand")
                .appendField(new Blockly.FieldImage(collapseImage, 30, 30, "collapse", function(){
                    this.switchVisibility();
                }.bind(this)), "collapse");
            this.appendDummyInput("TEXT").appendField("JSON")
            this.appendDummyInput("PLUS")
                .appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKeyValuePair.bind(this)),"PLUS")
                .setOnNewRow(true);
            this.setOutput(true,"Object")
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.switchVisibility();
            this.setBtnVisible();

        },
        setBtnVisible: function(){
            if(!this.visible){
                this.getField('expand').setVisible(true);
                this.getField('collapse').setVisible(false);
                if(this.length > 0) { this.getInput("TEXT").fieldRow[0].setText("JSON (..)"); }
            }else{
                this.getField('expand').setVisible(false);
                this.getField('collapse').setVisible(true);
                this.getInput("TEXT").fieldRow[0].setText("JSON");
            }
            // this.initSvg();
            // this.render();
            },
         switchVisibility: function(){
             this.visible = !this.visible;
             this.setVisibleBetween(this.visible, 'TEXT', null);
             this.initSvg();
             this.render();
         },

         setVisibleBetween:function(visible, first, last){
             let toHide = [];
             let startHiding = false;
             for(let i in this.inputList){
                 if(this.inputList[i].name===last) break;
                 if(startHiding){
                     toHide.push(this.inputList[i])
                 } else if (this.inputList[i].name===first){
                     startHiding = true;
                 }
             }
             toHide.forEach(function(x){
                 x.setVisible(visible);
                 if(x.connection && x.connection.targetBlock()){
                     x.connection.targetBlock().initSvg();
                     x.connection.targetBlock().render();
                 }
             }.bind(this));
             this.setBtnVisible();
             this.render();
         },
        addKeyValuePair: function(){
            // shouldRender = shouldRender===undefined?true:shouldRender

            // this.visible = true;
            // this.switchVisibility();
            let index = this.length;
            let keyInput = this.appendValueInput("key_" + index)
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*", function(){this.removeKeyValuePair(index)}.bind(this)), "delete_"+index)
                .appendField("key")
                .setCheck(["String"]);

            let valueInput = this.appendValueInput("value_" + this.length).appendField("value");
            // this.appendDummyInput("deleteInput_"+index)
            // .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*", function(){this.removeKeyValuePair(index)}.bind(this)), "delete_"+index);
            this.moveInputBefore(keyInput.name, "PLUS");
            this.moveInputBefore(valueInput.name, "PLUS");

            let keyBlock = this.workspace.newBlock("fixed_width_text");
            keyBlock.setDisplayWidth(9);
            keyBlock.setShadow(true);
            keyBlock.setMovable(false);
            let valueBlock = this.workspace.newBlock("fixed_width_text");
            valueBlock.setDisplayWidth(9);
            valueBlock.setShadow(true);

            keyInput.connection.connect(keyBlock.outputConnection);
            valueInput.connection.connect(valueBlock.outputConnection);

            this.length++;
            if(this.rendered){
                keyBlock.initSvg();
                valueBlock.initSvg();
                keyBlock.render();
                valueBlock.render();
                this.initSvg();
                this.render();
            }
        },
        removeKeyValuePair: function(index) {
            let keyInput = this.getInput("key_" + index);
            let valueInput = this.getInput("value_" + index);

            this.getInputTargetBlock(keyInput.name).dispose(true,true);
            this.getInputTargetBlock(valueInput.name).dispose(true,true);
            this.removeInput(keyInput.name);
            this.removeInput(valueInput.name);


            let indexes = [];
            for(let i = index+1; i<this.length; i++) {
                indexes.push(i);
            }

            indexes.forEach(function(i){
                let key = this.getInput("key_"+i);
                let value = this.getInput("value_"+i);

                let button = this.getField("delete_"+i);
                let newIndex = i-1;
                key.name = "key_"+(newIndex);
                value.name = "value_"+(newIndex);
                button.name = "delete_"+(newIndex);

                button.clickHandler_ = function() {
                    this.removeKeyValuePair( i-1);
                }.bind(this);

                this.getInputTargetBlock(key.name).initSvg();
                this.getInputTargetBlock(key.name).render();
                this.getInputTargetBlock(value.name).initSvg();
                this.getInputTargetBlock(value.name).render();
            }.bind(this));
            this.length = this.length-1;
        },
        mutationToDom: function(){
            let mutation = Util.dom('mutation',{length:this.length, visibility: this.visible});
            return mutation;
        },
        domToMutation: function(xml){
            let length = parseInt(xml.getAttribute("length"));
            let visibility  = xml.getAttribute("visibility");
            for (let i = 0; i < length; i++){
                this.addKeyValuePair(false)
            }
            // this.switchVisibility();

            if (visibility == "false") {
                this.visible = false;
            } else {
                this.visible = true;
            }
            this.setVisibleBetween(this.visible, 'TEXT', null);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {// Set element at index.
            let r = []
            for (let i = 0; i < block.length; i++) {
                let key = Blockly.JavaScript.valueToCode(block, "key_" + i, Blockly.JavaScript.ORDER_NONE);
                let value = Blockly.JavaScript.valueToCode(block, "value_" + i, Blockly.JavaScript.ORDER_NONE);
                r.push([key,value]);
            }

            let code = '['
            for (let i = 0; i < r.length; i++) {
                code += '[' + r[i] + '],';
            }
            if (r.length > 0){
                code = code.substring(0,code.length-1);
            }
            code = code + '].reduce(function(acc, x){acc[x[0]] = x[1]; return acc},{})';
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const MakeJSONWith = function (props) {
    let name = "make_json_with";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}/>
    )
}
export default MakeJSONWith
