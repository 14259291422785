/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        init: function () {
            let selectRepeaterDropdown = new Blockly.FieldWidgetsDropdown(context, 'repeater', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("navigate")
                .appendField(selectRepeaterDropdown, "ID")
                .appendField("to the last entry");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Navigate to the last entry of repeater widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let repeaterId = block.getFieldValue('ID');
            let code = `Snap.widgets.repeater.navigateLast("${repeaterId}");\n`;
            return code;
        }
    }
}

const RepeaterNavigateLast = function (props) {
    const name = 'snap_repeater_navigate_last';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}></block>;
}

export default RepeaterNavigateLast