import React from 'react'
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const dropdownIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M24.2,21.65a.87.87,0,0,0,.87-.87V9.22a.87.87,0,0,0-.87-.87H3.51L3,6.39H25.2A1.84,1.84,0,0,1,27,8.22V21.78a1.84,1.84,0,0,1-1.83,1.83H11.73l-.49-2Z'/%3E%3Cpolygon points='16.07 13.38 22.32 13.38 19.08 16.62 16.07 13.38'/%3E%3C/svg%3E";

export default function Dropdown(props) {
    let widgets = util.widgetsOfType(props.context, 'dropdown');
    let defaultWidget = {
        "id": "",
        "name": "",
        "type": "< no dropdowns >",
        "properties": {
            "options": [{
                "name": "",
                "id": ""
            }]
        }
    };
    let newProps = Object.assign({}, props, {widgets, defaultWidget});
    var components = [
        newProps.widgets.length > 0 ? <label text={"Dropdowns"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components;
}

