/* global Blockly */
import React from 'react';
import moment from 'moment';

function defineBlock() {
    return {
        init: function () {
            let date = new Date();
            let dateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + '-' + date.getDate();
            this.appendDummyInput()
                .appendField(new Blockly.FieldDate(dateStr), "DATE");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Date"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#dates');
        }
    }
}

// Everything should be in ES5
function defineGenerators() {
    return {
        'JavaScript': function () {
            let code = this.getFieldValue('DATE');
            let date = moment(code).format("YYYY/MM/DD");
            code = '(new Date(\'' + date + '\'))';
            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}


// Should return null if block shouldn't appear in toolbox
function DateBlock(props) {
    const name = 'date';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (<block type={name}></block>)
}


export default DateBlock
