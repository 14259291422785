/* global Blockly */
/* global Util */

/*
NOTE: This block should not be used outside the context of an ajax call.
The JS it returns depends on the namespace established for the
error or success callback of an ajax call.
 Thus this function always returns null (should never appear in the toolbox)
*/
const RequestErrorResponse = function () {
    var name = 'request_error_response';

    Blockly.Blocks[name] = {
        init: function(){
            this.jsonStructure = this.jsonStructure || {};
            this.appendDummyInput().appendField('','LABEL');
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            var col = {};
            Object.assign(Blockly.Msg.CONNECTIONS_HUE,col);
            col.primary = '#b10069';
            this.setColour(col);
            this.setOutput(true,null);
            this.setTooltip('Get the value of an API response.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#connections");
        },
        mutationToDom: function(){
            var path = this.inputList[0].fieldRow.map(function(x){return x.getValue()}.bind(this));
            path = path.slice(1); // get rid of 'value of ' field
            var mutation = Util.dom('mutation',{label:this.getFieldValue('LABEL'),path:path,parent_id:this.parent_id});
            var jsonStructure = Util.dom('jsonStructure',{innerText:JSON.stringify(this.jsonStructure)});
            mutation.appendChild(jsonStructure);
            return mutation;
        },
        domToMutation: function(xml){
            var label = (xml.getAttribute('label') || this.getFieldValue('LABEL'));
            this.setLabel(label);
            var jsonStructure = (Array.prototype.slice.call(xml.children)).filter(function(x){return x.tagName.toLowerCase()==='jsonstructure'})[0] || {innerText:'{}'};

            // this.jsonStructure = JSON.parse(xml.querySelector('jsonStructure').innerText)
            this.jsonStructure = JSON.parse(jsonStructure.innerText || jsonStructure.textContent);
            this.path = xml.getAttribute('path');
            this.path = this.path?this.path.split(','):[];
            this.parent_id = xml.getAttribute('parent_id');
            this.specToBlock(this.jsonStructure, this.path, 0);
        },
        setLabel: function(label){
            this.getField('LABEL').setValue(label);
        },
        setParentId: function(id){
            this.parent_id = id;
        },
        setJsonStructure: function(json){
            this.jsonStructure = json;
            this.specToBlock(this.jsonStructure, this.path, 0);
        },
        // json: structure at this depth
        // path: path through which to traverse json structure. can be undefined
        // depth: depth of json structure/dds, 0 = top level. so first dd = depth of 0
        specToBlock: function(json, path, depth){
            // necessary to clone path (otherwise .split(0,1) below gets confusing)
            path = [].concat(path) || [];
            // remove all fields greater than current depth (dd's to the right)
            var removes = [];
            var fieldList = this.inputList[0].fieldRow.slice(1); // exclude 'LABEL' field
            for (var i = 0; i<fieldList.length; i++){
                if (parseFloat(fieldList[i].name) >= depth){
                    removes.push(fieldList[i].name);
                }
            }
            removes.forEach(function(x){this.inputList[0].removeField(x)}.bind(this));
            // check if there should be another dd or maybe we hit a node/value;
            if(typeof(json)=='object' && !Array.isArray(json) && Object.keys(json).length){
                var ddOptions = Object.keys(json).map(function(x){return [x,x]}.bind(this));
                var currentVal = path.splice(0,1)[0] || ddOptions[0][0];

                var dd = new Blockly.FieldDropdown(function(){return ddOptions},function(value){
                    this.specToBlock(json[value],path,depth+1)
                }.bind(this));
                dd.setValue(currentVal);
                this.inputList[0].appendField(dd, depth);
                this.specToBlock(json[currentVal],path,depth+1);
            }
        }
    };

    Blockly.JavaScript[name] = function(block){
        var fieldList = block.inputList[0].fieldRow.slice(1); // exclude 'LABEL' field
        var code = '';
        for(var i = 0; i <fieldList.length; i++){
            var val = fieldList[i].getValue();
            code = code + '[\''+val+'\']';
        }
        code = 'ResponseScope[\'' + this.parent_id + '\']'+code;
        return [code,Blockly.JavaScript.ORDER_NONE]
    };

    // this should never show in the toolbox
    return null

};

export default RequestErrorResponse;