import React from 'react'
import _ from "underscore";

const Lists  = function(props){
    return (
        <category name='Lists' id='lists' iconclass='category category_lists'>
            <label text="Lists"></label>
            { _.values(props.blocks).map((Block, id) => <Block.component context={props.context} key={id}/>) }
        </category>
    )
}

export default Lists;