/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        pageId: "",
        init: function () {
            let selectWidgetDropdown = new Blockly.FieldWidgetsDropdown(context, undefined, undefined, undefined, this.updatePageId.bind(this));

            this.appendDummyInput('WIDGET')
                .appendField("set focus in")
                .appendField(selectWidgetDropdown, "ID");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Navigate to a widget and set focus.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        },

        updatePageId: function(widgetId) {
            this.pageId = util.widgetsOfType(context).find(widget => widget.id == widgetId).page.id;
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let code = `
                var navEvent = new navigateEventService();
                navEvent.executeNavigate("${this.pageId}", undefined, "${block.getFieldValue('ID')}");
            `;
            return code;
        }
    }
}

const SetFocus = function (props) {
    const name = 'snap_action_set_focus';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context).length ? <block type={name}></block> : null;
}

export default SetFocus