/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import { mapIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectMapDropdown = new Blockly.FieldWidgetsDropdown(context, 'map', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField('remove from')
                .appendField(new Blockly.FieldImage(mapIcon, 30, 30, 'map icon', null), 'mapIcon')
                .appendField(selectMapDropdown, 'ID');

            this.appendValueInput('MARKERID')
                .appendField('marker');

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Remove marker from a map.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let mapId = block.getFieldValue('ID');
            let markerId = Blockly.JavaScript.valueToCode(block, 'MARKERID', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            var code = "Snap.widgets.map.removeMarker('" + mapId + "'," + markerId + ");\n";
            return code;
        }
    }
}

const RemoveMarker = function (props) {
    const name = 'snap_map_remove_marker';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return util.widgetsOfType(props.context, "map").length ?
    <block type={name}>
        <value name="MARKERID">
            <shadow type="text"></shadow>
        </value>
    </block> :
    null;
}

export default RemoveMarker
