/* global Blockly */
/* global $ */
/* global Util */
import React from 'react';
import RestCore from "../AssistingBlocks/RestCore";

const RestService = function (prop) {
    const name = 'rest_service';

    let blockInstance = RestCore(prop);
    Object.assign(blockInstance, {

        init: function() {
            // customization
            this.coreColor = Blockly.Msg.CONNECTIONS_HUE;
            this.setColour(Blockly.Msg.CONNECTIONS_HUE);
            
            // expand/collapse button
            this.initToggleButton();

            // block header: rest method selector and url
            this.initRestUrl(false /* use all four verbs */, false /* do not put on new row */);

            // init header tabs and rest parameters
            this.initHeaderTabs();

            // init "test" button
            this.appendDummyInput('TEST')
                .appendField(new Blockly.FieldButton( 'Set Schema', this.testButton, function(){
                    this.openTestPage();
                }.bind(this)),'TEST')
                .setOnNewRow(true);

            // init success/error blocks
            this.initRestResults();

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setTooltip('Invoke a RESTful API request.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#connections");
            this.setOnChange(this.changeHandler);
        },

         // render default state for "delete/edit row for table view" action
         initTableViewAction(method) {
            // selected method
            this.setFieldValue(method, 'METHOD');
            
            // create text function for url/id
            let urlBlock = this.workspace.newBlock("text_join");
            var urlTextBlock = this.workspace.newBlock("fixed_width_text")
            urlTextBlock.setDisplayWidth(39);
            urlTextBlock.setShadow(true);
            urlBlock.inputList[1].connection.connect(urlTextBlock.outputConnection);
            var urlIdBlock = this.workspace.newBlock("fixed_width_text")
            urlIdBlock.setDisplayWidth(9);
            urlIdBlock.setShadow(true);
            urlBlock.inputList[2].connection.connect(urlIdBlock.outputConnection);
            let urlInput = this.getInput('URL');
            urlInput.connection.connect(urlBlock.outputConnection);
            setTimeout(function() {
                // amatveev: if any input block is placed in first row of this block, its text bubble is not rendered for some reason
                // rendering it again or moving by moveInputBefore fixes it; the underlying issue is still unknown
                urlTextBlock.initSvg();
                urlTextBlock.render();
                urlIdBlock.initSvg();
                urlIdBlock.render();
                urlBlock.initSvg();
                urlBlock.render();
            });

            // render username and password
            let usernameBlock = this.workspace.newBlock("fixed_width_text");
            usernameBlock.setDisplayWidth(29);
            usernameBlock.setShadow(true);
            this.getInput('USERNAME').connection.connect(usernameBlock.outputConnection);
            let passwordBlock = this.workspace.newBlock("password_text");
            passwordBlock.setDisplayWidth(29);
            passwordBlock.setShadow(true);
            this.getInput('PASSWORD').connection.connect(passwordBlock.outputConnection);

            // render rest_params for url blocks
            let headersBlock = this.workspace.newBlock("rest_params");
            this.getInput('HEADERS').connection.connect(headersBlock.outputConnection);
            let queryStringsBlock = this.workspace.newBlock("rest_params");
            this.getInput('QUERY_STRINGS').connection.connect(queryStringsBlock.outputConnection);
            let dataBlock = this.workspace.newBlock("rest_params");
            this.getInput('DATA').connection.connect(dataBlock.outputConnection);
        
            // render
            this.initSvg();
            this.render();
        },

        domToMutation: function(xml) {            
            this.domToMutationCore(xml);
        },

        mutationToDom: function() {
            var mutationObj = this.mutationToDomCore();
            let mutation = Util.dom("mutation", mutationObj);
            return mutation;
        },
        
        changeHandler: function(event) {
            this.changeHandlerCore(event);
        },

        // run test request
        openTestPage: function() {
            this.openTestPageCore(prop.context);
        },

        openHelpPage: function(){
            Blockly.WidgetDiv.hide();
            Blockly.WidgetDiv.show(this);
            let dom;
            dom = Util.dom('iframe', {src:"http://localhost:3000/docs#connections", position: 'absolute',width: '800px', height: '900px'})
            Blockly.WidgetDiv.DIV.style.right = 0;
            Blockly.WidgetDiv.DIV.style.width = 'auto';
            Blockly.WidgetDiv.DIV.style.height = 'auto';
            Blockly.WidgetDiv.DIV.appendChild(dom);
        },
    });
    
    Blockly.Blocks[name] = blockInstance;

    Blockly.JavaScript[name] = function(block) {
        let success = Blockly.JavaScript.statementToCode(block, 'SUCCESS');
        success += `// indicate that we have finished loading records successfully
                if (BlocklyModule && BlocklyModule.onRestAjaxFinished)
                    BlocklyModule.onRestAjaxFinished(true);`;
        let error = Blockly.JavaScript.statementToCode(block, 'ERROR');
        error += `// indicate that we have finished loading records with error
                if (BlocklyModule && BlocklyModule.onRestAjaxFinished)
                    BlocklyModule.onRestAjaxFinished(false);`;
        return block.getJavascriptCore(prop.context, success, error, true);
    };

    return (
        <block type={name}>
            <value name="USERNAME">
                <shadow type="fixed_width_text">
                    <mutation length="29"></mutation>
                </shadow>
            </value>
            <value name="PASSWORD">
                <shadow type="password_text">
                    <mutation length="29"></mutation>
                </shadow>
            </value>
            <value name="HEADERS">
                <block type="rest_params"></block>
            </value>
            <value name="QUERY_STRINGS">
                <block type="rest_params"></block>
            </value>
            <value name="DATA">
                <block type="rest_params"></block>
            </value>
        </block>
    )
};

export default RestService;
