/* global Blockly */
import React from 'react';
import {barcodeScannerIcon} from "./index";

// everything here should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("get list of values scanned by")
                .appendField(new Blockly.FieldImage(barcodeScannerIcon, 50, 30, "barcode scanner icon", null), 'barcodeScannerIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"barcode", undefined, '- select -'), "DD");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Array"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Returns the list of values scanned by the selected barcode widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        onchange: function(){
            if (this.getField('barcodeScannerIcon') && this.getField('barcodeScannerIcon').imageElement_)
                this.getField('barcodeScannerIcon').setTooltip('Barcode Scanner');
        }
    }
}

// everything here should be ES5
function defineGenerators() {
    return {
        'JavaScript': function (block) {
            var id = block.getFieldValue('DD');
            var code = "Snap.widgets.barcode.getScannedValues(`" + id + "`)";

            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const GetScannedValues = function(props) {
    const name = "snap_get_scanned_values_of_barcode_widget";
    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }


    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)?
                    <field name="DD">{props.widget.id}</field>
                    : null
            }
        </block>
    )
}

export default GetScannedValues
