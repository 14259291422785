/* global Blockly */
import React from 'react';
import util from "../../../../utils/es5Utils";
import {textBoxIcon} from "./index";

// everything in init should be ES5
function defineBlock(context) {
    return {
        init: function () {
            this.appendValueInput("TEXT")
                .appendField("set")
                .appendField(new Blockly.FieldImage(textBoxIcon, 30, 30, "text box icon", null), 'textBoxIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context,"textbox", undefined, '- select -'), "ID")
                .appendField("placeholder text to");
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setOutput(false); // add appropriate checks
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set the placeholder text of the provided widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
            if (this.getField('textBoxIcon') && this.getField('textBoxIcon').imageElement_)
                this.getField('textBoxIcon').setTooltip('Textbox');
        }
    }
}

function defineGenerators() {
    // generators dictionary in ES5
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue("ID");
            let text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_NONE);
            let code = "$('[id=\"" + id + "\"]').attr('placeholder'," + text + ");\n";
            return code;
        }
    }
}

const SetTextboxPlaceholderText = function (props) {
    let name = "snap_set_textbox_placeholder_text";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    let widgets = util.widgetsOfType(props.context, "textbox");

    if (widgets.length < 1) {
        return null
    }
    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name="ID">{props.widget.id}</field>
                : null
            }
            <value name="TEXT">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default SetTextboxPlaceholderText