/* global Blockly*/

import React from 'react'

// NOTE this overrides Blockly's colour_rgb block
const ColourRGB = function () {
    const name = "colour_rgb";
    Blockly.defineBlocksWithJsonArray([{
        "type": name,
        "message0": "%{BKY_COLOUR_RGB_TITLE} %{BKY_COLOUR_RGB_RED} %1 %{BKY_COLOUR_RGB_GREEN} %2 %{BKY_COLOUR_RGB_BLUE} %3",
        "args0": [
            {
                "type": "input_value",
                "name": "RED",
                "check": "Number",
                "align": "LEFT"
            },
            {
                "type": "input_value",
                "name": "GREEN",
                "check": "Number",
                "align": "LEFT"
            },
            {
                "type": "input_value",
                "name": "BLUE",
                "check": "Number",
                "align": "LEFT"
            }
        ],
        "output": "Colour",
        "outputShape": Blockly.OUTPUT_SHAPE_ROUND,
        "helpUrl": "%{BKY_COLOUR_RGB_HELPURL}",
        "style": "colour_blocks",
        "tooltip": "%{BKY_COLOUR_RGB_TOOLTIP}"
    }]);

    Blockly.JavaScript['colour_rgb'] = function (block) {
        // Compose a colour from RGB components expressed as percentages.
        var red = Blockly.JavaScript.valueToCode(block, 'RED',
            Blockly.JavaScript.ORDER_COMMA) || 0;
        var green = Blockly.JavaScript.valueToCode(block, 'GREEN',
            Blockly.JavaScript.ORDER_COMMA) || 0;
        var blue = Blockly.JavaScript.valueToCode(block, 'BLUE',
            Blockly.JavaScript.ORDER_COMMA) || 0;
        var functionName = Blockly.JavaScript.provideFunction_(
            'colourRgb',
            ['function ' + Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +
            '(r, g, b) {',
                '  r = (\'0\' + (Math.round(Math.max(0,Math.min(255,r))) || 0).toString(16)).slice(-2);',
                '  g = (\'0\' + (Math.round(Math.max(0,Math.min(255,g))) || 0).toString(16)).slice(-2);',
                '  b = (\'0\' + (Math.round(Math.max(0,Math.min(255,b))) || 0).toString(16)).slice(-2);',
                '  return \'#\' + r + g + b;',
                '}']);
        var code = functionName + '(' + red + ', ' + green + ', ' + blue + ')';
        return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
    };

    return (
        <block type={name}>
            <value name="RED">
                <shadow type="math_number"/>
            </value>
            <value name="GREEN">
                <shadow type="math_number"/>
            </value>
            <value name="BLUE">
                <shadow type="math_number"/>
            </value>
        </block>)
}
export default ColourRGB