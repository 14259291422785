/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        init: function () {
            let selectRepeaterDropdown = new Blockly.FieldWidgetsDropdown(context, 'repeater', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField("delete entry for")
                .appendField(selectRepeaterDropdown, "ID");

            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown([["with confirmation", "true"], ["without confirmation", "false"]], undefined, undefined), "CONFIRM");

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Delete current entry for repeater widget, optionally show confirmation.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let repeaterId = block.getFieldValue('ID');
            let confirm = this.getFieldValue('CONFIRM');
            let code = `Snap.widgets.repeater.deleteCurrentEntry("${repeaterId}", ${confirm});\n`;
            return code;
        }
    }
}

const RepeaterEntryDelete = function (props) {
    const name = 'snap_repeater_entry_delete';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}></block>;
}

export default RepeaterEntryDelete