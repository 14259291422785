/* global Blockly */
import React, {Component} from 'react';
import {Parser} from "acorn";
import {Toast} from "react-bootstrap";
import './index.scss';
import { withRouter } from 'react-router-dom';
import NameBlock from "./Steps/NameBlock";
import DefineArguments from "./Steps/DefineArguments";
import AssignScript from "./Steps/AssignScript";
import {blockToXmlSanitized} from "../../utils";

class CreateJSBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            toastVisible: false
        };
    }

    componentDidMount() {
        this.props.sendValidatedToParent(false);
    }

    componentDidUpdate(prevProps) {
        const { history } = this.props;
        if (prevProps.isSaving && !this.props.isSaving) {
            history.replace(`/${history.location.search}`)
        }
    }

    componentWillUnmount() {
        this.props.sendValidatedToParent(true);
    }

    _createNewLibrary (name) {
        this.props.saveLibrary({
            userId: this.props.userId,
            tenantId: this.props.tenantId,
            'name': name,
            'version': '1.0',
            'dependentLibrary': []
        })
    }

    _incrementStep () {
        this.setState({ step: this.state.step + 1 });
    }

    _decrementStep () {
        this.setState({ step: this.state.step - 1 });
    }

    _handleNameBlockNext (blockInfo) {
        this.setState({ blockInfo });

        let createNewLibrary = true;
        for (let i = 0; i < this.props.libraries.length; i++) {
            if (blockInfo.library === this.props.libraries[i].name) {
                createNewLibrary = false;
            }
        }

        if (blockInfo.library && blockInfo.library != "" && createNewLibrary) {
            this._createNewLibrary(blockInfo.library);
        }

        this._incrementStep();
    }

    _handleDefineArgumentsNext (blockArgs, blockDom) {
        this.setState({ blockArgs, blockDom });
        this._incrementStep();
    }

    _handleAssignScriptNext (blockScript) {
        this.setState({ blockScript });
        const { blockInfo, blockDom: xml } = this.state;

        const xml_text = Blockly.Xml.domToText(blockToXmlSanitized(xml));
        let libraryId = "";
        for (let i = 0; i < this.props.libraries.length; i++) {
            if (blockInfo.library === this.props.libraries[i].name) {
                libraryId = this.props.libraries[i].id;
            }
        }

        try {
            Parser.parse(blockScript, {})

            this.props.saveBlock({
                userId: this.props.userId,
                tenantId: this.props.tenantId,
                name: blockInfo.name,
                xml: xml_text,
                code: blockScript,
                language: 'javascript',
                version: '1.0.0',
                type: 'custom_js',
                keywords: blockInfo.keywords ? blockInfo.keywords.toString() : " ",
                libraryId: libraryId,
                description: blockInfo.description ?  blockInfo.description.toString() : " "
            });

            this.props.history.push(`/${window.location.search}`);
        } catch (err) {
            this.setState({toastVisible: true});
        }
    }


    _handleAssignScriptBack (blockScript) {
        this.setState({blockScript});
        this._decrementStep();
    }

    render() {
        if (!this.props.block) { return null; }
        const { blockInfo, blockArgs, step, blockScript } = this.state;
        const { isSaving } = this.props;

        return (
            <div id={"save-modal-container"}>
                {
                    step === 1 && <NameBlock
                        values={blockInfo}
                        handleCancel={() => {}}
                        handleNext={this._handleNameBlockNext.bind(this)}
                        libraries={this.props.libraries}
                        JSFlag={"Custom Script"}
                        title={"Create Custom JS Code Block"}/>
                }
                {
                    step === 2 && <DefineArguments
                        values={blockArgs}
                        blockName={blockInfo.name}
                        type={"custom_js_block"}
                        handleBack={this._decrementStep.bind(this)}
                        handleCancel={() => {}}
                        handleNext={this._handleDefineArgumentsNext.bind(this)}
                        description={this.state.blockInfo.description ? this.state.blockInfo.description : ""}
                        JSFlag={"Custom Script"}
                        title={"Create Custom JS Code Block"}/>
                }
                {
                    step === 3 && <AssignScript
                        values={blockScript}
                        args={blockArgs}
                        isSaving={isSaving}
                        handleBack={this._handleAssignScriptBack.bind(this)}
                        handleCancel={() => {}}
                        handleNext={this._handleAssignScriptNext.bind(this)} />
                }
                {
                    this.state.toastVisible ?
                        <div className={"bootstrap-override"}>
                            <Toast onClose={() => {this.setState({toastVisible: false})}}
                                style={{position: 'absolute', margin: '0 auto', bottom: '50px'}}>
                                <Toast.Header>
                                    <strong className="mr-auto">Script Error</strong>
                                </Toast.Header>
                                <Toast.Body>Please ensure that your code contains no syntax errors before saving.</Toast.Body>
                            </Toast>
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default withRouter(CreateJSBlock);
