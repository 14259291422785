import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const imageIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 30'%3E%3Cpath transform='scale(0.05,-0.05) translate(0,-800)' d='M102.4 753.152v-610.304h819.2v610.304zM878.251 186.197h-732.501v523.605h732.501zM385.707 424.448l174.763-172.032 30.379 31.061-36.523 35.84 108.203 72.704 135.509-135.851 30.72 30.72-160.768 160.768-145.408-97.28-134.144 131.755-191.488-152.917 26.965-34.133 161.792 129.365zM666.965 491.349c41.137 0.194 74.411 33.587 74.411 74.751 0 41.284-33.468 74.752-74.752 74.752s-74.752-33.467-74.752-74.751v0c0 0 0-0.001 0-0.001 0-41.284 33.468-74.752 74.752-74.752 0.12 0 0.24 0 0.36 0.001h-0.018zM666.965 597.504c17.343 0 31.403-14.059 31.403-31.403s-14.059-31.403-31.403-31.403c-17.343 0-31.403 14.059-31.403 31.403v0c0 17.343 14.059 31.403 31.403 31.403v0z'/%3E%3Crect x='16.1' y='10.23' width='0.88' height='9.5'/%3E%3C/svg%3E";

export default function Image (props) {
    let widgets = util.widgetsOfType(props.context, 'image'); // "PictureControl"
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Images"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];
    return components;
}

