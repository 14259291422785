import React, { useState, useCallback, useRef } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Form from 'react-bootstrap/Form';
import _ from 'underscore';
import CreatableSelect  from "react-select/creatable";
import { components } from "react-select";
import ModalButtons from "../../ModalButtons";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NameBlock = ({ values: defaultValues = {}, handleNext, libraries = [], JSFlag, title}) => {
    const [values, setValues] = useState(defaultValues);
    const [validated, setValidated] = useState(false);
    const form = useRef(null);

    let libraryNames = [];
    libraries.forEach((item) => {
        libraryNames.push({value: item.name, label: item.name});
    });

    const handleChange = useCallback(newValue => {
        setValues({...values, ...newValue});
    });

    const _handleNext = () => {
        if (form.current.checkValidity() && values.library) {
            if (checkNameAlreadyExists()) {
                setValidated(true);
                return;
            }
            handleNext(values);
        }
        setValidated(true);
    };

    // validate the similary named object already exist in selected library
    let checkNameAlreadyExists = () => {
        let exists = false;
        if (values.library && values.name) {
            let library = libraries.find(item => item.name == values.library);
            if (library && library.blocks) {
                if (library.blocks.find(block => block.name.trim() == values.name.trim()) != null)
                    exists = true;
            }
        }
        return exists;
    }

    return (
        <div className="modal-wrapper">
            <div className={"modal-header"}>
                <h4>{title}</h4>
                <br></br>
                <div className={"icon-active"}>&emsp;</div>
                <div className={"text-description-active"}>Block Details</div>
                <div className={"straight-line"}>———</div>
                <div className={"icon"}>2</div>
                <div className={"text-description"}>Add Inputs</div>
                <div className={"straight-line"}>———</div>
                <div className={"icon"}>3</div>
                <div className={"text-description"}>Assign {JSFlag}</div>
            </div>
            <Form id={"tagsArea"} noValidate validated={validated} className={"bootstrap-override"} onSubmit={_handleNext} ref={form}>
                <Container>
                    <Row>
                            <Form.Group className={"input-group"} id={"block-name-div"}>
                                <Col xs={6}>
                                <p>Block Name <span style={{color: "red"}}>*</span></p>
                                </Col>
                                <Col xs={6}>
                                <Form.Control type={"text"}
                                              id={"block-name"}
                                              maxLength={"25"}
                                              required
                                              value={values.name}
                                              autoComplete={"off"}
                                              isInvalid={validated && (!values.name || checkNameAlreadyExists())}
                                              onChange={e => setValues({ ...values, name: e.target.value })}/>
                                <Form.Control.Feedback type={"invalid"}>
                                    {checkNameAlreadyExists() ? (
                                        <span>The block with this name already exists</span>
                                    ) : (
                                        <span>Please Choose a Valid Name for the Block</span>
                                    )}
                                </Form.Control.Feedback>
                                <br />
                                </Col>
                            </Form.Group>
                    </Row>
                    <Row>
                            <Form.Group className={"input-group"} id={"save-to-library"}>
                                <Col xs={6}>
                                <p>Save to Library <span style={{color: "red"}}>*</span></p>
                                </Col>
                                <Col xs={6}>
                                <CreatableSelect
                                    defaultValue={values.library ? { label: values.library, value: values.library } : null}
                                    className={validated && !values.library && 'is-invalid'}
                                    id={"libraries-dropdown"}
                                    placeholder={"Select One or Type to Create"}
                                    isClearable
                                    onChange={valObj => handleChange({ ...values, library: valObj ? valObj.value : valObj})}
                                    options={libraryNames}
                                    components={{ Input: function customInput(props) {return <components.Input {...props} maxLength={"15"} /> }}}
                                    maxMenuHeight={200}
                                    classNamePrefix={"inner"}
                                    tabSelectsValue={true}
                                    noOptionsMessage={() => <span>No Existing Libraries Found. <br/> Please Type a Name to Create a New Library</span>}
                                />
                                <Form.Control.Feedback type={"invalid"}>
                                    Please Select a Valid Library
                                </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                    </Row>
                    <Row>
                        <div className={"keywords-div"}>
                            <Form.Group className={"input-group"}>
                                <Col xs={6}>
                                    <p>Search Keywords<br></br><span className={"footnote"}>Press Enter to Add Keyword.</span></p>
                                </Col>
                                <Col xs={6}>
                                <TagsInput value={values.keywords || []}
                                           onlyUnique
                                           addKeys={[9, 13, 188, 32]}
                                           onChange={keywords => setValues({ ...values, keywords: _.uniq(keywords, false, _.iteratee(str => str.trim().toLowerCase())) })}
                                           inputProps={{placeholder: '', maxLength: 15}}
                                />
                                </Col>
                            </Form.Group>
                        </div>
                    </Row>
                    <Row>
                        <div className={"description-div"}>
                            <Form.Group className={"input-group"}>
                                <Col xs={6}>
                                <p>Block Description</p>
                                </Col>
                                <Col xs={6}>
                                <Form.Control as={"textarea"} id="block-description" value={values.description} maxLength={"500"} onChange={e => setValues({ ...values, description: e.target.value })}/><br />
                                </Col>
                            </Form.Group>
                        </div>
                    </Row>
                </Container>
            </Form>
            <div className={"modal-footer"}>
                <ModalButtons step={1} back={null} next={_handleNext} />
            </div>
        </div>
    );
};

export default NameBlock;