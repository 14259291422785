/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        tab: null,
        widget: null,
        init: function () {
            this.appendDummyInput('Widget')
                .appendField("add to")
                .appendField(new Blockly.FieldWidgetsDropdown(context, "tableview", undefined, '- select -', this.updateShape_.bind(this)), "ID")
                .appendField("row");
            this.setPreviousStatement(true,null);
            this.setNextStatement(true,null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Add a new row to a Table widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        // domToMutation: function() {
        //
        // },
        // mutationToDom: function () {
        //     let mutation = document.createElement('mutation');
        //     return mutation;
        // },
        createInputBlock: function (name) {
            let inputBlock = this.workspace.newBlock("number_text");
            inputBlock.setLabel(name);
            inputBlock.setShadow(true);
            inputBlock.setShadowColour("#A74EFF");
            inputBlock.initSvg();
            inputBlock.render();
            return inputBlock;
        },
        checkInputNames: function(name) {
            return (name !== "Widget");
        },
        updateColumn:function (smartList) {
            if(this.tab != null) {
                for (let i = this.inputList.length - 1; i > 0; i--) {
                    if (this.checkInputNames(this.inputList[i].name)) {
                        this.removeInput(this.inputList[i].name);
                    }
                }
            }
            Object.keys(smartList.columns).map(argName => {
                this.appendDummyInput('Spacing')
                    .appendField("   ");
                let inputBlock = this.createInputBlock(smartList.columns[argName].name);
                let inputSlot = this.appendValueInput(`${smartList.columns[argName].id}`);
                inputSlot.connection.connect(inputBlock.outputConnection);
            });
        },
        getTab: function(){
            return this.tab;
        },
        updateShape_:function() {
            let widgets = util.widgetsOfType(context, 'tableview');
            let id = this.getFieldValue('ID');
            for (let i = 0; i < widgets.length; i++) {
                if (widgets[i].id == id && this.tab != id) {
                    this.updateColumn(widgets[i]);
                    this.tab = id;
                    this.widget = widgets[i];
                }
            }
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block) {
            let id = block.getFieldValue('ID');
            var length = this.widget ? this.widget.columns.length : 0;
            var code = '';
            for (var i = 0; i < length; i++) {
                if (Blockly.JavaScript.valueToCode(block, this.widget.columns[i].id, Blockly.JavaScript.ORDER_ATOMIC) != '(undefined)') {
                    code += this.widget.columns[i].id + ':';
                    code += Blockly.JavaScript.valueToCode(block, this.widget.columns[i].id, Blockly.JavaScript.ORDER_ATOMIC);
                    code += ',';
                }
            }
            code = code.length > 0 && code[code.length - 1] == ',' ? code.substring(0, code.length - 1) : code;
            code = "Snap.widgets.tableview.addRecord(`" + id + "`,{" + code + "});";
            return code;
        }
    }
}

const AddRow = function(props){
    const name = 'add_row';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id!==undefined) ?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default AddRow
