/* global Blockly */
/* global Util */

/*
NOTE: This block should not be used outside the context of an ajax call.
The JS it returns depends on the namespace established for the
error or success callback of an ajax call.
 Thus this function always returns null (should never appear in the toolbox)
*/
const plusImage = "data:image/svg+xml,%3Csvg width=\'28px\' height=\'28px\' viewBox=\'0 0 28 28\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Ctitle%3Eicon-add 02-SNAP%3C/title%3E%3Cg id=\'icon-add-02-SNAP\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z\' id=\'Combined-Shape\' fill=\'%23FFFFFF\'%3E%3C/path%3E%3Crect id=\'Rectangle\' fill=\'%23FFFFFF\' x=\'8\' y=\'13\' width=\'12\' height=\'2\' rx=\'0.75\'%3E%3C/rect%3E%3Crect id=\'Rectangle-Copy\' fill=\'%23FFFFFF\' transform=\'translate(14.000000, 14.000000) rotate(90.000000) translate(-14.000000, -14.000000) \' x=\'8\' y=\'13\' width=\'12\' height=\'2\' rx=\'0.75\'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";
const minusImage = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-remove 02-SNAP 2%3C/title%3E%3Cg id='icon-remove-02-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='8' y='12.5' width='12' height='3' rx='1.25'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";
const RestParams = function () {
    const name = 'rest_params';

    function validateBlock(block){
        var l =[];
        var isValid = block.type !== 'variables_get' && block.type !== 'getter' && block.type !== 'snap_get_textbox_value' && block.type !== 'request_response_parameter';
        l.push(isValid);

        if(!isValid){
            block.setWarningText('This value can only be known at runtime. Please substitute this for a different block for testing your API call');
            var blockOnchange = block.onchange;
            block.setOnChange(function(x){
                if(x.blockId===block.id && (x.element !=='warningOpen')){
                    block.setWarningText(false);
                    block.setOnChange(blockOnchange);
                }
            });
        }

        block.getChildren().forEach(function(child){
            l.push(validateBlock(child));
        });
        return l.reduce(function(acc, cv){return acc&&cv});
    }

    function notifyRestParamsError(block,error) {
        if (block.getParent().type == 'rest_service' || block.getParent().type == 'load_data_table_rest') {
            if (error) {
                block.getParent().setRestErrorMessage("Rest block does not support one or more of the input blocks. Please substitute these blocks for a local variable block.");
            }else {
                block.getParent().setRestErrorMessage(false);
            }
        }
    }

    function generateKeys(block) {
        let keys = '';
        for(let i =0; i <block.length; i++){
            let key = Blockly.JavaScript.valueToCode(block, 'key_' + i, Blockly.JavaScript.ORDER_NONE);
            //eval and get the output of text_join block before adding into key list
            if (validateBlock(block.getInput('key_' + i).connection.targetBlock())) {
                let targetKeyBlock = block.getInput('key_' + i).connection.targetBlock();
                let targetKeyType = block.getInput('key_' + i).connection.targetBlock().type;
                if (["text_join", "make_json_with", "lists_create_with"].includes(targetKeyType)) {
                    try {
                        key = JSON.stringify(eval(key));
                        notifyRestParamsError(block,false);
                    } catch (e) {
                        notifyRestParamsError(block,true);
                        targetKeyBlock.setWarningText('Rest block does not support this block. Please substitute this for a different block');
                        var blockOnchange = targetKeyBlock.onchange;
                        targetKeyBlock.setOnChange(function(x){
                            if(x.blockId===targetKeyBlock.id && (x.element !=='warningOpen')){
                                targetKeyBlock.setWarningText(false);
                                targetKeyBlock.setOnChange(blockOnchange);
                            }
                        });
                    }

                } else {
                    notifyRestParamsError(block,false);
                }
            }
            if (i < block.length -1) {
                keys += '"' + i + '":' + key + ',\n';
            } else {
                keys += '"' + i + '":' + key;
            }
        }
        return keys;
    }

    function generateValues(block) {
        let values = '';
        for(let i =0; i <block.length; i++){
            let value = Blockly.JavaScript.valueToCode(block, 'value_' + i, Blockly.JavaScript.ORDER_NONE);
            //eval and get the output of text_join block before adding into value list
            if (validateBlock(block.getInput('value_' + i).connection.targetBlock())) {
                let targetValueBlock = block.getInput('value_' + i).connection.targetBlock();
                let targetValueType = block.getInput('value_' + i).connection.targetBlock().type;
                if (["text_join", "make_json_with", "lists_create_with"].includes(targetValueType)) {
                    try {
                        value = JSON.stringify(eval(value));
                        notifyRestParamsError(block,false);
                    } catch (e) {
                        notifyRestParamsError(block,true);
                        targetValueBlock.setWarningText('Rest block does not support this block. Please substitute this for a different block');
                        let blockOnchange = targetValueBlock.onchange;
                        targetValueBlock.setOnChange(function(x){
                            if(x.blockId===targetValueBlock.id && (x.element !=='warningOpen')){
                                targetValueBlock.setWarningText(false);
                                targetValueBlock.setOnChange(blockOnchange);
                            }
                        });
                    }

                } else {
                    notifyRestParamsError(block,false);
                }
            }
            if (i < block.length -1) {
                values += '"' + i + '":' + value + ',\n';
            } else {
                values += '"' + i + '":' + value;
            }
        }
        return values;
    }
    // key/value pairs for headers and query strings
    Blockly.Blocks[name] = {
        length:0,
        init: function(){
            this.backgroundColor = Blockly.Msg.CONNECTIONS_HUE;
            this.buttonCol = Object.assign({},this.backgroundColor);
            this.buttonCol.tertiary = '#888888';
            this.setColour(this.backgroundColor);
            this.appendDummyInput('PLUS')
                .appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKeyValuePair.bind(this)),'PLUS')
                .setOnNewRow(true);
            this.appendDummyInput()
                .appendField(' ').setOnNewRow(false);
            this.setOutput(true);
            this.setShadow(true);

            this.setHelpUrl(Blockly.BASE_HELP_URL+"#connections");
        },
        addKeyValuePair: function(){
            let index = this.length;
            let keyInput = this.appendValueInput('key_' + index)
                .setOnNewRow(true)
                .setCheck("String")
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*", function(){this.removeKeyValuePair(index)}.bind(this)), 'delete_'+index)
                .appendField('key');
            let valueInput = this.appendValueInput('value_' + this.length).appendField('value ');
            this.moveInputBefore(keyInput.name, 'PLUS');
            this.moveInputBefore(valueInput.name, 'PLUS');

            let keyBlock = this.workspace.newBlock('fixed_width_text');
            keyBlock.setDisplayWidth(9);
            keyBlock.setTooltip('Invoke a RESTful API request.');
            keyBlock.setShadow(true);
            let valueBlock = this.workspace.newBlock('fixed_width_text');
            valueBlock.setDisplayWidth(9);
            valueBlock.setTooltip('Invoke a RESTful API request.');
            valueBlock.setShadow(true);

            keyInput.connection.connect(keyBlock.outputConnection);
            valueInput.connection.connect(valueBlock.outputConnection);
            this.length++;
            this.initSvg();
            keyBlock.initSvg();
            valueBlock.initSvg();
            keyBlock.render();
            valueBlock.render();
            this.render();
        },
        removeKeyValuePair: function(index) {
            let keyInput = this.getInput("key_" + index);
            let valueInput = this.getInput("value_" + index);

            this.getInputTargetBlock(keyInput.name).dispose(true,true)
            this.getInputTargetBlock(valueInput.name).dispose(true,true)

            this.removeInput(keyInput.name);
            this.removeInput(valueInput.name);

            let indexes = [];
            for(let i = index+1; i<this.length; i++) {
                indexes.push(i);
            }
            indexes.forEach(function(i){
                let key = this.getInput("key_"+i);
                let value = this.getInput("value_"+i);
                let button = this.getField("delete_"+i);
                let newIndex = i-1;
                key.name = "key_"+(newIndex);
                value.name = "value_"+(newIndex);
                button.name = "delete_"+(newIndex);
                button.clickHandler_ = function() {
                    this.removeKeyValuePair( i-1);
                }.bind(this);
                this.getInputTargetBlock(key.name).initSvg();
                this.getInputTargetBlock(key.name).render();
                this.getInputTargetBlock(value.name).initSvg();
                this.getInputTargetBlock(value.name).render();
            }.bind(this));
            this.length = this.length-1;
        },
        mutationToDom: function(){
            return Util.dom('mutation', {
                length: this.length,
                background: this.backgroundColor
            });
        },
        domToMutation: function(xml){
            let background = xml.getAttribute('background');
            if (background) {
                this.backgroundColor = background;
                this.setColour(this.backgroundColor);
            }
            let length = parseInt(xml.getAttribute('length'));
            for(let i =0; i<length; i++){
                this.addKeyValuePair();
            }
        },
        validateBlocksForTest: function(){
            let isValid = true;
            // Need to init variableDB for variables to check properly;
            Blockly.JavaScript.init(Blockly.mainWorkspace);
            for(let i =0; i<this.length; i++){
                let keyBlock = this.getInput('key_'+i).connection.targetBlock();
                let valueBlock = this.getInput('value_'+i).connection.targetBlock();
                let keyValid = validateBlock(keyBlock);
                let valueValid = validateBlock(valueBlock);
                isValid = isValid && keyValid && valueValid
            }
            return isValid;
        },

        getTestJson: function(){
            let r ={};
            for(let i = 0; i < this.length; i++){
                let key = eval(Blockly.JavaScript.valueToCode(this, 'key_'+i,Blockly.JavaScript.ORDER_NONE));
                r[key] = eval(Blockly.JavaScript.valueToCode(this, 'value_'+i,Blockly.JavaScript.ORDER_NONE));
            }
            return r;
        },
        _stringifyTryCatch: function(s){
            return '(function(){' +
                'try{' +
                'return '+s+
                '} catch(e){' +
                'return undefined' +
                '}' +
                '})()';
        }
    };

    Blockly.JavaScript[name] = function(block) {
        let keys = generateKeys(block);
        let key = '' +
            '{\n' +
            keys+
            '\n}\n';

        let values = generateValues(block);
        let value = '' +
            '{\n' +
            values+
            '\n}\n';

        let r = '' +
            '{\n' +
            '"key":' + key + ',\n' +
            '"value":' + value + '\n' +
            '\n}\n';
            
        return [r, Blockly.JavaScript.ORDER_NONE];
    };


    // this should never show in the toolbox
    return null

};

export default RestParams;
