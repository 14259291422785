/* global Blockly */
import React from 'react';

const LogicCompareNew = function () {
    const name = 'logic_compare_new';

    Blockly.Blocks[name] = {
        init: function() {
            let value1Input = this.appendValueInput("VALUE_1")
                .setCheck(["String", "Number"]);
            this.appendDummyInput("OP")
                .appendField(new Blockly.FieldDropdown([
                    ['=', "EQUAL"],
                    ['≠', "NOT EQUAL"],
                    ['<', "LESS THAN"],
                    ['≤', "LESS OR EQUAL"],
                    ['>', "GREATER THAN"],
                    ['≥', "GREATER OR EQUAL"]
                ]), "OPERATOR");
            let value2Input = this.appendValueInput("VALUE_2")
                .setCheck(["String", "Number"]);
            this.setOutput(true, "Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.LOGIC_HUE);
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#logic");

            var thisBlock = this;
            this.setTooltip(function() {
                let mode = thisBlock.getFieldValue('OPERATOR');
                let TOOLTIPS = {
                    'EQUAL': 'Return true if both inputs equal each other.',
                    'NOT EQUAL': 'Return true if both inputs are not equal to each other.',
                    'LESS THAN': 'Return true if the first input is smaller than the second input.',
                    'LESS OR EQUAL': 'Return true if the first input is smaller than or equal to the second input.',
                    'GREATER THAN': 'Return true if the first input is greater than the second input.',
                    'GREATER OR EQUAL': 'Return true if the first input is greater than or equal to the second input.'
                };
                return TOOLTIPS[mode];
            });

            // add number blocks to the inputs
            let value1Block = this.workspace.newBlock("math_number");
            value1Block.setShadow(true);
            value1Input.connection.connect(value1Block.outputConnection);
            let value2Block = this.workspace.newBlock("math_number");
            value2Block.setShadow(true);
            value2Input.connection.connect(value2Block.outputConnection);
        }
    };

    Blockly.JavaScript[name] = function(block) {
        let value1 = Blockly.JavaScript.valueToCode(block, 'VALUE_1', Blockly.JavaScript.ORDER_ASSIGNMENT) || 'null';
        let value2 = Blockly.JavaScript.valueToCode(block, 'VALUE_2', Blockly.JavaScript.ORDER_ASSIGNMENT) || 'null';
        let operator = block.getFieldValue("OPERATOR");
        let code = value1 + ' ';
        switch (operator) {
            case "EQUAL":
                code += '==';
                break;
            case "NOT EQUAL":
                code += '!=';
                break;
            case "LESS THAN":
                code += '<';
                break;
            case "LESS OR EQUAL":
                code += '<=';
                break;
            case "GREATER THAN":
                code += '>';
                break;
            case "GREATER OR EQUAL":
                code += '>=';
                break;
        }
        code += ' ' + value2;
        return [code, Blockly.JavaScript.ORDER_NONE];
    };

    return (
        <block type={name}></block>
    )
};

export default LogicCompareNew;