import React from 'react'
import _ from 'underscore';
import util from "../../../../utils/es5Utils";
export const labelIcon = "data:image/svg+xml,%3Csvg fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 12'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath d='M7.53,10.33v1.43H5v-1a3.27,3.27,0,0,1-2.4,1.13,2.49,2.49,0,0,1-1.79-.73A2.44,2.44,0,0,1,0,9.31,2.47,2.47,0,0,1,.81,7.42a2.69,2.69,0,0,1,1.94-.76,3.55,3.55,0,0,1,2.05.7V6.55a3.44,3.44,0,0,0-.11-1A1.2,1.2,0,0,0,4.18,5a1.86,1.86,0,0,0-1-.27,1.68,1.68,0,0,0-1.61,1L.1,5.26A3.35,3.35,0,0,1,3.36,3.35a3.9,3.9,0,0,1,1.47.25,2.1,2.1,0,0,1,.92.64,2.23,2.23,0,0,1,.41.85,7.33,7.33,0,0,1,.1,1.46v3.78ZM4.8,8.72a3,3,0,0,0-1.94-.81,1.45,1.45,0,0,0-1,.39,1.33,1.33,0,0,0-.41,1,1.37,1.37,0,0,0,.36,1,1.28,1.28,0,0,0,1,.39,2.83,2.83,0,0,0,2-1Z'/%3E%3Cpath d='M8.16,11.76V10.33H9.5V1.43H8.16V0H11V4.74a3.36,3.36,0,0,1,2.73-1.39A3.42,3.42,0,0,1,16.3,4.51a4.42,4.42,0,0,1,1.05,3.07,4.65,4.65,0,0,1-1,3.07,3.44,3.44,0,0,1-4.3.83A3.06,3.06,0,0,1,11,10.54v1.22ZM11,7.69a2.79,2.79,0,0,0,.72,2,2.27,2.27,0,0,0,1.67.75,2.19,2.19,0,0,0,1.7-.79,3.06,3.06,0,0,0,.72-2.14,2.71,2.71,0,0,0-.71-2,2.28,2.28,0,0,0-1.67-.72,2.36,2.36,0,0,0-1.69.74A2.93,2.93,0,0,0,11,7.69Z'/%3E%3Cpath d='M25.76,3.58V6.67H24.43a2,2,0,0,0-3.6-1.07,3.11,3.11,0,0,0-.62,2,3.62,3.62,0,0,0,.59,2,2,2,0,0,0,1.72.89,2.36,2.36,0,0,0,2.26-1.61L26,9.56A3.59,3.59,0,0,1,22.45,12a3.37,3.37,0,0,1-2.82-1.33,4.86,4.86,0,0,1-1-3.06,4.33,4.33,0,0,1,1.12-3.06,3.45,3.45,0,0,1,2.58-1.2,3,3,0,0,1,2.12.8V3.58Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";

export default function Label(props){
    let widgets = util.widgetsOfType(props.context, 'label');
    let newProps = Object.assign({},props,{widgets});
    var components = [
        newProps.widgets.length>0?<label text={"Labels"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];

    return components;
}