/* global Blockly */
/* global Util */
/* global $ */
import React from 'react';


function getSuccessStr(id,evalStr){
    return '' +
        'function(data,status,xhr){\n' +
        'var ResponseScope = ResponseScope || {};\n' +
        'ResponseScope[\''+id+'\'] = data;\n' +
        evalStr +
        '}';
}

function getErrorStr(id,evalStr){
    return '' +
        'function(xhr){\n' +
        'var ResponseScope = ResponseScope || {};\n' +
        'ResponseScope[\''+id+'\'] = xhr;\n' +
        evalStr +
        '\n}';
}

function getTestDom(requestSpec, callback){
    var container = Util.dom('div', {class:'rest-service-test'});
    var explanation = Util.dom('div',{innerText:'Click \'test\' to confirm that your request is properly configured. Note: this will invoke the service.'});
    var test = Util.dom('input',{type:'button',value:'test'});
    var txt = Util.dom('div',{innerText:'Response:'});
    var responseArea = Util.dom('textarea',{class:'responseArea',readonly:true});
    var buttons = Util.dom('div');
    var cancel = Util.dom('input',{type:'button',value:'cancel',style:'color:black;background-color:rgb(237,240,241)'});
    var done = Util.dom('input',{type:'button',value:'done', style:'background-color:rgb(222,222,222);color:white;', disabled:true});
    buttons.appendChild(cancel);
    buttons.appendChild(done);
    container.appendChild(explanation);
    container.appendChild(test);
    container.appendChild(txt);
    container.appendChild(responseArea);
    container.appendChild(buttons);
    cancel.addEventListener('click',function(){
        callback();
    });
    requestSpec.success = function(data){
        responseArea.innerHTML = JSON.stringify(data,null,2);
        done.removeAttribute('disabled');
        done.style.backgroundColor = 'rgb(30, 173, 138)';
        done.addEventListener('click',function(){
            callback(data);
        })
    };
    requestSpec.error = function(xhrErr, statusErr, errorMessage){
        var errStr = 'An error occurred: ' + xhrErr.status;
        if(errorMessage){
            errStr += ' - '+errorMessage;
        }
        responseArea.innerText = errStr;
    };
    test.addEventListener('click',function(){
        $.ajax(requestSpec)
    });
    return container
}


function validateBlock(block) {
    var l = [];
    var isValid = block.type !== 'variables_get' && block.type !== 'getter';
    l.push(isValid);

    if (!isValid) {
        block.setWarningText('This value can only be known at runtime. Please substitute this for a different block for testing your API call');
        var blockOnchange = block.onchange;
        block.setOnChange(function (x) {
            if (x.blockId === block.id && (x.element !== 'warningOpen')) {
                block.setWarningText(false);
                block.setOnChange(blockOnchange);
            }
        }.bind(this));
    }

    block.getChildren().forEach(function (child) {
        l.push(validateBlock(child));
    }.bind(this));
    return l.reduce(function (acc, cv) {
        return acc && cv
    });
}

function defineBlock() {
    return {
        headers: 0,
        queryStrings: 0,
        init: function () {
            this.responseJsonStructure = {}
            this.setColour(Blockly.Msg.CONNECTIONS_HUE)

            this.appendDummyInput()
                .appendField("get web resource")
            this.appendValueInput("URL").appendField("URL").setOnNewRow(true)
            this.appendDummyInput("TEST")
                .appendField(new Blockly.FieldButton(" Test ", undefined, function () {
                    this.openTestPage()
                }.bind(this)), "TEST")
                .setOnNewRow(true)
            this.appendValueInput("SUCCESS_PARAM")
                .appendField("On Success with:")
                .setOnNewRow(true)
            this.appendStatementInput('SUCCESS')
            this.appendValueInput("ERROR_PARAM")
                .appendField("On error with:")
            this.appendStatementInput('ERROR')
            this.setTooltip('Get a resource from the provided URL.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#connections");
            this.setPreviousStatement(true, null);
            this.setOnChange(this.changeHandler)
        },
        changeHandler: function () {
            let errParam = this.getInput("ERROR_PARAM")
            let successParam = this.getInput("SUCCESS_PARAM")
            if (!successParam.connection.isConnected()) {
                let block = this.createSuccessResponseBlock(this.workspace)
                successParam.connection.connect(block.outputConnection)
            } else {
                successParam.connection.targetBlock().setParentId(this.id)
            }

            if (!errParam.connection.isConnected()) {
                let block = this.createErrorResponseBlock(this.workspace)
                errParam.connection.connect(block.outputConnection)
            } else {
                errParam.connection.targetBlock().setParentId(this.id)
            }
        },
        createSuccessResponseBlock: function (ws) {
            ws = ws || this.workspace
            let jsonBlock = ws.newBlock("request_response_parameter")
            jsonBlock.setLabel('response data')
            jsonBlock.setParentId(this.id)
            jsonBlock.setJsonStructure(this.responseJsonStructure || {});
            if (this.rendered) {
                jsonBlock.initSvg();
                jsonBlock.render();
            }
            return jsonBlock;
        },
        createErrorResponseBlock: function (ws) {
            ws = ws || this.workspace
            let jsonBlock = ws.newBlock("request_response_parameter")
            jsonBlock.setLabel('error data')
            jsonBlock.setParentId(this.id)
            jsonBlock.setJsonStructure({"readyState": 4, "responseText": "", "status": 404, "statusText": "Not Found"});
            if (this.rendered) {
                jsonBlock.initSvg();
                jsonBlock.render();
            }
            return jsonBlock;
        },
        openTestPage: function () {
            // Necessary to make sure variableDb is initialized
            Blockly.JavaScript.init(Blockly.mainWorkspace);

            Blockly.WidgetDiv.hide();
            Blockly.WidgetDiv.show(this);

            let testField = this.getField('TEST');
            let viewportBBox = Blockly.utils.getViewportBBox();
            let anchorBBox = testField.getScaledBBox_();
            let dom;

            let urlBlock = this.getInput('URL').connection.targetBlock();
            let urlValidated = validateBlock(urlBlock);
            if (!urlValidated) {
                dom = Util.dom('div', {
                    innerHTML: 'The provided URL cannot be determined until runtime. Please provide a default value for blocks with warnings to test.',
                    style: 'height:0px;padding:0px;transition: all 0.15s ease; background-color:rgb(130,130,130,0.1);color:white'
                });
                Blockly.WidgetDiv.positionWithAnchor(viewportBBox, anchorBBox, {
                    width: dom.offsetWidth,
                    height: dom.offsetHeight
                }, false);
                Blockly.WidgetDiv.DIV.style.padding = '0px';
                Blockly.WidgetDiv.DIV.style.width = 'auto';
                Blockly.WidgetDiv.DIV.style.height = 'auto';
                setTimeout(function () {
                    dom.style.height = 'auto';
                    dom.style.padding = '7px';
                    dom.style.backgroundColor = 'rgb(130,130,130)'
                }, 1);
                setTimeout(function () {
                    Blockly.WidgetDiv.hide();
                }, 4000);
            } else {
                let dimensions = {height: 300, width: 500};
                Blockly.WidgetDiv.positionWithAnchor(viewportBBox, anchorBBox, dimensions, false);
                Blockly.WidgetDiv.DIV.style.width = dimensions.width + "px";
                Blockly.WidgetDiv.DIV.style.height = dimensions.height + "px";
                let requestSpec = this.getRequestSpec();
                dom = getTestDom(requestSpec, function (responseData) {
                    Blockly.WidgetDiv.hide();
                    if (responseData) {
                        this.responseJsonStructure = responseData;
                        let block = this.createSuccessResponseBlock()
                        let successParamInput = this.getInput("SUCCESS_PARAM")
                        successParamInput.connection.targetBlock().dispose()
                        successParamInput.connection.connect(block.outputConnection)
                    }
                }.bind(this));
            }
            Blockly.WidgetDiv.DIV.appendChild(dom);
        },
        // gets a request object for an ajax, without success or failure callbacks (for test popup)
        getRequestSpec: function () {
            let url = Util.parseString(Blockly.JavaScript.valueToCode(this, "URL", Blockly.JavaScript.ORDER_NONE))
            let r = {
                async: true,
                crossDomain: true,
                url: url,
                method: "GET",
                dataType: 'json',
                contentType: 'application/json',
            }
            return r
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let url = Blockly.JavaScript.valueToCode(this, "URL", Blockly.JavaScript.ORDER_NONE);
            let props = '' +
                '{\n' +
                'async:true,\n' +
                'crossDomain:true,\n' +
                'dataType:\'json\',' +
                'contentType:\'application/json\',\n' +
                'url:' + url + ',\n' +
                'method:\'GET\'\n' +
                '}';

            let success = Blockly.JavaScript.statementToCode(block, 'SUCCESS');
            let successAsStr = getSuccessStr(this.id, success);
            let error = Blockly.JavaScript.statementToCode(block, 'ERROR');
            let errorAsStr = getErrorStr(this.id, error);
            let propsStr = props;
            propsStr = propsStr.slice(0, -1) + ", success: " + successAsStr + "}";
            propsStr = propsStr.slice(0, -1) + ", error: " + errorAsStr + "}";
            let execute = '$.ajax(' + propsStr + ');'
            return execute
        }
    }
}

const SimpleRestService = function (props) {
    const name = 'rest_get_simple';
    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}>
            <value name="URL">
                <shadow type="fixed_width_text">
                    <mutation length="39"></mutation>
                </shadow>
            </value>
        </block>
    )
};

export default SimpleRestService;