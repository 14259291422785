/* global Blockly */
import React from 'react';
import {datesIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput('VALUE')
                .appendField("get")
                .appendField(new Blockly.FieldImage(datesIcon, 30, 30, "dates icon", null), 'datesIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "date", undefined, '- select -'), "ID");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, ["Date"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the date inside a datepicker widget.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function() {
            if (this.getField('datesIcon') && this.getField('datesIcon').imageElement_)
                this.getField('datesIcon').setTooltip('Date');
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block){
            var id = block.getFieldValue('ID');
            var code = "(window.UtilityModule && UtilityModule.formatDateToString(createDateAsUTC(new Date($('[id=\"" + id + "\"]').val()))))";
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetDateValue = function(props){
    const name = "snap_get_date_value"
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if (!props.widgets || (props.widgets.length < 1)) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id!==undefined)?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
};

export default GetDateValue;