/*eslint no-mixed-operators: "off"*/
/*global io, ExecutionRuntimeModule, BlocklyPromises*/
import postRobot from 'post-robot';

// Generic utility functions
import { assign, clone } from 'lodash';

const serverURL = process.env.REACT_APP_DASHBOARD_API_ROOT;

export const WORKFLOW_APP_TYPE_ID = 2;

// Is a value string or null?
export function isStringOrNull (value) {
    return !value || typeof value === 'string';
}

// If the value has an id property return its id, else return the value
export function getId (value) {
    return value && value.id || value;
}

// Sort objects by id property of
export function sortById (a = {}, b = {}) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
}

// Sort objects by name property
export function sortByName (a = {}, b = {}) {
    a = a.name && a.name.toUpperCase();
    b = b.name && b.name.toUpperCase();

    return (a > b) ? 1 : (a < b) ? -1 : 0;
}

// Return a new clone of the object with args assigned to it
export function compose (obj, ...args) {
    return assign(clone(obj), ...args);
}

// Convert a space seperated string to an upper snake cased string
// e.g. "cat and dog" -> "CAT_AND_DOG"
export function upperSnakeCase (string) {
    return string.split(' ').join('_').toUpperCase();
}

// Convert an underscore or space sepertated sting to a camel-cased string
// e.g. "eat RHUBARB_pie" -> "eatRhubarbPie"
export function camelCase (string) {
    let words = string.toLowerCase().split(/_| /);
    return words.reduce((word, next) =>
        word + next[0].toUpperCase() + next.slice(1));
}

// Log message with a timestamp.
export const log = logFactory();

// Log error with a timestamp.
export const logError = logFactory('error');

// Create new loggers with the specified severity
export function logFactory (severity = 'log') {
    return (...messages) => {
        let timestamp = new Date().toISOString();
        console[severity](timestamp, ...messages);
    };
}

export function dom(type, properties,children){
  let r = document.createElement(type);
  for (let i in properties){
    r.setAttribute(i,properties[i])
    r[i] = properties[i]
  }
  if(children){
    children = Array.isArray(children)?children:[children]
    for (let i in children){
      r.appendChild(children[i])
    }
  }
  return r
}

export function parseString(str){

  if((str[0]==="'" && str[str.length-1]==="'") || (str[0] === '"' && str[str.length-1] === '"') ){
    str = str.substring(1)
    str = str.substring(0,str.length-1)
  }
  return str
}

export function stringifyJSON(json){
  if(!Object.keys(json).length){
    return "{}"
  }
  let str = "{\n"
  for (let i in json){
    str += "\t  "+i+": "+json[i]+",\n"
  }
  str = str.substring(0,str.length-2)+"\n\t}"
  return str
}

// remove values from json, leave just the structure w/ primitive values
// arrays get [], functions are removed entirely
export function pruneJSON (obj){
  let json;
  if(typeof(obj)==="object" && !Array.isArray(obj) && obj != null && Object.keys(obj).length){
    json = {}
    let keys = Object.keys(obj)
    for (let i in keys){
      let key = keys[i]

      json[key] = pruneJSON(obj[key])
      if(json[key]===undefined){
        delete json[key]
      }
    }
  } else if (typeof(obj) ==="function") {
    json = undefined
  } else if (Array.isArray(obj)){
    json = []
  } else {
    json = obj
  }
  return json
}




/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 **/

function parseToJsonPromise (data) {
    return new Promise(res => {
        data.json().then(object => {
            let response = {
                successful: [200, 201].includes(data.status),
                data: object,
                code: data.status
            };

            return res(response);
        }).catch(function(error) {
            console.error("Error while reading response JSON", error, data);
        })
    });
}

const apiGenerator = (uri, method, payload, type) => {
    const token = localStorage.getItem("auth_token");

    let headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    if (type !== "file") {
        headers["Content-Type"] = "application/json";
        payload = JSON.stringify(payload);
    }
    console.log(serverURL)
    return fetch(`${serverURL}${uri}`, {
        method,
        body: payload,
        headers
    }).then(parseToJsonPromise).catch(error => { throw error });
};

const socketGenerator = (url, method, payload) => {
    return new Promise((res) => {
        io.socket.request({
            url: url,
            method: method,
            headers: io.sails.headers,
            data: payload
        }, (resData, jwRes) => {
            const successful = jwRes.statusCode === 200;
            res({ data: resData, successful });
        })
    })
};

const networkAdapter = (generator) => {
    return {
        POST: (url, payload, type) => generator(url, 'POST', payload, type),
        PUT: (url, payload) => generator(url, 'PUT', payload),
        DELETE: (url, payload) => generator(url, 'DELETE', payload),
        GET: (url, token, payload) => generator(url, 'GET', payload)
    }
};

const bridgeAdapter = (generator) => {
    return {
        send: (type, payload, timeout) => generator(type, payload, timeout),
    }
};

const bridgeGenerator = (uri, payload) => {
    return new Promise((res) => {
        try {
            postRobot.send(window.parent, uri, payload)
                .then(({ data }) => {
                    res({ successful: true, data })
                }).catch(e => {
                res({ successful: false, error: e })
            })
        } catch (e) {
            res({ successful: false, error: e })
        }
    });
};

const blocklyPrefixFunctions = function () {
    const funcs = {
        toNumber: function (a, defaultVal){
            let r;
            if (typeof(a)=='number'){
                r = a
            } else if (typeof(a)=='string'){
                r = parseFloat(a);
                if (isNaN(r)){r = defaultVal}
            } else{
                r = defaultVal
            }
            return r
        },
        meanOfNumbers: function (list) {
            list = list.filter(function(x){return typeof(x)=='number'});
            return list.reduce(function(x,y){return x+y}) / list.length;
        },
        medianOfNumbers: function (list) {
            let localList = list.filter(function(x){return typeof x == 'number'});

            if (!localList.length) return null;
            localList.sort(function(a, b) {return b - a;});

            if (localList.length % 2 === 0) {
                return (localList[localList.length / 2 - 1] + localList[localList.length / 2]) / 2;
            } else {
                return localList[(localList.length - 1) / 2];
            }
        },
        getDateField: function (field, date) {
            let timestamp = new Date();
            if (date) { timestamp = date && date.getMonth ? date : new Date(date); }
            // var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            switch (field) {
                case 'date':
                    return timestamp.getDate();
                case 'month':
                    return timestamp.getMonth()+1;
                case 'year':
                    return timestamp.getFullYear();
            }
        },
        getTimeField: function (field, time) {
            let timestamp = new Date();
            if (time) {
                if (time.getHours) {
                    timestamp = time;
                } else {
                    let hour = Math.floor(time / 3600);
                    let minute = Math.floor((time - hour * 3600) / 60);
                    let second = time - hour * 3600 - minute * 60;
                    timestamp.setHours(hour, minute, second);
                }
            }

            switch (field) {
                case 'hour':
                    return timestamp.getHours();
                case 'minute':
                    return timestamp.getMinutes();
                case 'second':
                    return timestamp.getSeconds();
            }
        },
        convertTimeToString: function (datetime) {
            datetime.setFullYear(2020,1,1);
            let hour = datetime.getHours();
            let minute = datetime.getMinutes();
            let second = datetime.getSeconds();

            let tosec = ((hour * 60) + minute) * 60 + second;
            return tosec.toString();
        },
        convertStringToTime: function () {
            let sec = parseInt();
            let hour = Math.floor(sec / 3600);
            let minute = Math.floor((sec - hour * 3600) / 60);
            let second = sec - hour * 3600 - minute * 60;

            let d = new Date();
            d.setHours(hour, minute, second);

            d.setFullYear(2020,1,1);
            return d
        },
        numberPad: function (n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        },
        createDateAsUTC: function (date) {
            if (typeof date === "string") {
                date = new Date(date);
            }
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        },
        isJSON: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        BlocklyTmps:'{}', // For intermediate variables, so blockly vars don't conflict. eg: BlocklTmps[<blockId>] = some intermediate calculation
        BlocklyPromises: '[]'
    };

    return Object.keys(funcs).reduce((stringifiedFuncs, funcName) => {
        return `${stringifiedFuncs}\nvar ${funcName} = ${funcs[funcName].toString()};`;
    }, "");
};

const blocklyPostfixFunctions = function () {
    const funcs = {
        executeBlocklyPromises: function () {
            BlocklyPromises.length && ExecutionRuntimeModule.Execute(BlocklyPromises)
        }
    };

    var postFuncs = Object.keys(funcs).reduce((stringifiedFuncs, funcName) => {
        return `${stringifiedFuncs}\nvar ${funcName} = ${funcs[funcName].toString()};`;
    }, "");

    postFuncs += "\nexecuteBlocklyPromises()";
    return postFuncs;
};


// returns true if bounding boxes rectA and rectB intersect
export function doDomNodesIntersect(domA, domB) {
    const rectA = domA.getBoundingClientRect();
    const rectB = domB.getBoundingClientRect();
    const maxLeft = rectA.left > rectB.left ? rectA.left : rectB.left;
    const minRight = rectA.right < rectB.right ? rectA.right : rectB.right;
    const maxTop = rectA.top > rectB.top ? rectA.top : rectB.top;
    const minBottom = rectA.bottom < rectB.bottom ? rectA.bottom : rectB.bottom;
    return (maxLeft < minRight && maxTop < minBottom);
}

export function blockToXmlSanitized (block) {
    if (block.getElementsByTagName("block").length > 0) {
        for (let i = block.getElementsByTagName("block")[0].children.length - 1; i >= 0; i--) {
            let child = block.getElementsByTagName("block")[0].children.item(i);
            if (child.tagName === "value") {
                block.getElementsByTagName("block")[0].removeChild(child);
            }
        }
    }
    for (let i = block.getElementsByTagName("variables").length - 1; i >= 0; i--) {
        let variable = block.getElementsByTagName("variables")[i];
        variable.parentNode.removeChild(variable);
    }
    return block;
}

export function encodeHTML (html) {
    return html.replace(/[\u00A0-\u9999<>&]/gim, i => `&#${i.charCodeAt(0)};`);
}

export const BRIDGE = bridgeAdapter(bridgeGenerator);
export const SOCKETS = networkAdapter(socketGenerator);
export const API = networkAdapter(apiGenerator);
export const blocklyPrefix = blocklyPrefixFunctions();
export const blocklyPostfix = blocklyPostfixFunctions();
