/* global Blockly */
import React from 'react';
import util from "../../../utils/es5Utils";

function defineBlock(context) {
    return {
        innerWidgets : [
            ['- select -', 'InnerWidgetName']
        ],

        init: function () {
            let selectRepeaterDropdown = new Blockly.FieldWidgetsDropdown(context, 'repeater', undefined, '- select -');
            let selectInnerWidgetDropdown = new Blockly.FieldDropdown([['','']], undefined, '- select -');

            this.appendDummyInput('INNERWIDGET')
                .appendField("get value")
                .appendField(selectInnerWidgetDropdown, "INNERID")
                .appendField("of entry #");

            this.appendValueInput("ENTRYINDEX")
                .setCheck("Number");

            this.appendDummyInput('WIDGET')
                .appendField("for")
                .appendField(selectRepeaterDropdown, "ID");

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Number");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the value of specified inner widget of current entry for repeater widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        },

        // handle repeater id dropdown changed
        onchange: function(e) {
            if (e.name != "ID" || e.newValue == e.oldValue || e.blockId != this.id) {
                return;
            }
            this.ID = this.getFieldValue('ID');
            this.getInnerWidgets(this.ID);
            this.updateInnerWidgets();
        },

        // gather inner widgets
        getInnerWidgets: function (parentWidgetId){
            this.innerWidgets = [];
            for (var i = 0; i < context.pages.length; i++){
                var page = context.pages[i];
                for(var j = 0; j < page.widgets.length; j++){
                    var widget = page.widgets[j];
                    if (widget.formId === parentWidgetId && 
                        ['textbox', 'rating', 'checkboxlist', 'flipswitch', 'radiobuttonlist', 'dropdown', 'date'].includes(widget.type)) {
                        this.innerWidgets.push([widget.name, widget.id]);
                    }
                }
            }
            if (this.innerWidgets.length === 0) {
                this.innerWidgets = [['- select -', 'InnerWidgetName']];
            }
        },

        // render inner widgets
        updateInnerWidgets: function() {
            let input = this.getInput("INNERWIDGET");
            input.removeField("INNERID");
            input.appendField(new Blockly.FieldDropdown(this.innerWidgets), "INNERID");
        },

        // create XML to represent list inputs.
        mutationToDom: function() {
            var container = Blockly.utils.xml.createElement('mutation');
            container.setAttribute('ID', this.getFieldValue('ID'));
            container.setAttribute('INNERID', this.getFieldValue('INNERID'));
            return container;
        },

        // parse XML to restore the list inputs
        domToMutation: function(xml){
            let ID = xml.getAttribute("ID");
            this.getInnerWidgets(ID);
            this.updateInnerWidgets();
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let repeaterId = block.getFieldValue('ID');
            let innerWidgetId = block.getFieldValue('INNERID');
            let entryIndex = 'toNumber(' + Blockly.JavaScript.valueToCode(block, 'ENTRYINDEX', Blockly.JavaScript.ORDER_FUNCTION_CALL) + ', 0)';
            let code = `Snap.widgets.repeater.getEntryValue("${repeaterId}", ${entryIndex}, "${innerWidgetId}")`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const RepeaterEntryIndexValue = function (props) {
    const name = 'snap_repeater_entry_index_value';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    return <block type={name}>
                <value name="ENTRYINDEX">
                    <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                {props.widget && (props.widget.id !== undefined)
                    ? <field name='INNERID'>{props.widget.id}</field>
                    : null
                }
            </block>;
}

export default RepeaterEntryIndexValue