/* global Blockly */
import React from 'react';
import {imageIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("get ")
                .appendField(new Blockly.FieldDropdownSearch([["source", "source"], ["base64 string", "base64"]], undefined, undefined), "METHOD")
            this.appendDummyInput('VALUE')
                .appendField(" of ")
                .appendField(new Blockly.FieldImage(imageIcon, 30, 30, "test box icon", null), 'imageIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "image", undefined, '- select -'), "ID")

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, ["String"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Get the source (URL or base64 string) for image widget');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
           if (this.getField('imageIcon') && this.getField('imageIcon').imageElement_)
               this.getField('imageIcon').setTooltip('Image control');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block){
            let id = block.getFieldValue('ID');
            let methodSelector = block.getFieldValue('METHOD');
            let method = methodSelector == "base64" ? "getSourceBase64" : "getSource";
            let code = `Snap.widgets.image.${method}("${id}")`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetImageSource = function(props) {
    const name = 'snap_get_image_source';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id!==undefined)?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetImageSource
