/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput("FROM")
                .appendField("get number of days from")

            let fromDateInput = this.appendValueInput('FROM_DATE')
                .setCheck("Date");

            let fromDateBlock = this.workspace.newBlock("date");
            fromDateBlock.setShadow(true);
            fromDateInput.connection.connect(fromDateBlock.outputConnection);

            this.appendDummyInput("TO")
                .appendField("to")

            let toDateInput = this.appendValueInput("TO_DATE")
                .setCheck("Date");

            let toDateBlock = this.workspace.newBlock("date");
            toDateBlock.setShadow(true);
            toDateInput.connection.connect(toDateBlock.outputConnection);

            this.setOutput(true, "Number");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('Get the number of days from one date to another');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            const funcName = Blockly.JavaScript.provideFunction_('toDate',[
                'function '+Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +'(s) {',
                's =  new Date(s);',
                's.setHours(0);',
                's.setMinutes(0);',
                's.setSeconds(0);',
                's.setMilliseconds(0);',
                'return s',
                '}'
            ]);

            let fromDate = funcName + "(" + Blockly.JavaScript.valueToCode(block, 'FROM_DATE', Blockly.JavaScript.ORDER_NONE) + ")";
            let toDate = funcName + "(" + Blockly.JavaScript.valueToCode(block, 'TO_DATE', Blockly.JavaScript.ORDER_NONE) + ")";

            let code = 'Math.round((' + toDate + '-' + fromDate + ') / (1000 * 3600 * 24)) >= 0 ? ' +
                'Math.round((' + toDate + '-' + fromDate + ') / (1000 * 3600 * 24)) : ' +
                '"Error: second date is before first date."';

            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

function GetDaysApart(props) {
    const name = "get_days_apart";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}></block>
    )
}
export default GetDaysApart;