import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './index.css';

class DeleteLibraryDialog extends Component {

    _deleteLibrary() {
        let libraryId = "";
        for (let i = 0; i < this.props.libraries.length; i++) {
            if (this.props.currentLibrary === this.props.libraries[i].name) {
                libraryId = this.props.libraries[i].id;
            }
        }

        this.props.deleteLibrary({
            userId: this.props.userId,
            tenantId: this.props.tenantId,
            libraryId: libraryId
        });
    }

    render() {

        return (
            <div id={"delete-library-dialog-container"}>
                <div id={"delete-library-dialog-wrapper"}>
                    <div className={"delete-library-dialog-header"}>
                        <h4>Confirm Delete Library</h4>
                    </div>
                    <div className={"delete-library-dialog-body"}>
                        <p>
                            Are you sure you want to delete &quot;{this.props.currentLibrary}&quot;?
                        </p>
                        <p>
                            This library will be deleted, along with any blocks inside it. This action cannot be undone.
                        </p>
                    </div>
                    <div className="delete-library-dialog-footer">
                        <Link to={`/${window.location.search}`}>
                        <button className="btn-continue"
                                onClick={this._deleteLibrary.bind(this)}>CONFIRM DELETE</button>
                        </Link>
                        <Link to={`/${window.location.search}`}>
                            <button className="btn-cancel">CANCEL</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteLibraryDialog
