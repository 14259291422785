import React from 'react'
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const datesIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23006699;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ebc_date%3C/title%3E%3Cpath d='M19,6.65V2.25H14.77V1.09h-2.5V2.25H7.73V1.09H5.23V2.25H1V18.91H19V7.85h0V6.65ZM6.77,15h-2V13h2Zm-1.94-3.4V9.72H6.72v1.89ZM11,15H9V13h2Zm0-3.35H9v-2h2Zm4.23,0h-2v-2h2Zm2.58-5H2.2V3.46H17.8Z'/%3E%3C/svg%3E";

export default function Dates (props){

    let widgets = util.widgetsOfType(props.context, 'date');
    let newProps = Object.assign({},props,{widgets});

    let components = [
        newProps.widgets.length>0?<label text={"Dates"}/>:null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key={id}/>)
    ];
    return components;
}

