import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';
import Editor  from '@monaco-editor/react';
import ModalButtons from "../ModalButtons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons';

const CustomJSModal = ({ customJSBlockCode, customJSBlockCodeChange }) => {
    var argString = 'params';
    let init = `
/* Blockly Custom Script
 * Implemented in Javascript
 * To learn more about Javascript, 
 * please visit https://developer.mozilla.org/en-US/docs/Web/JavaScript/
 *
 *
 * Block Arguments: ${argString}
 * 
 * Any return values from this code can be stored in Blockly Local Variable
 * using \`SnapReturns\` keyword as follows:
 * SnapReturns.result = "Hello World!"
 * variable result containing the assigned value can be found in
 * the local variables blocks.
 * 
 */
 
// Add your custom script below
`;
    let initVal = customJSBlockCode ? customJSBlockCode : init;
    const [value, setValue] = useState(initVal);
    const [editor, setEditor] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [completionProvider, setCompletionProvider] = useState(null);

    const _sanitizeCode = code => {
        return code.replace(/^\s*\b(const|let)\b/gm, 'var');
    };

    const _handleEditorBeforeMount = monaco => {
        function createProposals(args) {
            return [
                ...args.map(arg => ({
                    label: arg,
                    kind: monaco.languages.CompletionItemKind.Variable,
                    insertText: arg
                })),
                {
                    label: "SnapReturns",
                    kind: monaco.languages.CompletionItemKind.Method,
                    insertText: "SnapReturns."
                }
            ];
        }

        const completionProvider = monaco.languages.registerCompletionItemProvider('javascript', {
            provideCompletionItems: function() {
                var suggestions = createProposals([argString]);
                return {
                    suggestions: suggestions
                };
            }
        });
        setCompletionProvider(completionProvider);
        setEditor(monaco);
    };

    const _expandModal = () => {
        if (expanded) {
            setExpanded(false);
            let modalWrapper = document.getElementById("save-modal-wrapper");
            modalWrapper.className = "modal-wrapper";
        } else {
            setExpanded(true);
            let modalWrapper = document.getElementById("save-modal-wrapper");
            modalWrapper.className = "modal-wrapper-expanded";
        }
    }

    useEffect(() => {
        return function cleanup() {
            editor && editor.editor.getModels().map(model => model.dispose());
        };
    }, [editor]);

    useEffect(() => {
        return function cleanup() {
            completionProvider && completionProvider.dispose()
        }
    }, [completionProvider]);

    return (
        <div id={"save-modal-container"}>
            <div className={"modal-wrapper"} id={"save-modal-wrapper"}>
                <div className={"modal-header small"}>
                    <h4>Create Custom JS Code Block</h4>
                    <div className={"expand-icon-container"} onClick={_expandModal} >
                        { expanded ?
                            <FontAwesomeIcon icon={faCompressAlt} /> :
                            <FontAwesomeIcon icon={faExpandAlt} />
                        }
                    </div>
                </div>
                <div id={"scriptArea"} className={"modal-body"}>
                    <Editor
                        language="javascript"
                        value={value}
                        options={{filteredTypes: { interface: false }}}
                        beforeMount={_handleEditorBeforeMount}
                        onChange={val => {setValue(val)}}
                        theme={"vs-dark"}/>
                </div>
                <div className="modal-footer">
                    <ModalButtons next={() => customJSBlockCodeChange(_sanitizeCode(value))} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(CustomJSModal);