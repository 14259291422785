/* global Blockly */
import React from 'react';
import {radioButtonListIcon} from "./index";

// everything here should be ES5
function defineBlock(context,radiobuttonLists) {

    var radiobuttonOpts = {};
    radiobuttonLists.forEach(function (widget) {
        radiobuttonOpts[widget.id] = [["< no selection >", "__CLEAR__"]].concat(widget.properties.options.map(function (cb) {
            return [cb.name, cb.id];
        }));
    });

    return {
        init: function () {

            var widgetDD = new Blockly.FieldWidgetsDropdown(context,"radiobuttonlist", undefined, '- select -');
            this.appendDummyInput("A")
                // .appendField("radio button")
                .appendField(new Blockly.FieldImage(radioButtonListIcon, 30, 30, "radio button list icon", null), 'radioButtonListIcon')
                .appendField(widgetDD, "RB")
                .appendField("is set to")
                .appendField(new Blockly.FieldDropdown([['', '']], undefined, '- select -'), "VALUE");
            widgetDD.setOnChange(this._ddChanged.bind(this));

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Boolean"); // add appropriate checks
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('\'true\' or \'false\' depending on whether the chosen radio widget is set to the specified option.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this._ddChanged(this.getFieldValue("RB"));
        },
        onchange: function(){
            if (this.getField('radioButtonListIcon') && this.getField('radioButtonListIcon').imageElement_)
                this.getField('radioButtonListIcon').setTooltip('Radio Button');
        },
        onLoad: function(){
            var id= this.getFieldValue('RB');
            var widget = radiobuttonLists.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']];
            } else {
                opts = radiobuttonOpts[id] || [['', '']];
            }

            // var value = Blockly.JavaScript.valueToCode(this, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            var dd = this.getField("VALUE");
            var value = dd.getValue();

            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                dd.setToSelectOption();
                dd.setWarning(true);
                var closure = dd.onChange;

                dd.setOnChange(function(){
                    dd.setWarning(false);
                    if(closure){
                        closure();
                        dd.setOnChange(closure);
                    }
                })
            }
        },
        _ddChanged: function (val) {
            var fieldDropDown;
            var input = this.getInput("A");
            input.removeField("VALUE");

            if(this.getField('RB').hasBeenSet){
                fieldDropDown = new Blockly.FieldDropdown(radiobuttonOpts[val] || [['', '']], undefined, '- select -');
            } else {
                fieldDropDown = new Blockly.FieldDropdown([['', '']], undefined, '- select -');
                fieldDropDown.setEnabled(false);
            }
            input.appendField(fieldDropDown,'VALUE');
            if (this.rendered) {
                this.initSvg();
                this.render();
            }
        }
    }
}

// everything here should be ES5
function defineGenerators(context, rbs) {
    return {
        'JavaScript': function (block) {
            var id = block.getFieldValue('RB');
            var val = block.getFieldValue('VALUE');
            var rb = rbs.find(function (x) {
                return x.id === id
            });
            if (!rb) {
                console.warn('unable to find radiobuttonlist with id: ' + id);
                return ['undefined', Blockly.JavaScript.ORDER_NONE];
            }

            if(val==='__CLEAR__'){
                val = 'undefined';
            } else {
                val = '"' + val + '"';
            }

            var code = '$(\'[id="' + id + '"] input:checked\').first().attr("id") ===' + val;
            code = "(" + code + ")";
            return [code, Blockly.JavaScript.ORDER_NONE]
        }
    }
}

const RadiobuttonListIsSetTo = function (props) {
    const name = 'snap_radiobutton_is_set_to';
    let widgets = props.widgets.length>0?props.widgets:[props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for(let i in generators){
        Blockly[i][name] = generators[i]
    }

    if(props.widgets.length<1){
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='RB'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}


export default RadiobuttonListIsSetTo