/* global Blockly */
import React from 'react';
import util from '../../../../utils/es5Utils'
import {checkBoxListIcon} from "./index";

function defineGenerators(context) {
    let widgets = util.widgetsOfType(context, 'checkboxlist');
    return {
        /**
         * @return {string}
         */
        "JavaScript": function (block) {
            var id = block.getFieldValue("ID");
            let by = block.getFieldValue("BY");

            // get block value (js-compatible)
            let checkboxValue = Blockly.JavaScript.valueToCode(block, "CB", Blockly.JavaScript.ORDER_NONE);
            // by default, get checkbox value as a text (string constant) from the dropdown selector, i.e. "Option 1"
            let cb = `"${util.parseString(checkboxValue)}"`;
            let checkboxValueInput = block.getInput('CB');
            if (checkboxValueInput.connection.isConnected()) {
                let checkboxValueTargetBlock = checkboxValueInput.connection.targetBlock();
                if (!!checkboxValueTargetBlock && !checkboxValueTargetBlock.isShadow_) {
                    // non-default block is connected, which means the result value is not a text string, but rather a js code
                    cb = checkboxValue;
                }
            }
            let value = Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_NONE);
            var widget = widgets.find(function (x) {
                return x.id === id
            });

            if (widget === undefined) {
                console.warn('unable to find checkbox with id:' + id);
                return "console.warn('unable to find checkbox with id:' + id);\n"
            }

            if (by === 'text') {
                let optionText = cb;
                let code = `$('[id="${id}"] label').each(function(i, obj) {
                                    if ($(obj).text().trim() === ${optionText}) {
                                        $(obj).siblings("input").prop('checked', ${value}).checkboxradio().checkboxradio("refresh");
                                    }
                                });
                            $('[id="${id}"]').trigger("change");
                            `;

                return code;
            } else {
                let optionValue = cb;
                let code = `$('[id="${id}"] input').each(function(i, obj) {
                                if ($(obj).attr('value').trim() === ${optionValue}) {
                                    $(obj).prop('checked', ${value}).checkboxradio().checkboxradio("refresh");
                                }
                            });
                            $('[id="${id}"]').trigger("change");
                            `;
                return code;
            }

        }
    }
}

function defineBlock(context) {
    var checkboxlists = util.widgetsOfType(context, 'checkboxlist');
    var checkboxOpts = {};
    checkboxlists.forEach(function (widget) {
        checkboxOpts[widget.id] = {
            text: widget.properties.checkboxes.map(function (cb) {
                return [cb.name, cb.name]
            }),
            value: widget.properties.checkboxes.map(function (cb) {
                return [cb.properties.value, cb.properties.value]
            })
        }
    });

    return {
        init: function () {
            var widgetDD = new Blockly.FieldWidgetsDropdown(context, "checkboxlist", undefined, '- select -');
            var textValueDD = new Blockly.FieldDropdown([['text', 'text'], ['value', 'value']]);
            this.appendDummyInput()
                .appendField("in")
                .appendField(new Blockly.FieldImage(checkBoxListIcon, 30, 30, "check box list icon", null), 'checkBoxListIcon')
                .appendField(widgetDD, "ID")
                .appendField("set checkbox with")
                .appendField(textValueDD, "BY");
            this.appendValueInput("CB").setCheck("String");
            this.appendValueInput("VALUE")
                .appendField('to')
                .setCheck("Boolean");
            widgetDD.setOnChange(this.updateByWidget.bind(this));
            textValueDD.setOnChange(this.updateByType.bind(this));

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Set a checkbox in the checkbox list to checked or unchecked');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this.update(this.getFieldValue("ID"), this.getFieldValue("BY"));
        },
        onchange: function(){
            if (this.getField('checkBoxListIcon') && this.getField('checkBoxListIcon').imageElement_)
                this.getField('checkBoxListIcon').setTooltip('Checkbox List');
        },
        updateByType: function (val) {
            this.update(this.getFieldValue('ID'), val)
        },
        updateByWidget: function (val) {
            this.update(val, this.getFieldValue('BY'))
        },
        onLoad: function(){
            var id= this.getFieldValue('ID');
            var by= this.getFieldValue('BY');
            var widget = checkboxlists.find(function (x) {return x.id === id});
            var opts;
            if (!widget) {
                console.warn('widget not found: '+id);
                opts = [['','']];
            } else {
                opts = checkboxOpts[id][by] || [['', '']];
            }

            var value = Blockly.JavaScript.valueToCode(this, 'CB', Blockly.JavaScript.ORDER_NONE);
            
            if(!opts.filter(function(opt) { return opt[1] === value }).length){
                console.warn('option not found: '+value);
                var input = this.getInput('CB');
                var valuesDDBlock = input.connection.targetBlock();
                // var dd = valuesDDBlock.getFieldDropdown();
                if (valuesDDBlock.type === 'dropdown_options') {
                    var dd = valuesDDBlock.setFieldDropdown(opts);
                    dd.setToSelectOption();
                    dd.setWarning(true);
                    var closure = dd.onChange;

                    dd.setOnChange(function(){
                        dd.setWarning(false);
                        if(closure){
                            closure();
                            dd.setOnChange(closure);
                        }
                    });
                }
            }
        },
        // update the last input
        update: function (val, by) {

            var input = this.getInput('CB');
            var existingBlock = input.connection.targetBlock();
            var block = this.workspace.newBlock('dropdown_options');
            block.setShadow(true);

            if(this.getField('ID').hasBeenSet){
                var widget = checkboxlists.find(function (x) {return x.id === val});
                var opts;
                if (!widget) {
                    console.warn('widget ' + val + " not found");
                    opts = [['','']]
                } else {
                    opts = checkboxOpts[val][by]
                }
                block.setFieldDropdown(opts, undefined, '- select -');
            } else{
                var dd = block.setFieldDropdown([['','']], undefined, '- select -');
                dd.setEnabled(false);
            }
            input.connection.connect(block.outputConnection);

            // If there was already a block connected to this input, re-connect it to the input.
            // Still needed to connect the shadow block prior so if user then removes the added block,
            // the underlying shadow block still shows the correct drop-down options for that widget and text/ value option
            if(existingBlock && !existingBlock.isShadow()){
                input.connection.connect(existingBlock.outputConnection);
                if (this.rendered) {
                    existingBlock.initSvg();
                    existingBlock.render();
                }
            } else{
                if(this.rendered){
                    block.initSvg();
                    block.render();
                }
            }
        }
    }
}


const SetCheckboxlistOption = function (props) {
    var name = "snap_set_checkboxlist_option";

    let widgets = props.widgets.length > 0 ? props.widgets : [props.defaultWidget];

    Blockly.Blocks[name] = defineBlock(props.context, widgets);
    let generators = defineGenerators(props.context, widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }

    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined)
                ? <field name='ID'>{props.widget.id}</field>
                : null
            }
            <value name="VALUE">
                <shadow type="checkbox"/>
            </value>
        </block>
    )
}

export default SetCheckboxlistOption