/* global Blockly, Util */
import React, { Component } from 'react';
import NumberTextInput from "../assets/number_text_input.PNG";
import BooleanInput from "../assets/boolean_input.PNG";
import TextLabel from "../assets/text_label.PNG";
import _ from 'underscore';
import ModalButtons from "../../ModalButtons";

class DefineArguments extends Component {
    constructor (props) {
        super(props);
        this.mountDiv = React.createRef();
        this._addInputBoolean = this._addInputBoolean.bind(this);
        this._addInputValue = this._addInputValue.bind(this);
        this._addTextLabel = this._addTextLabel.bind(this);
        this._addInput = this._addInput.bind(this);
        const { values = {} } = props;
        this.state = {
            workspace: null,
            blockToSave: null,
            blockHeight: null,
            args: values,
            argId: _.keys(values).length + 1 || 1,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            step: this.props.step
        }
    }

    _createWorkspace () {
        let workspace = Blockly.inject(this.mountDiv.current, {
            toolbox: <xml></xml>,
            media: "/vendor/pxt-blockly/media/",
            grid: {
                spacing: 50,
                length: 51,
                colour: "#555559",
                snap: false
            },
            zoom: {
                controls: true,
                startScale: 0.9,
                maxScale: 1.5,
                minScale: 0.3,
                scaleSpeed: 1.2,
                wheel: true
            },
            trashcan: false,
            sounds: false,
            scrollbars: true
        });
        workspace.isCreateBlockWorkspace = true;
        Blockly.setTheme(Blockly.Themes.SOTI);
        if (document.getElementById("saveBlockWorkspace")) {
            let zoomControls = document.getElementById("saveBlockWorkspace").getElementsByClassName("blocklyZoom")[0];
            let actionButtonsBackground = zoomControls.getElementsByClassName("icon-background")[0];
            actionButtonsBackground.setAttribute('height', 135);
            actionButtonsBackground.setAttribute('y', 14);
        }
        return workspace;
    }

    _renderBlockToSave (workspace) {
        const { values = {} } = this.props;

        const blockToSave = workspace.newBlock(this.props.type);
        blockToSave.setLabel(this.props.blockName);
        if (!_.isEmpty(values)) {
            this._setArgs(values);
            blockToSave.updateShapeMyBlock({
                name: this.props.blockName,
                args: values
            });
        }
        if (this.props.type === "blank_block") {
            let xml = Blockly.Xml.textToDom(this.props.block);
            let innerBlock = Blockly.Xml.domToBlock(xml.lastChild, workspace);
            blockToSave.getInput("DEFINITION").setVisible(true);
            let blockToSaveLocation = blockToSave.getInput("DEFINITION");
            blockToSaveLocation.connection.connect(innerBlock.previousConnection);
            blockToSave.getInput("DEFINITION").setVisible(false);
        }
        blockToSave.initSvg();
        blockToSave.render();
        workspace.scrollCenter();
        return blockToSave;
    }

    _setArgId (argId) {
        this.setState({ argId })
    }

    _setArgs (args) {
        this.setState({ args });
    }

    _addInput (type, name, value = "") {
        let that = this;
        let { blockToSave, workspace, argId, args, blockHeight } = this.state;
        const { blockName } = this.props;
        const inputArg = name ? name.split('_')[2] : argId;
        !name && this._setArgId(++argId);
        let inputName = name || `INPUT_ARG_${inputArg}`;
        if (type === "label" && !inputName.includes("-readonly")) {
            inputName += "-readonly";
        }

        const inputDom = Util.dom('input', {
            value,
            class: 'customHtmlInput',
            id: `customHtmlInput-${inputArg}`,
            'data-input-id': inputName,
            autofocus: true,
            autocomplete: "off",
            maxLength: 15
        });
        inputDom.onkeypress = e => {
            e = e || window.event;
            let charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
            let charStr = String.fromCharCode(charCode);
            if (type === "label") {
                return true;
            }
            if (/[a-zA-Z]/.test(charStr) || (/\d/.test(charStr) && e.target.value.length && document.getSelection().toString() !== e.target.value)) {
                return true;
            } else {
                return false;
            }
        };
        blockToSave.type !== "custom_js_block" && blockToSave.switchVisibility(false);
        const removeIconDom = Util.dom('a', { class: 'customHtmlInputRemove' });
        var inputContainerDom = Util.dom('div', { class: "customHtmlInputContainer" }, [inputDom, removeIconDom]);
        var inputField = new Blockly.FieldHTML(inputContainerDom, { height: blockHeight, width: 150, backgroundColor: blockToSave.getColour() }, function (e) {
            switch (e.type) {
                case "pointerdown":
                    if ((e.target === removeIconDom)) {
                        let inputBlock = blockToSave.getInput(inputName);
                        inputBlock && blockToSave.removeInput(inputName);
                        delete args[inputName];
                        this.setState({args: args})
                    }
                    break;
                case 'keyup':
                    if (e.keyCode !== 13) {
                        break;
                    }
                    // falls through
                case 'focusout':
                    var reservedKeywords = ["", "await", "break", "case", "catch", "class", "const", "continue", "debugger", "default", "delete", "do", "else", "enum", "export", "extends", "false", "finally", "for", "function", "if", "implements", "import", "in", "instanceof", "interface", "let", "new", "null", "package", "private", "protected", "public", "return", "super", "switch", "static", "this", "throw", "try", "true", "typeof", "var", "void", "while", "with", "yield"];
                    if (type !== "label") {
                        if (reservedKeywords.includes(e.target.value)) {
                            e.target.value = `input${e.target.value}`;
                        }
                        let allValues = _.values(args).map((arg) => {
                            return arg.value;
                        });
                        var repeatIndex = 0;
                        while (allValues.includes(e.target.value)) {
                            e.target.value = `${e.target.value.replace(/\d/g, '')}${++repeatIndex}`;
                        }
                    }
                    
                    setTimeout(function () {
                        let inputBlock = blockToSave.getInput(inputName);
                        if (!inputBlock) return;
                        that._setArgs({
                            ...args,
                            [inputName]: {
                                value: e.target.value,
                                type
                            }
                        });
                        inputBlock && blockToSave.removeInput(inputName);
                        setTimeout(() => {
                            blockToSave.updateShapeMyBlock({
                                name: blockName,
                                args: {
                                    ...args,
                                    [inputName]: {
                                        value: e.target.value,
                                        type
                                    }
                                }
                            });
                        }, 0);

                    }, 100)
            }
            workspace.scrollCenter();
        }.bind(this));
        setTimeout(() => {
            const argInputBox = document.querySelector(`#customHtmlInput-${inputArg}`)
            if (!argInputBox) { return; }
            argInputBox.focus()
            argInputBox.select()
        }, 100);

        blockToSave.updateShapeMyBlock({
            name: blockName,
            args: {
                ...args,
                [inputName]: {
                    value: inputField,
                    type: 'html'
                }
            }
        });
        blockToSave.initSvg();
        blockToSave.render();
        workspace.scrollCenter();
    }

    _addInputValue () {
        setTimeout(() => {
            this._addInput("number_text")
        }, 50)
    }

    _addInputBoolean(){
        setTimeout(() => {
            this._addInput("boolean")
        }, 50)
    }

    _addTextLabel () {
        setTimeout(() => {
            this._addInput("label")
        }, 50)
    }

    componentDidMount () {
        const workspace = this._createWorkspace();
        let blockToSave = this._renderBlockToSave(workspace);
        const { height: blockHeight } = blockToSave;
        this.setState({ blockToSave, workspace, blockHeight });

        // set block to save and its descendants to immovable
        let descendants = blockToSave.getDescendants();
        descendants.map((descendant) => {
            if (descendant.id !== blockToSave.id) {
                descendant.setMovable(false);
            }
        });

        workspace.addChangeListener(blocklyEvent => {
            if (blocklyEvent.element === 'click') {
                try {
                    blockToSave.setSelectedBlock(false);
                    let clickedBlock = workspace.getBlockById(blocklyEvent.blockId);
                    if (clickedBlock && clickedBlock.type !== this.props.type &&
                        clickedBlock.parentBlock_.id === blockToSave.id &&
                        !(clickedBlock.nextConnection && clickedBlock.previousConnection)) {
                        var inputSlot = blockToSave.getInputWithBlock(clickedBlock);
                        blockToSave.removeInput(inputSlot.name);
                        const { args = {} } = this.state;
                        this._addInput(clickedBlock.type, inputSlot.name, args[inputSlot.name] && args[inputSlot.name].value);
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        });

        // disable undo through ctrl+Z
        workspace.MAX_UNDO = 0;

        blockToSave.setTooltip(this.props.description);

        window.onresize = () => {
            try {
                this.setState({screenHeight: window.innerHeight});
                this.setState({screenWidth: window.innerWidth});
                if (this.state.step === 2) {
                    this.state.workspace.scrollCenter();
                }
            } catch (e) {
                console.log(e);
            }
        };
    }

    componentDidUpdate () {
        const modalWorkspace = document.getElementById("saveBlockWorkspace");
        const blocklyArea = document.getElementById("blocklyArea");
        const injectionDiv = blocklyArea.getElementsByClassName("injectionDiv")[0];
        modalWorkspace.appendChild(injectionDiv);
    }

    componentWillUnmount() {
        this.state.workspace.dispose();
    }

    render() {
        const { handleBack, handleNext } = this.props;
        const { args } = this.state;
        const {blockToSave, workspace} =  this.state;
        const saveBlockParams = (
            <div id="saveBlockParams">
                <div className={"save-button-flex-container"} onClick={this._addInputValue}>
                    <div className={"save-button-flex-container-content"}>
                        <img src={NumberTextInput}/>
                        <p className={"save-button-bold"}>Number or Text Input</p>
                    </div>
                </div>
                <div className={"save-button-flex-container"} onClick={this._addInputBoolean}>
                    <div className={"save-button-flex-container-content"}>
                        <img src={BooleanInput}/>
                        <p className={"save-button-bold"}>True or False Input</p>
                    </div>
                </div>
                <div className={"save-button-flex-container"} onClick={this._addTextLabel}>
                    <div className={"save-button-flex-container-content"}>
                        <img src={TextLabel}/>
                        <p className={"save-button-bold"}>Text Label</p>
                    </div>
                </div>
            </div>
        );
        const saveBlockWorkspace = (
            <div id="saveBlockWorkspace" ref={this.mountDiv}></div>
        );

        return (
            <div className="modal-wrapper">
                <div className={"modal-header"}>
                    <h4>{this.props.title}</h4>
                    <br></br>
                    <div className={"icon"}>1</div>
                    <div className={"text-description"}>Block Details</div>
                    <div className={"straight-line"}>———</div>
                    <div className={"icon-active"}>&emsp;</div>
                    <div className={"text-description-active"}>Add Inputs</div>
                    <div className={"straight-line"}>———</div>
                    <div className={"icon"}>3</div>
                    <div className={"text-description"}>Assign {this.props.JSFlag}</div>
                </div>
                <div id={"blocklyArea"} className={"modal-body"}>
                    {this.state.screenWidth > 768 ?
                        [saveBlockParams, saveBlockWorkspace]
                        :
                        [saveBlockWorkspace, saveBlockParams]
                    }
                </div>
                <div className="modal-footer">
                    <ModalButtons step={2} back={handleBack} next={() => {
                        blockToSave.updateShapeMyBlock({
                            name: this.props.blockName,
                            args: args
                        });
                        handleNext(args, Blockly.Xml.workspaceToDom(workspace));}
                    }/>
                </div>
            </div>
        );
    }
}

export default DefineArguments;
