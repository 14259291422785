/* global Blockly */
import React from 'react';
import {getPagesFromContext} from "../helpers";

function defineBlock(context) {
    const pages = getPagesFromContext(context);

    let forms = [];
    let formsIdList = [];
    pages.forEach((element) => {

        let formFields = element[1].split("<<<>>>");
        let formId = formFields[0];
        if (!formsIdList.includes(formId)) {
            let formName = element[0].split(" > ");
            formName = formName[0];
            forms.push([formName,element[1]]);
            formsIdList.push(formId);
        }
    });

    return {
        init: function () {
            this.appendDummyInput()
                .appendField("reset form ")
                .appendField(new Blockly.FieldDropdown(forms), "FORMSLUG");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.APP_ACTIONS_HUE);
            this.setTooltip('Reset a form or a screen in the application.');
            this.setHelpUrl(Blockly.BASE_HELP_URL + "#app-actions");
        }
    }
}

function defineGenerators() {
    return {
        "JavaScript": function (block) {
            let formSlug = block.getFieldValue('FORMSLUG');
            let formFields = formSlug.split("<<<>>>");
            let formId = formFields[0];
            let pageId = formFields[1];

            let code = `
                BlocklyPromises.push(actionsModule.getExecuteActionPromise({
                    action: {
                        actionType: "resetForm"
                    },
                    formDataGenyId: "${formId || pageId}",
                }))
            `;

            return code;
        }
    }
}

const ResetPage = function (props) {

    let name = "snap_action_reset_page";

    Blockly.Blocks[name] = defineBlock(props.context);

    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if(!getPagesFromContext(props.context).length){
        return null;
    }


    return (
        <block type={name}></block>
    )
};

export default ResetPage;
