/* global Blockly */
import React from 'react';


function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput("CATEGORY")
                .appendField("get")
                .appendField(new Blockly.FieldDropdown([['day', 'date'], ['month', 'month'], ['year', 'year']]), "DATE_FIELD");

            this.appendDummyInput("FROM")
                .appendField("from");

            let valueInput = this.appendValueInput('DATE')
                .setCheck("Date");

            let valueBlock = this.workspace.newBlock("date");
            valueBlock.setShadow(true);
            valueInput.connection.connect(valueBlock.outputConnection);

            this.setOutput(true, "Number");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('Get the day/month/year of the provided date as a number');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
            this.setOnChange(this.changeHandler);
        },
        changeHandler: function() {
            if (this.getInput("DATE").connection != null) {
                if (this.getInput("DATE").connection.targetBlock().type == "tableview_selected_row") {
                    this.getInput("DATE").connection.disconnect();
                }
            }
        },
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let selected_field = block.getFieldValue("DATE_FIELD");
            let code = Blockly.JavaScript.valueToCode(block, 'DATE', Blockly.JavaScript.ORDER_FUNCTION_CALL);

            switch (selected_field) {
                case "date":
                    code = '' + code + '.getDate()';
                    break;
                case "month":
                    code = '' + code + '.getMonth() + 1';
                    break;
                case "year":
                    code = '' + code + '.getFullYear()';
                    break;
            }

            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

function GetDayFromDate(props) {
    const name = "get_day_from_date";
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return (
        <block type={name}></block>
    )
}
export default GetDayFromDate;
