/* global Blockly */
import React from 'react';
/*
import util from "../../../../utils/es5Utils";
import {textBoxIcon} from "./index";
*/

import { defaultSound } from './defaultSound';

function defineBlock() {

    return {
        init: function () {
            let testButton = {primary:'#ffffff', secondary:'#000000', tertiary:'#C1C1C1', text:'#7200e7'};

            this.appendValueInput("VALUE")
                .appendField("play ")
                .appendField(new Blockly.FieldDropdownSearch([["scan", "0"], ["beep1", "1"], ["beep2", "2"],["beep3", "5"], ["beep4", "3"], ["beep5", "6"], ["beep6", "7"], ["beep7", "8"], ["woosh1", "4"], ["woosh2", "9"], ["games1", "10"], ["games2", "11"], ["games3", "12"], ["games4", "13"], ["doodee", "14"], ["pause", "15"], ["unpause", "16"], ["ding1", "17"], ["ding2", "18"], ["ding3", "19"], ["ding4", "20"], ["ding5", "21"], ["ding6", "22"], ["ding7", "23"], ["ding8", "24"], ["ding9", "25"], ["ding10", "26"]], undefined, undefined), "SOUND")
                .appendField(" at volume ")
                .setCheck(["Number"])
            this.appendDummyInput("TEXT")
                .appendField("%  ")
            this.appendDummyInput('TEST')
                .appendField(new Blockly.FieldButton( ' Test ', testButton, this._testSound.bind(this)),'TEST')
                .setOnNewRow(false);
            this.appendDummyInput("FINISHED")
                .appendField("on sound finished")
                .setOnNewRow(true);
            
            this.appendStatementInput('CALLBACK');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Play from a list of pre-determined sounds');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },
        _testSound: function (){
            let sound = this.getFieldValue('SOUND');
            let firstSound = defaultSound[parseInt(sound)];
            var snd = new Audio(firstSound);
            let value = Blockly.JavaScript.valueToCode(this, 'VALUE', Blockly.JavaScript.ORDER_NONE) / 100;
            snd.volume = value;
            snd.play();
        }
    }
}



function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let sound = this.getFieldValue('SOUND');
            let firstSound = defaultSound[parseInt(sound)];
            let callback = Blockly.JavaScript.statementToCode(block, 'CALLBACK');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE) / 100;
            let code =  `(function(){
                        var snd = new Audio("${firstSound}");
                        snd.volume = ${value};
                        snd.play();
                        snd.onended = function() {
                            ${callback};
                        };
                        })();\n`
            return code;
        }
    }
}


const PlayBeep = function () {
    let name = 'snap_play_beep';

    Blockly.Blocks[name] = defineBlock();
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }
 
    return (
        <block type={name}>
            <value name="VALUE">
                <shadow type="math_whole_number"><field name="NUM">100</field></shadow>
            </value>
        </block>
    )
}

export default PlayBeep
