/* global Blockly */
import React from 'react';

function defineBlock(){
    return {
        init:function(){
            this.appendValueInput("FIRST");
            this.appendDummyInput()
                .appendField("is")
                .appendField(new Blockly.FieldDropdown([['before','<'],['after','>'],['same date as','==']]),"COMPARISON");
            this.appendValueInput("SECOND");
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true,"Boolean");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('Compare two dates');
            this.setHelpUrl(Blockly.BASE_HELP_URL+'#dates');
        }
    }
}

function defineGenerator(){
    return function(block){
        let funcName = Blockly.JavaScript.provideFunction_('toDate',[
            'function '+Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +'(s) {',
            's =  new Date(s);',
            's.setHours(0);',
            's.setMinutes(0);',
            's.setSeconds(0);',
            's.setMilliseconds(0);',
            'return s',
            '}'
        ]);

        let first = funcName+"("+Blockly.JavaScript.valueToCode(block,'FIRST',Blockly.JavaScript.ORDER_NONE)+")";
        let second = funcName+"("+Blockly.JavaScript.valueToCode(block,'SECOND',Blockly.JavaScript.ORDER_NONE)+")";
        let comparison = block.getFieldValue('COMPARISON');
        let code;

        code = '(('+first+'-'+second+')'+comparison+'0)';

        return [code,Blockly.JavaScript.ORDER_ATOMIC];
    }
}


const DateIsBefore = function() {
    const name = "date_is_before";

    Blockly.Blocks[name] = defineBlock();
    Blockly.JavaScript[name] = defineGenerator();

    return (
        <block type={name}>
            <value name="SECOND"><shadow type="date"/></value>
        </block>
    )
}

export default DateIsBefore