/* global Blockly */
/* global Util */
import React from 'react';

function defineBlock(context) {
    return {
        outputType: null,
        init: function () {
            this.appendValueInput("VALUE")
                .appendField("convert")
            this.appendDummyInput()
                .appendField("to");
            this.appendValueInput("DECIMALS")
                .setCheck("Number")
                //.setVisible(false);
            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown([["integer", "integer"], ["decimals", "decimals"], ["floating", "floating"]], undefined, undefined), "OUTPUTTYPE");
            
            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Number"); 
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.MATH_HUE);
            this.setTooltip(function() {
                let outputType = this.getFieldValue('OUTPUTTYPE');
                let allFirstText = outputType == "floating" ? "a floating point" :
                    outputType == "decimals" ? "a decimal number with speficied places" :
                    "integer number";
                return `Convert a value to ${allFirstText}`;
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#math');
            this.setOnChange(this.changeHandler);
        },

        // show decimals input on change
        changeHandler: function() {
            let outputType = this.getFieldValue('OUTPUTTYPE');
            if (this.outputType == outputType) 
                return;
            this.outputType = outputType;
            let decimalsVisible = outputType == "decimals";
            let decimals = this.getInput('DECIMALS');
            decimals.setVisible(decimalsVisible);
            if (decimalsVisible) {
                // make sure there is connected value
                if (!(decimals.connection && decimals.connection.isConnected())) {
                    let decimalsNumberField = this.workspace.newBlock("math_number");
                    decimalsNumberField.setFieldValue(2, "NUM");
                    decimalsNumberField.setShadow(true);
                    decimals.connection.connect(decimalsNumberField.outputConnection);
                    decimalsNumberField.initSvg();
                    decimalsNumberField.render();
                }
            }
            this.initSvg();
            this.render();
        }
    }
}
function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let outputType = this.getFieldValue('OUTPUTTYPE');
            let value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
            let parseFunction = outputType == "integer" ? "parseInt" : "parseFloat";
            let code = `(() => {
                var value = ${parseFunction}(${value});
                if (isNaN(value))
                    value = 0;
                `;
            if (outputType == "decimals") {
                let decimals = Blockly.JavaScript.valueToCode(block, 'DECIMALS', Blockly.JavaScript.ORDER_NONE);
                code += `value = value.toFixed(${decimals});
                `;
            }
            code += `return value;
            })()`;
            return [code, Blockly.JavaScript.ORDER_ATOMIC]
        }
    }
}
const MathToNumber = function (props) {
    let name = 'math_to_number';
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }
    return (
        <block type='math_to_number'>
            <value name="VALUE">
                <shadow type="fixed_width_text">
                    <mutation length="10"></mutation>
                    <field name="TEXT">1.234</field>
                </shadow>
            </value>
        </block>
    )
}

export default MathToNumber