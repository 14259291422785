import { ActionTypes } from '../actions';

const defaultState = {
    inTraffic: false,
    isInitialized: false,
    isMockData: true,
    myBlocks: [],
    blockLibrary: []
};

export default (state = defaultState, action) => {
    try {


        switch(action.type) {

            case ActionTypes.INITIALIZE_BLOCK:
                return {
                    ...state,
                    inTraffic: true
                };

            case ActionTypes.LOADED_JS_BLOCKS:
                return {
                    ...state,
                    jsBlocks: action.payload,
                    isInitialized: true,
                    isMockData: false
                };
            case ActionTypes.LOADED_LIBRARIES:
                return {
                    ...state,
                    blockLibrary: action.payload,
                    isInitialized: true,
                    isMockData: false
                };

            case ActionTypes.FAILED_MY_BLOCKS:
                return {
                    ...state,
                    context: action.payload,
                    isInitialized: true,
                    isMockData: true
                };

            case ActionTypes.LOADED_NEW_VARIABLE: {
                let environmentVariables = action.payload;
                let context = Object.assign({}, state.context, {environmentVariables});
                return {
                    ...state,
                    context,
                };}

            case ActionTypes.ON_SEARCH:
                return {
                    ...state,
                    searchQuery: action.payload
                };

            case ActionTypes.DONE_SAVE_MY_BLOCK:
                return {
                    ...state,
                    blockLibrary: state.blockLibrary.map(library => {
                        if (library.id === action.payload.library) {
                            library.blocks = library.blocks || [];
                            library.blocks.push(action.payload);
                        }
                        return library;
                    })
                };

            case ActionTypes.DOING_SAVE_JS_BLOCK:
                return {
                    ...state,
                    isSavingJsBlock: true
                };

            case ActionTypes.DONE_SAVE_JS_BLOCK:
                return {
                    ...state,
                    isSavingJsBlock: false,
                    blockLibrary: state.blockLibrary.map(library => {
                        if (library.id === action.payload.library) {
                            library.blocks = library.blocks || [];
                            library.blocks.push(action.payload);
                        }
                        return library;
                    })
                };

            case ActionTypes.DONE_SAVE_LIBRARY:
                return {
                    ...state,
                    blockLibrary: [
                        action.payload,
                        ...state.blockLibrary,
                    ]
                };

            case ActionTypes.DONE_DELETE_LIBRARY: {
                let pos = state.blockLibrary.map((e) => e.id).indexOf(action.payload.libraryId)
                delete state.blockLibrary[pos];

                return {
                    ...state,
                    blockLibrary: state.blockLibrary.filter(function (el) {
                        return el != null;
                    })
                };}

            case ActionTypes.DONE_DELETE_BLOCK:
                console.log(state)
                return {
                    ...state,
                    blockLibrary: state.blockLibrary.map(library => {
                        if (library.id === action.payload.libraryId) {
                            library.blocks = library.blocks || [];
                            for (let i = 0; i < library.blocks.length; i++) {
                                if (library.blocks[i].id === action.payload.blockId) {
                                    library.blocks.splice(i, 1);
                                }
                            }
                        }
                        return library;
                    })
                };

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
