/* global Blockly */
import React from 'react';
import {fileuploadIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("remove ")
                .appendField(new Blockly.FieldDropdown([["all uploaded files", "all"], ["first uploaded file", "first"], ["last uploaded file", "last"]], undefined, undefined), "ALLFIRST")
                .appendField(" from")
                .appendField(new Blockly.FieldImage(fileuploadIcon, 30, 30, "fileupload icon", null), 'fileuploadIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "fileupload", undefined, '- select -'), "ID");
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function() {
                let allFirst = this.getFieldValue('ALLFIRST');
                let allFirstText = allFirst == "first" ? "first uploaded file" : allFirst == "last" ? "last uploaded file" : "all uploaded files";
                return `Remove ${allFirstText} from file upload widget`;
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
            if (this.getField('fileuploadIcon') && this.getField('fileuploadIcon').imageElement_)
                this.getField('fileuploadIcon').setTooltip('FileUpload control');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let id = block.getFieldValue('ID');
            let allFirst = this.getFieldValue('ALLFIRST');
            let method = allFirst == "first" ? "clearFirst" : allFirst == "last" ? "clearLast" : "clearAll";
            let code = `await Snap.widgets.fileupload.${method}("${id}");\n`;
            return code;
        }
    }
}

const ClearFileUpload = function (props) {
    let name = 'snap_clear_fileupload';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    if (props.widgets.length < 1) {
        return null;
    }
    return (
        <block type={name}>
            {
                props.widget && (props.widget.id !== undefined)
                    ? <field name="ID">{props.widget.id}</field>
                    : null
            }
            <value name="VALUE">
                <shadow type="text"/>
            </value>
        </block>
    )
}

export default ClearFileUpload
