/* global Blockly */
import React from 'react';
import { userListIcon } from "./index";


function defineBlock(context) {
    return {
        init: function () {
            let selectUserListDropdown = new Blockly.FieldWidgetsDropdown(context, 'userlist', undefined, '- select -');

            this.appendDummyInput('WIDGET')
                .appendField('get')
                .appendField(new Blockly.FieldImage(userListIcon, 30, 30, "userList icon", null), 'userListIcon')
                .appendField(selectUserListDropdown, 'ID')
                .appendField('user list');

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, "Array");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip('Return the array of selected users from a User List widget.');
            this.setHelpUrl(`${Blockly.BASE_HELP_URL}#widget-values`);
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            let userListDropdownId = block.getFieldValue('ID');
            var code = "UserListModule.getSelectedUsers('" + userListDropdownId + "')";
            return [code, Blockly.JavaScript.ORDER_NONE];;
        }
    }
}

const GetUserList = function (props) {
    const name = 'snap_userlist_get_user_list';

    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators();
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }

    return props.widgets.length ? <block type={name}></block> : null;
}

export default GetUserList