import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { ActionTypes, Actions } from '../actions';
import {loadInitInfo, sendCodeToParent, closeBlockly, sendValidatedToParent, getNewVariable} from './network';
import { dummyState } from "../dummyState";

// All data sagas to add to middleware.
export default [
    // [ActionTypes.LOAD_DATA, loadData]
    [ActionTypes.GET_INIT_INFO, getInitInfo],
    [ActionTypes.DO_SEND_CODE_TO_PARENT, doSendCodeToParent],
    [ActionTypes.DO_CLOSE, doClose],
    [ActionTypes.DO_SEND_VALIDATED_TO_PARENT, doSendValidatedToParent],
    [ActionTypes.GET_NEW_VARIABLE, createNewVariable]
];

function * getInitInfo () {
    yield put(Actions.loadingInitInfo());
    const response = yield call(loadInitInfo);
    if (!response.successful) {
        console.error("error => ", response.error);
        yield put(Actions.failedInitInfo(dummyState));
        // handle error here
        // yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInitInfo(response.data));
    }
}

function * createNewVariable(payload){
    yield put(Actions.loadingNewVariable());
    const response = yield call(getNewVariable, payload);
    if(!response.successful){
        console.error('error => ', response.error);
        yield put(Actions.failedNewVariable());
    } else {
        yield put (Actions.loadedNewVariable(response.data));
    }
}


function * doSendCodeToParent ({ payload }) {
    yield put(Actions.doingSendCodeToParent());
    const response = yield call(sendCodeToParent, payload);
    yield call(delay, 10);
    yield put(Actions.doClose());
    if (!response.successful) {
        console.error("error => ", response.error);
        // handle error here
        // yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSendCodeToParent(response.data));
    }
}

function * doSendValidatedToParent({ payload }){
    yield put(Actions.doingSendValidatedToParent(payload));
    yield call(sendValidatedToParent, payload);
    yield put(Actions.doneSendValidatedToParent());
}

function * doClose () {
    yield put(Actions.doingClose());
    const response = yield call(closeBlockly);
    if (!response.successful) {
        console.error("error => ", response.error);
        // handle error here
        // yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneClose(response.data));
    }
}