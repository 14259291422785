/* global Blockly */
import React from 'react';
import {fileuploadIcon} from "./index";

function defineBlock(context) {
    return {
        init: function () {
            this.appendDummyInput()
                .appendField("get ")
                .appendField(new Blockly.FieldDropdownSearch([["base64", "path"], ["filename", "fullname"], ["size", "size"], ["extension", "extension"]], undefined, undefined), "PROPERTY")
                .appendField(" of ")
                .appendField(new Blockly.FieldDropdown([["first uploaded file", "first"], ["last uploaded file", "last"]], undefined, undefined), "ALLFIRST")
                .appendField(" from")
                .appendField(new Blockly.FieldImage(fileuploadIcon, 30, 30, "fileupload icon", null), 'fileuploadIcon')
                .appendField(new Blockly.FieldWidgetsDropdown(context, "fileupload", undefined, '- select -'), "ID")

            this.setPreviousStatement(false);
            this.setNextStatement(false);
            this.setOutput(true, ["String"]);
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.WIDGET_VALUES_HUE);
            this.setTooltip(function() {
                let property = this.getFieldValue('PROPERTY');
                let propertyText = property == "fullname" ? "filename" : property;
                return `Get the ${propertyText} of the first uploaded file for file upload widget`;
            }.bind(this));
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        },

        onchange: function(){
           if (this.getField('fileuploadIcon') && this.getField('fileuploadIcon').imageElement_)
               this.getField('fileuploadIcon').setTooltip('FileUpload control');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function(block){
            let id = block.getFieldValue('ID');
            let property = block.getFieldValue('PROPERTY');
            let method =  this.getFieldValue('ALLFIRST') == "last" ? "getLastUploadedItem" : "getFirstUploadedItem";
            let code = `(() => {
                var uploadedItems = Snap.widgets.fileupload.${method}("${id}");
                if (uploadedItems.length)
                    return uploadedItems.map(file => file.${property})[0];
                return "";
            })()`;
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const GetUploadedValue = function(props) {
    const name = 'snap_get_uploaded_value';
    Blockly.Blocks[name] = defineBlock(props.context, props.widgets);
    let generators = defineGenerators(props.context, props.widgets);
    for (let i in generators) {
        Blockly[i][name] = generators[i];
    }

    if (props.widgets.length < 1) {
        return null;
    }
    return (
        <block type={name}>
            {props.widget && (props.widget.id !== undefined) ?
                <field name='ID'>{props.widget.id}</field>
                : null
            }
        </block>
    )
}

export default GetUploadedValue
