/* global Blockly */
import React, {Component} from 'react';
import ModalButtons from "../../ModalButtons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import {blockToXmlSanitized} from "../../../utils";

class AssignArguments extends Component {
    constructor (props) {
        super(props);
        this.state = {
            workspace: null,
            blockToSave: null,
            inputParameters: {},
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            undoStack: [],
            redoStack: [],
            eventList: []
        };
        this.mountDiv = React.createRef();
        this.restoreUndo = this.restoreUndo.bind(this);
        this.restoreRedo = this.restoreRedo.bind(this);
    }

    _createWorkspace () {
        let workspace = Blockly.inject(this.mountDiv.current, {
            toolbox: <xml></xml>,
            media: "/vendor/pxt-blockly/media/",
            grid: {
                spacing: 50,
                length: 51,
                colour: "#555559",
                snap: false
            },
            zoom: {
                controls: true,
                startScale: 0.9,
                maxScale: 1.5,
                minScale: 0.3,
                scaleSpeed: 1.2,
                wheel: true
            },
            trashcan: false,
            sounds: false,
            scrollbars: true
        });
        workspace.isCreateBlockWorkspace = true;
        Blockly.setTheme(Blockly.Themes.SOTI);
        if (document.getElementById("saveBlockWorkspace")) {
            let zoomControls = document.getElementById("saveBlockWorkspace").getElementsByClassName("blocklyZoom")[0];
            let actionButtonsBackground = zoomControls.getElementsByClassName("icon-background")[0];
            actionButtonsBackground.setAttribute('height', 189);
            actionButtonsBackground.setAttribute('y', -40);
        }
        this.setState({workspace: workspace});
        return workspace;
    }

    _renderBlockToSave (workspace) {
        let blockDom = Blockly.Xml.textToDom(this.props.xmlText);
        blockDom = blockToXmlSanitized(blockDom);
        let blockToRender = Blockly.Xml.domToBlock(blockDom.firstChild, workspace);
        blockToRender.initSvg();
        blockToRender.render();

        this.setState({blockToSave: blockToRender});
        workspace.render();
        workspace.scrollCenter();
        this._convertShadowToRegular(workspace, blockToRender);
    }

    _resizeWorkSpace(workspace) {
        // resize the Blockly workspace to fit the whole modal body
        let blocklyArea = document.getElementById('assignArea');
        let blocklyDiv = document.getElementById('saveBlockWorkspace');
        if (blocklyDiv) {
            blocklyDiv.style.removeProperty("width");

            let element = blocklyArea;
            let x = 0;
            let y = 0;
            do {
                x += element.offsetLeft;
                y += element.offsetTop;
                element = element.offsetParent;
            } while (element);

            // position blocklyDiv over blocklyArea
            blocklyDiv.style.left = x + 'px';
            blocklyDiv.style.top = y + 'px';
            blocklyDiv.style.width = blocklyArea.offsetWidth + 'px';
            // blocklyDiv.style.height = blocklyArea.offsetHeight + 'px';
            Blockly.svgResize(workspace);
            workspace.scrollCenter();
        }
    }

    _convertShadowToRegular(workspace, blockToRender) {
        let blockToSave = blockToRender;
        let inputList = blockToSave.inputList;
        this.lockInputs(blockToSave);
        for (let i = 0; i < inputList.length; i++) {
            setTimeout(() => {
                if (inputList[i] && inputList[i].name.includes("INPUT_ARG") && !inputList[i].name.includes("readonly")) {
                    let label = inputList[i].connection.targetConnection.sourceBlock_.getLabel();
                    let type = inputList[i].connection.check_;
                    let parentName = inputList[i].name;
                    let inputBlock =  this._createInputBlock(label, parentName, type, workspace);
                    inputList[i].connection.connect(inputBlock.outputConnection);
                    this._updateInputParaList(inputBlock.id, inputList[i], null,false)
                } else if (inputList[i] && inputList[i].name.includes("INPUT_ARG")) {
                    let inputName = inputList[i].name;
                    let nextInputName = null;
                    if (i + 1 < inputList.length) {
                        nextInputName = inputList[i + 1].name;
                    }
                    if (inputList[i].connection.targetConnection) {
                        let inputText = inputList[i].connection.targetConnection.sourceBlock_.getLabel();
                        blockToSave.removeInput(inputName);
                        blockToSave.appendDummyInput(inputName)
                            .appendField(inputText, inputName);
                        if (nextInputName) {
                            blockToSave.moveInputBefore(inputName, nextInputName)
                        }
                    }
                }
            }, i);
        }
    }

    _updateInputParaList (blockId, input, idToRemove, isRemove) {
        let inputParameters = this.state.inputParameters;
        if (isRemove) delete inputParameters[idToRemove];
        inputParameters[blockId] = input;
        this.setState({inputParameters: inputParameters});
    }

    // create a new input block that can be inserted into a parent block
    _createInputBlock(label, parentName, type, workspace) {
        if (type) {
            var inputBlock;
            (type[0] !== "Argument_Boolean") ? inputBlock = workspace.newBlock("number_text") : inputBlock = workspace.newBlock("boolean");
            inputBlock.setLabel(label)
            inputBlock.setParentName(label);
            inputBlock.initSvg();
            inputBlock.render();
            return inputBlock;
        }
    }

    // if a block is dragged, duplicate the block so that it can be dragged
    // into the block's functionality
    _addInputBlockDragListener(workspace) {
        Blockly.mainWorkspace.addChangeListener(blocklyEvent => {
            this.recordEvent(blocklyEvent);
            switch (blocklyEvent.type) {
                case Blockly.Events.END_DRAG: {
                    if (this.state.inputParameters[blocklyEvent.blockId]) {
                        let draggedInput = this.state.inputParameters[blocklyEvent.blockId]
                        let label = draggedInput.connection.shadowDom_.childNodes[0].label;
                        let type = draggedInput.connection.check_;
                        let parentName = draggedInput.name;

                        // create new draggable block
                        let inputBlock = this._createInputBlock(label, parentName, type, workspace);
                        draggedInput.connection.connect(inputBlock.outputConnection);

                        // update parameter list
                        this._updateInputParaList(inputBlock.id, draggedInput, blocklyEvent.blockId, true);
                    }

                    // dispose any blocks that are not nested inside the main block
                    let topBlocks = workspace.getTopBlocks();
                    for (let i = 0; i < topBlocks.length; i++) {
                        if (topBlocks[i].id != this.state.blockToSave.id) {
                            topBlocks[i].dispose();
                        }
                    }

                    setTimeout(() => {
                        // see if an undo should be recorded
                        //
                        // an undo should be recorded in the following cases:
                        // - block dragged from parameter slot to empty slot in block definition
                        // - block dragged from parameter slot to shadow slot in block definition
                        // - block dragged from parameter slot to filled slot in block definition
                        // - block dragged from block definition to empty slot in block definition
                        // - block dragged from block definition to shadow slot in block definition
                        // - block dragged from block definition to filled slot in block definition
                        // - block dragged from shadow slot in block definition to empty area of workspace
                        // - block dragged from empty slot in block definition to empty area of workspace
                        //
                        // an undo should NOT be recorded in the following cases:
                        // - block dragged from parameter slot to empty area of workspace
                        // - block dragged from empty slot in block definition to the same slot
                        // - block dragged from shadow slot in block definition to the same slot
                        let record = true;
                        let eventList = this.state.eventList;

                        // if there is a Blockly.Events.UI event after the end block drag event but not
                        // immediately after it, the block was dragged from a parameter slot to an
                        // empty area of the workspace
                        let lastUiIndex = -1;
                        let lastEndDragIndex = -1;
                        for (let i = 0; i < eventList.length; i++) {
                            if (eventList[i].type === Blockly.Events.UI) {
                                lastUiIndex = i;
                            } else if (eventList[i].type === Blockly.Events.END_DRAG) {
                                lastEndDragIndex = i;
                            }
                        }
                        if (lastUiIndex - lastEndDragIndex > 1) {
                            record = false;
                        }

                        // check if a block was dragged from a slot in the block definition
                        // to the same slot
                        let blockId = blocklyEvent.blockId;
                        let firstMoveIndex = -1;
                        let lastMoveIndex = -1;
                        for (let i = 0; i < eventList.length; i++) {
                            if (eventList[i].type === Blockly.Events.MOVE &&
                                eventList[i].blockId === blockId) {
                                firstMoveIndex = firstMoveIndex < 0 ? i : firstMoveIndex;
                                lastMoveIndex = i;
                            }
                        }
                        if (eventList[firstMoveIndex].oldInputName === eventList[lastMoveIndex].newInputName &&
                            eventList[firstMoveIndex].oldParentId === eventList[lastMoveIndex].newParentId) {
                            record = false;
                        }

                        // set output type of argument block so that it cannot be dragged
                        // back into the block signature
                        let draggedBlock = workspace.getBlockById(blockId);
                        if (draggedBlock) {
                            this.lockArgumentBlock(draggedBlock);
                        }

                        setTimeout(() => {
                            if (record) {
                                this.recordUndo(this.state.blockToSave);
                            }
                        }, 0);

                        this.setState({eventList: []});
                    }, 0);
                    break;}
                case Blockly.Events.MOVE: {
                    let eventList = this.state.eventList;
                    if (eventList.length === 4) {
                        let blockId = eventList[1].blockId;
                        let block = workspace.getBlockById(blockId);
                        if (block) {
                            this.lockArgumentBlock(block);
                        }
                    }
                    break;}
            }
        })
    }

    // set input check so that argument blocks cannot be dragged back into the input
    lockInputs(block) {
        let inputList = block.inputList;
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i] && inputList[i].name.includes("INPUT_ARG") &&
                !inputList[i].name.includes("readonly") && inputList[i].connection &&
                inputList[i].connection.check_ && inputList[i].connection.check_.length > 0) {
                if (inputList[i].connection.check_.includes("Boolean")) {
                    inputList[i].setCheck("Argument_Boolean");
                } else if (inputList[i].connection.check_.includes("String")) {
                    inputList[i].setCheck("Argument_String_Number");
                }
            }
        }
    }

    // set output type of argument blocks nested inside the block so that they cannot
    // be dragged back into the block signature
    lockArgumentBlocksType(block) {
        let descendants = block.getDescendants();
        for (let i = 0; i < descendants.length; i++) {
            if (descendants[i].parentBlock_ && descendants[i].parentBlock_.id != block.id) {
                if (descendants[i].type === "boolean") {
                    descendants[i].setOutput(true, ["Boolean"]);
                } else if (descendants[i].type === "number_text") {
                    descendants[i].setOutput(true, ["String", "Number"]);
                }
            }
        }
    }

    // set output type of argument block so that it cannot be dragged back into the block signature
    lockArgumentBlock(block) {
        if (block.type === "boolean") {
            block.setOutput(true, ["Boolean"]);
        } else if (block.type === "number_text") {
            block.setOutput(true, ["String", "Number"]);
        }
    }

    // expands a block with a statement input
    expandBlock(block) {
        if (block.statementInput) {
            block.statementInput.setVisible(true);
            block.setVisible(true);
            setTimeout(() => {
                try {
                    block.initSvg();
                    block.render();
                    Blockly.getMainWorkspace().render();
                } catch (e) {
                    return;
                }
            }, 0);
        }
    }

    // updates the parameter list for a block that is newly generated
    updateParametersNewBlock(block) {
        this.setState({inputParameters: {}});
        let inputList = block.inputList;
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i] && inputList[i].name.includes("INPUT_ARG") && !inputList[i].name.includes("readonly")) {
                let inputBlock = inputList[i].connection.targetConnection.getSourceBlock();
                this._updateInputParaList(inputBlock.id, inputList[i], null,false)
            }
        }
    }

    // get all descendants of the block and set all statement blocks to immovable
    setDescendantsToImmovable(block) {
        let descendants = block.getDescendants();
        for (let i = 0; i < descendants.length; i++) {
            if (descendants[i].nextConnection && descendants[i].previousConnection) {
                descendants[i].setMovable(false);
            } else {
                descendants[i].setMovable(true);
            }
        }
    }

    // records a Blockly event to the event list
    recordEvent(event) {
        let eventList = this.state.eventList;
        eventList.push(event);
        this.setState({eventList: eventList});
    }

    // record the state of the block to the undo stack
    recordUndo(block) {
        let undoStack = this.state.undoStack;
        let blockDOM = Blockly.Xml.blockToDom(block);
        let domText = Blockly.Xml.domToText(blockDOM);
        undoStack.push(domText);
        this.setState({undoStack: undoStack, redoStack: []});
    }

    // restore the last state of the block from the undo stack
    restoreUndo(workspace) {
        // retrieve last state from undo stack
        let undoStack = this.state.undoStack;
        let redoStack = this.state.redoStack;
        if (undoStack.length > 1) {
            let mostRecentState = undoStack.pop();
            redoStack.push(mostRecentState);
        } else {
            return;
        }

        let lastState = undoStack[undoStack.length - 1];

        // delete current block and restore block from undo stack
        this.state.blockToSave.dispose();
        let blockDOM = Blockly.Xml.textToDom(lastState);
        var newBlock = Blockly.Xml.domToBlock(blockDOM, workspace);

        setTimeout(() => {
            this.setState({blockToSave: newBlock});
            this.updateParametersNewBlock(newBlock);
            this.expandBlock(newBlock);
            this.lockInputs(newBlock);
            this.lockArgumentBlocksType(newBlock);

            // always keep the initial state of the block to save in the stack
            if (undoStack.length === 0) {
                this.recordUndo(newBlock);
            }

            this.setState({eventList: []})
        }, 0);
    }

    // restore the last state of the block from the redo stack
    restoreRedo(workspace) {
        // retrieve last state from redo stack
        let undoStack = this.state.undoStack;
        let redoStack = this.state.redoStack;
        if (redoStack.length > 0) {
            let lastState = redoStack.pop();
            undoStack.push(lastState);

            // delete current block and restore block from undo stack
            this.state.blockToSave.dispose();
            let blockDOM = Blockly.Xml.textToDom(lastState);
            var newBlock = Blockly.Xml.domToBlock(blockDOM, workspace);

            setTimeout(() => {
                this.setState({blockToSave: newBlock});
                this.updateParametersNewBlock(newBlock);
                this.expandBlock(newBlock);
                this.lockInputs(newBlock);
                this.lockArgumentBlocksType(newBlock);
                this.setState({eventList: []})
            }, 0);
        }
    }

    // position undo and redo buttons
    positionUndoRedo() {
        if (document.getElementById("saveBlockWorkspace")) {
            let zoomControls = document.getElementById("saveBlockWorkspace").getElementsByClassName("blocklyZoom")[0];
            let zoomControlsRect = zoomControls.getBoundingClientRect();
            let undoRedo = document.getElementById("undoRedo");
            undoRedo.style.left = (zoomControlsRect.left + 13.5) + "px";
            undoRedo.style.top = (zoomControlsRect.top + 3) + "px";
        }
    }

    componentDidMount () {
        var workspace = this._createWorkspace();
        this._resizeWorkSpace(workspace);
        this._renderBlockToSave(workspace);

        setTimeout(() => {
            this._addInputBlockDragListener(workspace);
            this.setDescendantsToImmovable(this.state.blockToSave);
            this.positionUndoRedo();

            // expand block to save to see block definition and record its state to the undo stack
            let blockToSave = this.state.blockToSave;
            blockToSave.statementInput.setVisible(true);
            blockToSave.setVisible(true);
            setTimeout(() => {
                blockToSave.initSvg();
                blockToSave.render();
                workspace.scrollCenter();
                workspace.render();
            }, 0);
            this.recordUndo(this.state.blockToSave);

            // override undo through ctrl+Z
            workspace.MAX_UNDO = 0;
            this.keydown = false; // to prevent user from holding down ctrl+Z for multiple undos
            document.addEventListener("keydown", function(e) {
                if (e.key === "z" && e.ctrlKey) {
                    if (this.keydown) return;
                    this.keydown = true;
                    this.restoreUndo(workspace);
                } else if (e.key === "Z" && e.ctrlKey) {
                    if (this.keydown) return;
                    this.keydown = true;
                    this.restoreRedo(workspace);
                }
            }.bind(this));

            document.addEventListener("keyup", function() {
                this.keydown = false;
            }.bind(this));
        }, 100);

        window.onresize = () => {
            this.positionUndoRedo();
            this.setState({screenHeight: window.innerHeight});
            this.setState({screenWidth: window.innerWidth});
            this._resizeWorkSpace(this.state.workspace);
        };
    }

    componentWillUnmount() {
        this.state.workspace.dispose();
    }

    render()
    {
        const {workspace} = this.state;
        const { handleBack, handleNext } = this.props;

        return (
            <div className="modal-wrapper">
                <div className={"modal-header"}>
                    <h4>Save Block to Library</h4>
                    <br></br>
                    <div className={"icon"}>1</div>
                    <div className={"text-description"}>Block Details</div>
                    <div className={"straight-line"}>———</div>
                    <div className={"icon"}>2</div>
                    <div className={"text-description"}>Add Inputs</div>
                    <div className={"straight-line"}>———</div>
                    <div className={"icon-active"}>&emsp;</div>
                    <div className={"text-description-active"}>Assign Inputs</div>
                </div>
                <div id={"assignArea"} className={"modal-body"}>
                    <div id="saveBlockWorkspace" ref={this.mountDiv}></div>
                </div>
                <div className="modal-footer">
                    <ModalButtons step={3} back={handleBack}
                                  next={() => {
                                      // get all descendants of the block to save and set all statement blocks to movable
                                      let descendants = this.state.blockToSave.getDescendants();
                                      for (let i = 0; i < descendants.length; i++) {
                                          descendants[i].setMovable(true);
                                      }

                                      // reset input connection types
                                      let inputList = this.state.blockToSave.inputList;
                                      for (let i = 0; i < inputList.length; i++) {
                                          if (inputList[i].connection && inputList[i].connection.check &&
                                              inputList[i].connection.check_.length > 0) {
                                              if (inputList[i].connection.check_[0] === "Argument_String_Number") {
                                                  inputList[i].setCheck(["String", "Number"]);
                                              } else if (inputList[i].connection.check_[0] === "Argument_Boolean") {
                                                  inputList[i].setCheck(["Boolean"]);
                                              }
                                          }
                                      }

                                      handleNext(Blockly.Xml.workspaceToDom(workspace));
                                  }} />
                </div>
                <div id={"undoRedo"}>
                    <p>
                        <FontAwesomeIcon icon={faUndoAlt} className={"undoRedoIcon"} onClick={() => {this.restoreUndo(workspace)}}/>
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faRedoAlt} className={"undoRedoIcon"} onClick={() => {this.restoreRedo(workspace)}}/>
                    </p>
                </div>
            </div>
        );
    }
}

export default AssignArguments;
