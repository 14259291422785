import fieldDropdown from "./fields/field_dropdown";
import fieldWidgetsDropdown from "./fields/field_widgets_dropdown";
import fieldDropdownSearch from "./fields/field_dropdown_search";
import fieldDropdownSearchCategories from "./fields/field_dropdown_search_categories";

const initBlocklyOverrides =  function(){
    // these should be in order to manage dependency/inheritance (eventually make these es6 subclasses, for now goog inheritance)
    fieldDropdown();
    fieldDropdownSearch();
    fieldDropdownSearchCategories();
    fieldWidgetsDropdown();
    // fieldTime();
}

export default initBlocklyOverrides