/* global Blockly */
import React from 'react';

function defineBlock() {
    return {
        init: function () {
            this.appendDummyInput("Date")
                .appendField("date");
            this.appendValueInput("DAY")
                .setCheck("Number")
                .appendField("day");
            this.appendValueInput("MONTH")
                .appendField("month")
                .setCheck("Number");
            this.appendValueInput("YEAR")
                .appendField("year")
                .setCheck("Number");

            this.setOutput(true, "Date");
            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            this.setColour(Blockly.Msg.DATES_HUE);
            this.setTooltip('Create a date given the day, month, and year (eg. 25/1/1970 = Jan. 25th,1970)');
            this.setHelpUrl(Blockly.BASE_HELP_URL + '#widget-values');
        }
    }
}

function defineGenerators() {
    return {
        'JavaScript': function (block) {
            var year = Blockly.JavaScript.valueToCode(block, 'YEAR', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            var month = Blockly.JavaScript.valueToCode(block, 'MONTH', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            var day = Blockly.JavaScript.valueToCode(block, 'DAY', Blockly.JavaScript.ORDER_FUNCTION_CALL);
            var code = '(new Date(' + year + ', ' + month + ' - 1, ' + day + '))';
            return [code, Blockly.JavaScript.ORDER_NONE];
        }
    }
}

const DatePickerNumber = function (props) {
    const name = 'snap_date_picker_number';
    Blockly.Blocks[name] = defineBlock(props.context);
    let generators = defineGenerators(props.context);
    for (let i in generators) {
        Blockly[i][name] = generators[i]
    }


    return (
        <block type={name}>
            <value name="DAY">
                <shadow type="math_number"/>
            </value>
            <value name="MONTH">
                <shadow type="math_number"/>
            </value>
            <value name="YEAR">
                <shadow type="math_number"/>
            </value>
        </block>
    )
}
export default DatePickerNumber;

