import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";

export const externalDataIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E6E9E9;%7D .st1%7Bfill:%23E6E9E9;%7D%0A%3C/style%3E%3Cg id='Icons'%3E%3Cpath class='st0' d='M18,6v12H2V6H18 M19,4H1C0.4,4,0,4.4,0,5v14c0,0.5,0.4,1,1,1h18c0.5,0,1-0.5,1-1V5C20,4.4,19.5,4,19,4L19,4z' /%3E%3Cpath class='st0' d='M22.9,2H1.1C0.5,2,0,2.5,0,3.1v1.8C0,5.5,0.5,6,1.1,6h21.8C23.5,6,24,5.5,24,4.9V3.1C24,2.5,23.5,2,22.9,2 C22.9,2,22.9,2,22.9,2z'/%3E%3Cpath class='st1' d='M15,9H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S15.6,9,15,9L15,9z'/%3E%3Cpath class='st1' d='M15,13H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S15.6,13,15,13L15,13z'/%3E%3C/g%3E%3C/svg%3E%0A";

export default function ExternalData(props) {

    let widgets = util.widgetsOfType(props.context, 'dynamicdropdown');
    let newProps = Object.assign({}, props, { widgets });

    let components = [
        newProps.widgets.length ? <label text={"External Data"} /> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component {...newProps} key={id} />)
    ];

    return components;
}

