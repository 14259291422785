import React from 'react';
import util from "../../../../utils/es5Utils";
import _ from "underscore";
export const fileuploadIcon = "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 30'%3E%3Cpath transform='scale(0.05,-0.05) translate(0,-770)' d='M813.056 450.389c-2.127 132.209-109.801 238.569-242.317 238.569-86.704 0-162.773-45.532-205.601-113.989l-0.593-1.017c-16.207 13.7-37.339 22.028-60.416 22.028-51.841 0-93.867-42.026-93.867-93.867 0-0.064 0-0.128 0-0.192v0.010c0-0.044 0-0.095 0-0.147 0-11.475 2.129-22.453 6.013-32.56l-0.21 0.621c-71.577-4.886-127.787-64.145-127.787-136.533 0-75.571 61.262-136.833 136.833-136.833 3.183 0 6.34 0.109 9.468 0.322l-0.423-0.023h253.611v170.667l-50.859-45.056c-4.218-3.507-9.691-5.636-15.661-5.636-7.35 0-13.946 3.226-18.449 8.34l-0.023 0.027c-3.674 4.18-5.915 9.697-5.915 15.738 0 7.382 3.347 13.982 8.607 18.365l0.038 0.031 90.112 76.8c4.149 3.565 9.586 5.736 15.531 5.736s11.382-2.171 15.562-5.763l-0.032 0.027 91.819-76.459c5.458-4.478 8.912-11.224 8.912-18.776 0-13.384-10.85-24.235-24.235-24.235-0.133 0-0.266 0.001-0.399 0.003h0.020c-5.891 0.064-11.26 2.25-15.39 5.828l-51.17 42.642v-168.96h268.288c66.145 5.262 117.829 60.235 117.829 127.28 0 64.025-47.133 117.042-108.594 126.245l-0.701 0.086z'/%3E%3C/svg%3E";

export default function FileUpload(props) {
    let widgets = util.widgetsOfType(props.context, 'fileupload');
    let newProps = Object.assign({}, props, { widgets });

    let components = [
        newProps.widgets.length > 0 ? <label text={"File Uploads"}/> : null,
        ..._.values(props.childComponents).map((Component, id) => <Component.component { ...newProps } key = {id} />)
    ];
    return components;
}
